import React from "react";
import { Container, Row, Col } from "reactstrap";
import funderLogo from "../../assets/images/funder-logos.png";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ height: "168px" }}>
        <Container fluid={true}>
          {/* <Row className="d-flex justify-content-center">
            <Col md={6} className="mb-4 d-flex justify-content-center">
              <img src={funderLogo} width="400px" />
            </Col>
          </Row> */}
          <Row>
            <Col
              md={4}
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              className="my-3"
            >
              <p className="m-0">© 2021 MTML. All rights reserved.</p>
              <p className="m-0">
                Charitable Registration Number: 13226 9572 RR0001
              </p>
              <p className="m-0">
                <a href="https://www.mtml.ca" target="_blank" rel="noreferrer">
                  www.mtml.ca
                </a>
              </p>
              <p className="m-0">
                <a
                  href="https://drive.google.com/file/d/1UO3oq6XKNBHX3IcXSc12jNM8TApFr-Td/view"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read our Privacy Policy.
                </a>
              </p>
            </Col>
            <Col md={4} style={{ textAlign: "center" }} className="my-3">
              <img src={funderLogo} width="250px" />
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-center justify-content-lg-end align-items-center my-3"
            >
              <div className="d-flex flex-row">
                <div
                  className="mx-2"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <a
                    href="https://www.google.com/url?q=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fmtml-metro-toronto-movement-for-literacy-219424204%2F&sa=D&sntz=1&usg=AOvVaw06pLLESozAiT98uCBq0Ig0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="avatar-title rounded bg-primary"
                      style={{ fontSize: "30px" }}
                    >
                      <i className="mdi mdi-linkedin text-white" />
                    </span>
                  </a>
                </div>
                <div
                  className="mx-2"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <a
                    href="https://www.google.com/url?q=https%3A%2F%2Fm.facebook.com%2FMTMLiteracy%2F&sa=D&sntz=1&usg=AOvVaw1FnK2ubNT5qamQ4m0h5OEN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="avatar-title rounded bg-primary"
                      style={{ fontSize: "30px" }}
                    >
                      <i className="mdi mdi-facebook text-white" />
                    </span>
                  </a>
                </div>
                <div
                  className="mx-2"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <a
                    href="https://www.google.com/url?q=https%3A%2F%2Ftwitter.com%2Fmtmltorontoyork%3Flang%3Den&sa=D&sntz=1&usg=AOvVaw0yuHYq8nxO6czx4eibg9SY"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="avatar-title rounded bg-primary"
                      style={{ fontSize: "30px" }}
                    >
                      <i className="mdi mdi-twitter text-white" />
                    </span>
                  </a>
                </div>
                <div
                  className="mx-2"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <a
                    href="https://www.youtube.com/channel/UC8LRYBpgwYNTEwQnAqm8JOA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="avatar-title rounded bg-primary"
                      style={{ fontSize: "30px" }}
                    >
                      <i className="mdi mdi-youtube text-white" />
                    </span>
                  </a>
                </div>
                <div
                  className="ms-2"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <a
                    href="mailto:info@mtml.ca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="avatar-title rounded bg-primary"
                      style={{ fontSize: "30px" }}
                    >
                      <i className="mdi mdi-email text-white" />
                    </span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center flex-wrap mt-4">
              <p style={{ textAlign: "center" }}>
                This Employment Ontario project is funded in part by the
                Government of Canada and the Government of Ontario.
              </p>
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
