import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import NotificationItem from "components/Common/NotificationItem";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPagedNotifications } from "../../../store/notifications/actions";

//i18n
import { withTranslation } from "react-i18next";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      intervalId: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  componentDidMount() {
    this.props.getPagedNotifications({ page: 1, pageSize: 1000 }); //Make this actually page in the future.
    const intervalId = setInterval(() => {
      this.props.getPagedNotifications({ page: 1, pageSize: 1000 });
    }, 60000);

    //Save intervalId
    this.setState(prevState => {
      return {
        intervalId,
      };
    });
  }

  componentWillUnmount() {
    if (this.state.intervalId > 0) {
      clearInterval(this.state.intervalId);
    }
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  renderItems(items) {
    if (!items) {
      return null;
    }

    const notifications = items.map(item => (
      <NotificationItem
        key={item.id}
        title={item.title}
        content={item.content}
        link={item.link}
        severity={item.severity}
        dateCreated={new Date(item.createdDate)}
      />
    ));
    return <Fragment>{notifications}</Fragment>;
  }

  render() {
    const { items, itemCount } = this.props;

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada" />
            <span className="badge bg-danger rounded-pill">{itemCount}</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>
                <div className="col-auto">
                  {/* <a href="#" className="small">
                    {" "}
                    View All
                  </a> */}
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "500px" }}>
              {items &&
                items.map(item => (
                  <NotificationItem
                    key={item.id}
                    title={item.title}
                    content={item.content}
                    link={item.link}
                    severity={item.severity}
                    dateCreated={new Date(item.createdDate)}
                  />
                ))}
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              {/* <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to="#"
                onClick={() => console.log("click")}
              >
                <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                <span key="t-view-more">{this.props.t("View More..")}</span>
              </Link> */}
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
  notifications: PropTypes.any,
  getPagedNotifications: PropTypes.func,
  items: PropTypes.array,
  itemCount: PropTypes.number,
};

const mapStateToProps = ({ notifications }) => {
  return notifications;
};

export default withRouter(
  connect(mapStateToProps, { getPagedNotifications })(
    withTranslation()(NotificationDropdown)
  )
);
