import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardTitle, Col, Collapse, Row } from "reactstrap";

const MiniLesson = ({ item }) => {
  const [isExpanding, setIsExpanding] = useState(true);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  return (
    <div className="accordion mb-3 bg-white" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.name}</CardTitle>
          </button>
        </h2>

        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <Row>
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <p className="card-title-desc">{item.name}</p>
                  <div>
                    <a
                      href={item.link}
                      target="_blank"
                      rel={"noreferrer"}
                      className="w-lg btn btn-outline-primary btn-sm"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

MiniLesson.propTypes = {
  item: PropTypes.object,
};

export default MiniLesson;
