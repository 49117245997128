import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
} from "reactstrap";
import moment from "moment";
import { sumBy } from "lodash";
import Rating from "react-rating";

export class BidOfferCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      bidServices: [],
    };
  }

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleAccept = () => {
    const { bidServices } = this.state;
    const { id } = this.props.bid;
    this.props.onConfirmAccept({ id, bidServices });
  };

  handleBookNow = () => {
    const { id } = this.props.bid;
    this.props.onConfirmBookNow(id);
  };

  componentDidMount() {
    const { bid } = this.props;
    if (bid) {
      const { bidServices } = bid;
      this.setState({
        bidServices,
      });
    }
  }

  handleChange = e => {
    const { value, checked } = e.target;
    const { bidServices } = this.state;

    var bidService = bidServices.find(e => e.id === Number(value));
    this.setState(prevState => ({
      bidServices: prevState.bidServices.map(a => {
        return a.id === bidService.id
          ? {
              ...bidService,
              isAcceptService: checked,
            }
          : a;
      }),
    }));
  };

  render() {
    const {
      note,
      statusName,
      company,
      phone,
      email,
      starRating,
      distance,
      address,
      expiryDate,
    } = this.props.bid;

    const { isOpen, bidServices } = this.state;
    let totalPrice = sumBy(
      bidServices.filter(e => e.isAcceptService),
      a => {
        return a.price;
      }
    );

    return (
      <Card
        className={
          "mb-2 border " + (statusName === "Accepted" ? "border-success" : "")
        }
      >
        <CardHeader
          className="border-bottom"
          style={{ cursor: "pointer" }}
          onClick={this.toggleBody}
        >
          <Row>
            <Col md={4}>
              {statusName === "Accepted" ? (
                <>
                  <h5 className="font-size-14 mb-1">{company ?? "Unknown"}</h5>
                  <p className="text-muted mb-0">{phone ?? "Unknown"}</p>
                  <p className="text-muted mb-0">{email ?? "Unknown"}</p>
                  <p className="text-muted mb-0">{address ?? "Unknown"}</p>
                  <p className="text-muted mb-0">
                    {Number(distance).toFixed(2)} Km
                  </p>
                </>
              ) : (
                <>
                  <h5 className="font-size-14 mb-1">Distance</h5>
                  <p className="text-muted mb-0">
                    {Number(distance).toFixed(2)} Km
                  </p>
                </>
              )}
            </Col>

            <Col md={4}>
              <h5 className="font-size-14 mb-1">Google Rating</h5>
              <Rating
                max={5}
                initialRating={starRating}
                emptySymbol="mdi mdi-star-outline text-primary fa-1x"
                fullSymbol="mdi mdi-star text-primary fa-1x"
                fractions={2}
              />
            </Col>
            <Col md={4}>
              <h5 className="font-size-14 mb-1">Expires By</h5>
              <p className="text-muted mb-0">
                {moment(expiryDate).format("DD-MMM-YYYY")}
              </p>
            </Col>
          </Row>
          <div style={{ position: "absolute", top: "12px", right: "12px" }}>
            <span className="font-size-24">
              <i
                className={isOpen ? "fas fa-angle-up" : "fas fa-angle-down"}
              ></i>
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            <Row>
              <Col md={8}>
                <table className="table align-middle mb-0 table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Bid</th>
                      <th>Avg</th>
                      <th>Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bidServices &&
                      bidServices.map((bidService, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Input
                                type="checkbox"
                                name="isAcceptService"
                                checked={bidService.isAcceptService}
                                value={bidService.id}
                                className="form-check-input"
                                onChange={this.handleChange}
                              />
                            </td>
                            <td>
                              <p className="font-size-14 mb-0">
                                {bidService.name}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                ${Number(bidService.price).toFixed(2)}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                ${Number(bidService.average).toFixed(2)}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">{bidService.rank}</p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Col>
              <Col md={4}>
                <h5>Benefits</h5>
                <p>{note}</p>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="bg-transparent border-bottom">
            <Row>
              <Col md={12} className="text-end">
                {statusName === "Offered" && (
                  <Button color="primary" onClick={this.handleAccept}>
                    <i className="fas fa-check"></i> Accept
                  </Button>
                )}{" "}
                {statusName === "Accepted" && (
                  <>
                    <Button
                      outline
                      disabled
                      color="primary"
                      // onClick={this.handleBookNow}
                    >
                      <i className="fas fa-calendar-check"></i> Accepted
                    </Button>{" "}
                    {/* <Button outline color="info">
                      <i className="fas fa-ban"></i> Cancel
                    </Button> */}
                  </>
                )}
              </Col>
            </Row>
          </CardFooter>
        </Collapse>
      </Card>
    );
  }
}

BidOfferCard.propTypes = {
  isAccept: PropTypes.bool,
  bid: PropTypes.object,
  onConfirmAccept: PropTypes.func,
  onConfirmBookNow: PropTypes.func,
};

export default BidOfferCard;
