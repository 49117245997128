import Information from "containers/sidebar/Information";
import MoreModule from "containers/sidebar/MoreModule";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";

export class FeedbackForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile } = match.params;
    this.setState({
      profile,
    });
  };

  render() {
    const { profile } = this.state;

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container fluid>
            <Row>
              <Col md={8}>
                <div className="mt-5 mb-5 text-center">
                  <h1 className="mb-4">Feedback Form</h1>
                  <p>Your feedback matters to us.</p>
                  <p>
                    Please fill in this form after you have used any material
                    (video, tip sheets, or mini lessons).
                  </p>
                  <p>
                    If you have any difficulty viewing this page on your phone,
                    please click or tap HERE to complete the survey.
                  </p>
                </div>
                {profile === "service-providers" ? (
                  <div className="text-center mb-5">
                    <iframe
                      height={"1000px"}
                      width="640"
                      src="https://forms.gle/38eJjLLWavbXCF4g7"
                    ></iframe>
                  </div>
                ) : (
                  <div className="text-center mb-5">
                    <iframe
                      height={"1000px"}
                      width="640"
                      src="https://forms.gle/NNpaxwCpXLbTEFko8"
                    ></iframe>
                  </div>
                )}
              </Col>
              <Col md={4}>
                <Information profile={this.state.profile} />
                <MoreModule profile={this.state.profile} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

FeedbackForm.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default FeedbackForm;
