import Information from "containers/sidebar/Information";
import MoreModule from "containers/sidebar/MoreModule";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardFooter,
  CardHeader,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import ruth from "../assets/images/ruth.jpg";
import ryan from "../assets/images/ryan-pike.jpg";

export class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: "",
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile } = match.params;
    this.setState({
      profile,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container fluid>
            <Row>
              <Col md={8}>
                <div className="mt-5 mb-5 text-center">
                  <h1 className="mb-4">Contact Us</h1>
                </div>
                <div className="d-flex flex-column justify-content-center mb-5 text-center">
                  <p>
                    <a href="https://www.mtml.ca" target="_blanks">
                      www.mtml.ca
                    </a>
                  </p>
                  <p>email: info@mtml.ca</p>
                  <p>LAN# 416-961-5557</p>
                </div>
                <div className="text-center mb-5">
                  <p>
                    Not Sure who to contact? Please fill out our contact form
                    below.
                  </p>
                  <iframe
                    height={"800"}
                    width="640"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSf7fxL4DPBHtGQ1edDkxUk_SCDr_0PkQzEMagvqSkKlahJUqQ/viewform?embedded=true"
                  ></iframe>
                </div>
              </Col>

              <Col md={4}>
                <Information profile={this.state.profile} />
                <MoreModule profile={this.state.profile} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ContactUs.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ContactUs;
