import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Alert } from "reactstrap";

export class ConfirmBookNowModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isOpen, toggle, onSubmit, title } = this.props;
    return (
      <Modal
        size="md"
        backdrop="static"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Alert color="info" role="alert">
            Are you sure want to book now ?
          </Alert>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={onSubmit}>
            <i className="fas fa-calendar-check"></i> Book Now
          </Button>

          <Button outline data-dismiss="modal" color="primary" onClick={toggle}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

ConfirmBookNowModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  onSignup: PropTypes.func,
  error: PropTypes.string,
};

export default ConfirmBookNowModal;
