import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const IconEdit = ({ imageSrc, width, onChange, value, appName }) => (
  <div
    key={appName}
    style={{
      maxWidth: "200px",
      // border: "1px solid black",
      alignItems: "center",
    }}
    className="d-flex flex-column"
  >
    <img style={{ width: width, height: "98px" }} src={imageSrc} />

    <div
      style={{
        width: "100%",
        // border: "1px solid purple",
        padding: "0 1rem",
      }}
      className="mb-3"
    >
      <Input
        style={{ width: "100%" }}
        id="menuWords"
        name="menuWords"
        type="text"
        className={
          value &&
          value.length > 0 &&
          value.toLowerCase() !== appName.toLowerCase()
            ? "is-invalid"
            : ""
        }
        placeholder="Enter app name"
        value={value}
        onChange={e => {
          e.preventDefault();
          console.log(e);
          onChange(e.target.value);
        }}
      />
      {value &&
        value.length > 0 &&
        value.toLowerCase() !== appName.toLowerCase() && (
          <div className="invalid-feedback-custom">Incorrect app name</div>
        )}
    </div>
  </div>
);

IconEdit.propTypes = {
  imageSrc: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  appName: PropTypes.string,
  width: PropTypes.string,
};

export { IconEdit };
