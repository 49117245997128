import { get, post, del, put as update } from "helpers/api_helper";
const findPlaceFromTextRequest = async query => {
  return await get("/api/GoogleMapAPI/findPlaceFromText", { params: query });
};

const getPlaceAutocompleteRequest = async query => {
  return await get("/api/GoogleMapAPI/placeAutocomplete", { params: query });
};

export const googleMapService = {
  findPlaceFromTextRequest,
  getPlaceAutocompleteRequest,
};
