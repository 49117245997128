import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import Rating from "react-rating";
import { withResponsive } from "components/Common/ResponsiveHoc";

export class VendorsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  render() {
    const { isMobile } = this.props;
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onChangePage,
      onPageSizeChange,
      onRating,
    } = this.props;
    return (
      <Card style={{ backgroundColor: isMobile ? "transparent" : "white" }}>
        <CardBody className={isMobile ? "p-0" : ""}>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table className="table table-striped table-nowrap table-bordered align-middle border-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company</th>
                      <th>Shop Type</th>
                      <th>Contact</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Rating</th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <p>{item.id}</p>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <p className="text-muted mb-0">
                                {item.companyName}
                              </p>
                            </div>
                          </td>
                          <td>{item.shopType}</td>
                          <td>
                            <div>
                              <p>{item.contactName}</p>
                            </div>
                          </td>
                          <td>
                            <p>{item.phone}</p>
                          </td>
                          <td>
                            <p>{item.email}</p>
                          </td>
                          <td>{item.address}</td>
                          <td>
                            <Rating
                              max={5}
                              initialRating={item.starRating}
                              emptySymbol="mdi mdi-star-outline text-primary fa-2x"
                              fullSymbol="mdi mdi-star text-primary fa-2x"
                              fractions={2}
                            />
                          </td>
                          <td>
                            <div className="text-end">
                              <Button
                                size="sm"
                                className="me-1"
                                outline
                                disabled={item.isVerified}
                                color={
                                  item.isVerified ? "secondary" : "success"
                                }
                                onClick={() => {
                                  this.props.onVerifyUser(item.id);
                                }}
                              >
                                {item.isVerified ? "Verified" : "Verify"}
                              </Button>
                              <Button
                                color="info"
                                size="sm"
                                outline
                                onClick={() => onRating(item.id)}
                              >
                                Rating
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

VendorsTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onBidDetail: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isMobile: PropTypes.bool,
  onVerifyUser: PropTypes.func,
  onRating: PropTypes.func,
};

export default withResponsive(VendorsTable);
