import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Alert, FormGroup, Label, Input } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { getProfile } from "helpers/utils";

export class ConfirmAcceptBidModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preferedContactMethod: "",
      phone: "",
      checkBoxPhone: false,
      checkBoxEmail: false,

      submitted: false,
      profile: null,
    };
  }

  componentDidMount() {
    let profile = getProfile();

    if (profile) {
      this.setState({
        phone: profile.phone,
      });
    }
    this.setState({
      profile,
    });
  }

  handleCheckChange = e => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });

    let method = "";

    switch (name) {
      case "checkBoxPhone":
        method = checked ? "Phone" : "";
        break;
      case "checkBoxEmail":
        method = checked ? "Email" : "";
        break;
    }

    this.setState({
      preferedContactMethod: method,
    });
  };

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  isOpen = () => {
    this.setState({
      submitted: false,
    });
  };

  handleSubmit = () => {
    const { profile, phone, checkBoxEmail, checkBoxPhone } = this.state;

    this.setState({
      submitted: true,
    });

    let contactMethods = [];

    if (checkBoxEmail) {
      contactMethods.push("Email");
    }

    if (checkBoxPhone) {
      contactMethods.push("Phone");
    }

    const data = {
      preferedContactMethod: contactMethods.join("|"),
      phone: profile.phone === "" ? this.state.phone : profile.phone,
    };

    let isValid =
      (checkBoxEmail && !checkBoxPhone && !phone) ||
      (!checkBoxEmail && checkBoxPhone && phone) ||
      (checkBoxEmail && checkBoxPhone && phone);
    if (isValid) {
      console.log(data);
      this.props.onSubmit(data);
    }
  };

  render() {
    const { checkBoxEmail, checkBoxPhone, submitted, profile } = this.state;
    const { isOpen, toggle, title } = this.props;

    return (
      <Modal
        size="md"
        backdrop="static"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <FormGroup>
            <Label>What is your preferred method of contact?</Label>
            <div
              className={
                "form-check mb-2 " +
                (submitted && !this.state.preferedContactMethod
                  ? "is-invalid"
                  : "")
              }
            >
              <Input
                type="checkbox"
                name="checkBoxPhone"
                className="form-check-Input"
                value={"Phone"}
                checked={checkBoxPhone}
                id="horizontal-customCheck"
                onChange={this.handleCheckChange}
              />
              <Label
                className="form-check-label"
                htmlFor="horizontal-customCheck"
              >
                By Phone
              </Label>
            </div>
            <div className="form-check mb-4">
              <Input
                type="checkbox"
                name="checkBoxEmail"
                checked={checkBoxEmail}
                className="form-check-Input"
                id="horizontal-customCheck"
                onChange={this.handleCheckChange}
              />
              <Label
                className="form-check-label"
                htmlFor="horizontal-customCheck"
              >
                By Email
              </Label>
            </div>
            {submitted && !(checkBoxPhone || checkBoxEmail) && (
              <div className="invalid-feedback-custom">
                Preferred method of contact is required.
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Please enter phone number</Label>
            <Input
              type="tel"
              name="phone"
              placeholder="Enter Phone Number"
              className={
                "form-control" +
                (submitted && checkBoxPhone && !this.state.phone
                  ? " is-invalid"
                  : "")
              }
              value={this.state.phone}
              onChange={this.handleOnChange}
            ></Input>
            {submitted && checkBoxPhone && !this.state.phone && (
              <div className="invalid-feedback-custom">Phone is request.</div>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            onClick={this.handleSubmit}
            // onClick={onSubmit}
          >
            Submit
          </Button>

          <Button outline data-dismiss="modal" color="primary" onClick={toggle}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

ConfirmAcceptBidModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  onSignup: PropTypes.func,
  error: PropTypes.string,
};

export default ConfirmAcceptBidModal;
