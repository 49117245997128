import React, { Component } from "react";
import PropTypes from "prop-types";
import moduleThumbnail from "../assets/images/module-thumbnail.png";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import { modules } from "common/data/modules";
let tranerGuide =
  "https://mtml.s3.ca-central-1.amazonaws.com/guides/Revised+April+2023+-+SP+GUIDE.pdf";

export class SearchModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      profileLabel: "",
      items: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile } = match.params;
    this.setState({
      profile: profile,
      profileLabel: profile === "learner" ? "Learners" : "Service Providers",
      items: modules,
    });
  };

  handleSearch = e => {
    const { value } = e.target;
    this.setState({
      items: modules.filter(e => e.name.toLocaleLowerCase().includes(value)),
    });
  };

  handleOnClick = id => {
    const { profile } = this.state;
    const { history } = this.props;
    history.push(`/${profile}/module/${id}`);
  };

  handleClickServiceProviderDetail = () => {
    const { history } = this.props;
    history.push("/service-providers/service-provider-guide");
  };

  handleDownloadTrainerGuide = () => {
    window.open(tranerGuide, "_blank");
    window.focus();
  };

  render() {
    const { items } = this.state;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="d-flex justify-content-center account-pages my-5 pt-sm-5">
          <Container className="nice-container">
            <div className="d-flex justify-content-center">
              <div style={{ width: "600px" }}>
                <Row>
                  <Col md={12}>
                    <div className="mt-5 mb-5 text-center">
                      <h1>{this.state.profileLabel}</h1>
                      <p style={{ fontSize: "1rem" }}>
                        Choose a topic to learn about your smartphone
                      </p>
                      <div className="d-flex justify-content-center pt-2">
                        <Input
                          type="search"
                          className="search-input-hero"
                          placeholder="Search ..."
                          onKeyUp={e => this.handleSearch(e)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {this.state.profile === "service-providers" && (
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center mb-5">
                    <div style={{ width: "800px" }}>
                      <Row>
                        <Col md={6}>
                          <Card
                            className="card-hover-border"
                            style={{ cursor: "pointer", height: "330px" }}
                            onClick={this.handleClickServiceProviderDetail}
                          >
                            <CardImg
                              height={"280px"}
                              src="https://img.youtube.com/vi/jEXM96mGGCc/sddefault.jpg"
                            ></CardImg>

                            <CardFooter className="bg-transparent">
                              <CardTitle>Short Video Guide</CardTitle>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card
                            className="card-hover-border"
                            style={{ cursor: "pointer", height: "330px" }}
                            onClick={this.handleDownloadTrainerGuide}
                          >
                            <CardImg
                              height={"280px"}
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/trainer's-guide.png"
                            ></CardImg>
                            <CardFooter className="bg-transparent">
                              <CardTitle>
                                {`Downloadable Trainer's Guide`}
                              </CardTitle>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              {items.map((item, index) => {
                return (
                  <Col md={3} key={index}>
                    <Card
                      className="rounded card-hover-border"
                      onClick={() => this.handleOnClick(item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardImg
                        className="img-fluid rounded"
                        src={item.imageUrl}
                        alt="Skote"
                      />
                      <CardFooter className="px-0">
                        <CardTitle className="text-truncate-2lines font-size-20">
                          {item.name}
                        </CardTitle>
                        <CardSubtitle>
                          {item.partnerName}
                          <i className="fas fa-check-circle font-size-14 ms-2"></i>
                        </CardSubtitle>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

SearchModule.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default SearchModule;
