import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";
import { Auth } from "aws-amplify";
import { get, post, del } from "../../../helpers/api_helper";

import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postSocialLogin } from "../../../helpers/fakebackend_helper";
import { role } from "constants/common";

const cognitoLoginUser = async (username, password) => {
  return Auth.signIn(username, password);
};

const loginUserRequest = query => get("/api/Account/login", { params: query });

function* loginUser({ payload: { user, history } }) {
  try {
    yield call(cognitoLoginUser, user.username, user.password);
    Auth.currentSession().then(res => {
      let newToken = res.getIdToken().getJwtToken();
      const { payload } = res.getIdToken();
      loginUserRequest({
        username: payload.sub,
      }).then(data => {
        const { roleNames } = data;
        localStorage.setItem("userProfile", JSON.stringify(data));
        localStorage.setItem("token", newToken);
        console.log(role.CUSTOMER);
        if (roleNames.some(r => r == role.CUSTOMER)) {
          history.push("/service-request");
        } else if (roleNames.some(r => r == role.VENDOR)) {
          history.push("/bid-offer");
        } else {
          history.push("/dashboard");
        }
      });
    });
  } catch (err) {
    const { message } = err;
    const errMsg =
      message ?? err.response?.data?.message ?? "Something was wrong";
    yield put(apiError(errMsg));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

export { loginUserRequest };
