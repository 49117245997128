import SelectionBox from "components/Common/SelectionBox";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import MiniLessonAndroidStepForm from "./MiniLessonAndroidStepForm";

import MiniLessonStepForm from "./MiniLessonStepForm";

const CreateHotspot = () => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isCorrect, setIsCorrect] = useState(null);

  const [whatIsCellularData1, setWhatIsCellularData1] = useState(null);
  const [whatIsCellularData2, setWhatIsCellularData2] = useState(null);
  const [whatIsCellularData3, setWhatIsCellularData3] = useState(null);

  const [whatIsAMobileHotspot1, setWhatIsAMobileHotspot1] = useState(null);
  const [whatIsAMobileHotspot2, setWhatIsAMobileHotspot2] = useState(null);

  const [whatIsWiFiHotspot1, setWhatIsWiFiHotspot1] = useState(null);
  const [whatIsWiFiHotspot2, setWhatIsWiFiHotspot2] = useState(null);
  const [yourTurn, setYourTurn] = useState("");

  const [selectedSmartphone, setSelectedSmartphone] = useState(null);
  const [selectedSmartphoneSubmitted, setSelectedSmartphoneSubmitted] =
    useState(false);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 8) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setActiveTab(2);
  };

  const handleOnClick = (name, value) => {
    switch (name) {
      case "whatIsCellularData1":
        setWhatIsCellularData1(value);
        break;
      case "whatIsCellularData2":
        setWhatIsCellularData2(value);
        break;
      case "whatIsCellularData3":
        setWhatIsCellularData3(value);
        break;

      case "whatIsAMobileHotspot1":
        setWhatIsAMobileHotspot1(value);
        break;
      case "whatIsAMobileHotspot2":
        setWhatIsAMobileHotspot2(value);
        break;

      case "whatIsWiFiHotspot1":
        setWhatIsWiFiHotspot1(value);
        break;
      case "whatIsWiFiHotspot2":
        setWhatIsWiFiHotspot2(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>Using your phone to create a hotspot</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href="https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/lessons/LAMP-Create+Username+_+Password_SL.pdf"
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div
                    style={{ height: "25rem" }}
                    className="center-container content-text"
                  >
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>True or False: Cellular Data</strong>
                      </p>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              1. Cellular data is a connection to the internet
                              through cell towers that sends out a cellular
                              signal to smartphones.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsCellularData1 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsCellularData1", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsCellularData1 === "false" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsCellularData1", "false")
                                }
                              />
                            </div>
                          </div>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              2. Cellular data is not free. You pay for a
                              cellular data plan with telecommunication
                              companies such as Rogers, Koodo, Telus, Bell,
                              Fido.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsCellularData2 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsCellularData2", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsCellularData2 === "false" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsCellularData2", "false")
                                }
                              />
                            </div>
                          </div>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              3. Cellular data is measured by bytes. Two common
                              measurements are Megabytes and Gigabytes.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsCellularData3 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsCellularData3", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsCellularData3 === "false" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsCellularData3", "false")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>What is a Mobile Hotspot? </strong>
                      </p>
                      <Row>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              1. Uses cellular data on your phone to connect a
                              computer or tablet to the internet at places that
                              do not have a Wi-Fi connection to the internet
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsAMobileHotspot1 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsAMobileHotspot1", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsAMobileHotspot1 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "whatIsAMobileHotspot1",
                                    "false"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              2. Can not be used when you are on the move such
                              as public transit, in vehicles or anywhere outside
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsAMobileHotspot2 === "true"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsAMobileHotspot2", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsAMobileHotspot2 === "false"
                                    ? true
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "whatIsAMobileHotspot2",
                                    "false"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>What is Wi-Fi Hotspot?</strong>
                      </p>
                      <Row>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              1. Uses cellular data on your phone to connect a
                              computer or tablet to the internet at places that
                              do not have a Wi-Fi connection to the internet
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsWiFiHotspot1 === "true" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsWiFiHotspot1", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsWiFiHotspot1 === "false" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsWiFiHotspot1", "false")
                                }
                              />
                            </div>
                          </div>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              2. A private location that has Wi-Fi such as your
                              home
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={
                                  whatIsWiFiHotspot2 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsWiFiHotspot2", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={
                                  whatIsWiFiHotspot2 === "false" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick("whatIsWiFiHotspot2", "false")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={5}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>
                          Your turn: In your own words describe Cellular Data,
                          Mobile Hotspot, and Wi-Fi Hotspot.
                        </strong>
                      </p>
                      <Row>
                        <Col md={12} className="mb-4">
                          <Input
                            id="lastName"
                            name="lastName"
                            type="textarea"
                            rows={4}
                            placeholder="Enter Your Last Name"
                            value={yourTurn}
                            onChange={e => {
                              const { value } = e.target;
                              setYourTurn(value);
                            }}
                          />
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={6}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Row>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              <strong>
                                Are you using Android or iPhone Smartphone?
                              </strong>
                            </Label>
                            <div
                              className={
                                selectedSmartphoneSubmitted &&
                                !selectedSmartphone
                                  ? "is-invalid"
                                  : ""
                              }
                            >
                              <SelectionBox
                                title="A"
                                name=""
                                value="Android"
                                isCorrect={
                                  selectedSmartphone === "Android" ? true : null
                                }
                                onClick={() => setSelectedSmartphone("Android")}
                              />
                              <SelectionBox
                                title="B"
                                value="iPhone"
                                isCorrect={
                                  selectedSmartphone === "iPhone" ? true : null
                                }
                                onClick={() => setSelectedSmartphone("iPhone")}
                              />
                            </div>
                            {selectedSmartphoneSubmitted &&
                              !selectedSmartphone && (
                                <div className="invalid-feedback-custom">
                                  Select your smartphone is required.
                                </div>
                              )}
                          </div>
                          <div className="mb-5">
                            <Button
                              style={{
                                width: "fit-content",
                                // marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                setSelectedSmartphoneSubmitted(true);
                                if (selectedSmartphone) {
                                  toggleTab(activeTab + 1);
                                }
                              }}
                            >
                              <span>Next Activity</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={7}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      {selectedSmartphone === "Android" && (
                        <MiniLessonAndroidStepForm />
                      )}
                      {selectedSmartphone === "iPhone" && (
                        <MiniLessonStepForm />
                      )}
                      <Row>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                  setSelectedSmartphone(null);
                                  setSelectedSmartphoneSubmitted(false);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={8}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col lg="12">
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <p>
                              <strong>
                                Congratulation! you have successfully finished
                                the lesson!
                              </strong>
                            </p>

                            <p className="text-muted">
                              If you would like to try and create a new click on
                              the Start Lesson again button to start over.
                            </p>
                          </div>
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <span>Restart Lesson</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CreateHotspot;
