import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  Input,
} from "reactstrap";

const DownloadModuleCard = ({ module, onCheckChange }) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const { name, checked, checkedVideo, checkedTipSheet, checkedMiniLession } =
    module;

  const toggleCollapse = value => {
    setIsCollapse(!value);
  };

  const handleOnCheckChange = e => {
    const { name, checked } = e.target;
    switch (name) {
      case "checkAll":
        module.checked = checked;
        module.checkedVideo = checked;
        module.checkedTipSheet = checked;
        module.checkedMiniLession = checked;
        break;
      case "checkedVideo":
        module.checkedVideo = checked;
        break;
      case "checkedTipSheet":
        module.checkedTipSheet = checked;
        break;
      case "checkedMiniLession":
        module.checkedMiniLession = checked;
        break;
    }

    onCheckChange(module);
  };

  return (
    <Card className="mb-1">
      <CardHeader className="bg-transparent border-bottom border-top d-flex justify-content-between px-4">
        <CardTitle>{name}</CardTitle>
        <div>
          <Input
            type="checkbox"
            checked={checked}
            name="checkAll"
            onChange={handleOnCheckChange}
            className="me-2"
          ></Input>
          <span
            style={{ cursor: "pointer" }}
            className="ps-2"
            onClick={() => toggleCollapse(isCollapse)}
          >
            <i
              className={isCollapse ? "fas fa-angle-up" : "fas fa-angle-down"}
              style={{ fontSize: "18px" }}
            ></i>
          </span>
        </div>
      </CardHeader>
      <Collapse isOpen={isCollapse}>
        <CardBody className="p-0">
          <table className="table align-middle mb-0 table">
            <tbody>
              <tr>
                <td className="ps-4">
                  <h5 className="font-size-14 mb-0">Video</h5>
                </td>
                <td className="text-end pe-4">
                  <Input
                    type="checkbox"
                    name="checkedVideo"
                    checked={checkedVideo}
                    onChange={handleOnCheckChange}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <h5 className="font-size-14 mb-0">Tip Sheet</h5>
                </td>
                <td className="text-end pe-4">
                  <Input
                    type="checkbox"
                    name="checkedTipSheet"
                    checked={checkedTipSheet}
                    onChange={handleOnCheckChange}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <h5 className="font-size-14 mb-0">Mini Lesson</h5>
                </td>
                <td className="text-end pe-4">
                  <Input
                    type="checkbox"
                    name="checkedMiniLession"
                    checked={checkedMiniLession}
                    onChange={handleOnCheckChange}
                  ></Input>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </Collapse>
    </Card>
  );
};

DownloadModuleCard.propTypes = {
  module: PropTypes.object,
  onCheckChange: PropTypes.func,
};

export default DownloadModuleCard;
