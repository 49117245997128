import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PropTypes from "prop-types";

class CustomPagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: this.props.defaultPageSize ?? 10,
    };
  }

  changePageSize(size) {
    this.props.onPageSizeChange(size);
    this.setState({ pageSize: size });
  }

  componentDidMount() {}
  onChangePage(e) {
    this.props.onChangePage(e);
  }
  render() {
    const {
      totalPage = 0,
      currentPage = 1,
      numberLimit = 5,
      lastIsActive = true,
      firstIsActive = true,
      pageSizeOptions,
      itemCount,
      defaultPageSize,
    } = this.props;

    let startPoint = 1;
    let endPoint = numberLimit;
    let beginItem = (currentPage - 1) * defaultPageSize + 1;
    let endItems =
      currentPage * defaultPageSize >= itemCount
        ? itemCount
        : currentPage * defaultPageSize;

    if (numberLimit > totalPage) {
      startPoint = 1;
      endPoint = totalPage;
    } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
      startPoint = 1;
      endPoint = numberLimit;
    } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
      startPoint = currentPage - parseInt(numberLimit / 2, 10);
      endPoint = currentPage + parseInt(numberLimit / 2, 10);
    } else {
      startPoint = totalPage - (numberLimit - 1);
      endPoint = totalPage;
    }
    startPoint = startPoint === 0 ? 1 : startPoint;
    const points = [];
    for (var i = startPoint; i <= endPoint; i++) {
      points.push(i);
    }

    let firstPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let lastPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    let prevPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let nextPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    return (
      <div className="text-center">
        <div className="pt-2" style={{ float: "left" }}>
          Showing {beginItem} to {endItems} of {itemCount} entries
        </div>
        {totalPage > 1 ? (
          <div className="d-inline-block">
            <Nav className="pagination pagination-rounded justify-content-center">
              {firstIsActive && (
                <NavItem className={`page-item ${firstPageButtonClassName}`}>
                  <NavLink
                    className="page-link first"
                    onClick={() => this.onChangePage(1)}
                  >
                    <i className="fas fa-angle-double-left" />
                  </NavLink>
                </NavItem>
              )}

              <NavItem className={`page-item ${prevPageButtonClassName}`}>
                <NavLink
                  className="page-link prev"
                  onClick={() => this.onChangePage(currentPage - 1)}
                >
                  <i className="fas fa-angle-left" />
                </NavLink>
              </NavItem>
              {points.map(i => {
                return (
                  <NavItem
                    key={i}
                    className={`page-item ${currentPage === i && "active"}`}
                  >
                    <NavLink
                      className="page-link"
                      onClick={() => this.onChangePage(i)}
                    >
                      {i}
                    </NavLink>
                  </NavItem>
                );
              })}
              <NavItem className={`page-item ${nextPageButtonClassName}`}>
                <NavLink
                  className="page-link next"
                  onClick={() => this.onChangePage(currentPage + 1)}
                >
                  <i className="fas fa-angle-right" />
                </NavLink>
              </NavItem>
              {lastIsActive && (
                <NavItem className={`page-item ${lastPageButtonClassName}`}>
                  <NavLink
                    className="page-link last"
                    onClick={() => this.onChangePage(totalPage)}
                  >
                    <i className="fas fa-angle-double-right" />
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
        ) : (
          <div></div>
        )}

        <div className="pt-2" style={{ float: "right" }}>
          <span className="text-muted text-small mr-1">Items </span>
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle caret color="outline-primary" size="xs">
              {this.state.pageSize}
            </DropdownToggle>
            <DropdownMenu>
              {pageSizeOptions.map((size, index) => {
                return (
                  <DropdownItem
                    key={index}
                    onClick={() => this.changePageSize(size)}
                  >
                    {size}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    );
  }
}

CustomPagination.propTypes = {
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  numberLimit: PropTypes.number,
  lastIsActive: PropTypes.bool,
  firstIsActive: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  itemCount: PropTypes.number,
  defaultPageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default CustomPagination;
