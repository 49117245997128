import { call, put, takeEvery } from "redux-saga/effects";
import { GET_NOTIFICATIONS } from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPagedNotificationsSuccess,
  getPagedNotificationsFail,
} from "./actions";

const getPagedNotificationsRequest = query =>
  get("/api/Notification/list", { params: query });

function* fetchNotifcations({ payload }) {
  try {
    const response = yield call(getPagedNotificationsRequest, payload);
    yield put(getPagedNotificationsSuccess(response));
  } catch (err) {
    const { status, statusText } = err?.response;
    if (status && status === 401) {
      window.location = "/login";
    }
    let errorMessage = statusText ?? "Something has gone wrong.";
    yield put(getPagedNotificationsFail(errorMessage));
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifcations);
}

export default notificationsSaga;

export { getPagedNotificationsRequest };
