import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// reactstrap
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import MegaMenuItemCard from "../Common/MegaMenuItemCard";

const menuItemColumnFirst=[
  {
    id: 1,
    name: "Creating a Username and Password",
    partnerName: "LAMP",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png"
  },
  {
    id: 2,
    name: "Using your phone to create a hotspot",
    partnerName: "LAMP",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png"
  },
  {
    id: 3,
    name: "Scanning documents with Android Phone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 4,
    name: "Scanning Documents with iphone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 5,
    name: "Signing documents with Android phone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 6,
    name: "Signing documents with iphone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 7,
    name: "How to use youtube",
    partnerName: "Literacy Council York Simcoe",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png"
  },
 
];

const menuItemColumnSecond=[
  {
    id: 8,
    name: "Learning about your smartphone",
    partnerName: "Literacy Council York Simcoe",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png"
  },
  {
    id: 9,
    name: "Switching and Closing Apps",
    partnerName: "Labour Education Centre",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png"
  },
  {
    id: 10,
    name: "Icons at top of screen",
    partnerName: "Labour Education Centre",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png"
  },
  {
    id: 11,
    name: "Security/Privacy",
    partnerName: "Toronto Council Fire",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png"
  },
  {
    id: 12,
    name: "Sharing Files",
    partnerName: "Toronto Council Fire",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png"
  },
  {
    id: 13,
    name: "Learning about Popular Apps",
    partnerName: "YMCA",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png"
  },
  {
    id: 14,
    name: "View Storage and uninstall Apps",
    partnerName: "YMCA",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png"
  },
  {
    id: 15,
    name: "Using Bluetooth Safely",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 16,
    name: "Protecting yourself from Phishing",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  
];

const menuItemColumnThird=[
  {
    id: 17,
    name: "Banking Safely on your Smartphone",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 18,
    name: "Location Safety on your Smartphone",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 19,
    name: "Using voice typing on smartphone",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 20,
    name: "Using google maps",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 21,
    name: "Using google lens",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 22,
    name: "Using text to speech",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 23,
    name: "Using Chatgpt",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
]

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  handleSelectModule = id => {
    window.location = `/learner/module/${id}`;
  };

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="60" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="19" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-2 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
                <span className="ms-3">Smartphone Modules</span>
              </button>

              <Dropdown
                className="dropdown-mega d-none d-lg-block"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                }}
              >
                <DropdownToggle
                  className="btn header-item"
                  caret
                  tag="button"
                  style={{ fontSize: "1.5rem" }}
                >
                  {this.props.t("Smartphone Modules")}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-megamenu">
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col md={4}>
                          {
                            menuItemColumnFirst.map((menuItem,index) =>{
                              return <MegaMenuItemCard key={index} menuItem={menuItem}  onClick={this.handleSelectModule}/>
                            })
                          }
                        </Col>
                        <Col md={4}>
                          {
                            menuItemColumnSecond.map((menuItem,index) =>{
                              return <MegaMenuItemCard key={index} menuItem={menuItem}  onClick={this.handleSelectModule}/>
                            })
                          }
                        </Col>
                        <Col md={4}>
                          {
                            menuItemColumnThird.map((menuItem,index) =>{
                              return <MegaMenuItemCard key={index} menuItem={menuItem}  onClick={this.handleSelectModule}/> 
                            })
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className="d-flex"></div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
);
