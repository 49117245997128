import React, { useReducer, useMemo, useEffect } from "react";
import Amplify from "aws-amplify";

const SettingsContext = React.createContext();

const initialState = {
  appInitialized: false,
  company: {},
  awsConfig: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "company": {
      const awsConfig = {
        Auth: {
          identityPoolId: action.payload.identityPoolId,
          region: "ca-central-1",
          userPoolId: "ca-central-1_76SIuHl4C",
          userPoolWebClientId: "6sgmh5qm8l253bikasp8dtt0h1",
          mandatorySignIn: true,
        },
        Storage: {
          //   bucket: `${action.payload.client}.escases.live.storage`,
          region: action.payload.region,
        },
      };
      Amplify.configure(awsConfig);
      console.log(`Amplify initialized app for company`);
      return {
        ...state,
        appInitialized: true,
        company: action.payload,
        awsConfig,
      };
    }

    default:
      return initialState;
  }
};

const SettingsProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {}, []);

  const value = useMemo(
    () => ({
      ...state,
    }),
    // eslint-disable-next-line comma-dangle
    [state]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SettingsContext.Provider value={value} {...props} />;
};

const useSettingsService = () => {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error(
      `useSettingsService must be used within an SettingsProvider`
    );
  }

  return context;
};

export { SettingsProvider, useSettingsService };
