import {
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUEST_BY_ID,
  GET_SERVICE_REQUEST_BY_ID_SUCCESS,
  // ADD_NEW_SERVICE_REQUEST,
  // UPDATE_SERVICE_REQUEST,
  SERVICE_REQUEST_CLEAR_ERROR,
} from "./actionTypes";

// ServiceRequest
export const getServiceRequests = query => ({
  type: GET_SERVICE_REQUESTS,
  payload: query,
});

export const getServiceRequestsSuccess = payload => ({
  type: GET_SERVICE_REQUESTS_SUCCESS,
  payload: payload,
});

export const getServiceRequestsFail = error => ({
  type: GET_SERVICE_REQUESTS_FAIL,
  payload: error,
});

export const getServiceRequestById = id => ({
  type: GET_SERVICE_REQUEST_BY_ID,
  payload: id,
});

export const getServiceRequestByIdSuccess = item => ({
  type: GET_SERVICE_REQUEST_BY_ID_SUCCESS,
  payload: item,
});

export const getServiceRequestByIdFail = error => ({
  type: GET_SERVICE_REQUEST_BY_ID_FAIL,
  payload: error,
});

// export const addNewRole = role => ({
//   type: ADD_NEW_SERVICE_REQUEST,
//   payload: role,
// });

// export const updateRole = Role => ({
//   type: UPDATE_SERVICE_REQUEST,
//   payload: Role,
// });

// export const deleteRole = role => ({
//   type: DELETE_ROLE,
//   payload: role,
// });

export const clearServiceRequestError = () => ({
  type: SERVICE_REQUEST_CLEAR_ERROR,
});
