import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge, Button, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displaySerivceRequestStatus } from "helpers/utils";
import { BadgeButton } from "components/Common/BadgeButton";
import { withResponsive } from "components/Common/ResponsiveHoc";
import ServiceRequestMobileItem from "pages/ServiceRequest/ServiceRequestMobileItem";

export class BidOfferTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  render() {
    const { isMobile } = this.props;
    console.log("IsMobile", isMobile);
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onBidDetail,
      onConfirmDelete,
      // onAddNew,
      // onSearch,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card style={{ backgroundColor: isMobile ? "transparent" : "white" }}>
        <CardBody className={isMobile ? "p-0" : ""}>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table className="table table-striped table-bordered align-middle border-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Services</th>
                      <th>Vehicle</th>
                      <th>Requested Date</th>
                      <th>Distance</th>
                      <th>Status</th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      let vehicle = `${item.year} ${item.make} ${item.model}`;
                      let distanceKm = item.distance;

                      if (isMobile)
                        return (
                          <tr key={index} className="border-0 p-0">
                            <td colSpan={6} className="border-0 p-0">
                              <ServiceRequestMobileItem
                                item={item}
                                onBidDetail={onBidDetail}
                              />
                            </td>
                          </tr>
                        );

                      return (
                        <tr key={index}>
                          <td>
                            <p>{item.id}</p>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              {item.services &&
                                item.services.map((a, index) => {
                                  return (
                                    <p key={index} className="text-muted mb-0">
                                      {a.name}
                                    </p>
                                  );
                                })}
                            </div>
                          </td>
                          <td>
                            <div>
                              <p>{vehicle}</p>
                            </div>
                          </td>
                          <td>
                            <p>
                              {moment(item.createdDate).format("DD-MMM-YYYY")}
                            </p>
                          </td>
                          <td>{Number(distanceKm).toFixed(2)} Km</td>
                          <td>
                            <p>{displaySerivceRequestStatus(item)}</p>
                          </td>
                          <td>
                            <div>
                              <p>
                                <BadgeButton
                                  action={() => onBidDetail(item.id)}
                                  color={"primary"}
                                  iconClass={"bx bxs-megaphone"}
                                  label={"Bid"}
                                />
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

BidOfferTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onBidDetail: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default withResponsive(BidOfferTable);
