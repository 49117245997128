import {
  GET_BIDS,
  GET_BIDS_SUCCESS,
  GET_BIDS_FAIL,
  GET_BID_BY_ID,
  GET_BID_BY_ID_SUCCESS,
  ADD_NEW_BID,
  ADD_NEW_BID_SUCCESS,
  UPDATE_BID,
  DELETE_BID,
  BID_CLEAR_ERROR,
  BID_CLEAR_NOTIFY,
  ACCEPT_BID,
  ACCEPT_BID_SUCCESS,
} from "./actionTypes";

export const getBids = query => ({
  type: GET_BIDS,
  payload: query,
});

export const getBidsSuccess = bids => ({
  type: GET_BIDS_SUCCESS,
  payload: bids,
});

export const getBidsFail = error => ({
  type: GET_BIDS_FAIL,
  payload: error,
});

export const getBidById = id => ({
  type: GET_BID_BY_ID,
  payload: id,
});

export const getBidByIdSuccess = bid => ({
  type: GET_BID_BY_ID_SUCCESS,
  payload: bid,
});

export const getBidByIdFail = error => ({
  type: GET_BID_BY_ID_FAIL,
  payload: error,
});

export const addNewBid = bid => ({
  type: ADD_NEW_BID,
  payload: bid,
});

export const addNewBidSuccess = bid => ({
  type: ADD_NEW_BID_SUCCESS,
  payload: bid,
});

export const updateBid = bid => ({
  type: UPDATE_BID,
  payload: bid,
});

export const deleteBid = bid => ({
  type: DELETE_BID,
  payload: bid,
});

export const clearBidError = () => ({
  type: BID_CLEAR_ERROR,
});

export const clearBidNotify = () => ({
  type: BID_CLEAR_NOTIFY,
});

export const acceptBid = payload => ({
  type: ACCEPT_BID,
  payload,
});

export const acceptBidSucces = payload => ({
  type: ACCEPT_BID_SUCCESS,
  payload,
});
