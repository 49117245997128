import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getProfile, handleDateChange } from "helpers/utils";
import { changeLayout, getServiceRequests } from "store/actions";
import { layoutTypes } from "constants/layout";
import BidOfferTable from "./BidOfferTable";

export class LeadsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      vendorId: null,
      currentPage: 1,
      pageSize: 10,

      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    const profile = getProfile();
    this.props.changeLayout(layoutTypes.VERTICAL);
    // Status = 2 is Requested;
    if (profile) {
      const { id } = profile;
      this.setState({
        vendorId: id,
      });
      this.props.getServiceRequests({
        status: 2,
        vendorId: id,
        hideVendorBid: true,
      });
    }
  }

  handleOnSearch = e => {
    const { value } = e.target;
    const { vendorId } = this.state;
    this.setState({
      term: value ?? "",
    });
    this.props.getServiceRequests({
      status: 2,
      term: value ?? "",
      vendorId,
      hideVendorBid: true,
    });
  };

  handleSearch = () => {
    const { term, startDate, endDate, vendorId } = this.state;
    console.log(startDate);
    this.props.getServiceRequests({
      status: 2,
      vendorId,
      hideVendorBid: true,
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
    });
  };

  handleOnPageChange = page => {
    const { pageSize, vendorId } = this.state;
    this.props.getServiceRequests({
      page,
      pageSize,
      status: 2,
      vendorId,
      hideVendorBid: true,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { vendorId } = this.state;
    this.props.getServiceRequests({
      page: 1,
      pageSize,
      status: 2,
      vendorId,
      hideVendorBid: true,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnBidDetail = id => {
    const { history } = this.props;
    console.log(history);
    history.push(`/bid-offer-detail/${id}`);
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        <Card className="mb-2">
          <CardBody className="pb-1">
            <Row className="g-2">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <FormGroup style={{ marginRight: "5px" }}>
                  <DatePicker
                    className="form-control"
                    selectsStart
                    name="startDate"
                    selected={this.state.startDate}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={date => handleDateChange(this, date, "startDate")}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Start Date"
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup style={{ marginRight: "5px" }}>
                  <DatePicker
                    className="form-control"
                    name="endDate"
                    selectsEnd
                    selected={this.state.endDate}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={date => handleDateChange(this, date, "endDate")}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="End Date"
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <Button
                  type="button"
                  outline
                  color="primary"
                  style={{ marginRight: "5px" }}
                  onClick={this.handleSearch}
                >
                  <i className="fas fa-filter" /> Filter
                </Button>
              </Col>
              <Col md={4} className="text-lg-end"></Col>
            </Row>
          </CardBody>
        </Card>
        {items && (
          <BidOfferTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onBidDetail={this.handleOnBidDetail}
            // onViewDetail={this.handleOnViewDetail}
            // onConfirmDelete={this.handleConfirmDelete}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
      </>
    );
  }
}

LeadsTab.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getServiceRequests: PropTypes.func,
  history: PropTypes.object,
  changeLayout: PropTypes.func,
};

const mapStateToProps = ({ serviceReq }) => {
  return serviceReq;
};

export default withRouter(
  connect(mapStateToProps, {
    getServiceRequests,
    changeLayout,
  })(LeadsTab)
);
