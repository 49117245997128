import axios from "axios";
import React, { Component } from "react";
import { debounce } from "lodash";

import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardHeader,
  Collapse,
} from "reactstrap";

export class CreatingPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      activeTab: 1,
      // activeIndex: 0,
      // passedSteps: [1],
      // passedStepsVertical: [1],
      isStarted: false,
      submitted: false,

      isInclude8Length: false,
      isIncludeLetterNumberAndSymbol: false,
      isIncludeLowerAndUpperCase: false,
      doesNotUseDictionaryWords: false,
      isExpanding: false,
    };

    this.validateRules = this.validateRules.bind(this);
  }

  toggleIsExpanding = () => {
    this.setState(prevState => ({
      isExpanding: !prevState.isExpanding,
    }));
  };

  lookupDictionary = async fulltext => {
    if (!fulltext) {
      return false;
    }

    // find collection of contiguous words
    const pattern = /([a-zA-Z]+)/gm;
    let match = fulltext.match(pattern);

    // if not found, pass rule
    if (!match) {
      return true;
    }

    //ignore words less than 3 letters
    match = match.filter(m => m.length > 2);
    console.log(match);

    // Set the URLs to access
    let urls = match.map(
      m => `https://api.dictionaryapi.dev/api/v2/entries/en/${m}`
    );

    /*
    | Perform the HTTP get request via Axios
    | It returns a Promise immediately,
    | not the response
    */
    const requests = urls;
    /*
    | For waiting the Promise is fulfilled
    | with the Response, use the then() method.
    | If the HTTP request received errors
    | use catch() method
    */

    for (let i = 0; i < requests.length; i++) {
      var req = requests[i];
      try {
        var response = await axios.get(req);
        //Getting here means that a word was found in the dictionary, which is bad return false
        console.log("-----[word was found-Invalid]----->", response);
        return false;
      } catch (error) {
        console.log("------**---->", error);
      }
    }

    // pass rule if no word was found in dictionary
    return true;
  };

  validateRules = debounce((name, value) => {
    console.log(value);

    this.lookupDictionary(value).then(validateDictionary => {
      console.log("validateDictionary", validateDictionary);

      switch (name) {
        case "password":
          this.setState({
            isInclude8Length: value
              ? /[a-zA-Z0-9!@#$%^&*]{8,}/.test(value)
              : false,
            isIncludeLetterNumberAndSymbol: value
              ? /([\d])?([a-zA-Z])?([!@#$%^&*])/.test(value)
              : false,
            isIncludeLowerAndUpperCase: value
              ? /[a-z]/.test(value) && /[A-Z]/.test(value)
              : false,
            doesNotUseDictionaryWords: value ? validateDictionary : false,
          });
          break;
        default:
          break;
      }
    });
  }, 1000);

  handleChange = async e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.validateRules(name, value);
    //this.validate(value);
  };

  // next = () => {
  //   if (this.animating) return;
  //   const nextIndex =
  //     this.state.activeIndex === items.length - 1
  //       ? 0
  //       : this.state.activeIndex + 1;
  //   this.setState({ activeIndex: nextIndex });
  // };

  // previous = () => {
  //   if (this.animating) return;
  //   const nextIndex =
  //     this.state.activeIndex === 0
  //       ? items.length - 1
  //       : this.state.activeIndex - 1;
  //   this.setState({ activeIndex: nextIndex });
  // };

  handleStartLesson = () => {
    this.setState({
      password: "",
      activeTab: 2,
      // activeIndex: 0,
      // passedSteps: [1],
      // passedStepsVertical: [1],
      isStarted: true,
      submitted: false,

      isInclude8Length: false,
      isIncludeLetterNumberAndSymbol: false,
      isIncludeLowerAndUpperCase: false,
      doesNotUseDictionaryWords: false,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  render() {
    const {
      isInclude8Length,
      isIncludeLetterNumberAndSymbol,
      isIncludeLowerAndUpperCase,
      doesNotUseDictionaryWords,
    } = this.state;

    let isValidUsername =
      isInclude8Length &&
      isIncludeLetterNumberAndSymbol &&
      isIncludeLowerAndUpperCase &&
      doesNotUseDictionaryWords;

    return (
      <div className="accordion bg-white mb-3" id="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header bg-primary" id="headingOne">
            <button
              className={
                "accordion-button fw-medium" +
                (this.state.isExpanding ? "" : " collapsed")
              }
              type="button"
              onClick={this.toggleIsExpanding}
              style={{ cursor: "pointer" }}
            >
              <CardTitle>Creating a Password for New Email Address</CardTitle>
            </button>
          </h2>

          <Collapse
            isOpen={this.state.isExpanding}
            className="accordion-collapse"
          >
            <div className="accordion-body">
              <div className="d-flex justify-content-end">
                <a
                  href="https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/lessons/LAMP-Create+Username+_+Password_SL.pdf"
                  target="_blank"
                  rel={"noreferrer"}
                  className="w-lg btn btn-outline-primary btn-sm"
                >
                  Download
                </a>
              </div>
              <Card style={{ minHeight: "25rem" }}>
                <TabContent
                  style={{ overflow: "hidden" }}
                  activeTab={this.state.activeTab}
                >
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={1}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <Button
                        style={{ width: "12rem" }}
                        className="mini-lesson-btn"
                        color="primary"
                        size="lg"
                        // outline
                        onClick={this.handleStartLesson}
                      >
                        Start Lesson
                      </Button>
                    </div>
                  </TabPane>
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={2}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <p className="mini-lesson-p">
                          Here are the suggestions for creating a good Password.
                        </p>
                        <div className="d-flex justify-content-center mt-4">
                          <div className="d-flex flex-column justify-content-center mt-2">
                            <div className="d-flex justify-content-center flex-column w-100">
                              <h5 className="text-truncate font-size-14 mb-1">
                                <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                                Includes at least 8 characters.
                              </h5>
                              <h5 className="text-truncate font-size-14 mb-1">
                                <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                                Includes letters, numbers & symbols
                              </h5>
                              <h5 className="text-truncate font-size-14 mb-1">
                                <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                                Includes uppercase and lowercase letters
                              </h5>
                              <h5 className="text-truncate font-size-14 mb-1">
                                <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                                <strong>Does not</strong> use dictionary words
                              </h5>
                            </div>
                            <Button
                              style={{
                                width: "5rem",
                                marginLeft: "1rem",
                                marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1);
                              }}
                            >
                              <span>OK</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={3}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <p className="mini-lesson-p">
                          Try to create a good username
                        </p>
                        <Row className="mt-4">
                          <Col md={5}>
                            <Input
                              id="password"
                              name="password"
                              type="text"
                              className="mb-4"
                              style={{ fontSize: "1.2rem" }}
                              value={this.state.password}
                              onChange={this.handleChange}
                            />

                            {isValidUsername && (
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            )}
                          </Col>
                          <Col md={7}>
                            <div className="d-flex justify-content-center flex-column w-100">
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isInclude8Length
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                Includes at least 8 characters.
                              </p>
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isIncludeLetterNumberAndSymbol
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                Includes letters, numbers & symbols
                              </p>
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isIncludeLowerAndUpperCase
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                Includes uppercase and lowercase letters
                              </p>
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.doesNotUseDictionaryWords
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                <strong>{"Does not "}</strong>include dictionary
                                words
                              </p>
                            </div>
                            {/* <table className="table align-middle table-hover mb-0 table">
                              <tbody>
                                <tr>
                                  <td>
                                    <p className="font-size-14 mb-1">
                                      <i
                                        className={
                                          "me-2 font-size-14  " +
                                          (this.state.isInclude8Length
                                            ? "fas fa-check-circle text-success"
                                            : "fas fa-times-circle text-danger")
                                        }
                                      ></i>{" "}
                                      Includes at least 8 characters.
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="font-size-14 mb-1">
                                      <i
                                        className={
                                          "me-2 font-size-14  " +
                                          (this.state
                                            .isIncludeLetterNumberAndSymbol
                                            ? "fas fa-check-circle text-success"
                                            : "fas fa-times-circle text-danger")
                                        }
                                      ></i>{" "}
                                      Includes letters, numbers & symbols
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="font-size-14 mb-1">
                                      <i
                                        className={
                                          "me-2 font-size-14  " +
                                          (this.state.isIncludeLowerAndUpperCase
                                            ? "fas fa-check-circle text-success"
                                            : "fas fa-times-circle text-danger")
                                        }
                                      ></i>{" "}
                                      Includes uppercase and lowercase letters
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="font-size-14 mb-1">
                                      <i
                                        className={
                                          "me-2 font-size-14  " +
                                          (this.state.doesNotUseDictionaryWords
                                            ? "fas fa-check-circle text-success"
                                            : "fas fa-times-circle text-danger")
                                        }
                                      ></i>{" "}
                                      Does not include dictionary words
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table> */}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={4}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <div className="row justify-content-center">
                          {isValidUsername ? (
                            <Col lg="12">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>
                                    Congratulation! you have successfully
                                    created a Password!
                                  </h5>
                                  <p className="text-muted">
                                    If you would like to try and create a new
                                    Password click on the Restart Lesson button
                                    to start over.
                                  </p>
                                </div>
                                <Button
                                  style={{
                                    width: "fit-content",
                                    marginTop: "1rem",
                                  }}
                                  className="mini-lesson-btn"
                                  color="primary"
                                  size="lg"
                                  outline
                                  onClick={() => {
                                    this.toggleTab(1);
                                  }}
                                >
                                  <span>Restart Lesson</span>
                                  <i
                                    style={{ marginLeft: "0.5rem" }}
                                    className="fa fa-check"
                                  />
                                </Button>
                              </div>
                            </Col>
                          ) : (
                            <Col lg="12">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-close-circle-outline text-danger display-4" />
                                </div>
                                <div>
                                  <h5>
                                    Sorry! you have unsuccessfully created a
                                    Password!
                                  </h5>
                                  <p className="text-muted">
                                    If you would like to try and create a new
                                    Password click on the Restart Lesson button
                                    to start over.
                                  </p>
                                </div>
                                <Button
                                  style={{
                                    width: "fit-content",
                                    marginTop: "1rem",
                                  }}
                                  className="mini-lesson-btn"
                                  color="primary"
                                  size="lg"
                                  outline
                                  onClick={() => {
                                    this.toggleTab(1);
                                  }}
                                >
                                  <span>Restart Lesson</span>
                                  <i
                                    style={{ marginLeft: "0.5rem" }}
                                    className="fa fa-check"
                                  />
                                </Button>
                              </div>
                            </Col>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
                <div className="actions clearfix">
                  {/* <ul>
                    {this.state.activeTab === 3 ? (
                      <li className={"next"}>
                        <Button
                          outline
                          color="primary"
                          className="mini-lesson-btn w-lg"
                          onClick={() => {
                            this.toggleTab(1);
                          }}
                        >
                          Restart Lesson
                        </Button>
                      </li>
                    ) : (
                      <li className={"next"}>
                        <Button
                          outline
                          color="primary"
                          className="mini-lesson-btn w-lg"
                          onClick={() => {
                            this.toggleTab(this.state.activeTab + 1);
                          }}
                        >
                          Next
                        </Button>
                      </li>
                    )}
                  </ul> */}
                  {this.state.activeTab !== 1 && (
                    <div
                      style={{ bottom: "0", position: "absolute", right: "0" }}
                      className="d-flex justify-content-end"
                    >
                      <Button
                        style={{ width: "3rem", marginRight: "1px" }}
                        className="mini-lesson-nav-up"
                        color="primary"
                        size="lg"
                        outline
                        onClick={() => {
                          this.toggleTab(this.state.activeTab - 1);
                        }}
                      >
                        <i className="fa fa-chevron-up"></i>
                      </Button>
                      <Button
                        style={{ width: "3rem" }}
                        className="mini-lesson-nav-down"
                        color="primary"
                        size="lg"
                        outline
                        onClick={() => {
                          this.toggleTab(this.state.activeTab + 1);
                        }}
                      >
                        <i className="fa fa-chevron-down"></i>
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default CreatingPassword;
