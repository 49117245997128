import Information from "containers/sidebar/Information";
import PropTypes from "prop-types";

import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import { modules } from "common/data/modules";
import MoreModule from "containers/sidebar/MoreModule";

import TipSheetCreateUsername from "containers/tips/TipSheetCreateUsername";
import TipSheetCreatePassword from "containers/tips/TipSheetCreatePassword";
import CreatingUsername from "containers/mini-lesson/CreatingUsername";
import CreatingPassword from "containers/mini-lesson/CreatingPassword";
import CreateHotspot from "containers/mini-lesson/CreateHotspot";
import MiniLesson from "containers/mini-lesson/MiniLesson";
import TipSheetEntry from "containers/tips/TipSheetEntry";
import ViewStorageAndUninstallApps from "containers/mini-lesson/ViewStorageAndUninstallApps";
import UsingYoutube from "containers/mini-lesson/UsingYoutube";
import LearningAboutPopularApps from "containers/mini-lesson/LearningAboutPopularApps";
import UnderstandingHowToShareAFile from "containers/mini-lesson/UnderstandingHowToShareAFile";
import LearningAboutSmartphone from "containers/mini-lesson/LearningAboutSmartphone";
import ClosingAppOnYourSmartphone from "containers/mini-lesson/ClosingAppOnYourSmartphone";
import IconAtTopOfScreen from "containers/mini-lesson/IconAtTopOfScreen";
import StepsToSecurity from "containers/mini-lesson/StepsToSecurity";
import { H5PPlayer } from "containers/h5p/H5PPlayer";
import LocationSafety from "containers/mini-lesson/LocationSafety";

let tranerGuide =
    "https://mtml.s3.ca-central-1.amazonaws.com/guides/Final_Jan+30_DRAFT+-+SP+GUIDE+outline+%5ELLL0+section+1+text+(1).pdf";

export class ModuleDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      isCollapse: false,
      col1: true,
      col2: false,
      isTipShow: true,
      isMiniShow: false,
      profile: "",
      module: {
        id: 1,
        name: "Creating a Username and Password",
        // imageUrl: moduleThumbnail,
        videos: [
          {
            id: 1,
            link: "https://www.youtube.com/embed/6iPbyZZZ7mA",
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile, id } = match.params;
    if (id) {
      let module = modules.find(e => e.id === Number(id));
      this.setState({
        module,
        profile,
        id,
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { match } = props;
    const { profile, id } = match.params;

    if (id !== state.id) {
      let module = modules.find(e => e.id === Number(id));
      return {
        module,
        profile,
        id,
      };
    }
    return {
      ...state,
    };
  }

  handleShowTipSheet = () => {
    this.setState({
      isTipShow: true,
      isMiniShow: false,
    });
  };

  handleShowMini = () => {
    this.setState({
      isTipShow: false,
      isMiniShow: true,
    });
  };

  handleOnDownload = module => {
    if ([ 15, 16, 17, 18 ].includes(module.id)) {
      window.open(module.videoLink, "_blank");
    } else {
      const { videos } = module;
      if (videos && videos.length > 0 && videos[0].link) {
        window.open(videos[0].link, "_blank");
      }
    }
    window.focus();
  };

  handleOnDownloadTranscript = module => {
    const { transcript } = module;
    if (transcript) {
      window.open(transcript, "_blank");
      window.focus();
    }
  };

  handleDownloadTrainerGuide = () => {
    window.open(tranerGuide, "_blank");
    window.focus();
  };
  
  render() {
    const { module, isCollapse, profile, isTipShow, isMiniShow } = this.state;
    const { videos, tipSheets, lessons, h5p } = module;
    const { id } = module;

    return (
        <React.Fragment>
          <div className="home-btn d-none d-sm-block"></div>
          <div className="account-pages my-5 pt-sm-5">
            <Container fluid>
              <Row>
                <Col md={ 8 }>
                  { h5p && <H5PPlayer src={ h5p }/> }
                  { !h5p && videos && videos.length > 0 && (
                      <video width="100%" height={ "500" } controls playsInline>
                        <source src={ videos[0].link } type="video/mp4"></source>
                        <track
                            label="English"
                            kind="subtitles"
                            srcLang="en"
                            src={ videos[0].subtitle }
                            default
                        />
                        <track
                            label="English"
                            kind="subtitles"
                            srcLang="en"
                            default
                        />
                      </video>
                  ) }
                  <h3 className="mt-2 ">{ module.name }</h3>

                  <Row>
                    <Col md={ 12 }>
                      <div className="mt-2 float-end">
                        <FormGroup>
                          { profile === "service-providers" && (
                              <Button
                                  color="primary"
                                  outline
                                  className="w-lg me-2"
                                  onClick={ this.handleDownloadTrainerGuide }
                              >
                                { `Download Trainer's Guide` }
                              </Button>
                          ) }
                          {
                              module.transcript &&
                              <Button
                                  color="primary"
                                  outline
                                  className="w-lg me-2"
                                  onClick={ () =>
                                      this.handleOnDownloadTranscript(module)
                                  }
                              >
                                Download Transcript
                              </Button>
                          }
                          {
                              module.videoLink &&
                              <Button
                                  color="primary"
                                  outline
                                  className="w-lg"
                                  onClick={ () => this.handleOnDownload(module) }
                              >
                                Download Video
                              </Button>
                          }
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md={ 12 }>
                      <Card className="rounded">
                        <CardBody>
                          <Row>
                            <Col md={ 6 }>
                              <CardTitle>
                                In this module you will learn:
                              </CardTitle>
                              <div
                                  dangerouslySetInnerHTML={ {
                                    __html: module.willLearnContent,
                                  } }
                              ></div>
                            </Col>
                            { module.greatForAnyoneContent && (
                                <Col md={ 6 }>
                                  <CardTitle>
                                    This module is great for anyone who:
                                  </CardTitle>
                                  <div
                                      dangerouslySetInnerHTML={ {
                                        __html: module.greatForAnyoneContent,
                                      } }
                                  ></div>
                                </Col>
                            ) }
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 12 }>
                      <h4 className="text-center">
                        For details, click on each tile separately below
                      </h4>
                    </Col>
                    <Col md={ 12 } className="mt-4">
                      <div>
                        <Row>
                          <Col
                              md={ 6 }
                              style={ {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              } }
                          >
                            <div>
                              <p style={ { fontSize: "1rem" } }>
                                Read our tip sheet for more information.
                              </p>
                            </div>
                            <Card
                                color={ isTipShow ? "primary" : "" }
                                className="card-hover"
                                style={ {
                                  cursor: "pointer",
                                  width: "250px",
                                  height: "270px",
                                } }
                                onClick={ this.handleShowTipSheet }
                            >
                              <CardHeader className="bg-transparent d-flex justify-content-center">
                                <h3 className={ isTipShow ? "text-white" : "" }>
                                  Tip Sheet
                                </h3>
                              </CardHeader>
                              <CardBody className="text-center">
                                <i
                                    className={
                                        "fas fa-file-alt " +
                                        (isTipShow ? "text-white" : "")
                                    }
                                    style={ { fontSize: "150px" } }
                                ></i>
                              </CardBody>
                            </Card>
                          </Col>
                          { lessons && lessons.length > 0 && (
                              <Col
                                  md={ 6 }
                                  style={ {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  } }
                              >
                                <div>
                                  <p style={ { fontSize: "1rem" } }>
                                    Practice what you learned in the video
                                  </p>
                                </div>
                                <Card
                                    color={ isMiniShow ? "primary" : "" }
                                    className="card-hover"
                                    style={ {
                                      cursor: "pointer",
                                      width: "250px",
                                      height: "270px",
                                    } }
                                    onClick={ this.handleShowMini }
                                >
                                  <CardHeader className="bg-transparent d-flex justify-content-center">
                                    <h3 className={ isMiniShow ? "text-white" : "" }>
                                      Mini Lesson
                                    </h3>
                                  </CardHeader>
                                  <CardBody className="text-center">
                                    <i
                                        className={
                                            "fas fa-pencil-alt " +
                                            (isMiniShow ? "text-white" : "")
                                        }
                                        style={ { fontSize: "150px" } }
                                    ></i>
                                  </CardBody>
                                </Card>
                              </Col>
                          ) }
                        </Row>
                      </div>

                      { this.state.isTipShow && (
                          <>
                            { id && id === 1 && (
                                <>
                                  <TipSheetCreateUsername module={ module }/>
                                  <TipSheetCreatePassword module={ module }/>
                                </>
                            ) }
                            { id &&
                                [
                                  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                                  17, 18, 19,20, 21, 22, 23
                                ].includes(id) && (
                                    <>
                                      { tipSheets.map((tipSheet, index) => {
                                        return (
                                            <TipSheetEntry key={ index } item={ tipSheet }/>
                                        );
                                      }) }
                                    </>
                                ) }
                          </>
                      ) }
                      { this.state.isMiniShow && (
                          <>
                            { id && id === 1 && (
                                <>
                                  <CreatingUsername/>
                                  <CreatingPassword/>
                                </>
                            ) }
                            { id && id === 2 && (
                                <>
                                  <CreateHotspot/>
                                </>
                            ) }

                            { id &&
                                [ 3, 4, 5, 6, 7, 8, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22, 23].includes(
                                    id
                                ) && (
                                    <>
                                      { lessons &&
                                          lessons.map((lesson, index) => {
                                            if (id === 7) {
                                              return <UsingYoutube item={ lesson }/>;
                                            } else if (id === 8) {
                                              return (
                                                  <LearningAboutSmartphone item={ lesson }/>
                                              );
                                            } else if (id === 9) {
                                              return (
                                                  <ClosingAppOnYourSmartphone
                                                      item={ lesson }
                                                  />
                                              );
                                            } else if (id === 10) {
                                              return <IconAtTopOfScreen item={ lesson }/>;
                                            } else if ([ 15, 16, 17, 18 ].includes(id)) {
                                              return <LocationSafety item={ lesson }/>;
                                            }

                                            return (
                                                <MiniLesson key={ index } item={ lesson }/>
                                            );
                                          }) }
                                    </>
                                ) }

                            { id && id === 11 && (
                                <>
                                  <StepsToSecurity/>
                                </>
                            ) }
                            { id && id === 12 && (
                                <>
                                  <UnderstandingHowToShareAFile/>
                                </>
                            ) }
                            { id && id === 13 && (
                                <>
                                  <LearningAboutPopularApps/>
                                </>
                            ) }
                            { id && id === 14 && (
                                <>
                                  <ViewStorageAndUninstallApps/>
                                </>
                            ) }
                          </>
                      ) }
                    </Col>
                    <Col md={ 12 }>
                      <div className="d-flex mb-4">
                        <div className="flex-shrink-0 me-3">
                          <a
                              href={ module.partnerWebsite }
                              target="_blank"
                              rel="noreferrer"
                          >
                            <img
                                className="rounded avatar-lg"
                                src={ module.partnerLogo }
                                alt={ "Skills Upgrading Center" }
                            />
                          </a>
                        </div>
                        <div className="flex-grow-1">
                          <CardTitle>{ module.partnerName }</CardTitle>
                          <p className="mb-0">
                            Learn about { module.partnerName } who made this
                            learning module possible
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={ 4 } className={ isCollapse ? "right-sidebar" : "" }>
                  <Information profile={ this.state.profile }/>
                  <MoreModule profile={ this.state.profile }/>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } className="mb-4"></Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
    );
  }
}

ModuleDetail.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ModuleDetail;
