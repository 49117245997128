import React from "react";
import PropTypes from "prop-types";

const SelectionBox = ({ title, value, isCorrect, onClick }) => {
  let borderBox = "";
  let icon = "";

  if (isCorrect !== null && isCorrect !== undefined) {
    borderBox = isCorrect === true ? "border-success" : "border-danger";
    icon =
      isCorrect === true ? "fa-check text-success" : "fa-times text-danger";
  }

  return (
    <div
      className={"border border-2 mb-1 " + borderBox}
      style={{
        display: "flex",
        width: "20rem",
        cursor: "pointer",
      }}
      onClick={e => onClick(e)}
    >
      <div className="py-2 px-3 border">{title}</div>
      <div className="py-2 px-3 flex-grow-1">{value}</div>
      <div className="py-2 px-3">
        <i className={"font-size-18 fas " + icon}></i>
      </div>
    </div>
  );
};

SelectionBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isCorrect: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SelectionBox;
