import React, { useState } from "react";
import {
  Card,
  CardBody,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";

const LearningAboutSmartphoneIPhone = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedStepsVertical, setPassedStepsVertical] = useState([1]);

  const toggleTab = tab => {
    // setActiveTab(id);
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
      }
    }
  };

  return (
    <>
      <h4 className="card-title mb-4">Finding your iPhone Model</h4>
      <div className="wizard clearfix">
        <div className="steps clearfix">
          <ul>
            <NavItem
              className={classnames({
                current: activeTab === 1,
              })}
            >
              <NavLink
                className={classnames({
                  active: activeTab === 1,
                })}
                onClick={() => {
                  toggleTab(1);
                }}
              >
                <span className="number">1</span>
              </NavLink>
            </NavItem>
            <NavItem
              className={classnames({
                current: activeTab === 2,
              })}
            >
              <NavLink
                disabled={!(passedSteps || []).includes(2)}
                className={classnames({
                  active: activeTab === 2,
                })}
                onClick={() => {
                  this.toggleTab(2);
                }}
              >
                <span className="number">2</span>
              </NavLink>
            </NavItem>
            <NavItem
              className={classnames({
                current: activeTab === 3,
              })}
            >
              <NavLink
                disabled={!(passedSteps || []).includes(3)}
                className={
                  (classnames({
                    active: activeTab === 3,
                  }),
                  "done")
                }
                onClick={() => {
                  toggleTab(3);
                }}
              >
                <span className="number">3</span>
              </NavLink>
            </NavItem>
            <NavItem
              className={classnames({
                current: activeTab === 4,
              })}
            >
              <NavLink
                disabled={!(passedSteps || []).includes(4)}
                className={
                  (classnames({
                    active: activeTab === 4,
                  }),
                  "done")
                }
                onClick={() => {
                  toggleTab(4);
                }}
              >
                <span className="number">4</span>
              </NavLink>
            </NavItem>
          </ul>
        </div>
        <div className="content clearfix">
          <TabContent activeTab={activeTab} className="body">
            <TabPane tabId={1}>
              <img
                width={"100%"}
                src="https://mtml.s3.ca-central-1.amazonaws.com/assets/108.png"
              />
            </TabPane>
            <TabPane tabId={2}>
              <img
                width={"100%"}
                src="https://mtml.s3.ca-central-1.amazonaws.com/assets/109.png"
              />
            </TabPane>
            <TabPane tabId={3}>
              <img
                width={"100%"}
                src="https://mtml.s3.ca-central-1.amazonaws.com/assets/110.png"
              />
            </TabPane>
            <TabPane tabId={4}>
              <img
                width={"100%"}
                src="https://mtml.s3.ca-central-1.amazonaws.com/assets/111.png"
              />
              <Input
                type="text"
                placeholder="Example: iPhone 12 Pro Max"
                name="model"
                id="model"
              />
            </TabPane>
          </TabContent>
        </div>
        <div className="actions clearfix">
          <ul>
            <li className={activeTab === 1 ? "previous disabled" : "previous"}>
              <Link
                to="#"
                onClick={() => {
                  toggleTab(activeTab - 1);
                }}
              >
                Previous
              </Link>
            </li>
            <li className={activeTab === 4 ? "next disabled" : "next"}>
              <Link
                to="#"
                onClick={() => {
                  toggleTab(activeTab + 1);
                }}
              >
                Next
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LearningAboutSmartphoneIPhone;
