import React, { useState } from "react";
import SelectionBox from "components/Common/SelectionBox";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Badge,
  Text,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

const StepsToSecurity = () => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isCorrect, setIsCorrect] = useState(null);

  const [question1, setQuestion1] = useState(null);

  const [lastSubmitted, setLastSubmitted] = useState(null);

  const [iphoneSelectedTerms, setIphoneSelectedTerms] = useState([]);
  const [androidSelectedTerms, setAndroidSelectedTerms] = useState([]);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab);
      }
    }
  };

  const handleOnClick = (name, value) => {
    switch (name) {
      case "question1":
        setQuestion1(value);
        break;

        questionIcon6;

      default:
        break;
    }
  };

  const iphoneToggleTerms = term => {
    console.log("toggle", term);
    if (iphoneSelectedTerms.indexOf(term) >= 0) {
      const newRemovedTerms = iphoneSelectedTerms.filter(t => t !== term);
      const arr = [...newRemovedTerms];
      setIphoneSelectedTerms(arr);
    } else {
      iphoneSelectedTerms.push(term);
      setIphoneSelectedTerms([...iphoneSelectedTerms]);
    }
  };

  const androidToggleTerms = term => {
    console.log("toggle", term);
    if (androidSelectedTerms.indexOf(term) >= 0) {
      const newRemovedTerms = androidSelectedTerms.filter(t => t !== term);
      const arr = [...newRemovedTerms];
      setAndroidSelectedTerms(arr);
    } else {
      androidSelectedTerms.push(term);
      setAndroidSelectedTerms([...androidSelectedTerms]);
    }
  };

  const handleStartLesson = () => {
    setQuestion1(null);

    setLastSubmitted(null);
    setActiveTab(2);
  };

  const androidTerms = [
    "Lock Screen",
    "Lock screen & security",
    "Passcode",
    "Password",
    "PIN",
    "Pattern",
    "Biometrics",
    "Facial Recognition",
    "Fingerprint",
  ];

  const iPhoneTerms = [
    "Lock Screen",
    "Passcode",
    "Password",
    "PIN",
    "Face ID",
    "Touch ID",
  ];

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>Steps to Security</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href="https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/lessons/Jan+16_Council+Fire_Security+and+Privacy+Mini+Lesson.pdf"
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent
                style={{ overflowX: "hidden", overflowY: "auto" }}
                activeTab={activeTab}
              >
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div style={{ height: "65rem" }} className="center-container">
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Word Search on Phone</p>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="1">
                            <li>
                              <p>
                                <span>{`Read the list of terminology specific to your device (iPhone or Android)
as it appears in the table below:`}</span>
                              </p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={6}>
                          <Table
                            id="android-term"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Android Terminology</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  {androidTerms.map(t => (
                                    <Badge
                                      onClick={() => androidToggleTerms(t)}
                                      style={{
                                        margin: ".5rem",
                                        fontSize: "1rem",
                                        cursor: "pointer",
                                      }}
                                      key={t}
                                      color={
                                        androidSelectedTerms.find(
                                          term => term === t
                                        )
                                          ? "primary"
                                          : "secondary"
                                      }
                                      className="px-2 py-1 btn-xs btn-outline"
                                    >
                                      {t}
                                    </Badge>
                                  ))}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <Table
                            id="android-term"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>iPhone Terminology</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  {iPhoneTerms.map(t => (
                                    <Badge
                                      onClick={() => iphoneToggleTerms(t)}
                                      style={{
                                        margin: ".5rem",
                                        fontSize: "1rem",
                                        cursor: "pointer",
                                      }}
                                      key={t}
                                      color={
                                        iphoneSelectedTerms.find(
                                          term => term === t
                                        )
                                          ? "primary"
                                          : "secondary"
                                      }
                                      className="px-2 py-1 btn-xs btn-outline"
                                    >
                                      {t}
                                    </Badge>
                                  ))}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="2">
                            <li>
                              <p>
                                <span>{`Tap on the settings icon on your phone:`}</span>
                              </p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <img
                            style={{ maxWidth: "518px", marginTop: "10px" }}
                            width="90px"
                            src="https://mtml.s3.ca-central-1.amazonaws.com/assets/android-cog.png"
                          />
                          <img
                            style={{ maxWidth: "518px", marginLeft: "3rem" }}
                            width="70px"
                            src="https://mtml.s3.ca-central-1.amazonaws.com/assets/iphone-setting.png"
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p>
                                <span>{`In the table above, tap the words you see in your phone’s settings.`}</span>
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div
                            style={{
                              border: "1px solid #5b235c",
                              background: "#efe9ef",
                              flexDirection: "column",
                            }}
                            className="d-flex p-2"
                          >
                            <strong>{`Hint:`}</strong>
                            <span>
                              {`When you find a word from the list above, tap on it.
It will usually lead to more words in the list.`}
                            </span>
                            <span>
                              {`Be sure to tap on lots of different words to fully explore your
security settings.`}
                            </span>
                            <span>
                              {`If you get lost, close the settings app and start over.`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div
                          // style={{ marginTop: "-4rem" }}
                          className="text-center"
                        >
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <span>OK</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Apps</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div
                            style={{
                              border: "1px solid #5b235c",
                              background: "#efe9ef",
                              flexDirection: "column",
                            }}
                            className="d-flex p-2"
                          >
                            <span>
                              {`Follow these instructions to view all of your apps then answer the questions
below.`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p>
                                <span>{`Read the list of terminology specific to your device (iPhone or Android)
as it appears in the table below:`}</span>
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={6}>
                          <Table
                            id="android-term"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Android Instructions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  <ul>
                                    <li>
                                      {`Swipe up from the very bottom
of the screen`}
                                    </li>
                                    <li>
                                      {`Swipe left and right to see
pages of apps`}
                                    </li>
                                  </ul>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <Table
                            id="android-term"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>iPhone Instructions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  <ul>
                                    <li>
                                      {`Swipe left several times until you see
the “App Library” page`}
                                    </li>
                                    <li>
                                      {`Swipe down once to see a full list of
apps`}
                                    </li>
                                  </ul>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Col>
                        <p>*works on most Android and iPhones</p>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="1">
                            <li>
                              <p>
                                <span>{`How many apps do you have on your phone?`}</span>
                              </p>
                              <div style={{ maxWidth: "200px" }}>
                                <Input></Input>
                              </div>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="2">
                            <li>
                              <p>
                                <span>{`Write the name of 3 apps that you use everyday:`}</span>
                              </p>
                              <div style={{ maxWidth: "200px" }}>
                                <Input style={{ marginBottom: "5px" }}></Input>
                                <Input style={{ marginBottom: "5px" }}></Input>
                                <Input style={{ marginBottom: "5px" }}></Input>
                              </div>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p>
                                <span>{`How many have you downloaded but no longer use?`}</span>
                              </p>
                              <div style={{ maxWidth: "200px" }}>
                                <Input></Input>
                              </div>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="4">
                            <li>
                              <p>
                                <span>{`Write the name of three apps that you would delete from your phone:`}</span>
                              </p>
                              <div style={{ maxWidth: "200px" }}>
                                <Input style={{ marginBottom: "5px" }}></Input>
                                <Input style={{ marginBottom: "5px" }}></Input>
                                <Input style={{ marginBottom: "5px" }}></Input>
                              </div>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="5">
                            <li>
                              <p>
                                <span>{`After watching the video, are you more likely to delete unused apps? Why
or why not?`}</span>
                              </p>
                              <div style={{ maxWidth: "400px" }}>
                                <Input
                                  type="textarea"
                                  name="text"
                                  id="exampleText"
                                />
                              </div>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row>
                        <div
                          // style={{ marginTop: "-4rem" }}
                          className="text-center"
                        >
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <span>OK</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">
                            Find your Wi-Fi and Data Icons
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="1">
                            <li>
                              <p>
                                <span>
                                  The icons below represent{" "}
                                  <strong>Wi-Fi</strong> and{" "}
                                  <strong>cellular data</strong>. Icons may
                                  differ depending on the type of phone you have
                                </span>
                              </p>
                              <p>
                                Select the cellular data and Wi-Fi icons for
                                your phone.
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row>
                        <div
                          style={{
                            marginLeft: "2rem",
                            display: "grid",
                            gridTemplateRows: "20px 100px 100px",
                            gridTemplateColumns: "100px 100px 100px",
                          }}
                        >
                          <div></div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Data
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Wi-Fi
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Android
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ maxWidth: "518px", marginTop: "10px" }}
                              width="70px"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/up-down-circle.png"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ maxWidth: "518px", marginTop: "10px" }}
                              width="70px"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/blue-wifi-circle.png"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            iPhone
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ maxWidth: "518px", marginTop: "10px" }}
                              width="70px"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/green-signal-circle.png"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ maxWidth: "518px", marginTop: "10px" }}
                              width="70px"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/blue-wifi-circle.png"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="2">
                            <li>
                              <p>
                                <span>{`Read the list of terminology specific to your device (iPhone or Android)
as it appears in the table below:`}</span>
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={6}>
                          <Table
                            id="android-term"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Android Instructions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  <ul>
                                    <li>
                                      {`Swipe down from the very top of
your screen`}
                                    </li>
                                  </ul>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <Table
                            id="android-term"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>iPhone Instructions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  <ul>
                                    <li>
                                      {`Swipe up from the very bottom of the
screen (iPhone SE, 8 and earlier`}
                                    </li>
                                    <li>
                                      {`Swipe down from top right corner of
screen (iPhone X and later)`}
                                    </li>
                                  </ul>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p>
                                <span>{`Wifi and Data can be turned on and off by tapping on the icon for each.`}</span>
                              </p>
                              <p>
                                When turned off, they appear grey/white. When
                                turned on, they appear as a bright colour like
                                green or blue.
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row>
                        <div
                          style={{
                            marginLeft: "2rem",
                            display: "grid",
                            gridTemplateRows: "100px",
                            gridTemplateColumns: "25px 100px 50px 25px 100px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            ON
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ maxWidth: "518px", marginTop: "10px" }}
                              width="70px"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/blue-wifi-circle.png"
                            />
                          </div>
                          <div></div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            OFF
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ maxWidth: "518px", marginTop: "10px" }}
                              width="70px"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/grey-wifi-circle.png"
                            />
                          </div>
                        </div>
                      </Row>

                      <Row style={{ marginLeft: "1rem", marginTop: "2rem" }}>
                        <Col md={12}>
                          <p>
                            <span>{`Is your Wi-Fi turned on?`}</span>
                          </p>
                          <div style={{ maxWidth: "100px" }}>
                            <Input />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: "1rem", marginTop: "2rem" }}>
                        <Col md={12}>
                          <p>
                            <span>{`Is your data turned on?`}</span>
                          </p>
                          <div style={{ maxWidth: "100px" }}>
                            <Input />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <div
                          // style={{ marginTop: "-4rem" }}
                          className="text-center"
                        >
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <span>OK</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={5}
                >
                  <div style={{ height: "65rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column">
                      <div className="row justify-content-center">
                        {
                          <Col lg="12">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>
                                  Congratulation! you have successfully finished
                                  the lesson!
                                </h5>
                                <p className="text-muted">
                                  If you would like to try again click on the
                                  Restart Lesson button to start over.
                                </p>
                              </div>
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(1);
                                }}
                              >
                                <span>Restart Lesson</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        }
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default StepsToSecurity;
