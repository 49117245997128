import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardTitle, Collapse } from "reactstrap";
import CreatingPassword from "./CreatingPassword";
import CreatingUsername from "./CreatingUsername";

export class TipSheetCreatePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanding: false,
    };
  }

  toggleIsExpanding = () => {
    this.setState(prevState => ({
      isExpanding: !prevState.isExpanding,
    }));
  };
  render() {
    return (
      <div className="accordion mb-3 bg-white" id="accordion-custom">
        <div className="accordion-item">
          <h2 className="accordion-header bg-primary" id="headingOne">
            <button
              className={
                "accordion-button fw-medium" +
                (this.state.isExpanding ? "" : " collapsed")
              }
              type="button"
              onClick={this.toggleIsExpanding}
              style={{ cursor: "pointer" }}
            >
              <CardTitle color="primary">
                Tip Sheet: Creating a Password for New Email Address
              </CardTitle>
            </button>
          </h2>

          <Collapse
            isOpen={this.state.isExpanding}
            className="accordion-collapse"
          >
            <div className="accordion-body">
              <CreatingPassword />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default TipSheetCreatePassword;
