import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardTitle, Col, Collapse, Row } from "reactstrap";
import TipSheetEntryChild from "./TipSheetEntryChild";

const TipSheetEntry = ({ item }) => {
  const { name, shortContent, items } = item;
  const [isCollapse, setIsCorrect] = useState(true);

  const toggleCollapse = () => {
    setIsCorrect(!isCollapse);
  };

  return (
    <div className="accordion mb-3 bg-white" id="accordion-custom">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isCollapse ? "" : " collapsed")
            }
            type="button"
            onClick={toggleCollapse}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{name}</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isCollapse} className="accordion-collapse">
          <div className="accordion-body pb-0">
            <Row>
              <Col md={12}>
                <div className="d-flex justify-content-end">
                  <div>
                    <a
                      href={item.link}
                      target="_blank"
                      rel={"noreferrer"}
                      className="w-lg btn btn-outline-primary btn-sm"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="accordion-body pb-0"
            dangerouslySetInnerHTML={{ __html: shortContent }}
          ></div>
          <div className="accordion-body">
            {items &&
              items.map((a, index) => {
                return <TipSheetEntryChild item={a} key={index} />;
              })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

TipSheetEntry.propTypes = {
  item: PropTypes.object,
};

export default TipSheetEntry;
