export default class Config {
  static get apiServerHost() {
    const hostname = "mtml-escases.azurewebsites.net";
    let apiHost = hostname;
    if (window.location.hostname.indexOf("local") >= 0) {
      apiHost = "localhost:5001";
    }
    return `https://${apiHost}`;
  }
}
