import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

class BadgeButton extends Component {
  render() {
    const { color, label, iconClass, action } = this.props;
    return (
      <Badge
        style={{ cursor: "pointer" }}
        onClick={action}
        color={color}
        className="px-2 py-2 btn-xs btn-outline"
      >
        <span>
          <i style={{ marginRight: "5px" }} className={iconClass} />
          <span>{label}</span>
        </span>
      </Badge>
    );
  }
}

BadgeButton.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  iconClass: PropTypes.string,
  action: PropTypes.func,
};

export { BadgeButton };
