import SelectionBox from "components/Common/SelectionBox";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  FormGroup,
  Label,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";

import UsingYoutubeStepA from "./UsingYoutubeStepA";

const UsingYoutube = ({ item }) => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [titleOfVideo, setTitleOfVideo] = useState(null);
  const [videoView, setVideoView] = useState(null);
  const [videoUpload, setvideoUpload] = useState(null);
  const [videoLike, setVideoLike] = useState(null);
  const [videoDescription, setVideoDescription] = useState(null);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setActiveTab(2);
  };

  const handleOnClick = (name, value) => {
    switch (name) {
      case "titleOfVideo":
        setTitleOfVideo(value);
        break;
      case "videoView":
        setVideoView(value);
        break;
      case "videoUpload":
        setvideoUpload(value);
        break;
      case "videoLike":
        setVideoLike(value);
        break;
      case "videoDescription":
        setVideoDescription(value);
        break;

      case "whatIsWiFiHotspot1":
        setWhatIsWiFiHotspot1(value);
        break;
      case "whatIsWiFiHotspot2":
        setWhatIsWiFiHotspot2(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.name}</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href={item.link}
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div
                    style={{ height: "25rem" }}
                    className="center-container content-text"
                  >
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <UsingYoutubeStepA />
                      <Col md={12}>
                        {
                          <div className="mb-5">
                            <Button
                              style={{
                                width: "fit-content",
                                // marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <span>Next Activity</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        }
                      </Col>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>
                          Locate Important Information about the YouTube Video
                        </strong>
                      </p>
                      <Row>
                        <Col md={6}>
                          <div>
                            <img
                              width={"100%"}
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/107.png"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>What is the title of the video?</Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="How to Move from Android to iPhone "
                              isCorrect={
                                titleOfVideo ===
                                "How to Move from Android to iPhone"
                                  ? true
                                  : null
                              }
                              onClick={() =>
                                handleOnClick(
                                  "titleOfVideo",
                                  "How to Move from Android to iPhone"
                                )
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="Transferring from SM-A525M"
                              isCorrect={
                                titleOfVideo === "Transferring from SM-A525M"
                                  ? false
                                  : null
                              }
                              onClick={() =>
                                handleOnClick(
                                  "titleOfVideo",
                                  "Transferring from SM-A525M"
                                )
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              Does the video have more or less than 1,000,000
                              views?
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="The video has more than 1000,000 views"
                              isCorrect={
                                videoView ===
                                "The video has more than 1000,000 views"
                                  ? true
                                  : null
                              }
                              onClick={() =>
                                handleOnClick(
                                  "videoView",
                                  "The video has more than 1000,000 views"
                                )
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="The video has more than 100,000 views"
                              isCorrect={
                                videoView ===
                                "The video has more than 100,000 views"
                                  ? false
                                  : null
                              }
                              onClick={() =>
                                handleOnClick(
                                  "videoView",
                                  "The video has more than 100,000 views"
                                )
                              }
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>When was the video uploaded?</Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="The video was uploaded on September 24th 2021"
                              isCorrect={videoUpload === "true" ? true : null}
                              onClick={() =>
                                handleOnClick("videoUpload", "true")
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="The video was uploaded on September 24th 2022"
                              isCorrect={videoUpload === "false" ? false : null}
                              onClick={() =>
                                handleOnClick("videoUpload", "false")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>How many likes does the video have?</Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="The video has 24K (24 thousand) likes"
                              isCorrect={videoLike === "true" ? true : null}
                              onClick={() => handleOnClick("videoLike", "true")}
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="The video has 1,815,983 views"
                              isCorrect={videoLike === "false" ? false : null}
                              onClick={() =>
                                handleOnClick("videoLike", "false")
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <Label>
                            Read the description of the video. Write 1-2
                            sentences about what the video is about.
                          </Label>
                          <Input
                            type="textarea"
                            placeholder="Example: The video is about how you can move important information, such as photos, and messages, from an Android phone to an iPhone."
                            name="descriptionSentence"
                            id="descriptionSentence"
                            style={{ height: "200px", marginBottom: "2rem" }}
                          />
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col lg="12">
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <p>
                              <strong>
                                Congratulation! you have successfully finished
                                the lesson!
                              </strong>
                            </p>

                            <p className="text-muted">
                              If you would like to try and create a new click on
                              the Start Lesson again button to start over.
                            </p>
                          </div>
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <span>Restart Lesson</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

UsingYoutube.propTypes = {
  item: PropTypes.object,
};

export default UsingYoutube;
