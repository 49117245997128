import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_BIDS,
  ADD_NEW_BID,
  GET_BID_BY_ID,
  UPDATE_BID,
  DELETE_BID,
  ACCEPT_BID,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getBidsSuccess,
  getBidByIdSuccess,
  addNewBidSuccess,
  getBidsFail,
  acceptBidSucces,
} from "./actions";
import { getServiceReqeustsRequest } from "store/service-request/saga";
import { getServiceRequestsSuccess } from "store/service-request/actions";

const getBidsRequest = query => get("/api/Bid/paging", { params: query });
const addNewBidRequest = bid => post("/api/Bid", bid);
const compareBidServicesRequest = (serviceRequestId, serviceIds) =>
  update(`/api/Bid/${serviceRequestId}/compaireBidServices`, serviceIds);
const getBidByIdRequest = id => get(`/api/Bid/${id}`);
const updateBidRequest = bid => update("/api/Bid", bid);
const deleteBidRequest = id => del(`/api/Bid/${id}`);
const acceptBidRequest = bid => post(`/api/Bid/accept`, bid);

function* fetchBids({ payload }) {
  try {
    const response = yield call(getBidsRequest, payload);
    yield put(getBidsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBidsFail(error));
  }
}

function* addNewBid({ payload }) {
  try {
    const { history, data } = payload;
    yield call(addNewBidRequest, data);
    const response = yield call(getBidsRequest, {});
    yield put(addNewBidSuccess(response));
    history.push("/bid-offer");
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBidsFail(error));
  }
}

function* updateBid({ payload }) {
  try {
    yield call(updateBidRequest, payload);
    const response = yield call(getBidsRequest, {});
    yield put(getBidsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBidsFail(error));
  }
}

function* getBidById({ payload }) {
  try {
    var response = yield call(getBidByIdRequest, payload);
    yield put(getBidByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBidsFail(error));
  }
}

function* acceptBid({ payload }) {
  try {
    const { userId, history, data } = payload;
    var response = yield call(acceptBidRequest, data);
    response = yield call(getServiceReqeustsRequest, { userId });
    yield put(getServiceRequestsSuccess(response));
    history.push("/service-request");
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBidsFail(error));
  }
}

function* deleteBid({ payload }) {
  try {
    yield call(deleteBidRequest, payload);
    const response = yield call(getBidsRequest, {});
    yield put(getBidsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBidsFail(error));
  }
}

function* bidSaga() {
  yield takeEvery(GET_BIDS, fetchBids);
  yield takeEvery(ADD_NEW_BID, addNewBid);
  yield takeEvery(GET_BID_BY_ID, getBidById);
  yield takeEvery(UPDATE_BID, updateBid);
  yield takeEvery(DELETE_BID, deleteBid);
  yield takeEvery(ACCEPT_BID, acceptBid);
}

export default bidSaga;

export { compareBidServicesRequest, getBidsRequest };
