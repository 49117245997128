import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
} from "reactstrap";
import { Speak } from "../components/Common/Speak";
import userIcon from "../assets/images/user-solid.svg";
import building from "../assets/images/building-user-solid.svg";
import photo1 from "../assets/images/photo1.jpg";
import photo2 from "../assets/images/photo2.jpg";
import photo3 from "../assets/images/photo3.jpg";
import photo4 from "../assets/images/photo4.jpg";
import TipSheetCreateUsername from "containers/tips/TipSheetCreateUsername";
import { modules } from "common/data/modules";
import ModulePilotModal from "containers/profile/ModulePilotModal";
import { Chevron } from "../components/Common/Chevron";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };
  }

  toggleModalIsOpen = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };

  handleOnClickProfile = id => {
    this.setState({ modalIsOpen: false });
    const { history } = this.props;
    history.push(`/${id}/search`);
  };

  handleOnDownloadTranscript = () => {
    window.open(
      "https://mtml.s3.ca-central-1.amazonaws.com/assets/home-page/Video+transcript+for+Webapp.docx",
      "_blank"
    );
    window.focus();
  };

  handleOnDownload = () => {
    window.open(
      "https://mtml.s3.ca-central-1.amazonaws.com/assets/home-page/MTML+Webapp+Orientation.mp4",
      "_blank"
    );
    window.focus();
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div style={{ marginTop: "70px" }}>
          <Row>
            <Col md={12}>
              <div
                // className="sider-section"
                style={{
                  height: "628px",
                  // backgroundColor: "#efe9ef",
                  background: "url(hero.png) no-repeat center center fixed",
                  backgroundSize: "cover",
                  backgroundPositionY: "top",
                }}
              >
                {/* <img
                  width={"100%"}
                  style={{ height: "628px" }}
                  src="https://mtml.s3.ca-central-1.amazonaws.com/assets/cover-2.png"
                /> */}
                <Container>
                  <Row>
                    <Col md="6">
                      <div style={{ marginTop: "100px" }}>
                        <h1 className="slider-title">
                          Smartphone Learning Modules{" "}
                        </h1>
                        <h2 className="sub-title">
                          Tools and resources to help you use a smartphone
                        </h2>
                        <div className="mt-5">
                          <Button
                            className="sider-button"
                            href="#"
                            onClick={() => {
                              this.setState({
                                modalIsOpen: true,
                              });
                            }}
                          >
                            Get Started
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
          <Container fluid className="nice-container">
            <Row className="mt-5">
              <Col md={12}>
                <div className="mt-5">
                  <h2 className="second-headline">
                    Who can use these Modules?
                  </h2>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={8}>
                <div>
                  <div className="content-text">
                    <p>
                      <strong>
                        Are you a learner or an individual who wants to improve
                        your skills to use a smartphone?
                      </strong>
                      <Speak
                        text="Are you a learner or an individual who wants to
                      improve your skills to use a smartphone?"
                      />
                    </p>

                    <p style={{ fontSize: "16px" }}>
                      The free resources can help you better understand how to
                      use a smartphone. You can either download the resources or
                      complete interactive activities using any digital device
                      to help you learn how to use your smartphones to:
                    </p>
                    <ul style={{ fontSize: "16px" }}>
                      <li>communicate with others</li>
                      <li>find information on the internet</li>
                      <li>conduct online job searching and applications</li>
                      <li>
                        participate in training or volunteer work.
                        <Speak
                          text="The free resources can help you better understand how to
                          use a smartphone. You can either download
                      the resources or complete interactive activities using any
                      digital device to help you learn how to use your
                      smartphones to: communicate with others. find information on the internet. conduct online job searching and applications, participate in training or volunteer work."
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div style={{}}>
                  <img width={"100%"} src={photo4} />
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div className="d-none d-lg-inline-block">
                  <img width={"100%"} src={photo1} />
                </div>
              </Col>
              <Col md={8}>
                <div className="">
                  <div className="content-text">
                    <p>
                      <strong>
                        Are you an instructor, teacher or practitioner who is
                        looking for resources to teach learners how to use a
                        smartphone?
                      </strong>
                      <Speak
                        text="Are you an instructor, teacher or practitioner who is
                        looking for resources to teach learners how to use a
                        smartphone?"
                      />
                    </p>

                    <p style={{ fontSize: "16px" }}>
                      The free interactive resources will help you develop
                      workshops or programs for your learners. They can be a
                      part of existing workshops or as stand-alone workshops.
                      You can develop programs for youth, seniors and adults.
                      <Speak
                        text="The free interactive resources will help you develop
                        workshops or programs for your learners. They can be a
                        part of existing workshops or as stand-alone workshops.
                        You can develop programs for youth, seniors and adults."
                      />
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div className="d-lg-none">
                  <img width={"100%"} src={photo1} />
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              {/* <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div className="d-none d-lg-inline-block">
                  <img width={"100%"} src={photo2} />
                </div>
              </Col> */}

              <Col md={8}>
                <div className="">
                  <div className="content-text">
                    <p>
                      <strong>
                        Are you an employment service staff who deals with
                        clients who do not know how to use smartphones for job
                        search? Do your clients lack fundamental smartphone
                        skills to complete simple tasks?
                      </strong>
                      <Speak
                        text="Are you an employment service staff who deals with
                        clients who do not know how to use smartphones for job
                        search? Do your clients lack fundamental smartphone
                        skills to complete simple tasks?"
                      />
                    </p>

                    <p style={{ fontSize: "16px" }}>
                      You can use these free modules to create stand-alone
                      workshops, or be a part of existing workshops so that you
                      can help clients with smartphone skills related to:
                    </p>
                    <ul style={{ fontSize: "16px" }}>
                      <li>Applying for jobs</li>
                      <li>On-the-job skills</li>
                      <li>
                        Accessing Employment Services
                        <Speak
                          text="You can use these free modules to create stand-alone
                        workshops, or be a part of existing workshops so that you
                        can help clients with smartphone skills related to: Applying for jobs. On-the-job skills.Accessing Employment Services."
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div>
                  <img width={"100%"} src={photo2} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mt-5 text-center">
                  <h2 className="second-headline">
                    How to Navigate the website?
                  </h2>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={10}>
                <div className="d-flex">
                  <video width="100%" height={"500"} controls playsInline>
                    <source
                      src={
                        "https://mtml.s3.ca-central-1.amazonaws.com/assets/home-page/MTML+Webapp+Orientation.mp4"
                      }
                      type="video/mp4"
                    ></source>
                    <track
                      label="English"
                      kind="subtitles"
                      srcLang="en"
                      // src={videos[0].subtitle}
                      default
                    />
                    <track
                      label="English"
                      kind="subtitles"
                      srcLang="en"
                      default
                    />
                  </video>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={1}></Col>
              <Col md={10}>
                <div className="mt-2 float-end">
                  <FormGroup>
                    <Button
                      color="primary"
                      outline
                      className="w-lg me-2"
                      onClick={() => this.handleOnDownloadTranscript()}
                    >
                      Download Transcript
                    </Button>
                    <Button
                      color="primary"
                      outline
                      className="w-lg"
                      onClick={() => this.handleOnDownload()}
                    >
                      Download Video
                    </Button>
                  </FormGroup>
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mt-5">
                  <div className="content-text">
                    <p>
                      <strong>
                        You can access the tools and resources in any order, or
                        if you are a first-time visitor to the site, you can
                        follow the recommended navigation path below.
                      </strong>{" "}
                      <Speak
                        text="You can access the tools and resources in any order, or
                      if you are a first-time visitor to the site, you can
                      follow the recommended navigation path below."
                      />
                    </p>
                  </div>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex flex-row justify-content-center flex-wrap mt-5">
                        <Chevron
                          title="Step 1"
                          text={"Videos"}
                          icon={"fas fa-film"}
                          descriptionComponent={
                            <div style={{ margin: "2rem", color: "#495057" }}>
                              <p style={{ fontSize: "1rem" }}>
                                A short video on a specific smartphone skill or
                                topic for clients to watch and learn from{" "}
                                <Speak
                                  text="A short video on a specific smartphone skill or topic
                        for clients to watch and learn from"
                                />
                              </p>
                            </div>
                          }
                        />
                        <Chevron
                          title="Step 2"
                          text={"Tip Sheet"}
                          icon={"fas fa-file-alt"}
                          descriptionComponent={
                            <div style={{ margin: "2rem", color: "#495057" }}>
                              <p style={{ fontSize: "1rem" }}>
                                Tip sheets with more information on the topic
                                presented in the video as well as wellness tips
                                <Speak
                                  text="Tip sheets with more information on the topic
                            presented in the video as well as wellness tips"
                                />
                              </p>
                            </div>
                          }
                        />
                        <Chevron
                          title="Step 3"
                          text={"Mini Lesson"}
                          icon={"fas fa-swatchbook"}
                          descriptionComponent={
                            <div style={{ margin: "2rem", color: "#495057" }}>
                              <p style={{ fontSize: "1rem" }}>
                                Interactive learning activities for clients &
                                learners to apply what they have learned in the
                                video
                                <Speak
                                  text="Interactive learning activities for clients & learners
                                to apply what they have learned in the video"
                                />
                                 
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="nice-container">
            <Row>
              <Col md={12}>
                <div className="mt-5 mb-5 text-center">
                  <h1>Learning About Your Smartphone</h1>
                  <p style={{ fontSize: "1rem" }}>
                    <div className="mt-5">
                      <Button
                        className="sider-button"
                        href="#"
                        onClick={() => {
                          this.setState({
                            modalIsOpen: true,
                          });
                        }}
                      >
                        Get Started
                      </Button>
                    </div>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <div style={{ marginTop: "3rem" }}></div>
            </Row>

            <ModulePilotModal
              toggle={this.toggleModalIsOpen}
              isOpen={this.state.modalIsOpen}
              onClick={this.handleOnClickProfile}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.object,
};

export default Profile;
