import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { apiError, loginUser, socialLogin } from "../../store/actions";

import profile from "../../assets/images/profile-img.png";
import { facebook, google } from "../../config";
import logo from "../../assets/images/logo.png";
import lightlogo from "../../assets/images/logo-light.svg";
import { verifyCode } from "../../store/actions";

export class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div
                    style={{ height: "155px" }}
                    className="bg-autotender-banner"
                  ></div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Alert color="primary">
                        Please check your <strong>email</strong> for your
                        confirmation link
                      </Alert>
                      <div className="mt-3 d-grid">
                        {/* <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {this.state.loading ? (
                            <i className="bx bx-loader-alt bx-spin"></i>
                          ) : (
                            <span></span>
                          )}{" "}
                          Log In
                        </button> */}
                        <Link to="/login" className="btn btn-primary btn-block">
                          Login
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Don&apos;t have an account ?
                    <Link to="/register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p>
                  <p>© {new Date().getFullYear()} AutoTender</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Confirm.propTypes = {
  match: PropTypes.object,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  verifyCode: PropTypes.func,
};

const mapStateToProps = ({}) => {
  return {};
};

export default withRouter(connect(mapStateToProps, { verifyCode })(Confirm));
