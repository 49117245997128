import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Input,
  CardHeader,
  Label,
  Badge,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Breadcrumbs from "components/Common/Breadcrumb";

import { getProfile } from "helpers/utils";

import BidOfferEntry from "containers/bid-offer/BidOfferEntry";
import { getServiceRequestByIdRequest } from "store/service-request/saga";
import { addNewBid, clearBidNotify, clearBidError } from "store/actions";
import ConfirmBidModal from "containers/bid-offer/ConfirmBidModal";

export class BidDefail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceRequestId: 0,
      bidServices: [],
      freeCarWash: false,
      freeAirFresher: false,
      freeLoanerCar: false,
      note: "",
      expiryDate: null,
      earliestAvailableDate: null,

      loading: false,
      item: null,
      totalBidPrice: 0,
      submitted: false,
      modalConfirmBid: false,
      bid: null, // bid Object ready to submid
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (id) {
      this.setState({ loading: true });
      getServiceRequestByIdRequest(id).then(data => {
        const { serivces } = data;
        console.log(serivces);
        this.setState({
          serviceRequestId: id,
          bidServices: serivces.map(a => {
            return {
              id: a.id,
              name: a.name,
              price: 0,
              isProduct: a.isProduct,
              isAcceptService: a.isProduct ? true : true,
            };
          }),
          loading: false,
          item: data,
        });
      });
    }
  }

  handleCheckboxChange = e => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  handleOnChangeBidOfferEntry = item => {
    const { bidServices } = this.state;
    let newBidServices = bidServices.map(a => {
      return a.id === item.id ? item : a;
    });
    let totalBidPrice = _.sumBy(newBidServices, a => {
      return a.price;
    });

    this.setState({
      bidServices: newBidServices,
      totalBidPrice,
    });
  };

  toggleModalConfirmBid = () => {
    this.setState(prevState => ({
      modalConfirmBid: !prevState.modalConfirmBid,
    }));
  };

  componentDidUpdate() {
    const { message, error } = this.props;

    toastr.options = {
      timeOut: 5000,
    };
    if (message) {
      toastr.success("Submit the bid offer successful", "Success");
      this.props.clearBidNotify();
    }

    if (error) {
      toastr.error(error, "Error");
      this.props.clearBidError();
    }
  }

  handleSubmit = () => {
    const {
      serviceRequestId,
      bidServices,
      note,
      freeCarWash,
      freeAirFresher,
      freeLoanerCar,
      expiryDate,
      earliestAvailableDate,
    } = this.state;
    const profile = getProfile();

    this.setState({
      submitted: true,
    });

    let benefits = [];

    if (freeCarWash) {
      benefits.push("Free car wash");
    }

    if (freeAirFresher) {
      benefits.push("Free air fresher");
    }

    if (freeLoanerCar) {
      benefits.push("Free loaner car");
    }
    let combindNote = benefits.join("|");

    combindNote += note;
    const data = {
      serviceRequestId,
      note: combindNote,
      status: 1, // Offered
      vendorId: profile.id,
      expiryDate,
      earliestAvailableDate,
      bidServices: bidServices
        .filter(e => e.isAcceptService)
        .map(a => {
          const { name, id, price, isAcceptService } = a;
          return {
            serviceId: id,
            name,
            price,
            note: "",
            isAcceptService,
          };
        }),
    };

    let isValid = data.expiryDate && earliestAvailableDate;
    if (isValid) {
      this.setState({
        bid: data,
        modalConfirmBid: true,
      });
      // this.props.addNewBid({ history, data });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/bid-offer");
  };

  handleSubmitConfirmBid = () => {
    const { history } = this.props;
    const { bid } = this.state;
    if (bid) {
      this.props.addNewBid({ history, data: bid });
      this.setState({
        modalConfirmBid: false,
      });
    }
  };

  render() {
    const {
      item,
      bidServices,
      loading,
      submitted,
      totalBidPrice,
      expiryDate,
      earliestAvailableDate,
      bid,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Bid Offer Detail | AutoTender</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Home"
              backLink={"/bid-offer"}
              breadcrumbItem="Bid Offer Detail"
            />
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {item && (
                  <Row>
                    <Col md={4}>
                      <Card>
                        <CardHeader className="bg-transparent border-bottom d-flex">
                          <div
                            className="d-flex justify-content-start align-items-center"
                            style={{ width: "60px" }}
                          >
                            <span style={{ fontSize: "35px" }}>
                              <i className="fas fa-cog text-danger"></i>
                            </span>
                          </div>
                          <div>
                            <div className="font-size-16 font-bold mb-2">
                              {item.vehicleYear} {item.vehicleMake}{" "}
                              {item.vehicleModel}
                            </div>
                            <div>
                              {item.isOnRims && (
                                <Badge
                                  color={"danger"}
                                  className="px-2 py-2 btn-xs btn-outline m-1"
                                >
                                  <span>Is On Rims </span>
                                </Badge>
                              )}{" "}
                              {item.needStorage && (
                                <Badge
                                  color={"danger"}
                                  className="px-2 py-2 btn-xs btn-outline m-1"
                                >
                                  <span>Needs Storage </span>
                                </Badge>
                              )}
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <p>What is your preferred time for service?</p>
                          <p className="fw-bolder">{item.preferredDayTime}</p>

                          <p>Can you wait up to 1 hour for the service?</p>
                          <p className="fw-bolder">
                            {item.canWaitOneHourForServicing ? (
                              <>Yes</>
                            ) : (
                              <>No</>
                            )}
                          </p>

                          <p>Do you require shuttle service?</p>
                          <p className="fw-bolder">
                            {item.requireShuttle ? <>Yes</> : <>No</>}
                          </p>

                          <p>How long can you wait for?</p>
                          <p className="fw-bolder">{item.maxTimeWithoutCar}</p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card>
                        <CardHeader className="bg-transparent border-bottom">
                          <div className="font-size-16 font-bold text-center pb-3">
                            Bid Offer
                          </div>
                          <Row>
                            <Col md={6}>
                              <div className="row">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-md-3 col-form-label"
                                >
                                  Total $
                                </Label>
                                <Col md={9}>
                                  <Input
                                    type="number"
                                    step={0.01}
                                    value={totalBidPrice}
                                    className="form-control"
                                    placeholder="Total $"
                                    readOnly
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="row">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-md-3 col-form-label"
                                >
                                  Expiry By:
                                </Label>
                                <Col md={9}>
                                  <div
                                    className={
                                      submitted && !expiryDate
                                        ? "is-invalid"
                                        : ""
                                    }
                                  >
                                    <DatePicker
                                      className={
                                        "form-control" +
                                        (submitted && !expiryDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                      selectsStart
                                      name="expiryDate"
                                      selected={expiryDate}
                                      onChange={date =>
                                        this.handleDateChange(
                                          date,
                                          "expiryDate"
                                        )
                                      }
                                      dateFormat="dd-MMM-yyyy"
                                      placeholderText="Expiry Date"
                                      isClearable
                                    />
                                  </div>
                                  {submitted && !expiryDate && (
                                    <div className="invalid-feedback-custom">
                                      Expiry Date is required.
                                    </div>
                                  )}
                                </Col>
                              </div>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row className="justify-content-center">
                            {bidServices.map((item, index) => {
                              return (
                                <Col key={index} md={8}>
                                  <BidOfferEntry
                                    item={item}
                                    onChange={this.handleOnChangeBidOfferEntry}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </CardBody>
                        <CardHeader className="bg-transparent border-bottom"></CardHeader>
                        <CardBody className="">
                          <Row>
                            <Col md={6}>
                              <Label htmlFor="invoiceNo">Benefits</Label>
                              <div className="form-check form-check-primary mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor1"
                                  checked={this.state.freeCarWash}
                                  onChange={e => {
                                    this.setState({
                                      freeCarWash: e.target.checked,
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheckcolor1"
                                >
                                  Free car wash
                                </label>
                              </div>
                              <div className="form-check form-check-primary mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor1"
                                  checked={this.state.freeAirFresher}
                                  onChange={e => {
                                    this.setState({
                                      freeAirFresher: e.target.checked,
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheckcolor1"
                                >
                                  Free air fresher
                                </label>
                              </div>
                              <div className="form-check form-check-primary mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor1"
                                  checked={this.state.freeLoanerCar}
                                  onChange={e => {
                                    this.setState({
                                      freeLoanerCar: e.target.checked,
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheckcolor1"
                                >
                                  Free loaner car
                                </label>
                              </div>
                            </Col>
                            <Col md={6}>
                              <FormGroup
                                className={
                                  submitted && !earliestAvailableDate
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Label>Earliest Available Date</Label>
                                <DatePicker
                                  className={
                                    "form-control" +
                                    (submitted && !earliestAvailableDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  selectsStart
                                  name="earliestAvailableDate"
                                  selected={earliestAvailableDate}
                                  onChange={date =>
                                    this.handleDateChange(
                                      date,
                                      "earliestAvailableDate"
                                    )
                                  }
                                  dateFormat="dd-MMM-yyyy"
                                  placeholderText="Earliest Available Date"
                                  isClearable
                                />
                              </FormGroup>
                              {submitted && !earliestAvailableDate && (
                                <div className="invalid-feedback-custom">
                                  Earliest Available Date is required.
                                </div>
                              )}
                            </Col>
                            <Col md={12}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="invoiceNo">
                                  Additional Information
                                </Label>
                                <Input
                                  type="textarea"
                                  name="note"
                                  rows={3}
                                  placeholder="Additional Information"
                                  value={this.state.note}
                                  onChange={this.handleChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-3">
                        <CardBody>
                          <Button
                            onClick={this.handleCancel}
                            outline
                            color="primary"
                          >
                            {" "}
                            Cancel
                          </Button>{" "}
                          <Button onClick={this.handleSubmit} color="primary">
                            {" "}
                            Bid Offer
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={12}>
                      {bid && (
                        <ConfirmBidModal
                          title="Confirm Your Bid"
                          isOpen={this.state.modalConfirmBid}
                          toggle={this.toggleModalConfirmBid}
                          item={bid}
                          onSubmit={this.handleSubmitConfirmBid}
                        />
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

BidDefail.propTypes = {
  match: PropTypes.object,
  message: PropTypes.string,
  error: PropTypes.string,
  // items: PropTypes.array,
  item: PropTypes.object,
  // loading: PropTypes.bool,
  // itemCount: PropTypes.number,
  // pageCount: PropTypes.number,
  history: PropTypes.object,
  addNewBid: PropTypes.func,
  clearBidNotify: PropTypes.func,
  clearBidError: PropTypes.func,
};

const mapStateToProps = ({ bid }) => {
  return bid;
};

export default withRouter(
  connect(mapStateToProps, { addNewBid, clearBidNotify, clearBidError })(
    BidDefail
  )
);

// export default BidDefail;
