import Information from "containers/sidebar/Information";
import MoreModule from "containers/sidebar/MoreModule";
import React, { Component } from "react";
import { Button, CardTitle, Col, Container, FormGroup, Row } from "reactstrap";
let tranerGuide =
  "https://mtml.s3.ca-central-1.amazonaws.com/guides/Revised+April+2023+-+SP+GUIDE.pdf";

export class ServiceProviderGuide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapse: false,
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isCollapse: !prevState.isCollapse,
    }));
  };

  handleOnSelectedModule = module => {
    const { id } = module;
    window.location = `/service-provider/module/${id}`;
  };

  handleDownloadTrainerGuide = () => {
    window.open(tranerGuide, "_blank");
    window.focus();
  };

  render() {
    const { isCollapse } = this.state;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container fluid>
            <Row>
              <Col md={isCollapse ? 12 : 8}>
                <video width="100%" height={"500"} controls playsInline>
                  <source
                    src="https://mtml.s3.ca-central-1.amazonaws.com/guides/Service+Provider's+Guide.mp4"
                    type="video/mp4"
                  ></source>
                  {/* <track
                    label="English"
                    kind="subtitles"
                    srcLang="en"
                    src={videos[0].subtitle}
                    default
                  />
                  <track
                    label="English"
                    kind="subtitles"
                    srcLang="en"
                    default
                  /> */}
                </video>
                {/* <div className="ratio ratio-16x9">
                  <iframe
                    title="test"
                    allowFullScreen
                    src={"https://www.youtube.com/embed/jEXM96mGGCc"}
                  ></iframe>
                </div> */}
                <h3 className="mt-2 ">
                  Service Provider&apos;s Guide to Piloting Modules - MTML
                  Smartphones and Employment Skills Project
                </h3>
                <div className="mt-2 float-end">
                  <FormGroup>
                    <Button
                      color="primary"
                      outline
                      size="sm"
                      className="w-lg me-2"
                      onClick={this.handleDownloadTrainerGuide}
                    >
                      {`Download Trainer's Guide`}
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      className="w-lg"
                      //   onClick={() => this.handleOnDownload(module)}
                    >
                      Download
                    </Button>
                  </FormGroup>
                </div>
              </Col>
              <Col md={4}>
                <Information profile={this.state.profile} />
                <MoreModule profile={this.state.profile} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceProviderGuide;
