import { call, put, takeEvery } from "redux-saga/effects";
import { SUBMIT_SERVICE_REQUEST_QUESTION } from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import { submitServiceRequestQuestionError } from "./actions";

const allowAnonymousSubmitServiceRequest = data =>
  post("/api/ServiceRequest/allowAnonymous", data);

const submitServiceRequestQuestionRequest = data =>
  post("/api/ServiceRequest", data);

function* submitServiceRequestQuestion({ payload }) {
  try {
    const { history, data } = payload;
    console.log(history, data);
    yield call(submitServiceRequestQuestionRequest, data);
    history.push("/thanks-submission");
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(submitServiceRequestQuestionError(error));
  }
}

function* roleSaga() {
  yield takeEvery(
    SUBMIT_SERVICE_REQUEST_QUESTION,
    submitServiceRequestQuestion
  );
}

export default roleSaga;
export {
  submitServiceRequestQuestionRequest,
  allowAnonymousSubmitServiceRequest,
};
