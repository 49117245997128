import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_USERS,
  GET_PAGED_VENDORS,
  VERIFY_USER,
  GET_USER_BY_ID,
  UPDATE_BY_USER,
  UPDATE_USER_STAR_RATING,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getUsersSuccess,
  getUserByIdSuccess,
  getUsersFail,
  getPagedVendorsSuccess,
  getPagedVendorsFail,
  verifyUserFail,
} from "./actions";

const getUsersRequest = query => get("api/account/paging", { params: query });
const getUserByIdRequest = id => get(`/api/account/${id}`);
const updateUerRequest = user => post("/api/account/updateUser", user);
const updateUserStarRatingRequest = user =>
  post("/api/Account/starRating", user);

const getVendorsRequest = query =>
  get("api/account/vendors", { params: query });

const verifyUserRequest = id => post(`api/account/${id}/verify`);

function* fetchVendors({ payload }) {
  try {
    const response = yield call(getVendorsRequest, payload);
    yield put(getPagedVendorsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPagedVendorsFail(error));
  }
}

function* verifyUser({ payload }) {
  try {
    const { id, page, pageSize, term } = payload;
    const response = yield call(verifyUserRequest, id);
    yield call(fetchVendors, { page, pageSize, term });
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(verifyUserFail(error));
  }
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersRequest, payload);
    yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* updateUer({ payload }) {
  try {
    yield call(updateUerRequest, payload);
    const response = yield call(getUsersRequest, {});
    yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* updateUserStarRating({ payload }) {
  try {
    yield call(updateUserStarRatingRequest, payload);
    const response = yield call(getVendorsRequest, {});
    yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* getUserById({ payload }) {
  try {
    var response = yield call(getUserByIdRequest, payload);
    yield put(getUserByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_ALL_USERS, fetchUsers);
  yield takeEvery(UPDATE_USER_STAR_RATING, updateUserStarRating);
  yield takeEvery(GET_USER_BY_ID, getUserById);
  yield takeEvery(UPDATE_BY_USER, updateUer);
  yield takeEvery(GET_PAGED_VENDORS, fetchVendors);
  yield takeEvery(VERIFY_USER, verifyUser);
}

export default userSaga;
