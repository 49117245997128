import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardTitle, Collapse } from "reactstrap";

const TipSheetEntryChild = ({ item }) => {
  const [isCollapse, setIsCorrect] = useState(true);

  const toggleCollapse = () => {
    setIsCorrect(!isCollapse);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header bg-primary">
        <button
          className={
            "accordion-button fw-medium" + (isCollapse ? "" : " collapsed")
          }
          type="button"
          onClick={toggleCollapse}
          style={{ cursor: "pointer" }}
        >
          <CardTitle>{item.title}</CardTitle>
        </button>
      </h2>

      <Collapse isOpen={isCollapse} className="accordion-collapse">
        <div
          className="accordion-body"
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>
      </Collapse>
    </div>
  );
};

TipSheetEntryChild.propTypes = {
  item: PropTypes.object,
};

export default TipSheetEntryChild;
