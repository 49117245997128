import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, Collapse } from "reactstrap";
import CreatingUsername from "./CreatingUsername";

export class TipSheetCreateUsername extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanding: false,
    };
  }

  toggleIsExpanding = () => {
    this.setState(prevState => ({
      isExpanding: !prevState.isExpanding,
    }));
  };
  render() {
    return (
      <div className="accordion mb-3 bg-white" id="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header bg-primary" id="headingOne">
            <button
              className={
                "accordion-button fw-medium" +
                (this.state.isExpanding ? "" : " collapsed")
              }
              type="button"
              onClick={this.toggleIsExpanding}
              style={{ cursor: "pointer" }}
            >
              <CardTitle>
                Tip Sheet: Creating a Username for New Email Address
              </CardTitle>
            </button>
          </h2>

          <Collapse
            isOpen={this.state.isExpanding}
            className="accordion-collapse"
          >
            <div className="accordion-body">
              <CreatingUsername module={this.props.module} />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

TipSheetCreateUsername.propTypes = {
  module: PropTypes.object,
};

export default TipSheetCreateUsername;
