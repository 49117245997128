import React, { useState } from "react";
import SelectionBox from "components/Common/SelectionBox";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const UnderstandingHowToShareAFile = () => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isCorrect, setIsCorrect] = useState(null);

  const [question1, setQuestion1] = useState(null);
  const [question2, setQuestion2] = useState(null);
  const [question3, setQuestion3] = useState(null);
  const [question4, setQuestion4] = useState(null);
  const [question5, setQuestion5] = useState(null);

  const [questionIcon1, setQuestionIcon1] = useState(null);
  const [questionIcon2, setQuestionIcon2] = useState(null);
  const [questionIcon3, setQuestionIcon3] = useState(null);
  const [questionIcon4, setQuestionIcon4] = useState(null);
  const [questionIcon5, setQuestionIcon5] = useState(null);
  const [questionIcon6, setQuestionIcon6] = useState(null);

  const [lastSubmitted, setLastSubmitted] = useState(null);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 6) {
        setActiveTab(tab);
      }
    }
  };

  const handleOnClick = (name, value) => {
    switch (name) {
      case "question1":
        setQuestion1(value);
        break;
      case "question2":
        setQuestion2(value);
        break;
      case "question3":
        setQuestion3(value);
        break;
      case "question4":
        setQuestion4(value);
        break;
      case "question5":
        setQuestion5(value);
        break;

      case "questionIcon1":
        setQuestionIcon1(value);
        break;
      case "questionIcon2":
        setQuestionIcon2(value);
        break;
      case "questionIcon3":
        setQuestionIcon3(value);
        break;
      case "questionIcon4":
        setQuestionIcon4(value);
        break;
      case "questionIcon5":
        setQuestionIcon5(value);
        break;
      case "questionIcon6":
        setQuestionIcon6(value);
        break;

        questionIcon6;

      default:
        break;
    }
  };

  const handleStartLesson = () => {
    setQuestion1(null);
    setQuestion2(null);
    setQuestion3(null);
    setQuestion4(null);
    setQuestion5(null);

    setQuestionIcon1(null);
    setQuestionIcon2(null);
    setQuestionIcon3(null);
    setQuestionIcon4(null);
    setQuestionIcon5(null);
    setQuestionIcon6(null);

    setLastSubmitted(null);
    setActiveTab(2);
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>How to Learn About Popular Apps</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href="https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/lessons/Jan+16_Council+Fire_Understanding+How+to+Share+a+File.pdf"
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent
                style={{ overflowX: "hidden", overflowY: "auto" }}
                activeTab={activeTab}
              >
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div style={{ height: "65rem" }} className="center-container">
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">
                            Share a Picture by Opening the Gallery App First
                          </p>
                        </Col>
                        <Col md={12}>
                          <p className="">
                            {`Follow the instructions below to share a picture with a trusted friend
or family member. `}
                            <p>
                              <strong>{`*To complete this task, you will need to use Wifi or your
phone data*.`}</strong>
                            </p>
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="1">
                            <li>
                              <p className="mini-lesson-p m-0">{`Tap on your device’s photo gallery app. Your photo gallery app may look
different than the images below depending on the app you are using.`}</p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "2rem" }}
                          >
                            <img
                              style={{
                                width: "200px",
                                height: "80px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/photo-gallery-icons.png"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="2">
                            <li>
                              <p className="mini-lesson-p m-0">{`Select a photo you would like to share.`}</p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p className="mini-lesson-p m-0">{`Tap on the share icon`}</p>
                            </li>
                          </ol>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "2rem" }}
                          >
                            <img
                              style={{
                                width: "180px",
                                height: "80px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/share-icons.png"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="4">
                            <li>
                              <p className="mini-lesson-p m-0">{`Choose the app you would like to use to share the photo. Examples of
apps you can use, include Gmail or WhatsApp.`}</p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "2rem" }}
                          >
                            <img
                              style={{
                                width: "80px",
                                height: "80px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/gmail-icon.png"
                            />
                            <img
                              style={{
                                width: "80px",
                                height: "62px",
                                marginTop: "5px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/whatsapp-icon.png"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="5">
                            <li>
                              <p className="mini-lesson-p m-0">
                                <span>For WhatsApp</span>{" "}
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "0",
                                  }}
                                  width="100%"
                                  src="https://mtml.s3.ca-central-1.amazonaws.com/assets/whatsapp-icon.png"
                                />{" "}
                                {`, scroll though your contacts. Tap on the name of
the person you want to share the photo with.`}
                              </p>
                              <p className="mini-lesson-p m-0">
                                <span>For email</span>{" "}
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "0",
                                  }}
                                  width="100%"
                                  src="https://mtml.s3.ca-central-1.amazonaws.com/assets/gmail-icon.png"
                                />{" "}
                                {`, scroll though your contacts. Tap on the name of
the person you want to share the photo with.`}
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="6">
                            <li>
                              <p className="mini-lesson-p m-0">{`Tap the send button`}</p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row>
                        <div
                          // style={{ marginTop: "-4rem" }}
                          className="text-center"
                        >
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <span>OK</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">
                            Share a Picture by Opening a Messaging/ Social Media
                            App First
                          </p>
                        </Col>
                        <Col md={12}>
                          <div
                            style={{
                              border: "1px solid #5b235c",
                              background: "#efe9ef",
                            }}
                            className="d-flex flex-column p-2"
                          >
                            <p className="m-0">
                              {`Follow the instructions below to share a picture with a trusted friend
or family member. `}
                            </p>
                            <p>
                              <strong>{`*To complete this task, you will need to use Wifi or your
phone data*.`}</strong>
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="1">
                            <li>
                              <p className="mini-lesson-p m-0">{`Open the social media/messaging app you would like to use to share the
picture. Some examples of social media/messaging apps are Gmail,
WhatsApp, or Telegram.`}</p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "2rem" }}
                          >
                            <img
                              style={{
                                width: "80px",
                                height: "80px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/gmail-icon.png"
                            />
                            <img
                              style={{
                                width: "80px",
                                height: "62px",
                                marginTop: "5px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/whatsapp-icon.png"
                            />
                            <img
                              style={{
                                width: "75px",
                                height: "65px",
                                marginTop: "5px",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/telegram-icon.png"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="2">
                            <li>
                              <p className="mini-lesson-p m-0">{`Tap on the recipient (or create a new message and enter the recipient)`}</p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p className="mini-lesson-p m-0">{`Tap on the share icon`}</p>
                            </li>
                          </ol>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "2rem" }}
                          >
                            <img
                              style={{
                                width: "60px",
                                height: "80px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/paperclip-icon.png"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="4">
                            <li>
                              <p className="mini-lesson-p m-0">{`Open the social media/messaging app you would like to use to share the
picture. Some examples of social media/messaging apps are Gmail,
WhatsApp, or Telegram.`}</p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "2rem" }}
                          >
                            <img
                              style={{
                                width: "200px",
                                height: "80px",
                                marginRight: "2rem",
                              }}
                              width="100%"
                              src="https://mtml.s3.ca-central-1.amazonaws.com/assets/photo-gallery-icons.png"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="5">
                            <li>
                              <p className="mini-lesson-p m-0">{`Select the photo to attach to your message`}</p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="6">
                            <li>
                              <p className="mini-lesson-p m-0">{`Tap the send button`}</p>
                            </li>
                          </ol>
                        </Col>
                      </Row>

                      <Row>
                        <div
                          // style={{ marginTop: "-4rem" }}
                          className="text-center"
                        >
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <span>OK</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p"></p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Answer True or False</p>
                        </Col>

                        <Col md={12}>
                          <div
                            style={{
                              border: "1px solid #5b235c",
                              background: "#efe9ef",
                            }}
                            className="d-flex flex-column p-2"
                          >
                            <p className="m-0">
                              {`Read the statements below. Decide if each statement is true or
false. Select the correct answer.`}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              A file can be photos, music, videos, or other
                              documents.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={question1 === "true" ? true : null}
                                onClick={() =>
                                  handleOnClick("question1", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={question1 === "false" ? false : null}
                                onClick={() =>
                                  handleOnClick("question1", "false")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              There is only one way you can share a file.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={question2 === "false" ? false : null}
                                onClick={() =>
                                  handleOnClick("question2", "false")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={question2 === "true" ? true : null}
                                onClick={() =>
                                  handleOnClick("question2", "true")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              If I am in my gallery app, I need to exit the app
                              if I want to share a photo.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={question3 === "false" ? false : null}
                                onClick={() =>
                                  handleOnClick("question3", "false")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={question3 === "true" ? true : null}
                                onClick={() =>
                                  handleOnClick("question3", "true")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Sharing a file means that you are sending a file
                              to a person, or many people.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={question4 === "true" ? true : null}
                                onClick={() =>
                                  handleOnClick("question4", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={question4 === "false" ? false : null}
                                onClick={() =>
                                  handleOnClick("question4", "false")
                                }
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              I am sharing file by first opening a social media
                              and or messaging app. After tapping on the share
                              icon, I can then choose the file I want to share.
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                name=""
                                value="True"
                                isCorrect={question5 === "true" ? true : null}
                                onClick={() =>
                                  handleOnClick("question5", "true")
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="False"
                                isCorrect={question5 === "false" ? false : null}
                                onClick={() =>
                                  handleOnClick("question5", "false")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {question1 === "true" &&
                        question2 === "true" &&
                        question3 === "true" &&
                        question4 === "true" &&
                        question5 === "true" && (
                          <Row>
                            <div
                              // style={{ marginTop: "-4rem" }}
                              className="text-center"
                            >
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Row>
                        )}
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={5}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p"></p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">
                            Identifying ‘Share’ Icons
                          </p>
                        </Col>
                        <Col md={12}>
                          <p>
                            Look at the icons in the chart below. Each symbol is
                            numbered 1-6. Which icons are ‘share’ icons?
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {`From the list of icons below. Select only the ones that are 'Share' icons.`}
                            </Label>
                            <div
                              className="d-flex"
                              style={{ flexWrap: "wrap", display: "flex" }}
                            >
                              <SelectionBox
                                title="1"
                                name=""
                                value={
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="d-flex"
                                    // style={{ marginLeft: "2rem" }}
                                  >
                                    <img
                                      style={{
                                        width: "70px",
                                        height: "50px",
                                        // marginRight: "2rem",
                                      }}
                                      width="100%"
                                      src="https://mtml.s3.ca-central-1.amazonaws.com/assets/share-box-up.png"
                                    />
                                  </div>
                                }
                                isCorrect={
                                  questionIcon1 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("questionIcon1", "true")
                                }
                              />
                              <SelectionBox
                                title="2"
                                name=""
                                value={
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="d-flex"
                                    // style={{ marginLeft: "2rem" }}
                                  >
                                    <img
                                      style={{
                                        width: "60px",
                                        height: "50px",
                                        // marginRight: "2rem",
                                      }}
                                      width="100%"
                                      src="https://mtml.s3.ca-central-1.amazonaws.com/assets/yellow-dots.png"
                                    />
                                  </div>
                                }
                                isCorrect={
                                  questionIcon2 === "false" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "questionIcon2",
                                    questionIcon2 === "false" ? null : "false"
                                  )
                                }
                              />{" "}
                              <SelectionBox
                                title="3"
                                name=""
                                value={
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="d-flex"
                                    // style={{ marginLeft: "2rem" }}
                                  >
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "50px",
                                        // marginRight: "2rem",
                                      }}
                                      width="100%"
                                      src="https://mtml.s3.ca-central-1.amazonaws.com/assets/paperclip-icon.png"
                                    />
                                  </div>
                                }
                                isCorrect={
                                  questionIcon3 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("questionIcon3", "true")
                                }
                              />{" "}
                              <SelectionBox
                                title="4"
                                name=""
                                value={
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="d-flex"
                                    // style={{ marginLeft: "2rem" }}
                                  >
                                    <img
                                      style={{
                                        width: "80px",
                                        height: "50px",
                                        // marginRight: "2rem",
                                      }}
                                      width="100%"
                                      src="https://mtml.s3.ca-central-1.amazonaws.com/assets/signal-tower.png"
                                    />
                                  </div>
                                }
                                isCorrect={
                                  questionIcon4 === "false" ? false : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "questionIcon4",
                                    questionIcon4 === "false" ? null : "false"
                                  )
                                }
                              />{" "}
                              <SelectionBox
                                title="5"
                                name=""
                                value={
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="d-flex"
                                    // style={{ marginLeft: "2rem" }}
                                  >
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        // marginRight: "2rem",
                                      }}
                                      width="100%"
                                      src="https://mtml.s3.ca-central-1.amazonaws.com/assets/circle-plus.png"
                                    />
                                  </div>
                                }
                                isCorrect={
                                  questionIcon5 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("questionIcon5", "true")
                                }
                              />{" "}
                              <SelectionBox
                                title="6"
                                name=""
                                value={
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="d-flex"
                                    // style={{ marginLeft: "2rem" }}
                                  >
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        // marginRight: "2rem",
                                      }}
                                      width="100%"
                                      src="https://mtml.s3.ca-central-1.amazonaws.com/assets/share-tripod.png"
                                    />
                                  </div>
                                }
                                isCorrect={
                                  questionIcon6 === "true" ? true : null
                                }
                                onClick={() =>
                                  handleOnClick("questionIcon6", "true")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {questionIcon1 === "true" &&
                        questionIcon2 === null &&
                        questionIcon3 === "true" &&
                        questionIcon4 === null &&
                        questionIcon6 === "true" &&
                        questionIcon6 === "true" && (
                          <Row>
                            <div
                              // style={{ marginTop: "-4rem" }}
                              className="text-center"
                            >
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Row>
                        )}
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={6}
                >
                  <div style={{ height: "65rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column">
                      <div className="row justify-content-center">
                        {
                          <Col lg="12">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>
                                  Congratulation! you have successfully finished
                                  the lesson!
                                </h5>
                                <p className="text-muted">
                                  If you would like to try again click on the
                                  Restart Lesson button to start over.
                                </p>
                              </div>
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(1);
                                }}
                              >
                                <span>Restart Lesson</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        }
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default UnderstandingHowToShareAFile;
