import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardText,
  Alert,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import classnames from "classnames";
import LeadsTab from "./LeadsTab";
import MyBidTab from "./MyBidTab";
import { getBids, changeLayout, getServiceRequests } from "store/actions";
import { getProfile, isVerifiedVendor } from "helpers/utils";
import { layoutTypes } from "constants/layout";

export class BidOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.props.changeLayout(layoutTypes.VERTICAL);
  }

  handlePopulateLeads = () => {
    const profile = getProfile();
    // Status = 2 is Requested;
    if (profile) {
      const { id } = profile;
      this.props.getServiceRequests({ status: 2, vendorId: id });
    }
  };

  handlePopulateMyBids = () => {
    let userProfile = getProfile();
    const { id } = userProfile;
    this.props.getBids({ userId: id });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Bid Offer | AutoTender</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Bid Offer List" />
            {isVerifiedVendor() ? (
              <Row>
                <Col lg={12}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                          this.handlePopulateLeads();
                        }}
                      >
                        Leads
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                          this.handlePopulateMyBids();
                        }}
                      >
                        My Bids
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <LeadsTab />
                    </TabPane>
                    <TabPane tabId="2">
                      <MyBidTab />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={12}>
                  <Alert color="danger">
                    <i
                      style={{ marginRight: "10px" }}
                      className="fa fa-exclamation-triangle"
                    />
                    <span>
                      You have not been verified. Please contact the administor
                      and request verification.
                    </span>
                  </Alert>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

BidOffer.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getBids: PropTypes.func,
  changeLayout: PropTypes.func,
  getServiceRequests: PropTypes.func,
};

const mapStateToProps = ({ bid }) => {
  return bid;
};

export default withRouter(
  connect(mapStateToProps, { getBids, changeLayout, getServiceRequests })(
    BidOffer
  )
);
