// BID
export const GET_BIDS = "GET_BIDS";
export const GET_BIDS_SUCCESS = "GET_BIDS_SUCCESS";
export const GET_BIDS_FAIL = "GET_BIDS_FAIL";

export const GET_BID_BY_ID = "GET_BID_BY_ID";
export const GET_BID_BY_ID_SUCCESS = "GET_BID_BY_ID_SUCCESS";

export const ADD_NEW_BID = "ADD_NEW_BID";
export const ADD_NEW_BID_SUCCESS = "ADD_NEW_BID_SUCCESS";

export const ACCEPT_BID = "ACCEPT_BID";
export const ACCEPT_BID_SUCCESS = "ACCEPT_BID_SUCCESS";

export const UPDATE_BID = "UPDATE_BID";

export const DELETE_BID = "DELETE_BID";

export const BID_CLEAR_ERROR = "BID_CLEAR_ERROR";
export const BID_CLEAR_NOTIFY = "BID_CLEAR_NOTIFY";
