import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Container, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import VendorsTable from "containers/users/vendors/VendorsTable";

import {
  getPagedVendors,
  verifyUser,
  getUserById,
  updateUserStarRating,
} from "store/user/actions";
import { changeLayout } from "store/actions";
import { layoutTypes } from "constants/layout";
import ModalRatingForm from "./ModalRatingForm";

export class Vendors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      selectedId: null,
      modalRatingIsOpen: false,
    };

    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.handleOnPageSizeChange = this.handleOnPageSizeChange.bind(this);
    this.handleOnVerifyUser = this.handleOnVerifyUser.bind(this);
    this.handleOnSearch = this.handleOnSearch.bind(this);
  }

  toggleRatingModal = () => {
    this.setState(prevState => ({
      modalRatingIsOpen: !prevState.modalRatingIsOpen,
    }));
  };

  componentDidMount() {
    const { currentPage, pageSize, term } = this.state;
    this.props.getPagedVendors({
      page: currentPage,
      pageSize,
      term,
    });
    this.props.changeLayout(layoutTypes.VERTICAL);
  }

  handleOnPageChange = page => {
    const { pageSize, term } = this.state;
    this.props.getPagedVendors({
      page,
      pageSize,
      term,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { term } = this.state;
    this.props.getPagedVendors({
      page: 1,
      pageSize,
      term,
    });
    this.setState({
      pageSize,
    });
  };

  handleOnVerifyUser = id => {
    const { pageSize, page } = this.state;
    this.props.verifyUser({ id, page, pageSize });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    console.log(value);
    this.setState({ term: value });
  };

  handleSearch = e => {
    const { page, pageSize, term } = this.state;
    this.props.getPagedVendors({
      page,
      pageSize,
      term,
    });
  };

  handleOnRating = id => {
    this.setState({
      modalRatingIsOpen: true,
      id,
    });
    this.props.getUserById(id);
  };

  handleSubmitRating = data => {
    this.props.updateUserStarRating(data);
    this.setState({ modalRatingIsOpen: false });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Vendors | AutoTender</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Vendors" />
            <Row>
              <Col md={12}>
                <Card className="mb-2">
                  <CardBody className="pb-1">
                    <Row className="g-2">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          style={{ marginRight: "5px" }}
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-search" /> Search
                        </Button>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-between">
                      <div className="d-flex"></div>

                      <div className="text-sm-end"></div>
                    </div>
                  </CardBody>
                </Card>

                {items && (
                  <VendorsTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                    onVerifyUser={this.handleOnVerifyUser}
                    onRating={this.handleOnRating}
                  />
                )}
                {item && (
                  <ModalRatingForm
                    title="Star Rating"
                    item={item}
                    isOpen={this.state.modalRatingIsOpen}
                    toggle={this.toggleRatingModal}
                    onSubmit={this.handleSubmitRating}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Vendors.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getPagedVendors: PropTypes.func,
  verifyUser: PropTypes.func,
  getUserById: PropTypes.func,
  updateUserStarRating: PropTypes.func,
  changeLayout: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ user }) => {
  return user;
};

export default withRouter(
  connect(mapStateToProps, {
    getPagedVendors,
    verifyUser,
    getUserById,
    updateUserStarRating,
    changeLayout,
  })(Vendors)
);
