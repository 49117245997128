export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";

export const UPDATE_BY_USER = "UPDATE_BY_USER";
export const UPDATE_USER_STAR_RATING = "UPDATE_USER_STAR_RATING";

export const USER_CLEAR_ERROR = "USER_CLEAR_ERROR";

export const GET_PAGED_VENDORS = "GET_PAGED_VENDORS";
export const GET_PAGED_VENDORS_SUCCESS = "GET_PAGED_VENDORS_SUCCESS";
export const GET_PAGED_VENDORS_FAIL = "GET_PAGED_VENDORS_FAIL";

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";
