import React from 'react';
import PropTypes from 'prop-types';
import { CardSubtitle, CardTitle } from "reactstrap";

const MegaMenuItemCard = props => {
  const { menuItem, onClick } = props;
  
  
  return (
      <div
          className="d-flex p-2 megamenu-item"
          onClick={() => onClick(menuItem.id)}
      >
        <div className="flex-shrink-0 me-3">
          <img
              className="rounded avatar-sm"
              alt={menuItem?.name}
              src={menuItem.partnerLogo}
          />
        </div>
        <div className="flex-grow-1">
          <CardTitle>
            {menuItem?.name}
          </CardTitle>
          <CardSubtitle>{menuItem.partnerName}</CardSubtitle>
        </div>
      </div>
  );
};

MegaMenuItemCard.propTypes = {
  menuItem: PropTypes.object,
  onClick: PropTypes.func
};

export default MegaMenuItemCard;