import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Modal,
  Row,
} from "reactstrap";
import userIcon from "../../assets/images/user-solid.svg";
import building from "../../assets/images/building-user-solid.svg";

const ModulePilotModal = ({ isOpen, toggle, onClick }) => {
  return (
    <Modal size="xl" backdrop="static" isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="mb-5 text-center">
          <h4 style={{ fontSize: "1.5rem" }}>
            {" "}
            Learning About Your Smartphone
          </h4>
          <p style={{ fontSize: "1rem" }}>
            Click or tap on an option below to get started
          </p>
        </div>
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-center mb-5">
              <div style={{ width: "800px" }}>
                <Row>
                  <Col md={6}>
                    <Card
                      className="card-hover"
                      style={{ cursor: "pointer" }}
                      onClick={() => onClick("learner")}
                    >
                      <CardHeader className="bg-transparent d-flex justify-content-center">
                        <h3>Learners</h3>
                      </CardHeader>
                      <CardBody>
                        <CardImg height={150} src={userIcon} />
                      </CardBody>
                    </Card>
                    <div>
                      <p style={{ fontSize: "1rem" }}>
                        I am Learning about my Smartphone.
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Card
                      className="card-hover"
                      style={{ cursor: "pointer" }}
                      onClick={() => onClick("service-providers")}
                    >
                      <CardHeader className="bg-transparent d-flex justify-content-center">
                        <h3>Service Providers</h3>
                      </CardHeader>
                      <CardBody>
                        <CardImg height={150} src={building} />
                      </CardBody>
                    </Card>
                    <div className="d-flex justify-content-center">
                      <p style={{ fontSize: "1rem" }}>
                        I am supporting clients who are learning about their
                        smartphones
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

ModulePilotModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onClick: PropTypes.func,
};

export default ModulePilotModal;
