import Information from "containers/sidebar/Information";
import MoreModule from "containers/sidebar/MoreModule";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardFooter,
  CardHeader,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import ruth from "../assets/images/ruth.jpg";
import ryan from "../assets/images/ryan-pike.jpg";

export class SetYourselfUpForSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: "",
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile } = match.params;
    this.setState({
      profile,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container fluid>
            <Row>
              <Col md={8}>
                <div className="mt-5 mb-5 text-center">
                  <h1 className="mb-4">
                    Set Yourself Up for Success While Learning
                  </h1>
                  <p>
                    Did you know that learning something new can make you
                    instantly nervous? Review these guides to get the most from
                    your learning.
                  </p>
                </div>
                <Row>
                  {this.state.profile === "learner" ? (
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-center mb-4"
                      >
                        <iframe
                          height={"800"}
                          width="640"
                          src="https://drive.google.com/file/d/1-K7JMlMOCIJs-3PiMMoZBOxLqviKBkh0/preview"
                        ></iframe>
                      </Col>
                      <Col md={12} className="d-flex justify-content-center">
                        <iframe
                          height={"800"}
                          width="640"
                          src="https://drive.google.com/file/d/1Xz3ccwFNsX3bYRPw4eOAy-I7sasv8eFD/preview"
                        ></iframe>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-center mb-4"
                      >
                        <iframe
                          height={"800"}
                          width="640"
                          src="https://drive.google.com/file/d/1pdRh3JZ6N3v3Ko2LRgYU2vPABlr5lIXu/preview"
                        ></iframe>
                      </Col>
                      <Col md={12} className="d-flex justify-content-center">
                        <iframe
                          height={"800"}
                          width="640"
                          src="https://drive.google.com/file/d/1rKFIwDqnUvZyRuMmf31PpZ9xRCooPRYA/preview"
                        ></iframe>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>

              <Col md={4}>
                <Information profile={this.state.profile} />
                <MoreModule profile={this.state.profile} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

SetYourselfUpForSuccess.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default SetYourselfUpForSuccess;
