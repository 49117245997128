import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Button,
  ButtonGroup,
} from "reactstrap";

import Autosuggest from "react-autosuggest";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "yup-phone";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logoImg from "../../assets/images/logo.png";
import { googleMapService } from "services/google-map-service";

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : languages.filter(
        lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => <div>{suggestion.name}</div>;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",

      companyName: "",
      address: "",
      shopType: "Franchise Dealership",
      phone: "",
      contactName: "",
      latitude: 0.0,
      longitude: 0.0,

      roleId: 3,
      password: "",
      passwordConfirmation: "",
      loading: false,
      isCustomer: true,
      type: null,

      value: "",
      suggestions: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { type } = match.params;
    if (type) {
      if (type === "customer") {
        this.setState({
          isCustomer: true,
          roleId: 3,
          type,
        });
      } else if (type === "vendor") {
        this.setState({
          isCustomer: false,
          roleId: 4,
          type,
        });
      } else {
        this.setState({
          type: null,
        });
      }
    } else {
      this.setState({
        type: null,
      });
    }
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  handleClickShipType = name => {
    this.setState({
      ...this.state,
      shopType: name,
    });
  };

  handleClickCustomer = () => {
    this.setState({
      ...this.state,
      roleId: 3,
      isCustomer: true,
    });
  };

  handleClickVender = () => {
    this.setState({
      ...this.state,
      roleId: 4,
      isCustomer: false,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (value) {
      googleMapService
        .getPlaceAutocompleteRequest({ input: value ?? "" })
        .then(res => {
          const { data } = res;
          if (data) {
            const { predictions } = data;
            this.setState({
              suggestions: predictions.map(item => {
                return {
                  name: item.description,
                };
              }),
            });
          }
        });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleSuggestionOnChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { isCustomer } = this.state;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Search Google Maps...",
      value,
      onChange: this.handleSuggestionOnChange,
    };

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div
                    style={{ height: "155px" }}
                    className="bg-autotender-banner"
                  ></div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          firstName: this.state && this.state.firstName,
                          lastName: (this.state && this.state.lastName) || "",
                          email: (this.state && this.state.email) || "",
                          companyName: this.state.companyName || "",
                          address: this.state.address || "",
                          latitude: this.state.latitude || 0.0,
                          longitude: this.state.longitude || 0.0,
                          shopType: this.state.shopType || "",
                          phone: this.state.phone || "",
                          contactName: this.state.contactName || "",
                          roleId: this.state.roleId,
                          password: (this.state && this.state.password) || "",
                          passwordConfirmation:
                            (this.state && this.state.passwordConfirmation) ||
                            "",
                        }}
                        validationSchema={Yup.object().shape({
                          firstName: isCustomer
                            ? Yup.string().required("Please Enter First Name")
                            : null,
                          lastName: isCustomer
                            ? Yup.string().required("Please Enter Last Name")
                            : null,
                          companyName: !isCustomer
                            ? Yup.string().required("Please Enter Company Name")
                            : null,
                          address: Yup.string().required(
                            "Please Enter Address"
                          ),
                          shopType: !isCustomer
                            ? Yup.string().required("Please Select Shop Type")
                            : null,
                          phone: !isCustomer
                            ? Yup.string()
                                .phone(
                                  "CA",
                                  true,
                                  "Please Enter a valid phone number"
                                )
                                .required("Please Enter Phone")
                            : null,
                          contactName: !isCustomer
                            ? Yup.string().required(
                                "Please Enter Contact Full name"
                              )
                            : null,
                          email: Yup.string()
                            .required("Please Enter Your Email")
                            .email("Invalid Email"),
                          roleId: Yup.number().required(
                            "Please Select Register As"
                          ),
                          password: Yup.string()
                            .required("Please Enter Valid Password")
                            .min(6, "Must be at least 6 characters long."),
                          passwordConfirmation: Yup.string()
                            .required(
                              "Please Enter Valid Password Confirmation"
                            )
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            ),
                        })}
                        validateOnChange={e => {
                          console.log(e);
                        }}
                        onSubmit={values => {
                          this.setState({
                            loading: true,
                          });

                          this.props.registerUser(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched, setFieldValue }) => (
                          <Form className="form-horizontal">
                            {isCustomer && (
                              <>
                                <div className="mb-3">
                                  <Label for="firstName" className="form-label">
                                    First Name
                                  </Label>
                                  <Field
                                    name="firstName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label for="lastName" className="form-label">
                                    Last Name
                                  </Label>
                                  <Field
                                    name="lastName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </>
                            )}
                            {!isCustomer && (
                              <>
                                <div className="mb-3">
                                  <Label
                                    for="companyName"
                                    className="form-label"
                                  >
                                    Company Name
                                  </Label>
                                  <Field
                                    name="companyName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.companyName && touched.companyName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="companyName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="roleId" className="form-label">
                                    Shop Type
                                  </Label>
                                  <div className="d-flex">
                                    <ButtonGroup style={{ width: "100%" }}>
                                      <Button
                                        outline={
                                          !(
                                            this.state.shopType ===
                                            "Franchise Dealership"
                                          )
                                        }
                                        color="primary"
                                        className="btn-block"
                                        onClick={() =>
                                          this.handleClickShipType(
                                            "Franchise Dealership"
                                          )
                                        }
                                      >
                                        Franchise Dealership
                                      </Button>
                                      <Button
                                        outline={
                                          !(
                                            this.state.shopType ===
                                            "After Market Service Dealer"
                                          )
                                        }
                                        color="primary"
                                        className="btn-block"
                                        onClick={() =>
                                          this.handleClickShipType(
                                            "After Market Service Dealer"
                                          )
                                        }
                                      >
                                        After Market Service Dealer
                                      </Button>
                                    </ButtonGroup>
                                  </div>

                                  <ErrorMessage
                                    name="roleId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="phone" className="form-label">
                                    Contact Full Name
                                  </Label>
                                  <Field
                                    name="contactName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.contactName && touched.contactName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="contactName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </>
                            )}

                            <div className="mb-3">
                              <Label for="phone" className="form-label">
                                Phone {isCustomer ? "(Optional)" : ""}
                              </Label>
                              <Field
                                name="phone"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.phone && touched.phone
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="address" className="form-label">
                                Address
                              </Label>

                              <div
                                className={
                                  errors.address && touched.address
                                    ? "is-invalid"
                                    : ""
                                }
                              >
                                <Autosuggest
                                  suggestions={suggestions}
                                  onSuggestionsFetchRequested={
                                    this.onSuggestionsFetchRequested
                                  }
                                  onSuggestionsClearRequested={
                                    this.onSuggestionsClearRequested
                                  }
                                  onSuggestionSelected={(
                                    e,
                                    { suggestionValue }
                                  ) => {
                                    setFieldValue("address", suggestionValue);
                                    googleMapService
                                      .findPlaceFromTextRequest({
                                        input: suggestionValue,
                                      })
                                      .then(res => {
                                        const { data } = res;
                                        if (data) {
                                          const { candidates } = data;
                                          if (
                                            candidates &&
                                            candidates.length > 0
                                          ) {
                                            const { geometry } = candidates[0];
                                            const { latitude, longitude } =
                                              geometry.location;

                                            setFieldValue("latitude", latitude);
                                            setFieldValue(
                                              "longitude",
                                              longitude
                                            );
                                            console.log("latitude:", latitude);
                                            console.log(
                                              "longitude:",
                                              longitude
                                            );
                                          }
                                        }
                                      });
                                  }}
                                  getSuggestionValue={getSuggestionValue}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={inputProps}
                                />
                              </div>

                              <ErrorMessage
                                name="address"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            {!this.state.type && (
                              <div className="mb-3">
                                <Label for="roleId" className="form-label">
                                  Register As
                                </Label>
                                <div className="d-flex">
                                  <ButtonGroup style={{ width: "100%" }}>
                                    <Button
                                      outline={!(this.state.roleId === 3)}
                                      color="primary"
                                      className="btn-block"
                                      onClick={this.handleClickCustomer}
                                    >
                                      Customer
                                    </Button>
                                    <Button
                                      outline={!(this.state.roleId === 4)}
                                      color="primary"
                                      className="btn-block"
                                      onClick={this.handleClickVender}
                                    >
                                      Vendor
                                    </Button>
                                  </ButtonGroup>
                                </div>

                                <ErrorMessage
                                  name="roleId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}

                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                autoComplete="true"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirmPassword"
                                className="form-label"
                              >
                                Password Confirmation
                              </Label>
                              <Field
                                name="passwordConfirmation"
                                autoComplete="true"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.passwordConfirmation &&
                                  touched.passwordConfirmation
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="passwordConfirmation"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {this.state.loading && (
                                  <span
                                    style={{ width: "1rem", height: "1rem" }}
                                    className="spinner-border"
                                  ></span>
                                )}{" "}
                                Register
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you agree to the AutoTender{" "}
                                <Link to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} AutoTender</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
