import React, { useCallback } from "react";
import PropTypes from "prop-types";
import chevron from "../../assets/images/purple-chevron.png";

const Chevron = ({ text, icon, onClick, descriptionComponent, title }) => {
  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ maxWidth: "300px" }}
    >
      <div style={{ fontWeight: "bold", fontSize: "20px" }}>{title}</div>
      <div
        className="d-flex flex-column justify-content-center position-relative align-items-center chevron-hover"
        style={{
          color: "rgb(255, 255, 255)",
        }}
        onClick={onClick}
      >
        <img
          src={chevron}
          style={{
            width: "300px",
            position: "relative",
          }}
        />
        <div
          className="d-flex flex-column justify-content-center align-items-center position-absolute"
          style={{
            color: "#ffffff",
          }}
        >
          <i className={icon} style={{ fontSize: "2rem" }}></i>
          <span style={{ fontSize: "1rem" }}>{text}</span>
        </div>
      </div>
      <div>{descriptionComponent}</div>
    </div>
  );
};

Chevron.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  descriptionComponent: PropTypes.any,
  title: PropTypes.string,
  opacity: PropTypes.string,
};

export { Chevron };
