import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "./actionTypes";

export const getPagedNotifications = query => ({
  type: GET_NOTIFICATIONS,
  payload: query,
});

export const getPagedNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const getPagedNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});
