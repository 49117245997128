import React from "react";
import { useMediaQuery } from "react-responsive";

export const withResponsive = Component => {
  return props => {
    const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
    const isTablet = useMediaQuery({ query: "(max-width: 767px)" });

    return <Component isMobile={isMobile} isTablet={isTablet} {...props} />;
  };
};
