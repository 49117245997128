import { VERIFY_CODE, VERIFY_CODE_SUCCESS } from "./actionTypes";

export const verifyCode = (values, history) => {
  return {
    type: VERIFY_CODE,
    payload: { values, history },
  };
};

export const verifyCodeSucess = user => {
  return {
    type: VERIFY_CODE_SUCCESS,
    payload: user,
  };
};
