import React, { useEffect, useState } from "react";
import { H5P } from "h5p-standalone"; // ES6
import PropTypes from "prop-types";

const H5PPlayer = ({ src }) => {
  useEffect(() => {
    if (src) {
      const el = document.getElementById("h5p-container");
      const options = {
        h5pJsonPath: src, // ,
        frameJs: process.env.PUBLIC_URL + "/h5p/dist/frame.bundle.js",
        frameCss: process.env.PUBLIC_URL + "/h5p/dist/styles/h5p.css",
      };

      new H5P(el, options);
    }
  }, [src]);

  return <div id="h5p-container"></div>;
};

H5PPlayer.propTypes = {
  src: PropTypes.string,
};

export { H5PPlayer };
