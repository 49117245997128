import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardTitle, Collapse } from "reactstrap";

export class SubAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapse: false,
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isCollapse: !prevState.isCollapse,
    }));
  };

  render() {
    const { item } = this.props;
    return (
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary">
          <button
            className={
              "accordion-button fw-medium" +
              (this.state.isCollapse ? "" : " collapsed")
            }
            type="button"
            onClick={this.toggleCollapse}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.title}</CardTitle>
          </button>
        </h2>

        <Collapse isOpen={this.state.isCollapse} className="accordion-collapse">
          <div
            className="accordion-body"
            dangerouslySetInnerHTML={{ __html: item.content }}
          ></div>
        </Collapse>
      </div>
    );
  }
}

SubAccordion.propTypes = {
  item: PropTypes.object,
};

export default SubAccordion;
