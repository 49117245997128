import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { bidStatusOptions, getProfile, handleDateChange } from "helpers/utils";
import MyBidTable from "containers/bid-offer/MyBidTable";
import Select from "react-select";
import { getBids, getBidById } from "store/actions";
import moment from "moment";
import BidViewDetailsModal from "containers/bid-offer/BidViewDetailsModal";

export class MyBidTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      userId: null,

      startDate: null,
      endDate: null,
      status: null,
      modalViewDetails: false,
    };
  }

  toggleModalViewDetails = () => {
    this.setState(prevState => ({
      modalViewDetails: !prevState.modalViewDetails,
    }));
  };

  componentDidMount() {
    let userProfile = getProfile();
    const { id } = userProfile;
    this.setState({
      userId: id,
    });
  }

  handleOnSearch = e => {
    const { userId } = this.state;
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getBids({ userId, term: value ?? "" });
  };

  handleOnPageChange = page => {
    const { userId, pageSize } = this.state;
    this.props.getBids({
      userId,
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { userId } = this.state;
    this.props.getBids({
      userId,
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleSearch = () => {
    const { userId, term, startDate, endDate, status } = this.state;
    this.props.getBids({
      userId,
      status: status?.value ?? null,
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
    });
  };

  handleOnViewDetails = id => {
    this.setState({
      id,
      modalViewDetails: true,
    });
    this.props.getBidById(id);
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, acceptedCount, pageCount, loading } =
      this.props;
    let TotalFee = acceptedCount * 5.99;

    return (
      <>
        <Card className="mb-2">
          <CardBody className="pb-1">
            <Row className="g-2">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <FormGroup style={{ marginRight: "5px" }}>
                  <DatePicker
                    className="form-control"
                    selectsStart
                    name="startDate"
                    selected={this.state.startDate}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={date => handleDateChange(this, date, "startDate")}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Start Date"
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup style={{ marginRight: "5px" }}>
                  <DatePicker
                    className="form-control"
                    name="endDate"
                    selectsEnd
                    selected={this.state.endDate}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={date => handleDateChange(this, date, "endDate")}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="End Date"
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <Select
                  name="status"
                  placeholder="Status"
                  value={this.state.status}
                  options={bidStatusOptions()}
                  onChange={this.handleSelectChange}
                  isClearable
                />
              </Col>
              <Col md={2}>
                <Button
                  type="button"
                  outline
                  color="primary"
                  style={{ marginRight: "5px" }}
                  onClick={this.handleSearch}
                >
                  <i className="fas fa-filter" /> Filter
                </Button>
              </Col>
              <Col md={2} className="text-lg-end">
                <h5 className="font-size-14 mb-1">
                  Accepted Bid:{" "}
                  <Badge pill color="success">
                    {acceptedCount}
                  </Badge>
                </h5>
                <h5 className="font-size-14 mb-0 text-end">
                  Total Fees: ${TotalFee ? Number(TotalFee).toFixed(2) : "N/A"}
                </h5>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {items && (
          <MyBidTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
            onViewDetails={this.handleOnViewDetails}
          />
        )}

        {item && (
          <BidViewDetailsModal
            title={"Bid Summary"}
            item={item}
            isOpen={this.state.modalViewDetails}
            toggle={this.toggleModalViewDetails}
          />
        )}
      </>
    );
  }
}

MyBidTab.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  acceptedCount: PropTypes.number,
  getBids: PropTypes.func,
  getBidById: PropTypes.func,
};

const mapStateToProps = ({ bid }) => {
  return bid;
};

export default withRouter(
  connect(mapStateToProps, {
    getBids,
    getBidById,
  })(MyBidTab)
);
