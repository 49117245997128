import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Tooltip,
  Badge,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displaySerivceRequestStatus } from "helpers/utils";
import ServiceRequestRow from "./ServiceRequestRow";

export class ServiceRequestTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      // onEdit,
      // onConfirmDelete,
      // onAddNew,
      // onSearch,
      onChangePage,
      onPageSizeChange,
      onConfirmAccept,
      onConfirmBookNow,
    } = this.props;
    return (
      <>
        <div className="table-rep-plugin">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              {items.map((item, index) => {
                let num = (currentPage - 1) * defaultPageSize + (index + 1);
                return (
                  <ServiceRequestRow
                    key={index}
                    item={item}
                    num={num}
                    onConfirmAccept={onConfirmAccept}
                    onConfirmBookNow={onConfirmBookNow}
                  />
                );
              })}
            </div>
          )}
        </div>

        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </>
    );
  }
}

ServiceRequestTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onConfirmAccept: PropTypes.func,
  onConfirmBookNow: PropTypes.func,
};

export default ServiceRequestTable;
