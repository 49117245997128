import { VERIFY_CODE, VERIFY_CODE_SUCCESS } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const verifyCode = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_CODE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VERIFY_CODE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default verifyCode;
