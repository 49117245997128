import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeLayout } from "store/actions";
import { Container } from "reactstrap";
import { layoutTypes } from "constants/layout";
import { getProfile } from "helpers/utils";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    console.log(getProfile());
    this.props.changeLayout(layoutTypes.VERTICAL);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | AutoTender</title>
          </MetaTags>
          <Container fluid>
            {/* <PDFViewer showToolbar={true} width={500}>
              <DemoPDF />
            </PDFViewer> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  changeLayout: PropTypes.func,
};

const mapStateToProps = ({ Layout }) => {
  return Layout;
};

export default withRouter(
  connect(mapStateToProps, {
    changeLayout,
  })(Dashboard)
);
