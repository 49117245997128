import {
  SUBMIT_SERVICE_REQUEST_QUESTION,
  SUBMIT_SERVICE_REQUEST_QUESTION_SUCCESS,
  SUBMIT_SERVICE_REQUEST_QUESTION_ERROR,
} from "./actionTypes";

const initState = {
  item: null,
  error: null,
  loading: false,
};

const SerivceRequestQuestion = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUBMIT_SERVICE_REQUEST_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_SERVICE_REQUEST_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_SERVICE_REQUEST_QUESTION_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default SerivceRequestQuestion;
