import SelectionBox from "components/Common/SelectionBox";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const IconAtTopOfScreen = ({ item }) => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [icon1, setIcon1] = useState(null);
  const [icon2, setIcon2] = useState(null);
  const [icon3, setIcon3] = useState(null);

  const [wifiIcon1, setWifiIcon1] = useState(null);
  const [wifiIcon2, setWifiIcon2] = useState(null);
  const [wifiIcon3, setWifiIcon3] = useState(null);

  const [fileDownload1, setFileDownload1] = useState(null);
  const [fileDownload2, setFileDownload2] = useState(null);
  const [fileDownload3, setFileDownload3] = useState(null);

  const [phoneBattery1, setPhoneBattery1] = useState(null);
  const [phoneBattery2, setPhoneBattery2] = useState(null);
  const [phoneBattery3, setPhoneBattery3] = useState(null);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setActiveTab(2);
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.name}</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href={item.link}
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div
                    style={{ height: "25rem" }}
                    className="center-container content-text"
                  >
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>
                          Multiple choice: Choose the best answer for each
                          question
                        </strong>
                      </p>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label>
                              Icons at the top of your Smartphone screen are?
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Photos that represent things on your phone"
                              isCorrect={icon1 ? false : null}
                              onClick={() => setIcon1(!icon1)}
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="An idol representing something that is very important."
                              isCorrect={icon2 ? false : null}
                              onClick={() => setIcon2(!icon2)}
                            />
                            <SelectionBox
                              title="C"
                              name=""
                              value="Small images that represent a program or a function on your phone"
                              isCorrect={icon3 ? true : null}
                              onClick={() => setIcon3(!icon3)}
                            />
                          </FormGroup>

                          <FormGroup>
                            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/116.png" />
                            <Label>is an icon for:</Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Cellular wireless connection to the internet."
                              isCorrect={wifiIcon1 ? false : null}
                              onClick={() => setWifiIcon1(!wifiIcon1)}
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="Wi-Fi wireless connection to the internet."
                              isCorrect={wifiIcon2 ? true : null}
                              onClick={() => setWifiIcon2(!wifiIcon2)}
                            />
                            <SelectionBox
                              title="C"
                              name=""
                              value="Wireless radio on your phone."
                              isCorrect={wifiIcon3 ? false : null}
                              onClick={() => setWifiIcon3(!wifiIcon3)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/117.png" />
                            <Label>is an icon for:</Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Go to bottom of phone screen"
                              isCorrect={fileDownload1 ? false : null}
                              onClick={() => setFileDownload1(!fileDownload1)}
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="App download "
                              isCorrect={fileDownload2 ? false : null}
                              onClick={() => setFileDownload2(!fileDownload2)}
                            />
                            <SelectionBox
                              title="C"
                              name=""
                              value="File download"
                              isCorrect={fileDownload3 ? true : null}
                              onClick={() => setFileDownload3(!fileDownload3)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/118.png" />
                            <Label>is an icon for:</Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Turn phone on its side"
                              isCorrect={phoneBattery1 ? false : null}
                              onClick={() => setPhoneBattery1(!phoneBattery1)}
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="Phone battery "
                              isCorrect={phoneBattery2 ? true : null}
                              onClick={() => setPhoneBattery2(!phoneBattery2)}
                            />
                            <SelectionBox
                              title="C"
                              name=""
                              value="Battery recharge"
                              isCorrect={phoneBattery3 ? false : null}
                              onClick={() => setPhoneBattery3(!phoneBattery3)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col lg="12">
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <p>
                              <strong>
                                Congratulation! you have successfully finished
                                the lesson!
                              </strong>
                            </p>
                            <p className="text-muted">
                              If you would like to try and create a new click on
                              the Start Lesson again button to start over.
                            </p>
                          </div>
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <span>Restart Lesson</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

IconAtTopOfScreen.propTypes = {
  item: PropTypes.object,
};

export default IconAtTopOfScreen;
