// SERVICE_REQUEST
export const GET_SERVICE_REQUESTS = "GET_SERVICE_REQUESTS";
export const GET_SERVICE_REQUESTS_SUCCESS = "GET_SERVICE_REQUESTS_SUCCESS";
export const GET_SERVICE_REQUESTS_FAIL = "GET_SERVICE_REQUESTS_FAIL";

export const GET_SERVICE_REQUEST_BY_ID = "GET_SERVICE_REQUEST_BY_ID";
export const GET_SERVICE_REQUEST_BY_ID_SUCCESS =
  "GET_SERVICE_REQUEST_BY_ID_SUCCESS";

export const ADD_NEW_SERVICE_REQUEST = "ADD_NEW_SERVICE_REQUEST";

export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";

export const SERVICE_REQUEST_CLEAR_ERROR = "SERVICE_REQUEST_CLEAR_ERROR";
