import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Breadcrumbs from "components/Common/Breadcrumb";
import ServiceRequestTable from "containers/service-request/ServiceRequestTable";
import {
  getServiceRequests,
  clearServiceRequestError,
} from "store/service-request/actions";
import { getProfile, handleDateChange } from "helpers/utils";
import { changeLayout } from "store/actions";
import moment from "moment";
import { layoutTypes } from "constants/layout";
import ConfirmAcceptBidModal from "containers/bid-offer/ConfirmAcceptBidModal";
import { acceptBid } from "store/actions";
import ConfirmBookNowModal from "containers/bid-offer/ConfirmBookNowModal";

export class ServiceRequestList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,

      startDate: null,
      endDate: null,
      modalConfirmAccept: false,
      modalConfirmBookNow: false,
      selectedId: null,
      bidData: null,
    };
  }

  toggleModalConfirmAccept = () => {
    this.setState(prevState => ({
      modalConfirmAccept: !prevState.modalConfirmAccept,
    }));
  };

  toggleModalConfirmBookNow = () => {
    this.setState(prevState => ({
      modalConfirmBookNow: !prevState.modalConfirmBookNow,
    }));
  };

  componentDidMount() {
    let userProfile = getProfile();
    if (userProfile) {
      const { id } = userProfile;
      this.props.getServiceRequests({ userId: id });
    }
    this.props.changeLayout(layoutTypes.VERTICAL);
  }

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getServiceRequests({ term: value ?? "" });
  };

  handleSearch = () => {
    const { term, startDate, endDate } = this.state;
    this.props.getServiceRequests({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
    });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getServiceRequests({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getServiceRequests({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  componentDidUpdate() {
    const { error } = this.props;

    toastr.options = {
      timeOut: 5000,
    };

    if (error) {
      const { errorMessage } = error;

      toastr.error(errorMessage, "Error");
      this.props.clearServiceRequestError();
    }
  }

  handleOnConfirmAccept = data => {
    this.setState({
      bidData: data,
      modalConfirmAccept: true,
    });
  };

  handleSubmitAcceptBid = data => {
    let userProfile = getProfile();
    const { history } = this.props;
    const { bidData } = this.state;

    if (userProfile) {
      const { id } = userProfile;

      if (bidData) {
        const newData = {
          customerId: id,
          bidId: bidData.id,
          preferedContactMethod: data.preferedContactMethod,
          phone: data.phone,
          bidServices: bidData.bidServices,
        };
        this.props.acceptBid({ userId: id, history, data: newData });
        this.setState({
          modalConfirmAccept: false,
          bidData: null,
        });
      }
    }
  };

  handleOnConfirmBookNow = id => {
    this.setState({
      selectedId: id,
      modalConfirmBookNow: true,
    });
  };

  handleSubmitBookNow = () => {
    this.setState({
      modalConfirmBookNow: false,
    });
  };

  handleFindMyDeal = () => {
    const { history } = this.props;
    history.push("/find-my-deal");
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Service Request | AutoTender</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Service Request List" />
            <Row>
              <Col md={12}>
                <Card className="mb-2">
                  <CardBody className="pb-1">
                    <Row className="g-2">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup style={{ marginRight: "5px" }}>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup style={{ marginRight: "5px" }}>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          style={{ marginRight: "5px" }}
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-search" /> Search
                        </Button>
                      </Col>
                      <Col md={4} className="text-lg-end">
                        <Button
                          type="button"
                          color="primary"
                          onClick={this.handleFindMyDeal}
                        >
                          Find My Deal
                        </Button>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-between">
                      <div className="d-flex"></div>

                      <div className="text-sm-end">
                        {/* <Button
                          type="button"
                          color="primary"
                          onClick={this.handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>

                        <Button
                          type="button"
                          color="primary"
                          outline
                          size="md"
                          style={{ marginLeft: "5px" }}
                          onClick={this.handleExportToExcel}
                        >
                          <i className="fas fa-file-excel" /> Export to Excel
                        </Button> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>

                {items && (
                  <ServiceRequestTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                    onConfirmAccept={this.handleOnConfirmAccept}
                    onConfirmBookNow={this.handleOnConfirmBookNow}
                  />
                )}
                <ConfirmAcceptBidModal
                  title="Confirm Accept Bid Offer"
                  isOpen={this.state.modalConfirmAccept}
                  toggle={this.toggleModalConfirmAccept}
                  onSubmit={this.handleSubmitAcceptBid}
                />
                <ConfirmBookNowModal
                  title="Confirm Book Bid Offer"
                  isOpen={this.state.modalConfirmBookNow}
                  toggle={this.toggleModalConfirmBookNow}
                  onSubmit={this.handleSubmitBookNow}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ServiceRequestList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getServiceRequests: PropTypes.func,
  clearServiceRequestError: PropTypes.func,
  changeLayout: PropTypes.func,
  history: PropTypes.object,
  acceptBid: PropTypes.func,
};

const mapStateToProps = ({ serviceReq }) => {
  return serviceReq;
};

export default withRouter(
  connect(mapStateToProps, {
    getServiceRequests,
    clearServiceRequestError,
    changeLayout,
    acceptBid,
  })(ServiceRequestList)
);
