import moduleThumbnail from "../../assets/images/module-thumbnail.png";

const modules = [
  {
    id: 1,
    name: "Creating a Username and Password",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/videos/sddefault.jpg",
    partnerName: "LAMP",
    partnerWebsite: "https://lampchc.org",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png",
    willLearnContent: `<ul>
      <li>The definition of a username</li>
      <li>Suggestions for creating a username for personal and professional purposes</li>
      <li>Tips for creating a strong password</li>
      <li>Tips for remembering your passwords</li>
    </ul>`,
    greatForAnyoneContent: `<ul>
    <li>Is new to creating an email address</li>
    <li>Wants to learn more about usernames and passwords</li>
    <li>Needs to create a professional email address to connect with employers or submit job applications</li>
    <li>Needs to create an email address to use employment resources like LinkedIn or Magnet</li>
    </ul>`,
    tipSheets: [
      {
        id: 1,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/tipsheets/Creating+a+Password+for+Email+Address+Tip+Sheet.pdf",
        key: "modules/Creating a Username and Password/tipsheets/Creating a Password for Email Address Tip Sheet.pdf",
      },
      {
        id: 2,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/tipsheets/Creating+a+Username+for+Email+Address+Tip+Sheet.pdf",
        key: "modules/Creating a Username and Password/tipsheets/Creating a Username for Email Address Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/lessons/LAMP-Create+Username+_+Password_SL.pdf",
        key: "modules/Creating a Username and Password/lessons/LAMP-Create Username _ Password_SL.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/6iPbyZZZ7mA",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/videos/1.+Creating+a+Username+and+Password++-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Creating+a+Username+and+Password/videos/english.vtt`,
        key: "modules/Creating a Username and Password/videos/1. Creating a Username and Password  - HD 1080p.mov",
      },
    ],
    transcript: `https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/videos/1.+Creating+a+Username+and+Password+for+a+New+Email+Address+.docx`,
  },
  {
    id: 2,
    name: "Using your phone to create a hotspot",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+your+phone+to+create+a+hotspot/videos/sddefault.jpg",
    partnerName: "LAMP",
    partnerWebsite: "https://lampchc.org",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png",
    willLearnContent: `<ul>
    <li>The definition of a hotspot</li>
    <li>The cost of using the hotspot feature of your smartphone</li>
    <li>When you should use the hotspot feature on your smartphone</li>
    <li>How to enable the hotspot feature on your smartphone</li>
  </ul>`,
    greatForAnyoneContent: `<ul>
  <li>Wants to learn about how they might have internet access without wi-fi</li>
  <li>Needs to send a resume, email an employer etc. on the go</li>
  </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Understanding Cellular Data TipSheet",
        shortContent: `<p><strong>Instructions:</strong> Use the tip sheet to answer any questions you might still
        have about cellular data. You do not need to read the entire tip sheet.
        Instead, find the heading that is related to your question and read the
        information under that heading. </p>`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          ,
          {
            id: 2,
            title:
                "Is a cellular data plan needed to turn my smartphone into a hotspot?",
            content: `<ul>
              <li>Yes, a cellular data plan to use your smartphone as a hotspot.</li>
            </ul>`,
          },
          {
            id: 3,
            title: "What is cellular data on my smartphone? ",
            content: `<ul>
                        <li>Cellular data is one way you can connect to the internet on your
                        device. You might use cellular data when wi-fi is not available.
                       
                        </li>
                        <li>Cellular data provides internet connection to your smartphone
                        through a network broadcasted by cell towers.  <br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/12.png"/></li>
                        <li>You usually need to pay for cellular data through your smartphone’s
                        carrier, like Rogers, Koodo, Telus, Bell, Fido etc. </li>
                      </ul>`,
          },
          {
            id: 4,
            title: "How much does a cellular data plan cost?",
            content: `Every plan is different. Some plans give you unlimited data for a
            certain amount of money. Some plans may specify a certain amount
            of Gigabytes (GB) or Megabytes (MB) of data for a certain amount of
            money`,
          },
          {
            id: 5,
            title: "What does the word ‘data’ mean?",
            content: `In this case, data refers to electronic information that is sent from or
            to your smartphone whenever you are doing something online, like
            watching YouTube videos, making a video call, or searching on
            Google etc. 
            <br/>
            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/17.png"/>
            `,
          },
          {
            id: 6,
            title: "What are Gigabytes (GB) and Megabytes (MB)?",
            content: `<ul>
                        <li>Gigabytes and megabytes refer to units of measurement. In this case,
                        they refer to how much data can be sent and received by your
                        smartphone under your cellular data plan.</li>
                      <li>A plan with megabytes of data will allow you to do less online than a
                      plan with gigabytes of data. A gigabyte is bigger than a megabyte.
                      <br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/18.png"/></li>
                      <li>A cellular data plan with 8 GB of data will allow you to do more online
                      than a cellular data plan with 6 GB of data.</li>
                      <li>To learn more about how much data online activities may use, click
                      here to access a mobile data calculator:<a href="https://www.fido.ca/web/content/datacatalogue/data_calculator%26lang%3Den"> Mobile Data Calculator |
                      Calculate your Data Usage | Fido</a></li>
                      </ul>
            `,
          },
          {
            id: 7,
            title: "How do I know if I have a data plan for my smartphone? ",
            content: `The best way to know if you have a data plan on your smartphone is
            to check your cell phone bill, as it will usually be listed there. If it is not
            listed there, you can call your smartphone carrier.`,
          },
          {
            id: 8,
            title: "Will using a hotspot impact my cellular data plan?",
            content: `Yes, using your smartphone as a hotspot can impact your cellular
            data plan. This will depend on the activities you are doing online. It
            will also depend the cellular data plan you have. `,
          },
          {
            id: 9,
            title: "How can I check my data usage on my smartphone?",
            content: `<ul>
                        <li>For Android users, click here to learn how: <a href="https://www.androidpolice.com/check-data-use-android-phone-tablet/"  target="_blank" rel="noreferrer">How to check your data
                        use on your Android phone or tablet (androidpolice.com)</a></li>
                        <li>For iPhones with no home button, click here: <a href="https://devicehelp.optus.com.au/apple/iphone-13-pro-max-ios-15-0/internet-and-data/check-data-usage/"  target="_blank" rel="noreferrer">Check data usage -
                        Apple iPhone 13 Pro Max - Optus</a> and follow the instructions on this
                        interactive guide.</li>
                        <li>For iPhones with a home button, click here: <a href="https://devicehelp.optus.com.au/apple/iphone-8-ios-12-0/internet-and-data/check-data-usage/"  target="_blank" rel="noreferrer">Check data usage -
                        Apple iPhone 8 - Optus</a> and follow the instructions on this interactive
                        guide.</li>
                      </ul>`,
          },
          {
            id: 10,
            title:
                "I feel nervous about even trying this new skill. What can I do?",
            content: `<ul>
                        <li>Feeling nervous can be a sign that you need practice to feel
                        comfortable. </li>
                        <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
                      </ul>`,
          },
          {
            id: 11,
            title: "This process is frustrating. How can I handle frustration?",
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
                    <ul>
                      <li>Step 1: Pause. </li>
                      <li>Step 2: Name what you’re feeling without judgement. Write the
                      phrase “I am feeling______.” </li>
                      <li>Step 3: Take a 5-minute break. You have the option to do something
                      to help you feel calm, such as doing some gentle stretches, listening
                      to music, or breathing deeply until you feel calm. </li>
                      <li>You can also check out: Videos to Help you Feel Calm and Focused
                      or the document on Changing Unhelpful Thoughts for Success while
                      Learning. You can access them here:
                      <a href="https://www.mtml.ca/smartphones-and-employment-skills_1/module-pilot/learners-ses/set-yourself-up-for-success"> https://www.mtml.ca/smartphones-and-employment-skills_1/modulepilot/learners-ses/set-yourself-up-for-success</a></li>
                    </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+your+phone+to+create+a+hotspot/tipsheets/Understanding+Cellular+Data+Tip+Sheet.pdf",
        key: "modules/Using your phone to create a hotspot/tipsheets/Understanding Cellular Data Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "Understanding Hotspots Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about hotspots. You do not need to read the entire tip sheet. Instead, find
        the heading that is related to your question. Read the information under
        that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: "I’m still confused about what a hotspot is.",
            content: `<ul>
            <li>A hotspot could be a public place, like a mall, where public Wi-Fi is
            available for you to connect your smartphone, tablet, or laptop to.
            Once connected to Wi-Fi, you can use the internet.
            <br/>
            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/19.png"/>
            </li>
            <li>
            A hotspot could ALSO be a device, like a smartphone, that ‘shares’
its cellular data connection with a secondary device like a laptop,tablet, or another smartphone so that you can use the internet on the
secondary device(s) as well.
<br/>
                <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/20.png"/>
            </li>
            </ul>`,
          },
          {
            id: 3,
            title: `I sometimes hear the word ‘tethering’ when people are talking about
            hotspots. What does ‘tethering’ mean?`,
            content: "The term ‘tethering’ is another term for hotspot.",
          },
          {
            id: 4,
            title: "Can everyone use their smartphone as a hotspot? ",
            content: `No. You can only use your smartphone as a hotspot if you have a
            cellular data plan.`,
          },
          {
            id: 5,
            title: "Will using a hotspot impact my cellular data plan?",
            content: `Yes, using your smartphone as a hotspot can impact your cellular
            data plan. This will depend on the activities you are doing online and
            the cellular data plan you have. `,
          },
          {
            id: 6,
            title: `I’m trying to turn on my hotspot, but it is not working. What should I
            do?`,
            content: `<ul><li>On some devices, like the iPhone, you may notice that the setting for
            hotspot may be grayed out and that tapping on it does nothing. This
            happens if your cellular data is off. <br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/21.png"/></li>
            <li>
            To fix this, you must turn your cellular data on first, then tap on the
setting for hotspot. To learn more, click here: <a href="https://www.youtube.com/watch?v=ltgk8FiVHoE"  target="_blank" rel="noreferrer">How to use Personal
Hotspot on your iPhone | Apple Support - YouTube</a>
            </li>
            </ul>`,
          },
          {
            id: 7,
            title: `I’m trying to connect a device to my smartphone’s hotspot. I’ve
            clicked on the network settings on my device, but I don’t see my
            smartphone listed. What should I do?`,
            content: `<ul>
              <li>Be sure that your smartphone is not too far away from your
              secondary device. </li>
              <li>For Android users, double check the name of the hotspot network. </li>
              <li>For iPhone users, double check the name of your smartphone, as it
              may not simply say ‘iPhone’. </li>
              <li>To learn how to double check the name of your device, click here:
             <a href="https://helpcenter.trendmicro.com/en-us/article/tmka-21661"  target="_blank" rel="noreferrer"> How to check your device name | Trend Micro Help Center</a></li>
             
            </ul>`,
          },
          {
            id: 8,
            title: `I’m worried that anyone can connect to my hotspot`,
            content: `<ul><li>Your smartphone will provide you with a password to prevent other
            users from joining your hotspot. Only people you have given the
            password should be able to join your smartphone’s hotspot network. </li>
            <li>Some smartphones will allow you to change the hotspot password. If
            you fear someone might have your hotspot password, change it. </li>
            </ul>`,
          },
          {
            id: 9,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable.</li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul`,
          },
          {
            id: 10,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write the
              phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here:
              <a href="https://www.mtml.ca/smartphones-and-employment-skills_1/module-pilot/learners-ses/set-yourself-up-for-success">
              https://www.mtml.ca/smartphones-and-employment-skills_1/modulepilot/learners-ses/set-yourself-up-for-success</a></li>
             
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+your+phone+to+create+a+hotspot/tipsheets/Understanding+Hotspots+Tip+Sheet.pdf",
        key: "modules/Using your phone to create a hotspot/tipsheets/Understanding Hotspots Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+your+phone+to+create+a+hotspot/lessons/Jan+17_LAMP_Finding+Hotspot+Settings+mini+lesson.pdf",
        key: "modules/Using your phone to create a hotspot/lessons/Jan 17_LAMP_Finding Hotspot Settings mini lesson.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/cmVRmZXpcZc",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+your+phone+to+create+a+hotspot/videos/2.+What+is+a+Hotspot_++-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Using+your+phone+to+create+a+hotspot/videos/english.vtt`,
        key: "modules/Using your phone to create a hotspot/videos/2. What is a Hotspot_  - HD 1080p.mov",
      },
    ],
    transcript: `https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+your+phone+to+create+a+hotspot/videos/2.+Using+Your+Phone+to+Create+a+Hotspot.docx`,
  },
  {
    id: 3,
    name: "Scanning documents with Android Phone",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+documents+with+Android+Phone/videos/sddefault.jpg",
    partnerName: "ACCES Employment",
    partnerWebsite: "https://accesemployment.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png",
    willLearnContent: `<ul>
    <li>Difference between scanning documents and taking a picture of a document</li>
    <li>How to scan a document with Google Drive</li>
    <li>How to name a file once it has been scanned</li>
    <li>How to send a file</li>
    </ul>`,
    greatForAnyoneContent: `<ul>
    <li>Needs to scan an important form such as a program agreement, or medical form</li>
    <li>Needs to scan and send a resume or cover letter</li>
    </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Scanning Document with Android Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about scanning a document with your Android smartphone. You do not
        need to read the entire tip sheet. Instead, find the heading that is related to
        your question. Read the information under that heading.`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I don’t have Google Drive on my smartphone. How do I get Google
            Drive?`,
            content: `<ul>
              <li>To get the Google Drive app, download it from the Google Play Store</li>
              <li>To learn about downloading an app from the Google Play Store, click
              here:<a href="https://www.youtube.com/watch?v=wu3bWmPGIyQ"> How to Download an App or Game on ANY Samsung Phone -
              YouTube</a></li>
              <li>On some Android devices, you may need to sign into your Google
              Account when you open the Google Play Store. If you are already
              using Gmail, your Google Account will be the same as your Gmail
              address and password. If you have never created a Google Account, click here to learn more: <a href="https://www.youtube.com/watch?v=bU3BpROLfc0"  target="_blank" rel="noreferrer">How To Create a Google Account Using an
              Android Smartphone - YouTube</a></li>
            </ul>`,
          },
          {
            id: 3,
            title: `Tips for Scanning Document `,
            content: `<ul>
              <li>Avoid dark lighting</li>
              <li>Hold your device over the document you would like to scan.</li>
              <li>Be sure there are no shadows on your document before you scan it.
              It is ok to reposition your phone or document if needed. </li>
            </ul>`,
          },
          {
            id: 4,
            title: `I’m nervous about scanning my document. What should I do?`,
            content: `<ul>
                    <li>Feeling nervous can be a sign that you need practice to feel comfortable and confident. </li>
                    <li>Try scanning a ‘practice document.’ This could be a blank piece of
                    paper at first. After, try scanning a document with text, such as a
                    grocery list. </li>
                    <li>Remember, practicing is the best way to help your brain remember
                    these new skills!</li>
                      </ul>`,
          },
          {
            id: 5,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+documents+with+Android+Phone/tipsheets/Scanning+Document+with+Android+Tip+Sheet.pdf",
        key: "modules/Scanning documents with Android Phone/tipsheets/Scanning Document with Android Tip Sheet.pdf",
      },
    ],
    lessons: [],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/QlCdzaym-co",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+documents+with+Android+Phone/videos/11.+Scanning+Documents+-+Google+DRIVE+Android+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Scanning+documents+with+Android+Phone/videos/english.vtt`,
        key: "modules/Scanning documents with Android Phone/videos/11a. Scanning Documents with Android Phone.mov",
      },
    ],
    transcript: `https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+documents+with+Android+Phone/videos/11.+Scanning+Documents+with+an+Android+Phone.docx`,
  },
  {
    id: 4,
    name: "Scanning Documents with iphone",
    // imageUrl: moduleThumbnail,
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+Documents+with+iphone/videos/sddefault.jpg",
    partnerName: "ACCES Employment",
    partnerWebsite: "https://accesemployment.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png",
    willLearnContent: `<ul>
      <li>Difference between scanning documents and taking a picture of a document</li>
      <li>How to scan a document with the Notes app</li>
      <li>How to name a file once it has been scanned</li>
      <li>How to send a file</li></ul>`,
    greatForAnyoneContent: `<ul>
      <li>Needs to scan an important form such as a program agreement, or medical form</li>
      <li>Needs to scan and send a resume or cover letter</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Scanning Document with iPhone Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about scanning a document with your iPhone. You do not need to read the
        entire tip sheet. Instead, find the heading that is related to your question.
        Read the information under that heading.`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I’m trying to scan my document from the Notes app. It is scanning
            automatically. Why is that?`,
            content: `<ul>
                        <li>If the document is scanning automatically (without pressing the white
                          circle button), your device is in ‘auto’ mode. </li>
                        <li>To get out of ‘auto’ mode, tap ‘auto’ in the upper right corner of the
                        screen.
                        <br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/22.png"/>
                        </li>
                      </ul>`,
          },
          {
            id: 3,
            title: `I’m a newer iPhone. How do I scan my document?`,
            content: `
            <ul>
              <li>Open the Notes app and tap on the button to create a new note:</li>
              <li>Instead of looking for a plus sign, tap on the camera icon. 
              <br/>
              <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/23.png"/></li>
             
            </ul>`,
          },
          {
            id: 4,
            title: `I see the option to ‘scan text’. What does this mean?`,
            content: `Click here to learn about it: <a href="https://www.youtube.com/watch?v=gR8suXe64YQ"  target="_blank" rel="noreferrer"> How to scan text into Notes on iPhone,
            iPad, and iPod touch | Apple Support - YouTube</a>`,
          },
          {
            id: 5,
            title: `How can I get a good scan?`,
            content: `<ul>
            <li>Avoid dark lighting</li>
            <li>Hold your device over the document you would like to scan </li>
            <li>Be sure there are no shadows on your document before you scan. It
            is ok to reposition your phone or document if needed.</li>
            </ul>`,
          },
          {
            id: 6,
            title: `How Else can I Scan a Document with my iPhone?`,
            content: `<ul>
              <li>Tap on the Files app
              <br/>
              <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/24.png"/>
              </li>
              <li>Tap on the 3 dots in the upper right corner of the screen
              <br/>
              <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/25.png"/></li>
              <li>Tap on ‘scan documents’
              <br/>
              <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/26.png"/></li>
            </ul>`,
          },
          {
            id: 7,
            title: `I’m nervous about scanning my document. What should I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable and confident. </li>
              <li>Try scanning a ‘practice document.’ This could be a blank piece of
              paper. After, try scanning a document with text, such as a grocery list. </li>
              <li>Remember, practicing is the best way to help your brain remember
              these new skills!</li>
            </ul>`,
          },
          {
            id: 8,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+Documents+with+iphone/tipsheets/Scanning+Document+with+iPhone+Tip+Sheet.pdf",
        key: "modules/Scanning Documents with iphone/tipsheets/Scanning Document with iPhone Tip Sheet.pdf",
      },
    ],
    lessons: [],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/KYnHbe4hQxA",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+Documents+with+iphone/videos/12.+Scanning+Documents+-+NOTES+iPhone++-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Scanning+Documents+with+iphone/videos/english.vtt`,
        key: "modules/Scanning Documents with iphone/videos/12. Scanning+Documents - NOTES iPhone  - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Scanning+Documents+with+iphone/videos/12.+Scanning+Documents+on+your+iPhone.docx",
  },
  {
    id: 5,
    name: "Signing documents with Android phone",
    // imageUrl: moduleThumbnail,
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+Android+phone/videos/sddefault.jpg",
    partnerName: "ACCES Employment",
    partnerWebsite: "https://accesemployment.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png",
    willLearnContent: `<ul>
      <li>How to sign a document with Adobe Acrobat</li>
      <li>How to search for the file you want to sign with Adobe Acrobat</li>
      <li>How to send a copy of the signed file</li>
      <li>Where to find the Adobe Acrobat app on Android smartphone</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Needs to sign and send an important document, such as a cover letter or job application</li>
     
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Signing Document on Android Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you might still
        have about signing a document with your Android smartphone. You do not
        need to read the entire tip sheet. Instead, find the heading that is related to
        your question. Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `Someone emailed me a document. How do I open my document in the
            Adobe Acrobat app?`,
            content: `<ul>
                        <li>Open the email with the document.</li>
                        <li>Tap on the document. You should see a message that says the
                        document is downloading. If the document doesn't download
                        automatically, tap the download button.
                        <br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/27.png"/>
                        </li>
                        <li>Once the document has downloaded, a menu will appear at the
                        bottom of your screen. If this menu doesn't automatically appear, tap
                        on the document again. Tap on ‘Adobe Acrobat.’ Your document
                        should now open with Adobe Acrobat.
                        <br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/28.png"/>
                        </li>
                      </ul>`,
          },
          {
            id: 3,
            title: `Can I delete my signature after I’ve added it to the document?`,
            content: `Yes! Tap on your signature and then tap on the trash can icon. <br/>
            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/29.png"/>`,
          },
          {
            id: 4,
            title: `I’ve created a signature, but I don’t like it. Can I create a new one?`,
            content: `<ul><li>Yes! Tap on the button for signature.<br/>
            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/30.png"/></li>
            <li>
            . Tap on the minus sign beside the signature you have already
created. This will remove it so that you can create a new one. 
<br/>
<img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/31.png"/>
            </li></ul>`,
          },
          {
            id: 5,
            title: `I’m nervous about signing my document. What should I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              confident.</li>
              <li>Practicing is the best way to help your brain remember these new
              skills!</li>
              <li>Don’t be afraid of making mistakes. Making mistakes is how we learn. </li>
            </ul>`,
          },
          {
            id: 6,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+Android+phone/tipsheets/Signing+Document+on+Android+Tip+Sheet.pdf",
        key: "modules/Signing documents with Android phone/tipsheets/Signing Document on Android Tip Sheet.pdf",
      },
    ],
    lessons: [],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/h6nThe-EkVQ",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+Android+phone/videos/13.+Signing+ADOBE+Android+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Signing+documents+with+Android+phone/videos/english.vtt`,
        key: "modules/Signing documents with Android phone/videos/13. Signing ADOBE Android - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+Android+phone/videos/13.+Signing+Documents+on+an+Android+Phone.docx",
  },

  {
    id: 6,
    name: "Signing documents with iphone",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+iphone/videos/sddefault.jpg",
    partnerName: "ACCES Employment",
    partnerWebsite: "https://accesemployment.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png",
    willLearnContent: `<ul>
      <li>How to sign a document with Adobe Acrobat</li>
      <li>How to search for the file you want to sign with Adobe Acrobat</li>
      <li>How to send a copy of the signed file</li>
      <li>Where to find the Adobe Acrobat app on iPhone</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Needs to sign and send an important document, such as a cover letter or job application</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Signing Document on iPhone Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about signing a document with your iPhone. You do not need to read the
        entire tip sheet. Instead, find the heading that is related to your question.
        Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `Someone emailed me a document. How do I open my document in the
            Adobe Acrobat app?`,
            content: `<ul>
                        <li>Open the email with the document. Tap on the document.</li>
                        <li>Look for the share icon. This might be in the bottom left corner of the
                        screen. The share icon could also be in the upper right corner of the
                        screen depending on the mail app you are using.<br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/32.png"/> </li>
                        <li>You should see a menu at the bottom of your screen. The menu will
                        have the option ‘import to acrobat.’ Depending on the mail app you
                        are using, you might first have to tap on ‘share file via’ first. After, you
                        tap on ‘share file via,’ the menu will appear at the bottom of your
                        screen. <br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/33.png"/></li>
                        <li>Scroll down the menu. Tap on the option that says ‘import to Acrobat.’
                        Then tap on ‘import to Acrobat’ again. Your document will open in
                        Acrobat. <br/>
                        <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/34.png"/></li>
                       
                      </ul>`,
          },
          {
            id: 3,
            title: `Can I delete my signature after I’ve added it to the document?`,
            content: `Yes! Tap on your signature and then tap on the trash can icon. <br/>
            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/29.png"/>`,
          },
          {
            id: 4,
            title: `I’ve created a signature, but I don’t like it. Can I create a new one?`,
            content: `<ul><li>Yes! Tap on the button for signature.<br/>
            <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/30.png"/></li>
            <li>
            . Tap on the minus sign beside the signature you have already
created. This will remove it so that you can create a new one. 
<br/>
<img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/31.png"/>
            </li></ul>`,
          },
          {
            id: 5,
            title: `I’m nervous about signing my document. What should I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              confident.</li>
              <li>Practicing is the best way to help your brain remember these new
              skills!</li>
              <li>Don’t be afraid of making mistakes. Making mistakes is how we learn. </li>
            </ul>`,
          },
          {
            id: 6,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+iphone/tipsheets/Signing+Document+on+iPhone+Tip+Sheet.pdf",
        key: "modules/Signing documents with iphone/tipsheets/Signing Document on iPhone Tip Sheet.pdf",
      },
    ],
    lessons: [],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/8TgIcjZaJn8",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+iphone/videos/14.+Signing+ACROBAT+iPhone+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Signing+documents+with+iphone/videos/english.vtt`,
        key: "modules/Signing documents with iphone/videos/14. Signing ACROBAT iPhone - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Signing+documents+with+iphone/videos/14.+Signing+Documents+on+your+iPhone.docx",
  },

  {
    id: 7,
    name: "How to use youtube",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/How+to+use+youtube/videos/sddefault.jpg",
    partnerName: "Literacy Council York Simcoe",
    partnerWebsite: "https://www.skillsupgrading.ca",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png",
    willLearnContent: `<ul>
    <li>What is YouTube</li>
    <li>How to search for and watch YouTube videos</li>
    <li>How to play, and pause the YouTube video</li>
    <li>How to go back to the previous YouTube video, or go forward to the next YouTube video</li>
    <li>How to find important information about the YouTube video, such who created it, the description of the video etc.</li>
    </ul>`,
    greatForAnyoneContent: `<ul>
    <li>Is new to YouTube</li>
    <li>Wants to use YouTube to find entertaining videos</li>
    <li>Wants to use YouTube to search for informational videos about certain professions, or businesses</li>
    </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Using Youtube Tip Sheet",
        shortContent: `Instructions: Use the tip sheet to answer any questions you still have
        about using YouTube. You do not need to read the entire tip sheet. Instead,
        find the heading that is related to your question. Read the information
        under that heading.`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I don’t have the YouTube app on my iPhone. What should I do?`,
            content: `To learn how to download YouTube on iPhone, click the link. Then
            follow the instructions by tapping on the red circle:
            <a href="https://deviceguides.vodafone.co.uk/apple/iphone-11-ios-13-1/apps-and-media/install-youtube/">https://deviceguides.vodafone.co.uk/apple/iphone-11-ios-13-
            1/apps-and-media/install-youtube/</a>`,
          },
          {
            id: 3,
            title: `I don’t have the YouTube app on my Android phone. What should I
            do?`,
            content: `<ul>
              <li>On an Android, tap on the Google Play Store</li>
              <li>Locate the search bar. In the Google Play Store, the search bar is
              usually at the top of the screen.<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/35.png"/></li>
              <li>Look for YouTube in the search results. Then tap, ‘install’<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/36.png"/></li>
         
            </ul>`,
          },

          {
            id: 4,
            title: `I don’t see the ‘pause’ or ‘play’ buttons on YouTube. What should I
            do?`,
            content: `<ul>
            <li>Tap on the video. If you tap on the video while it is playing you will
            see the pause button</li>
            <li>If you tap on the video while it is paused, you will see the play button.
            The play button looks like a triangle: </li>
          
            </ul>`,
          },
          {
            id: 5,
            title: `How do I identify advertisements (ads) on YouTube? `,
            content: `<ul>
              <li>A video that is an ad will usually be indicated by this symbol:<img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/37.png"/></li>
              <li>You may see that ads appear in the middle of the video you are
              watching. Some ads in videos can be skipped by pressing the ‘skip
              ad’ button. The button looks like this: <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/38.png"/></li>
            </ul>`,
          },
          {
            id: 6,
            title: `I don’t see chapters on my YouTube video. Why is that?`,
            content: `Not all YouTube videos will have chapters. `,
          },
          {
            id: 7,
            title: `Is there a volume button on YouTube?`,
            content: `There is no volume button on YouTube videos on the smartphone. To
            adjust the volume, use the volume keys on your device.`,
          },
          {
            id: 8,
            title: `I want to search for a new video. How can I see the search bar again?`,
            content: `Tap on the video and look for the downward arrow in the upper left
            corner. Tap on the downward arrow to minimize the video and reveal
            the search bar again.<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/39.png"/>`,
          },
          {
            id: 9,
            title: `I’m worried about others being able to see what I’ve subscribed to on
            YouTube.`,
            content: `<ul><li>You are in control of the YouTube channels you subscribe to. You
            can choose to subscribe to some channels. You can choose not to
            subscribe to others.</li>
            <li>You can also choose to hide your YouTube subscriptions. To do this,
            you must be signed into your YouTube account on your computer. To
            learn more about this, click here: <a href="https://bit.ly/3q1Pdrx" target="_blank" rel="noreferrer">https://bit.ly/3q1Pdrx</a></li>
            <li>Knowledge can help us feel empowered. To learn more about privacy
            settings, such as YouTube history and Incognito Mode, visit the
            Google Safety Centre: <a href="https://safety.google/products/#youtube" target="_blank" rel="noreferrer">https://safety.google/products/#youtube</a></li>
            
            </ul>`,
          },
          {
            id: 10,
            title: `How do I turn on the closed captioning? `,
            content: `<ul>
            <li>
            Closed captioning means written text of what is being said in the
video will appear on screen.
            </li>
            <li>
            To turn closed captioning on, tap on the video and look for this
symbol:<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/40.png"/>
            </li>
            </ul>`,
          },
          {
            id: 11,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 12,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/How+to+use+youtube/tipsheets/Using+Youtube+Tip+Sheet.pdf",
        key: "modules/How to use youtube/tipsheets/Using Youtube Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Mini Lesson: Using YouTube",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/How+to+use+youtube/lessons/Jan+16_LCYS_Using+YouTube.pdf",
        key: "modules/How to use youtube/lessons/Jan 16_LCYS_Using YouTube.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/HoiH8ui56Os",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/How+to+use+youtube/videos/3.+How+to+Use+YouTube++-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/How+to+use+youtube/videos/english.vtt`,
        key: "modules/How to use youtube/videos/3. How to Use YouTube  - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/How+to+use+youtube/videos/3.+How+to+use+the+Youtube+App.docx",
  },
  {
    id: 8,
    name: "Learning about your smartphone",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+your+smartphone/videos/sddefault.jpg",
    partnerName: "Literacy Council York Simcoe",
    partnerWebsite: "https://www.skillsupgrading.ca",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png",
    willLearnContent: `<ul>
    <li>How to determine if you have an Apple or Android smartphone</li>
    <li>How to determine your smartphone's brand and model</li>
    <li>How to use YouTube to find videos that will help you to solve technical problems on your smartphone</li>
    <li>Search words or phrases you can use to search for instructional videos on YouTube</li>
    </ul>`,
    greatForAnyoneContent: `<ul>
    <li>Wants to problem solve issues with their smartphone</li>
    <li>Wants to learn about how to use keywords and create search phrases to be able to find YouTube videos with instructions. </li>
    <li>Would like to search for information about the smartphone that they have to use for work</li>
    </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Finding Information about Smartphone Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about how to find information about your smartphone. You do not need to
        read the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading.`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I’m on an iPhone. I’m looking for my App Library to find Settings. I
            swiped, but I only see more apps. `,
            content: `<uL>
              <li>Your home screen can have many pages that you may need to swipe
              through before you get to the App Library. You will know how many
              pages your home screen has by looking at dots at the bottom of your
              screen. </li>
              <li>
              In my example, you can see that I am on page 1 of my home screen.
I have 4 more pages to swipe through. After I’ve swiped through all
the pages of my home screen, I can swipe to see my App Library.
<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/41.png"/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `I’m on an Android phone and I can’t find settings on my home
            screen. What should I do?`,
            content: `<ul>
              <li>You can check the App Drawer on your smartphone. This where you
              will see all installed apps.</li>
              <li>To access the App Drawer, swipe up from the bottom of your home
              screen.</li>
              <li>On some Android devices, you can access the App Drawer by
              tapping on the grid icon. The grid icon may look similar to this this:</li>
            </ul>`,
          },
          {
            id: 4,
            title: `I’m on an iPhone and I see ‘software version.’ What does that mean?`,
            content: `
            <ul>
              <li>Software version on an iPhone refers to what version of iOS your
              iPhone is running on. iOS refers to the iPhone Operating System.
              iOS updates are usually automatically sent to your iPhone for you to
              install.</li>
              <li>Each new software version is indicated by a higher number than the
              previous version. For example, iOS 15 is a newer, more updated
              version of software than iOS 14. To learn more about different iOS
              versions, click here:<a href="https://www.pcmag.com/encyclopedia/term/ios-versions" target="_blank" rel="noreferrer"> Definition of iOS versions | PCMag</a></li>
              <li>Not all iOS updates will be compatible with your iPhone so be sure to
              read information about the update carefully.</li>
            </ul>`,
          },
          {
            id: 5,
            title: `I’m on an Android phone and I see ‘software version.’ What does that
            mean?`,
            content: `<ul>
              <li>Software version on an Android smartphone refers to the version of
              Android Operating System (OS) your device is running on. OS
              updates are usually automatically sent to your Android phone for you
              to install. </li>
              <li>Each new software version is indicated by a higher number than the
              previous version. For example, Android software version 10 is a
              newer, more updated version of software than software version 9.</li>
              <li>On an Android smartphone, you may also notice that some older
              software versions are nicknamed after food items. For example,
              Android software version 6 is nicknamed marshmallow. To learn
              more about different Android software versions, click here:<a href="https://www.computerworld.com/article/3235946/android-versions-a-living-history-from-1-0-to-today.html" target="_blank" rel="noreferrer"> Android
              versions: A living history from 1.0 to 13 | Computerworld</a></li>
              <li>Not all Android software updates will be compatible with your Android
              smartphone, so be sure to read information about the update
              carefully.</li>
            </ul>`,
          },
          {
            id: 6,
            title: `Where can I learn more about Android smartphones?`,
            content: `Click here:<a href="https://edu.gcfglobal.org/en/androidbasics/intro-to-android-devices/1/" target="_blank" rel="noreferrer">https://edu.gcfglobal.org/en/androidbasics/intro-toandroid-devices/1/</a>`,
          },
          {
            id: 7,
            title: `Where can I learn more about iPhones?`,
            content: `Click here: <a href="https://edu.gcfglobal.org/en/iphonebasics/buying-aniphone/1/" target="_blank" rel="noreferrer">https://edu.gcfglobal.org/en/iphonebasics/buying-aniphone/1/</a>`,
          },
          {
            id: 8,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `
            <ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 9,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+your+smartphone/tipsheets/Finding+Information+about+Smartphone+Tip+Sheet.pdf",
        key: "modules/Learning about your smartphone/tipsheets/Finding Information about Smartphone Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "Using Youtube for Smartphone Research Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about using YouTube for smartphone research. You do not need to read
        the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `What are some YouTube channels I can use to learn about my
            smartphone?`,
            content: `<ul>
              <li>For iPhone users, check out the channel, Apple Support:
              https://www.youtube.com/c/AppleSupport</li>
              <li>For Android users, check out the channel, Android:
              https://www.youtube.com/c/android</li>
            </ul>`,
          },
          {
            id: 3,
            title: `What terms can I include in my search?`,
            content: `<ul>
              <li>Try including the term ‘tutorial.’ Tutorial means lesson. </li>
              <li>Try including your level of understanding, such as ‘beginner,’ ‘basic,’
              ‘new user’</li>
              <li>Try including the phrase ‘step by step instructions to’ or ‘step by step
              guide to’</li>
              <li>Check out some smartphone terms you might want to include in your
              search: <a href="https://tech.co/phones/phone-specs-explained" target="_blank" rel="noreferrer">https://tech.co/phones/phone-specs-explained</a></li>
            </ul>`,
          },
          {
            id: 4,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable and confident.</li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 9,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+your+smartphone/tipsheets/Using+Youtube+for+Smartphone+Research+Tip+Sheet.pdf",
        key: "modules/Learning about your smartphone/tipsheets/Using Youtube for Smartphone Research Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Finding Information about your Smartphone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+your+smartphone/lessons/Jan+16_LCYS_Finding+Information+about+your+Smartphone.pdf",
        key: "modules/Learning about your smartphone/lessons/Jan 16_LCYS_Finding Information about your Smartphone.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/ZKgPPgSQqfg",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+your+smartphone/videos/4.+Learning+About+Your+Smartphone++-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Learning+about+your+smartphone/videos/english.vtt`,
        key: "modules/Learning about your smartphone/videos/4. Learning AboutYour+Smartphone  - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+your+smartphone/videos/4.+Learning+About+your+Smartphone.docx",
  },
  {
    id: 9,
    name: "Switching and Closing Apps",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Switching+and+Closing+Apps/videos/sddefault.jpg",
    partnerName: "Labour Education Centre",
    partnerWebsite: "https://www.laboureducation.org",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png",
    willLearnContent: `<ul>
      <li>More information about the navigation buttons on your smartphone, including the button to view open apps, the home button, and the back button</li>
      <li>How to close apps</li>
      <li>How to view apps currently in use</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Wants to view open apps on their smartphone and/or close them</li>
      <li>Wants to switch between different applications, such as the LinkedIn app and a resume in an app like Adobe</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Closing Apps Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about closing apps on your smartphone. You do not need to read the entire
        tip sheet. Instead, find the heading that is related to your question. Read
        the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `Is it necessary to close apps?`,
            content: `<ul>
              <li>On many smartphones, it is no longer necessary to close apps.
              Closing the app may make your smartphone run slower because your
              device has to take the time to open them up again! </li>
              <li>On many smartphones, apps paused in the background do not
              influence your smartphone’s battery</li>
              <li>Learn more here: <a href="https://www.youtube.com/watch?v=qG08JTs71Jc" target="_blank" rel="noreferrer">Is force closing apps bad for your phone? -
              YouTube</a></li>
            </ul>`,
          },
          {
            id: 2,
            title: `I see my list of apps displayed vertically, instead of horizontally on my
            Android smartphone.`,
            content: `
            <div style="display:flex">
            <ul>
              <li>Some Android smartphones will display apps
              from top to bottom. </li>
              <li>Some Android smartphones will allow you to
              swipe to the right or left to close vertical apps.
              On some Android Smartphones, there will be
              an ‘x’ that you can tap on to close them.</li>
            </ul>
<img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/42.png"/>
            </div>`,
          },
          {
            id: 3,
            title: `Is there a way to close all the apps at once on an iPhone?`,
            content: `Unfortunately, this option does not exist on an iPhone.`,
          },
          {
            id: 4,
            title: `Is there a way to close all the apps at once on an Android
            smartphone?`,
            content: `On some Android smartphones, you may see the ‘close all’ button.
            This button may appear at the bottom of the apps you are trying to
            switch between.<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/43.png"/>`,
          },
          {
            id: 5,
            title: `These gestures are new to me! I feel nervous`,
            content: `<ul>
              <li>It’s normal to feel nervous when trying something new on your
              smartphone. </li>
              <li>The best way to feel comfortable with these new gestures is to
              practice them! You can practice swiping up, left and right depending
              on your device. </li>
              <li>If you are looking to practice in a way that feels safe, try practicing the
              gestures with the phone turned off. This way, you won’t have to worry
              about making mistakes. When you are feeling confident, try the
              gestures with the phone turned on!</li>
            </ul>`,
          },
          {
            id: 6,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 7,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Switching+and+Closing+Apps/tipsheets/Closing+Apps+Tip+Sheet.pdf",
        key: "modules/Switching and Closing Apps/tipsheets/Closing Apps Tip Sheet.pdf",
      },

      {
        id: 2,
        name: "Switching Between Apps Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about switching between apps on your smartphone. You do not need to
        read the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `What is an app? `,
            content: `The word ‘app’ is short for applications. Apps exist for work, study,
            fitness, entertainment and much more! Apps typically look like a tile
            on your smartphone’s home screen. An app may look like this:`,
          },
          {
            id: 3,
            title: `Why are some apps available for me to switch between and others are
            not?`,
            content: `You can only switch between apps you have opened. When you open
            an app and exit it, the app remains in the background. `,
          },
          {
            id: 4,
            title: `I’m on a newer iPhone. I tried to see my used apps by swiping up from
            the bottom of my screen to the top of the screen. Nothing happened.
            What should I do?`,
            content: `You have swiped too far up the screen. Instead, swipe from the very
            bottom of your screen to the middle of your screen. Pause when you
            have reached the middle of your screen.<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/44.png"/>`,
          },
          {
            id: 5,
            title: `I’m on an older iPhone (with a home button). I pressed the home
            button twice to see my used apps. It is not working. What should I
            do?`,
            content: `<ul>
              <li>On an older iPhone, you must press the home button twice. Be sure
              you are not pausing between each push.</li>
              <li>To see an example of how to press home button twice, click here:
              iPhone 8/8 Plus: How to Close Running Background Apps - YouTube</li>
            </ul>`,
          },
          {
            id: 6,
            title: `The button to view apps looks different on my smartphone than what
            was shown in the video. `,
            content: `That’s normal! Android smartphones may have some differences
            depending on the device you have. For example, on some Android
            smartphones, the button to view apps may look like two overlapping
            rectangles: `,
          },
          {
            id: 7,
            title: `I’m trying to scroll through each app, but I keep scrolling past some.
            How can I correct this?`,
            content: `<ul>
              <li>If this is happening, you may be scrolling too fast.</li>
              <li>Tap on each app, and slowly drag the app in the direction you would
              like to scroll.</li>
            </ul>`,
          },
          {
            id: 8,
            title: `These gestures are new to me! I feel nervous.`,
            content: `<ul>
              <li>It’s normal to feel nervous when trying something new on your
              smartphone. </li>
              <li>The best way to feel comfortable with these new gestures is to
              practice them! You can practice swiping up, or double pressing your
              home button depending on your device. </li>
              <li>If you are looking to practice in a way that feels safe, try practicing the
              gestures with the phone turned off. This way, you won’t have to worry
              about making mistakes. When you are feeling confident, try the
              gestures with the device turned on.</li>
              
            </ul>`,
          },
          {
            id: 9,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },

          {
            id: 10,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Switching+and+Closing+Apps/tipsheets/Switching+Between+Apps+Tip+Sheet.pdf",
        key: "modules/Switching and Closing Apps/tipsheets/Switching Between Apps Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Looking for and closing apps",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Switching+and+Closing+Apps/lessons/Jan+16_LEC_Looking+for+and+closing+apps.pdf",
        key: "modules/Switching and Closing Apps/lessons/Jan 16_LEC_Looking for and closing apps.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/k0tgdkbb8Uw",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Switching+and+Closing+Apps/videos/9.+Switching+and+Closing+Apps+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Switching+and+Closing+Apps/videos/english.vtt`,
        key: "modules/Switching and Closing Apps/videos/9. Switching and Closing Apps - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Switching+and+Closing+Apps/videos/9.+Looking+for+and+Closing+Apps+on+your+Smartphone++.docx",
  },
  {
    id: 10,
    name: "Icons at top of screen",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Icons+at+top+of+screen/videos/sddefault.jpg",
    partnerName: "Labour Education Centre",
    partnerWebsite: "https://www.laboureducation.org",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png",
    willLearnContent: `<ul>
      <li>The definition of icons</li>
      <li>Which icons represent important information, such as: missed calls, voicemail, cellular connection strength for making calls etc.</li>
      <li>Understanding notifications</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Is new to the icons at the top of the screen</li>
      <li>Would like to quickly know if they missed a call, email or message from an important contact, such as an employer</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Android Status Bar Tip Sheet",
        shortContent: `Instructions: Use the tip sheet to answer any questions you still have
        about the status bar on your Android smartphone. You do not need to read
        the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `What’s the difference between status icons and notification icons?`,
            content: `<ul>
            <li>Status icons tell you important information about your phone, such as
            battery, wi-fi, signal strength, location services etc.<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/45.png"/></li>
            <li>Notification icons appear when apps on your phone are trying to
            notify you of a new message, updates from the app etc.<br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/46.png"/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `What are some other status icons I might see?`,
            content: `<ul>
              <li>You may see a symbol for Bluetooth: <br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/47.png"/></li>
              <li>If you see the bluetooth symbol, it means that your device is
              connected to a wireless speaker or headphones. If you are on a call,
              or playing music, the sound will go through the wireless speaker or
              headphones instead of through your smartphone.</li>
              <li>You may also see a symbol for downloading: <br/><img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/48.png"/></li>
              <li>If you see this symbol, it means your device is downloading
              something like a document, or picture from the internet onto your
              smartphone. </li>
              <li>It is important to pay attention when you see this symbol because
              some downloads can harm your device. </li>
              <li>Learn more about unsafe downloads here:
              <a href="https://us.norton.com/internetsecurity-malware-what-are-drive-by-downloads.html">https://us.norton.com/internetsecurity-malware-what-are-drive-bydownloads.html#</a></li>
            </ul>`,
          },
          {
            id: 4,
            title: `Where can I learn more about unfamiliar status icons?`,
            content: `
                  <ul>
                  <li>Click here: <a href="https://bit.ly/3L8kF14" target="_blank" rel="noreferrer">https://bit.ly/3L8kF14</a></li>
                  <li>Click here: <a href="https://www.nextpit.com/status-notification-icons-meaning" target="_blank" rel="noreferrer"> https://www.nextpit.com/status-notification-iconsmeaning</a></li>
                  </ul>`,
          },
          {
            id: 5,
            title: `Can I choose the status icons that appear?`,
            content: `Some Android devices may give you this option. Click here to learn
            how to choose the status icons that will appear in the status bar:
            <a href="https://www.youtube.com/watch?v=hWiligG5_DM" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=hWiligG5_DM</a>`,
          },
          {
            id: 6,
            title: `Can I choose the app notifications I see? `,
            content: `<ul>
              <li>Yes, many devices will give you this option.</li>
              <li>To learn more click here:
             <a href="https://www.youtube.com/shorts/Uem4ivwOel8" target="_blank" rel="noreferrer"> https://www.youtube.com/shorts/Uem4ivwOel8</a></li>
              <li>To learn more, you can also click here:
<a href="https://www.lifewire.com/what-are-android-status-bar-icons-4774877" target="_blank" rel="noreferrer">https://www.lifewire.com/what-are-android-status-bar-icons4774877</a></li>
            </ul>`,
          },
          {
            id: 7,
            title: `I feel uncertain when I see symbols I don’t recognize.`,
            content: `<ul>
              <li>That’s ok. Feeling uncertain doesn’t have to be a bad thing.</li>
              <li>Feeling uncertain is a sign that you need more information. You can
              use some of the resources mentioned in the tip sheet to learn more
              about new symbols. You might also try asking a trusted friend!</li>
            </ul>`,
          },
          {
            id: 8,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },

          {
            id: 9,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Icons+at+top+of+screen/tipsheets/Android+Status+Bar+Tip+Sheet.pdf",
        key: "modules/ Icons at top of screen/tipsheets/Android Status Bar Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "iPhone Status Bar Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about the status bar on your iPhone. You do not need to read the entire tip
        sheet. Instead, find the heading that is related to your question. Read the
        information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `How is the status bar on an iPhone different from the status bar on
            the Android smartphone?`,
            content: `
            <ul>
              <li>The status bar on the iPhone will not show you notifications from
              installed apps. For example, if you receive a new email, you will not
              see a notification from the mail app in the status bar. Instead, you will
              typically only see status icons, like battery, wi-fi, location services etc. </li>
              <li>Depending on the version of iPhone you have, the status bar will tell
              you when you are on a call or Facetime. If you are on a call, you will
              see a symbol similar to this one: <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/49.png"/> </li>
              <li>If you are on a Facetime, you will see a symbol similar to this: <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/50.png"/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `What are some other pieces of information I might get from my
            iPhone’s status bar?`,
            content: `<ul>
              <li>Depending on the version of iPhone you have, you might see an
              orange dot, or a green dot like this: <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/51.png"/></li>
              <li>The orange dot appears in the status bar when you are using an app
              that is accessing your microphone. The green dot appears when you
              are using an app that is accessing your camera.</li>
              <li>These symbols appear to help protect your privacy.</li>
            </ul>`,
          },
          {
            id: 4,
            title: `Will the status bar change if my screen is locked?`,
            content: `<ul>
              <li>When your screen is locked, you may notice an additional piece of
              information is present. You will see the name of your phone carrier.
              Examples of phone carriers are: Rogers, Koodo, Telus, Bell etc.</li>
            </ul>`,
          },
          {
            id: 5,
            title: `Where can I learn more about unfamiliar status icons on the iPhone?`,
            content: `
            <ul>
              <li>Click here: <a href="https://support.apple.com/en-us/HT207354" target="_blank" rel="noreferrer"> https://support.apple.com/en-us/HT207354</a></li>
              <li>Click here:  <a href="https://support.apple.com/enca/guide/iphone/iphef7bb57dc/ios" target="_blank" rel="noreferrer"> https://support.apple.com/enca/guide/iphone/iphef7bb57dc/ios</a></li>
            </ul>`,
          },
          {
            id: 6,
            title: `How will I know if an app has a message or other important
            information for me?`,
            content: `
            <ul>
              <li>Look for a small red circle in front of the app. This is called a badge. </li>
              <li>In the example below, you can see the Messages app is telling you
              that you have 10 unread text messages.<br/> <img src="https://mtml.s3.ca-central-1.amazonaws.com/assets/52.png"/></li>
             
            </ul>`,
          },
          {
            id: 7,
            title: `I feel uncertain when I see symbols I don’t recognize.`,
            content: `<ul>
              <li>That’s ok. Feeling uncertain doesn’t have to be a bad thing. Feeling
              uncertain is a sign that you need more information. There are many
              resources online that you can use to learn more about new symbols.
              You might even try asking a trusted friend!</li>
            </ul>`,
          },
          {
            id: 8,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
            <li>Feeling nervous can be a sign that you need practice to feel
            comfortable. </li>
            <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 9,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Icons+at+top+of+screen/tipsheets/iPhone+Status+Bar+Tip+Sheet.pdf",
        key: "modules/ Icons at top of screen/tipsheets/iPhone Status Bar Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Learning about Icons at top of your Screen mini lesson",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Icons+at+top+of+screen/lessons/Jan+16_LEC-Learning+about+Icons+at+top+of+your+Screen-mini+lesson.pdf",
        key: "modules/ Icons at top of screen/lessons/Jan 16_LEC-Learning about Icons at top of your Screen-mini lesson.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/cf3cUzno1X8",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Icons+at+top+of+screen/videos/10.+Icons+at+top+of+screen+V2+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/+Icons+at+top+of+screen/videos/english.vtt`,
        key: "modules/ Icons at top of screen/videos/10. Icons at top of screen V2 - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Icons+at+top+of+screen/videos/10.+Learning+About+Icons+at+top+of+Your+Screen.docx",
  },
  {
    id: 11,
    name: "Security/Privacy",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/videos/sddefault.jpg",
    partnerName: "Toronto Council Fire",
    partnerWebsite: "https://www.councilfire.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png",
    willLearnContent: `<ul>
      <li>Lock screen security</li>
      <li>Personal data collected by apps</li>
      <li>Risks of public wi-fi</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Wants to learn more about protecting their privacy on their smartphone</li>
      <li>Has personal information on their smartphone</li>
      <li>Has forms on their smartphone with sensitive and/or personal data, such as job application, banking forms etc.</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Lock Screen Security Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about lock screen security on your smartphone. You do not need to read
        the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `How do I find the settings for passcode, pattern, or biometric lock on
            my Android smartphone?`,
            content: `<ul>
              <li>Tap on the settings app:<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/53.png'/></li>
              <li>Scroll down. Look for ‘lock screen’. On some Android devices, you
              may have to tap on ‘display’ before you can see the option for ‘lock
              screen.’ On some Android devices, you may have to look for the
              option that says ‘security’ instead of ‘display’ or ‘lock screen’. Tap on
              ‘lock screen.’ Choose the type of lock screen you want.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/54.png'/></li>
              <li>On some Android devices, you may also have an option that says
              ‘Biometrics and Security’. Scroll to this option after tapping on the settings app. ‘Biometric and security’ will allow you to set up
              fingerprint security or facial recognition on your smartphone.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/55.png'/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `How can I learn more about setting up a passcode or removing a
            passcode on an Android device?`,
            content: `Click here: <a href="https://www.asurion.com/connect/tech-tips/how-to-set-up-change-remove-screen-lock-android/" target="_blank" rel="noreferrer"> https://www.asurion.com/connect/tech-tips/how-to-set-upchange-remove-screen-lock-android/</a>`,
          },
          {
            id: 4,
            title: `How can I find the settings for passcode, pattern, or biometric lock on
            my iPhone?`,
            content: `<ul>
              <li>Tap on the settings app: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/56.png'/></li>
              <li>Scroll down until you see Face ID & Passcode <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/57.png'/></li>
              <li>If your iPhone has a home button, scroll until you see Touch ID &
              Passcode instead. <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/58.png'/></li>
            </ul>`,
          },
          {
            id: 5,
            title: `How can I learn more about setting up a passcode and removing a
            passcode on an iPhone?`,
            content: `Click here: <a href="https://www.youtube.com/watch?v=THUDrBBIF5k" target="_blank" rel="noreferrer"> https://www.youtube.com/watch?v=THUDrBBIF5k</a>`,
          },
          {
            id: 6,
            title: `What happens if I forget my passcode or pattern?`,
            content: `<ul>
              <li>Some devices may have a ‘forgot pattern’ option.</li>
              <li>If you think you will have trouble remembering a passcode or pattern,
              write down the passcode. You can draw the pattern on a piece of
              paper. Store the paper in a safe place.</li>
            </ul>`,
          },
          {
            id: 7,
            title: `Can I change my passcode or pattern once I’ve set it? `,
            content: `<ul>
              <li>Yes!</li>
              <li>Please note that you will need your current password or pattern to
              make changes.</li>
              <li>To learn more about changing passcode or pattern on iPhone, click
              here: <a href="https://bit.ly/3UuFfx2">https://bit.ly/3UuFfx2</a></li>
              <li>To learn more about changing passcode on Android and iPhone, click
              here:<a href="https://www.keepsolid.com/passwarden/help/manuals/how-tochange-lock-screen-password">https://www.keepsolid.com/passwarden/help/manuals/how-tochange-lock-screen-password</a></li>
            </ul>`,
          },
          {
            id: 8,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Practicing is the best way to feel comfortable. Practicing will also help
              your brain remember these new skills!</li>
              <li>Don’t be afraid of making mistakes. Making mistakes is how we learn. </li>
            </ul>`,
          },
          {
            id: 9,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/tipsheets/Lock+Screen+Security+Tip+Sheet.pdf",
        key: "modules/ Security Privacy/tipsheets/Lock Screen Security Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "Understanding Data Collected by Apps Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about data collected by apps on your smartphone. You do not need to read
        the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I sometimes see the word ‘permissions’ when downloading apps.
            What does this mean?`,
            content: `<ul>
            <li>When an app asks for ‘permission,’ it means that the app is asking for
            you to give it approval to access your information, such as: contacts,
            location, photos etc. Often the app is asking for this information
            because it needs all or some of this information to work correctly. </li>
            <li>Even if you decide to give the app permissions now, you can usually
            turn some of them off later from your devices settings<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/59.png'/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `How can I check app permissions before downloading the app?`,
            content: `<ul>
              <li>The App Store and the Google Play Store both have Data Safety
              Sections. The Data Safety Section is visible once you tap on an app
              in the App Store <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/60.png'/>or Google Play Store <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/61.png'/></li>
              <li>Learn more about the Data Safety Section on iPhone here:
              https://www.youtube.com/watch?v=bjYUkDjyctE </li>
              <li>Learn more about the Data Safety Section on Android here:
              https://www.youtube.com/watch?v=62T6agNWpRI</li>
            </ul>`,
          },
          {
            id: 4,
            title: `I’m on an iPhone. Where can I review how apps are using the
            permissions after I’ve downloaded an app? `,
            content: `<ul>
              <li>Click here to learn more: <a href="https://support.apple.com/enca/guide/iphone/iph251e92810/ios" target="_blank" rel="noreferrer">https://support.apple.com/enca/guide/iphone/iph251e92810/ios</a></li>
            </ul>`,
          },
          {
            id: 5,
            title: `I’m on an Android smartphone. Where can I learn more about
            changing app permissions after I’ve downloaded an app?`,
            content: `Click here to learn more:
            <a href="https://www.youtube.com/watch?v=TucT1f1Rnag" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=TucT1f1Rnag</a>`,
          },
          {
            id: 6,
            title: `I feel uncertain about granting app permissions. `,
            content: `<ul>
              <li>Uncertainty can be a sign that you need more information. Before
              deciding if you would like to grant app permissions take some time to
              learn more about the app. Read more about the developer of the app.
              Read reviews about the app. This will give you more information
              about if the app is trustworthy.</li>
              <li>Remember that you are in control of the app. Even if you decide to
              give the app permissions now, you can usually turn off some of the
              permissions later.</li>
            </ul>`,
          },
          {
            id: 7,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous may be a sign that you need to practice to feel
              comfortable.</li>
              <li>Practicing will also help your brain remember these new skills!</li>
              <li>Don’t be afraid of making mistakes. Making mistakes is how we learn. </li>
            </ul>`,
          },
          {
            id: 8,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/tipsheets/Understanding+Data+Collected+by+Apps+Tip+Sheet.pdf",
        key: "modules/ Security Privacy/tipsheets/Understanding Data Collected by Apps Tip Sheet.pdf",
      },
      {
        id: 3,
        name: "Understanding Public Wi-Fi Tip Sheet Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about public wi-fi. You do not need to read the entire tip sheet. Instead, find
        the heading that is related to your question. Read the information under
        that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: "Where can I connect to public wi-fi? ",
            content: `Some examples of where you might find public wi-fi, include Walmart,
            McDonald’s, Starbucks, and Tim Hortons. Each company will have its
            own wi-fi network for its customers to connect. You can connect to
            the wi-fi while in their store or restaurant.`,
          },
          {
            id: 3,
            title: "How do I connect to public wi-fi?",
            content: `<ul>
              <li>You must be in a public location that offers wi-fi.</li>
              <li>Some wi-fi networks will not require a password, while some will
              require one.</li>
              <li>Wi-fi networks that require a password will have a padlock symbol
              next to it. A padlock looks similar to this:<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/62.png'/></li>
            </ul>`,
          },
          {
            id: 4,
            title:
                "How do I know if my device successfully connected to the wi-fi?",
            content: `These are some examples of symbols that mean that you have not
            successfully connected to wi-fi.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/63.png'/>`,
          },
          {
            id: 5,
            title: "Is public wi-fi safe to use?",
            content: `<ul>
            <li>The safest wi-fi to use is your wi-fi network at your own home (if you
              have it).</li>
              <li>There are some risks to using public wi-fi. It is typically safe to use
              when you are not doing anything online that requires private or
              sensitive information, such as credit card numbers, or SIN etc.</li>
              <li>Even if a public wi-fi network is password protected, you should not
              use it for tasks like banking, or online shopping. </li>
              <li>It is safer to use public wi-fi for tasks like video calling friends or
              watching YouTube videos.<br>
              <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/64.png'/></li>
            </ul>`,
          },

          {
            id: 6,
            title: "How else can I keep myself safe when using public wi-fi?",
            content: `<ul>
              <li>When you navigate to wi-fi settings, you might see a list of public wi-fi
              networks. Be sure you are connecting to public wi-fi networks from
              well-known companies and organizations.</li>
              <li>Read the name of the wi-fi network you are trying to connect to
              carefully. Be sure that the name of the wi-fi network is not misspelled.</li>
              <li>Also be sure the wi-fi network is the official network for that particular
              company.</li>
              <li>In my example below, there are 3 wi-fi networks containing the name
              ‘Starbucks.’ It is important to remember that anyone can name their
              network ‘Starbucks,’ so they may not be the real wi-fi network for the
              company. If you are unsure which network is the real one, ask a staff
              member working at the company.<br/> <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/65.png'/></li>
            </ul>`,
          },
          {
            id: 7,
            title: `I’m looking at my wi-fi settings and I see ‘ask to join.’ What does this
            mean?`,
            content: `‘Ask to join’ is a setting on your device. When it is turned on, your
            device will ask you if you would like to join a new wi-fi network if one
            is available. <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/66.png'/>`,
          },
          {
            id: 8,
            title: "The video mentioned VPN. What is that?",
            content: `<ul>
              <li>A VPN is a Virtual Private Network. To learn more about VPN, click
              here: What Is a VPN? (lifewire.com)</li>
              <li>To learn more about how safe a VPN is, click here: The VPN on Your
              iPhone May Leak Sensitive Information to Interested Parties
              (<a href="lifewire.com" target="_blank" rel="noreferrer">lifewire.com</a>)</li>
            </ul>`,
          },
          {
            id: 9,
            title:
                "I’m scared about the risks of using public wi-fi. What should I do? ",
            content: `<ul>
              <li>Anytime you do anything online, there are always risks, as well as
              benefits. If our fear prevents us from using public wi-fi, we will also
              miss out on the amazing benefits of public wi-fi. </li>
              <li>While risks exist for using public wi-fi, we can take steps to reduce
              risk. For example, we might only use public wi-fi for tasks that don’t
              require private or sensitive information.</li>
            </ul>`,
          },
          {
            id: 10,
            title:
                "I feel nervous about even trying this new skill. What can I do?",
            content: `<ul>
              <li>Feeling nervous may be a sign that you need practice to feel
              comfortable and confident. </li>
              <li>Practicing is the best way to help your brain remember these new
              skills!</li>
              <li>Don’t be afraid of making mistakes. Making mistakes is how we learn!</li>
            </ul>`,
          },

          {
            id: 11,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/tipsheets/Understanding+Public+Wi-Fi+Tip+Sheet.pdf",
        key: "modules/ Security Privacy/tipsheets/Understanding Public Wi-Fi Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Council Fire Security and Privacy Mini Lesson",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/lessons/Jan+16_Council+Fire_Security+and+Privacy+Mini+Lesson.pdf",
        key: "modules/ Security Privacy/lessons/Jan 16_Council Fire_Security and Privacy Mini Lesson.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/Ops5WS_wY-I",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/videos/7.+Security+and+Privacy++-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/+Security+Privacy/videos/english.vtt`,
        key: "modules/ Security Privacy/videos/7. Security and Privacy  - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/+Security+Privacy/videos/7.+Security+and+Privacy+tips+for+your+Smartphone.docx",
  },

  {
    id: 12,
    name: "Sharing Files",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/videos/sddefault.jpg",
    partnerName: "Toronto Council Fire",
    partnerWebsite: "https://www.councilfire.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png",
    willLearnContent: `<ul>
      <li>The definition of a file</li>
      <li>How to share a file by opening the file first</li>
      <li>How to share a file by opening a messaging and/or social media app first</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Needs to send an important form, such as a medical form or program agreement</li>
      <li>Needs to send homework to an instructor</li>
      <li>Needs to send a resume or cover letter</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Saving and Finding Files Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about saving and finding files. You do not need to read the entire tip sheet.
        Instead, find the heading that is related to your question. Read the
        information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I’m on an iPhone. Someone emailed me a file I want to save. How do I
            save it?`,
            content: `<ul>
              <li>Go to the email and find the file. Tap on the file to open it. </li>
              <li>Find the share icon. This is usually in the bottom left of the screen.
              The share icon looks similar to this:<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/67.png'/></li>
              <li>Tap on ‘save to files.’ Tap ‘on my iPhone’. Tap on ‘save.’ On some
              mail apps, you may have to tap on ‘share file via’ first before you can
              tap on ‘save to files.’</li>
              <li>Your file can now be found in the Files app. The files app looks like
              this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/68.png'/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `I’m on an Android. Someone emailed me a file I want to save. How do I
            save it?`,
            content: `<ul>
              <li>Go to the email and find the file. Look for the button that says ‘save,’
              usually next to the attachment.</li>
              <li>On some devices, there may not be a ‘save’ button. Instead, you
              might see a download button. Tap on the download button. The
              download button looks like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/48.png'/></li>
              <li>On some Android devices, you may not see a save or download
              button. Instead, you may have to tap and press down on the file for a
              few seconds first. This may open a menu with the option to ‘save,’ or
              the symbol to download.</li>
              <li>Your saved file will appear in the Files app on your device. The Files
              app may look similar to this:<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/69.png'/></li>
              <li>To learn more, click here:<a href="https://www.youtube.com/watch?v=fOUtK_UThHw" target="_blank" rel="noreferrer"> Galaxy S20/S20+: How To Save Email
              Attachments - YouTube</a></li>
            </ul>`,
          },
          {
            id: 4,
            title: `I’m on an iPhone and I can’t find the Files app. What should I do?`,
            content: `<ul>
              <li>Your home screen can have many pages that you may need to swipe
              through to locate the Files apps. You will know how many pages your
              home screen has by looking at dots at the bottom of your screen. In
              my example, you can see that I am on page 1 and I have 4 more
              pages to swipe through. Swipe through each screen to look for the
              Files app.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/70.png'/></li>
              
            </ul>`,
          },
          {
            id: 5,
            title: `I’m on an Android phone and I can’t find the Files app on my home
            screen. What should I do?`,
            content: `<ul>
              <li>You can check the App Drawer on your smartphone. This where you
              will see all the installed apps. To access the App Drawer, swipe up
              from the bottom of your home screen. </li>
              <li>On some Android devices, you can access the App Drawer by
              tapping on the grid icon. The grid icon may look similar to this:<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/71.png'/></li>
            </ul>`,
          },
          {
            id: 6,
            title: `I feel nervous because I don’t see a message that confirms my
            document was saved. `,
            content: `<ul>
              <li>Some devices may not display a message confirming your document
              was saved. In that case, look at the Files app to confirm.</li>
              <li>Remember to practice helpful thoughts like: “it may be true that I am
              unable to see a confirmation message. It may also be true that my
              document is being saved successfully to my device. If my document
              doesn’t save, I can always try again.”</li>
            </ul>`,
          },
          {
            id: 7,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/tipsheets/Saving+and+Finding+Files+Tip+Sheet.pdf",
        key: "modules/Sharing Files/tipsheets/Saving and Finding Files Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "Sharing Files Tip Sheet",
        shortContent: `<strong>Instructions: </strong>Use the tip sheet to answer any questions you still have
        about sharing files on your smartphone. You do not need to read the entire
        tip sheet. Instead, find the heading that is related to your question. Read
        the information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `Does sharing a digital file mean that many people can view it? `,
            content: `Sharing a digital file means that you are sending it to someone else.
            This could be 1 person or many people. It’s up to you to decide how
            many people you share the file with. `,
          },
          {
            id: 3,
            title: `What does the word ‘attachment’ mean?`,
            content: `An attachment is a digital file that we are sending or sharing with
            someone. Some examples of digital files are documents or pictures.
            When we attach a digital file to an email or text message, we are
            creating an attachment. `,
          },
          {
            id: 4,
            title: `I’m on an iPhone. I’m trying to send a picture by opening the text
            message app first. I don’t see any of the symbols for sharing
            mentioned in the video.`,
            content: `<ul>
              <li>When you open the text message app on some iPhones, there are
              different symbols for sharing located at the bottom of the screen. You
              may see one symbol like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/72.png'/>You can tap on this symbol to
              take a picture and send it.</li>
              <li>You might also see a symbol that looks like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/73.png'/>You can
              tap on this icon to reveal a list of apps below. Choose the app where
              your picture exists, such as the Gallery app or Google Photos.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/74.png'/></li>
            </ul>`,
          },
          {
            id: 5,
            title: `I’m on an iPhone. I’m trying to send a file by opening the Mail app
          first. I don’t see any of the symbols for sharing mentioned in the
          video.`,
            content: `<ul>
            <li>When you create a new email through the Mail app on some iPhones,
            you may not see any symbols to share that were mentioned in the
            video. </li>
            <li>To correct this, tap on the part of the window where you will write
            your message- just below the subject line. Once you have done that,
            you will see an arrow on the right of the screen. Tap on it to reveal a
            list of options for file sharing.<br/><img width="100%" src='https://mtml.s3.ca-central-1.amazonaws.com/assets/75.png'/></li>
            <li>On some versions of iPhones, you may have to double tap in the
            space where you would write your message. A black box may appear. Tap on the arrow until you see the option for adding a picture
            or another type of attachment.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/76.png'/></li>
            </ul>`,
          },
          {
            id: 6,
            title: `How can I tell if my file went through?`,
            content: `<ul>
              <li>If sending a file by email, check your outbox in your mail app.
              Messages that have not yet gone through will be in your outbox. 
              <br/>
              <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/77.png'/>
              </li>
              <li>If sending a file by text message, check to see if your message says
              ‘not delivered.’ 
              <br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/78.png'/></li>
            </ul>`,
          },
          {
            id: 7,
            title: `Why didn’t my file go through?`,
            content: `<ul>
            <li>You may find that your attachment does not go through if you’re
            trying to send it using Wi-Fi. You may need to turn your data on if you
            have a cellular data plan. </li>
            <li>To learn how to turn data on and off for iPhone and Android phone,
            click here: <a href="https://www.youtube.com/watch?v=cwbxrtJMw-Y" target="_blank" rel="noreferrer"> https://www.youtube.com/watch?v=cwbxrtJMw-Y</a></li>
          </ul>`,
          },
          {
            id: 8,
            title: `I am trying to share a picture through email. I see a message about
          file size. What does this mean?`,
            content: `<ul>
            <li>A picture that is too big may take a very long time to go through or
            may not go through at all. </li>
            <li>When you are trying to share an attachment by email, a message like
            the one below will automatically pop up after you tap on the send
            button. This is your device’s way of asking if you would like to reduce
            the size of your picture.</li>
            <li>You can tap on ‘small’ or ‘medium.’ Reducing the size may impact the
            quality of your picture.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/79.png'/></li>
            </ul>`,
          },
          {
            id: 9,
            title: `I’m frustrated the file didn’t go through!`,
            content: `<ul>
              <li>If you're frustrated that the file didn’t go through, it might also mean
              that you’re feeling really motivated to see it work. </li>
              <li>Take a break. Then think about how else you can share the file. For
              example, can you try sending your file through a different app like
              WhatsApp? The icon for WhatsApp looks like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/80.png'/></li>
              <li>To learn more about WhatsApp, click here:<a href="https://www.youtube.com/watch?v=fjVlDYu6erU"  target="_blank" rel="noreferrer">
              https://www.youtube.com/watch?v=fjVlDYu6erU</a></li>
            </ul>`,
          },
          {
            id: 10,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Practicing is the best way to feel comfortable. Practicing will also help
              your brain remember these new skills!</li>
              <li>Don’t be afraid of making mistakes. Making mistakes is how we learn. </li>
            </ul>`,
          },
          {
            id: 11,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/tipsheets/Sharing+Files+Tip+Sheet.pdf",
        key: "modules/Sharing Files/tipsheets/Sharing Files Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Council Fire Understanding How to Share a File",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/lessons/Jan+16_Council+Fire_Understanding+How+to+Share+a+File.pdf",
        key: "modules/Sharing Files/lessons/Jan 16_Council Fire_Understanding How to Share a File.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/Vie-_RDwk9c",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/videos/8.+Sharing+Files+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Sharing+Files/videos/english.vtt`,
        key: "modules/Sharing Files/videos/8. Sharing Files - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Sharing+Files/videos/8.+Sharing+Files+on+your+Smartphone.docx",
  },

  {
    id: 13,
    name: "Learning about Popular Apps",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/videos/sddefault.jpg",
    partnerName: "YMCA",
    partnerWebsite: "https://ymcagta.org",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png",
    willLearnContent: `<ul>
      <li>How you can lear to find information on different apps that you are interested in</li>
      <li>How to use the Google app</li>
      <li>Search words and phrases that can be used on Google to search for information about apps</li>
      <li>Where to download apps</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Wants to know how to research apps, such as employment apps like LinkedIn, Magnet</li>
      <li>Is new to using Google to search for information</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Searching in App Store and Google Play Store Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about searching in the App Store and Google Play Store. You do not need
        to read the entire tip sheet. Instead, find the heading that is related to your
        question. Read the information under that heading.`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `Where do I look for apps?`,
            content: `<ul>
          <li>Apps are available through an online store. If you are using an
          iPhone, you will use the App Store. The App Store may look like this:<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/60.png'/></li>
          <li>Apps are available through an online store. If you are using an
          Android phone, you will use the Google Play Store. The Google Play
          Store looks like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/61.png'/></li>
          </ul>`,
          },
          {
            id: 3,
            title: `How do I find out more information about the app I am interested in? `,
            content: `Tap on the app. Scroll through the page. You will see information
            such as reviews, developer, size of the app, data collected by the
            app, whether it will work with your device etc. <img width="100%" src='https://mtml.s3.ca-central-1.amazonaws.com/assets/81.png'/>`,
          },
          {
            id: 4,
            title: `Where’s a good place to start looking for apps in the App Store or
          Google Play Store?`,
            content: `You can look for keywords, such as ‘top free apps’ ‘top paid apps.’
            This will give you an idea of apps that are currently popular.`,
          },
          {
            id: 5,
            title: `Where can I learn more about the data the app collects?`,
            content: `
          <ul>
            <li>iPhone users, click here:
            <a href="https://www.youtube.com/watch?v=bjYUkDjyctE"  target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=bjYUkDjyctE</a></li>
            <li>Android users, click here:
            <a href="https://www.youtube.com/watch?v=62T6agNWpRI"  target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=62T6agNWpRI</a></li>
          </ul>`,
          },
          {
            id: 6,
            title: `How do I know if an app is free?`,
            content: `
          <ul>
            <li>If you’re on an iPhone, there will be a button that says ‘get’. The
            button may look like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/82.png'/></li>
            <li>Some older versions of the App Store may say ‘free’ instead.</li>
            <li>If you’re on an Android phone, the app will say ‘install’. The button
            may look like this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/83.png'/></li>
          </ul>`,
          },
          {
            id: 7,
            title: `There are so many apps. I feel overwhelmed because I don’t know
          which one is right for me!`,
            content: `<ul>
            <li>Consider the most important things you are looking for in an app.
            Then read the app description. Does the app description match with
            what you are looking for?</li>
            <li>It’s ok to embrace your curiosity! If you are not sure you will like the
            app, it’s ok to download it and try it out. Remember you are in control.
            If you don’t like the app, you can choose to delete it. </li>
          </ul>`,
          },
          {
            id: 8,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 9,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/tipsheets/Searching+in+App+Store+and+Google+Play+Store+Tip+Sheet.pdf",
        key: "modules/Learning about Popular Apps/tipsheets/Searching in App Store and Google Play Store Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "Using Google to Search for Info about Apps Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about using Google on your smartphone to search for information about
        apps. You do not need to read the entire tip sheet. Instead, find the
        heading that is related to your question. Read the information under that
        heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I’m not seeing the search results I want.`,
            content: `Try changing the words you used in your search. For example, you
            might search ‘best fitness apps.’ If you don’t see information about
            relevant apps in your search results, try changing your search to
            ‘popular fitness apps.’`,
          },
          {
            id: 3,
            title: `How can I enhance my search on Google?`,
            content: `<ul>
              <li>You can use a plus sign to tell Google to include words in your
              search. For example, fitness + nutrition apps. This will tell Google to
              include nutrition in your search results.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/84.png'/></li>
              <li>You can use a minus sign to tell Google to exclude words from your
              search. For example, fitness -nutrition apps. This tells Google to
              leave out ‘nutrition’ from your search results. <br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/85.png'/></li>
              <li>You can learn more about improving your Google search here:
              <a href="https://bit.ly/3REJ93Z">https://bit.ly/3REJ93Z</a></li>
            </ul>`,
          },
          {
            id: 4,
            title: `I’m not sure what the app I want to use looks like. What can I do?`,
            content: `<ul>
            <li>Enter your search terms into google and hit ‘enter’ or ‘search’ on your
            smartphone’s keyboard.</li>
            <li>Then tap on ‘images’ to see the app icon.<br/><img width="100%" src='https://mtml.s3.ca-central-1.amazonaws.com/assets/86.png'/></li>
            </ul>`,
          },
          {
            id: 5,
            title: `Where can I learn more about apps? `,
            content: `Learn more about app reviews, content, ratings, age level etc. click
            here:<a href="https://www.commonsensemedia.org/app-reviews">https://www.commonsensemedia.org/app-reviews</a>`,
          },
          {
            id: 6,
            title: `I’m feeling uncertain. I don’t know if this is the correct app I’m looking
            for.`,
            content: `<ul>
              <li>Often apps can have similar names. They might also look quite
              similar to each other. This can make it hard to know what app is the
              right app you are looking for. </li>
              <li>Noticing when you feel uncertain is important. Uncertainty is a good
              sign that you should do more research. It also means we should pay
              attention to details of the app like its name, and details of the icon.</li>
              <li>It is best practice not to download apps from websites.</li>
            </ul>`,
          },
          {
            id: 7,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
            <li>Feeling nervous can be a sign that you need practice to feel
            comfortable. </li>
            <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 8,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/tipsheets/Using+Google+to+Search+for+Info+about+Apps+Tip+Sheet.pdf",
        key: "modules/Learning about Popular Apps/tipsheets/Using Google to Search for Info about Apps Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Learning About Apps Mini Lesson",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/lessons/Jan+22_YMCW_+Learning+About+Apps+Mini+Lesson.pdf",
        key: "modules/Learning about Popular Apps/lessons/Jan 22_YMCW_ Learning About Apps Mini Lesson.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/0cqb3EpaR5o",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/videos/5.+Learn+About+Apps+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/Learning+about+Popular+Apps/videos/english.vtt`,
        key: "modules/Learning about Popular Apps/videos/5. Learn About Apps - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/videos/5.+How+to+Learn+about+Popular+Apps.docx",
  },

  {
    id: 14,
    name: "View Storage and uninstall Apps",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/videos/sddefault.jpg",
    partnerName: "YMCA",
    partnerWebsite: "https://ymcagta.org",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png",
    willLearnContent: `<ul>
      <li>How to check your smartphone’s overall storage</li>
      <li>How to determine how much of your smartphone’s storage is used</li>
      <li>What type of files are taking up your smartphone’s storage, including apps</li>
      <li>How to delete apps</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
      <li>Wants to free up space on their smartphone</li>
      <li>Wants to learn about storage on their smartphone</li>
      <li>Wants to ensure their smartphone has enough space for important apps or files, such as those related to employment</li>
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Deleting Apps Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about deleting apps on your smartphone. You do not need to read the
        entire tip sheet. Instead, find the heading that is related to your question.
        Read the information under that heading.`,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I’m trying to delete an app by tapping on it from the home screen. It is
            not working. What should I do?`,
            content: `<ul>
            <li>Be sure that you are tapping on the app with the pad of your finger</li>
            <li>Tap and hold the app down. Count to 5 slowly.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/87.png'/></li>
            <li>After holding down the app for 5 seconds, iPhone users
            should see the apps begin to shake. A small ‘x’ or horizontal line
            will appear in the corner of the apps. tap on the ‘x’ or horizontal
            line to delete the app you want. When you are done, press the
            home button, or tap on the screen. <br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/88.png'/></li>
            <li>After holding down the app for 5 seconds, Android users will most
            likely see a trash can appear. Tap on the trash can to delete the app. <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/89.png'/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `I’m on an iPhone, and I see the option to ‘offload apps.’ What does
            this mean?`,
            content: `To learn more about offloading apps, click here:<a href="https://www.youtube.com/watch?v=bnhuHSv_WrI"  target="_blank" rel="noreferrer"> How to free up space
            on your iPhone — Apple Support - YouTube</a>`,
          },
          {
            id: 4,
            title: `What happens if I delete the wrong app on iPhone? `,
            content: `<ul><li>You can see the list of deleted apps from the App Store as long as
            you are signed in with your Apple ID.
            The App Store looks like this:<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/60.png'/></li>
            <li>Open the App store. Tap on your profile icon in the top right corner of
            the screen. Then tap on ‘purchased.’ </li>
            <li>Tap on ‘Not on this iPhone’ on the top of the screen. This list will
            show you all the apps you have deleted on iPhone. It will also show
            you apps you have downloaded on other Apple devices. To reinstall
            the app, tap on the cloud icon with the downwards arrow. Reinstalling
            the app does not mean that you will get the information in the app
            back.<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/90.png'/></li>
            </ul>`,
          },
          {
            id: 5,
            title: `What happens if I delete the wrong app on an Android phone?`,
            content: `<ul>
              <li>You can see a list of deleted apps from the Google Play Store once
              you are signed in with Gmail credentials. The Google Play Store
              looks like this:<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/61.png'/></li>
              <li>Tap on your profile icon<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/91.png'/></li>
              <li>Tap on ‘manage apps and device’<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/92.png'/></li>
              <li>Tap on ‘manage’ <br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/93.png'/></li>
              <li>Tap on the arrow beside ‘installed.’ From the menu option that
              appears below, tap on ‘not installed.’<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/94.png'/></li>
              <li>A list of apps will appear. This list will show you all the apps you have
              deleted on your Android smartphone. This list might also include apps
              that you may have downloaded from the Google Play Store on other
              devices. Tap on the box beside the app you would like to reinstall.
              Then tap on the download button. Reinstalling the app does not mean
              that you will get the information in the app back. <br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/95.png'/></li>
              <li>(On older devices, the steps to find uninstalled apps will be different.
                After signing into the Google Play store, tap on 3 lines that look like
                this<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/96.png'/> Then tap on ‘my apps and games’. Then tap on ‘library.’)</li>
            </ul>`,
          },
          {
            id: 6,
            title: `I’m nervous about deleting the wrong thing`,
            content: `<ul>
              <li>Your smartphone will ask if you are sure you would like to delete the
              app<img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/97.png'/></li>
              <li>You are in control of the apps you delete. Before confirming that you
              would like to delete an app, ask yourself some questions: when was
              the last time you used the app? Is there valuable information in the
              app? Is it likely that you will need this app again?</li>
            </ul>`,
          },
          {
            id: 7,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 8,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/tipsheets/Deleting+Apps+Tip+Sheet.pdf",
        key: "modules/View Storage and uninstall Apps/tipsheets/Deleting Apps Tip Sheet.pdf",
      },
      {
        id: 2,
        name: "Understanding Storage on Smartphone Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about storage on your smartphone. You do not need to read the entire tip
        sheet. Instead, find the heading that is related to your question. Read the
        information under that heading. `,
        items: [
          {
            id: 1,
            title: "How do I know if I have an Apple or Android Smartphone?",
            content: `<div style="display:flex;">
                <div>
                <ul>
                        <li>If you have an iPhone, there will
                        be an image of an apple at the back. </li>
                        <li>
                        If there is no image of an apple,
you most likely have an Android phone. </li>
                      </ul>
                      </div><div><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png'/></div>`,
          },
          {
            id: 2,
            title: `I see GB, MB, and KB when I look at the storage space of each app.
            What do they mean?`,
            content: `<ul>
              <li>GB, MB, and KB refer to units of measurement. They refer to how
              much data your phone can store. Digital storage is often measured in
              GB, MB, or KB.</li>
              <li>GB means Gigabyte. MB means Megabyte. Megabytes are smaller
              than gigabytes. KB means kilobytes. Kilobytes are smaller than
              megabytes.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/98.png'/></li>
            </ul>`,
          },
          {
            id: 3,
            title: `I’m looking at the storage chart on my smartphone. I see System Data.
            What is that?`,
            content: `
            System data includes everything that does not fall into any of the
other categories on the storage chart. System data cannot be
removed from smartphones. System data might include things like:
            <ul>
            <li>Voice assistants<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/99.png'/></li>
            <li>Fonts ABCD</li>
              <li>Language translation when not online <br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/100.png'/></li>
            </ul>`,
          },
          {
            id: 4,
            title: "I can’t find the Settings app. What should I do?",
            content: `<ul>
              <li>If you are on an iPhone, tap the middle of your home screen. Swipe
              down from the middle of the screen. This will reveal a search bar on
              top of your screen. Tap on it. Type ‘settings.’ When the setting app
              appears, tap on it.<br/><img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/101.png'/></li>
              <li>If you are on an Android phone, you may be able to tap on the icon for
              the App Drawer. This will allow you to see all the installed apps on your
              phone, including settings. The icon for the App Drawer may look similar
              to this: <img src='https://mtml.s3.ca-central-1.amazonaws.com/assets/71.png'/></li>
            </ul>`,
          },
          {
            id: 5,
            title: `I feel nervous about even trying this new skill. What can I do?`,
            content: `<ul>
              <li>Feeling nervous can be a sign that you need practice to feel
              comfortable. </li>
              <li>Don’t be afraid to make a mistake. Making mistakes is how we learn.</li>
            </ul>`,
          },
          {
            id: 6,
            title: `This process is frustrating. How can I handle frustration?`,
            content: `<i>Frustration will make it harder to problem solve. To reduce frustration and
            increase problem solving, follow the steps below.</i>
            <ul>
              <li>Step 1: Pause. </li>
              <li>Step 2: Name what you’re feeling without judgement. Write down
              what you are feeling. Write the phrase “I am feeling______.” </li>
              <li>Step 3: Take a 5-minute break. You have the option to do something
              to help you feel calm, such as doing some gentle stretches, listening
              to music, or breathing deeply until you feel calm.</li>
              <li>You can also check out: Videos to Help you Feel Calm and Focused
              or the document on Changing Unhelpful Thoughts for Success while
              Learning. You can access them here: </li>
            </ul>`,
          },
        ],
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/tipsheets/Understanding+Storage+on+Smartphone+Tip+Sheet.pdf",
        key: "modules/View Storage and uninstall Apps/tipsheets/Understanding Storage on Smartphone Tip Sheet.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "View Storage and Uninstall Apps Mini Lesson.",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/lessons/Jan+22_YMCA_+View+Storage+and+Uninstall+Apps+Mini+Lesson.pdf",
        key: "modules/View Storage and uninstall Apps/lessons/Jan 22_YMCA_ View Storage and Uninstall Apps Mini Lesson.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/Qt-94brF-6Q",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/videos/6.+View+Storage+%26+Uninstall+Apps+-+HD+1080p.mov",
        subtitle: `${ window.location.protocol }//${ window.location.host }/modules/View+Storage+and+uninstall+Apps/videos/english.vtt`,
        key: "modules/View Storage and uninstall Apps/videos/6. View Storage & Uninstall Apps - HD 1080p.mov",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/videos/6.+Viewing+Storage+and+Uninstalling+Apps.docx",
  },
  {
    id: 15,
    name: "Using Bluetooth Safely",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo: "https://mtml.escases.ca/static/media/logo.326562fb.png",
    willLearnContent: `<ul>
      <li>How Bluetooth works</li>
      <li>How Bluetooth can be hacked</li>
      <li>How to recognize that your Bluetooth may be hacked</li>
      <li>How to protect yourself from Bluetooth hacking</li>
      </ul>`,
    greatForAnyoneContent: `<ul>
    
      </ul>`,
    tipSheets: [
      {
        id: 1,
        name: "Using Bluetooth Safely Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about using bluetooth safely. You do not need to read the
        entire tip sheet. Instead, find the heading that is related to your question.
        Read the information under that heading.`,

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Tipsheets/TipSheet_Using+Bluetooth+Safely.pdf",
        key: "modules/Using Bluetooth Safely/Tipsheets/TipSheet_Using Bluetooth Safely.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Using Bluetooth Safely",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Lessons/Mini+Lesson_Using+Bluetooth+Safely.pdf",
        key: "modules/Using Bluetooth Safely/lessons/Mini Lesson_Using Bluetooth Safely.pdf",
      },
    ],
    videos: null,
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Video+Transcript_Using+Bluetooth+safely.pdf",
    h5p: "/modules/h5p1",
    videoLink:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    videoKey:
        "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },
  {
    id: 16,
    name: "Protecting yourself from Phishing",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Saving+yourself+from+Phishing/videos/Saving+yourself+from+Phishing.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo: "https://mtml.escases.ca/static/media/logo.326562fb.png",
    willLearnContent: `<ul>
      <li>What phishing is and how it works</li>
      <li>Common phishing techniques</li>
      <li>How to protect yourself from phishing</li>
      <li>How to learn more about scams and phishing techniques</li>
      </ul>`,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Protecting yourself from Phishing Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about using phishing. You do not need to read the
        entire tip sheet. Instead, find the heading that is related to your question.
        Read the information under that heading.`,

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Saving+yourself+from+Phishing/tipsheets/TipSheet+Phishing.pdf",
        key: "modules/Saving yourself from Phishing/tipsheets/TipSheet Phishing.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Protecting yourself from Phishing",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Saving+yourself+from+Phishing/lessons/Mini+Lesson_Phishing.pdf",
        key: "modules/Saving yourself from Phishing/lessons/Mini Lesson_Phishing.pdf",
      },
    ],
    videos: null,
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Saving+yourself+from+Phishing/videos/Video+Transcript_Protecting+Yourself+from+Phishing.pdf",
    h5p: "/modules/h5p3",
    videoLink:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Saving+yourself+from+Phishing/videos/Protecting+Yourself+from+Phishing.mp4",
    videoKey:
        "modules/Saving yourself from Phishing/Protecting Yourself from Phishing.mp4",
  },
  {
    id: 17,
    name: "Banking Safely on your Smartphone",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Banking+Safely+on+your+Smartphone/Videos/Banking+Safely+on+your+Smartphone.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: `<ul>
      <li>How mobile banking works</li>
      <li>Common risks with mobile banking</li>
      <li>How to protect yourself from mobile banking risks</li>
      </ul>`,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Banking Safely on your Smartphone Tip Sheet",
        shortContent: `<strong>Instructions:</strong> Use the tip sheet to answer any questions you still have
        about using banking safely. You do not need to read the
        entire tip sheet. Instead, find the heading that is related to your question.
        Read the information under that heading.`,

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Banking+Safely+on+your+Smartphone/Videos/Video+Transcript_Banking+Safely+on+your+phone.pdf",
        key: "modules/Banking Safely on your Smartphone/Tipsheets/TipSheet_Banking Safely on your phone.pdf",
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Video Transcript: Banking Safely on your Smartphone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Banking+Safely+on+your+Smartphone/Videos/Video+Transcript_Banking+Safely+on+your+phone.pdf",
        key: "modules/Banking Safely on your Smartphone/lessons/Video Transcript_Banking Safely on your phone.pdf",
      },
    ],
    videos: null,
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Banking+Safely+on+your+Smartphone/Videos/Video+Transcript_Banking+Safely+on+your+phone.pdf",
    h5p: "/modules/h5p2",
    videoLink:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Banking+Safely+on+your+Smartphone/Videos/Banking+Safely+on+your+Smartphone.mp4",
    videoKey:
        "modules/Banking Safely on your Smartphone/videos/Banking Safely on your Smartphone.mp4",
  },
  {
    id: 18,
    name: "Location Safety on your Smartphone",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Location+Safety+on+your+Smartphone.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: `<ul>
      <li>How location settings work on your smartphone</li>
      <li>How to share your live location on Google Maps</li>
      <li>The advantages and disadvantages of sharing your location</li>
      <li>Tips for preventing apps from tracking your location</li>
      <li>Tips for preventing others from tracking your location</li>
      </ul>`,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Location Safety on your Smartphone",
        shortContent: ``,

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/tipsheets/TipSheet_Location+Safety+on+your+Smartphone.pdf",
        key: "modules/Location+Safety+on+your+Phone/tipsheets/TipSheet_Location+Safety+on+your+Smartphone.pdf",
        items: [
          {
            id: 1,
            title: "What is “location” on my smartphone?",
            content: `
                <p>Location is a setting on your smartphone. When you enable or allow it, apps and services can identify your location. Some apps need your location to work well, such as ride-sharing apps, food delivery apps, mapping apps, and weather apps.  </p>
                <p>You can manage the location settings for each app on your phone. You can allow location all the time, only while using the app, or not at all.</p>
                      `,
          },
          {
            id: 2,
            title:
                "What are the advantages and disadvantages of enabling location?",
            content: `
                <p>The advantage to enabling location is that it allows apps to give you services.</p>
                <p>The disadvantage is that apps can sell your location data to other companies. These companies can use that information to tailor advertising to you. Be careful to only allow location for apps that really need it.</p>
                      `,
          },
          {
            id: 3,
            title: "What is sharing live location on my smartphone?",
            content: `
                <p>Some apps allow you to share your live location with friends or family. This means that you are sharing the real-time location of your phone. If you have your phone with you, the people you share your location with can see where you are on a map.</p>`,
          },
          {
            id: 4,
            title:
                "I think my partner is tracking my location without my consent. What can I do?",
            content: `
            <ul>
            <li>Be aware of apps that can share your live location (e.g., Facebook Messenger, Snapchat, Google Maps, WhatsApp, and Find my Friends). If someone monitors your phone, they could share location in one of your apps, so they can see your location on their phone.</li>
            <li>Lock you phone screen and set a strong passcode, so no one can get into your phone.</li>
            <li>Check the apps on your phone’s settings. Make sure no new family locator apps have been downloaded without your knowledge.</li>
            <li>Check the location settings on your apps. If your live location is shared, turn it off.</li>
            </ul>`,
          },
          {
            id: 5,
            title: "How can I get help?",
            content: `
                <p>Visit the 211Ontario website, at <a href="https://211ontario.ca" target='_blank'> https://211ontario.ca</a> .</p>
                <p>211 Ontario is a free service that connects you to social and community supports. You can find help by calling, texting or searching the website.</p>
                `,
          },
        ],
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Location Safety on your Smartphone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/lessons/Mini+Lesson_Location+Safety+on+your+Smartphone.pdf",
        key: "modules/Location Safety on your Phone/lessons/Mini Lesson_Location Safety on your Smartphone.pdf",
      },
    ],
    videos: [
      {
        id: 1,
        youtubeLink: "https://www.youtube.com/embed/p2X-c9qQgng",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Smartphone/LocationSafety-on-yourSmartphone.mp4",
        // subtitle: `${window.location.protocol}//${window.location.host}/modules/Learning+about+Popular+Apps/videos/english.vtt`,
        subtitle: '',
        key: "modules/Location Safety on your Smartphone/LocationSafety-on-yourSmartphone.mp4",
      },
    ],
    transcript:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Video+Transcript_Location+Safety+on+your+Phone.pdf",
    // h5p: "/modules/h5p4",
    videoLink:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    videoKey:
        "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },
  {
    id: 19,
    name: "Using voice typing on smartphone",
    imageUrl: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+voice+typing+on+smartphone/Videos/thumbnail.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: null,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Tip Sheet: Voice Typing and Gboard",
        shortContent: ``,

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+voice+typing+on+smartphone/tipsheet/TipSheet_Voice+Typing+and+Gboard.pdf",
        key: "modules/Using voice typing on smartphone/tipsheet/TipSheet_Voice Typing and Gboard.pdf",
        items: [
          {
            id: 1,
            title: "What is Voice Typing?",
            content: `
                <p>Voice typing is a feature on most smartphones. Voice typing is when you speak and have
your phone type what you say. You can voice type anywhere that you can type. For example,
in a search bar, on social media, email, or in a notes app. And you can do it right from the
keyboard.</p>`,
          },
          {
            id: 2,
            title: "How do I know if I have voice typing on my phone?",
            content: `
                <p>To voice type, you need a microphone on the keyboard. It may be at the top or the botom
of the keyboard. This is diﬀerent from the microphone on your text or messaging app. That
microphone allows you to send voice messages. But the microphone on the keyboard allows
you to speak and have what you say, typed out.</p>`,
          },
          {
            id: 3,
            title: "How do I voice type?",
            content: `
                <p>Find the microphone on your keyboard and give it a try. Tap on it, and then speak. What you
say should be typed out.</p><p>
If you don’t see a microphone on your keyboard, check your keyboard se�ngs. Enable voice
input or voice typing.</p>`,
          },
          {
            id: 4,
            title: "What other features does my keyboard have?",
            content: `
                <p>All smartphones come with a keyboard, and each keyboard is a litle bit diﬀerent. Most
keyboards have voice typing and many other features. Experiment with the features on your
keyboard. Remember, you can change your keyboard if you want to.</p>`,
          },
          {
            id: 5,
            title: "What is Gboard?",
            content: `
                <p>Gboard is a keyboard made by Google. It is free and can be downloaded on an android or an
iPhone, from the Play Store or the App Store. It has voice typing, and also transla�on. You
can type or speak in one language, and it types your message in a diﬀerent language.</p>`,
          },
          {
            id: 6,
            title: "How can I learn more?",
            content: `
                <p>Search for how-to videos about the keyboard on your phone. Use search terms, such as the
model of your phone and the word keyboard. For example:</p>
<ul>
<li>Samsung keyboard</li>
<li>iPhone keyboard tips</li>
</ul>`,
          },
        ]
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Voice Typing on your Phone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+voice+typing+on+smartphone/lessons/Mini+Lesson_Voice+Typing+and+GBoard.pdf",
        key: "modules/Using voice typing on smartphone/lessons/Mini Lesson_Voice Typing and GBoard.pdf",
      },
    ],
    // videos: [
    //   {
    //     id: 1,
    //     youtubeLink: "https://www.youtube.com/embed/p2X-c9qQgng",
    //     link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+voice+typing+on+smartphone/Videos/Using+Voice+Typing+on+Smartphone.mp4",
    //     // subtitle: `${window.location.protocol}//${window.location.host}/modules/Learning+about+Popular+Apps/videos/english.vtt`,
    //     subtitle: '',
    //     key: "modules/Using voice typing on smartphone/Videos/Using Voice Typing on Smartphone.mp4",
    //   },
    // ],
    // transcript:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Video+Transcript_Location+Safety+on+your+Phone.pdf",
    h5p: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+voice+typing+on+smartphone/Videos/H5P_Voice+Typing+and+Gboard",
    videoLink:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    // videoKey:
    //     "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },
  {
    id: 20,
    name: "Using google maps",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Google+Maps+on+your+Phone/vidoes/thumbnail-map.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: null,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Tip Sheet: Using Google Maps on Your Phone",
        shortContent: ``,

        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Google+Maps+on+your+Phone/tipsheets/TipSheet_Using+Google+Maps+on+your+Phone.pdf",
        key: "modules/Google Maps on your Phone/tipsheets/TipSheet_Using Google Maps on your Phone.pdf",
        items: [
          {
            id: 1,
            title: "What is Google Maps?",
            content: `
                <p>Google maps is a free web app you can use to get directions for driving, walking, biking, or
transit. It gives real-time information about traﬃc and speed traps. It helps you find services
and explore what’s around you with road maps, satellite and street views.</p>`,
          },
          {
            id: 2,
            title: "What are the steps for finding directions?",
            content: `
                <ul>
                <li>Open Google maps by tapping on the icon on your phone.</li>
                <li>Type or tap on the microphone to say an address or place.</li>
                <li>Select the correct address from the list of results, and tap on “directions.”</li>
                <li>Select driving, walking, transit or biking directions.</li>
                <li>Tap on <strong>START</strong>.</li>
                </ul>`,
          },
          {
            id: 3,
            title: "How can I use street view or satellite view?",
            content: `
                <p>Satellite view provides real images from above. In street view, some streets will turn blue on
your map. If you tap on a blue area, you will see real images of the surroundings.</p>
<ul>
<li>Tap on the layers icon on a Google map.</li>
<li>Tap on a map type (default, satellite or terrain).</li>
<li>Tap on map details you want to see (public transit, traffic, street view).</li>
<li>Tap on the X to close the options window.</li>
</ul>`,
          },
          {
            id: 4,
            title: "How do I use diving directions without Internet?",
            content: `<p>You can use driving directions with or without internet. For example, if you are driving in
another country, you may want to use driving directions oﬄine. First, you need to download a
map, then you can use it oﬄine.</p>
<p><strong>Download a map:</strong></p>
<ul>
<li>Open Google Maps on your phone, and tap on your profile picture at the top right.</li>
<li>Select “Oﬄine Maps.”</li>
<li>Tap “Select Your Own Map.” You’ll see a map of your area. Pinch to zoom in or out on the
map until the rectangle marks the area of the map you want.</li>
<li>Tap “Download.” It’s best to do this while you have Wi-Fi.</li>
</ul>
<p><strong>Use a map offline:</strong></p>
<ul>
<li>Open Google Maps, and tap on your profile picture.</li>
<li>Select Offline maps.</li>
<li>Choose the map you downloaded, and tap on the map.</li>
<li>Enter your destination, and tap directions.</li>
</ul>`,
          },
        ]
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Using Google Maps on Your Phone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Google+Maps+on+your+Phone/lessons/Mini+Lesson_Using+Google+Maps.pdf",
        key: "modules/Google Maps on your Phone/lessons/Mini Lesson_Using Google Maps.pdf",
      },
    ],
    // videos: [
    //   {
    //     id: 1,
    //     youtubeLink: "https://www.youtube.com/embed/p2X-c9qQgng",
    //     link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Google+Maps+on+your+Phone/vidoes/Using+Google+Maps+on+Smartphones.mp4",
    //     // subtitle: `${window.location.protocol}//${window.location.host}/modules/Learning+about+Popular+Apps/videos/english.vtt`,
    //     subtitle: '',
    //     key: "modules/Google Maps on your Phone/vidoes/Using Google Maps on Smartphones.mp4",
    //   },
    // ],
    // transcript:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Video+Transcript_Location+Safety+on+your+Phone.pdf",
    h5p: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Google+Maps+on+your+Phone/vidoes/H5P_Using_Google_Maps",
    // videoLink:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    // videoKey:
    //     "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },
  {
    id: 21,
    name: "Using google lens",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Google+Lens/videos/thumbnail-g-len.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: null,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Tip Sheet: Using Google Lens on your phone",
        shortContent: ``,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Google+Lens/tipsheet/TipSheet_Using+Google+Lens.pdf",
        key: "modules/Using Google Lens/tipsheet/TipSheet_Using Google Lens.pdf",
        items: [
          {
            id: 1,
            title: "What is Google Lens?",
            content: `
                <p>Google Lens is a free app made by Google. It uses the camera on your phone to identify text
or objects in your surroundings. For an object, Lens can tell you what you are looking at and
give you links to learn more about it. For text, Lens can read it out loud or translate it.</p>`,
          },
          {
            id: 2,
            title: "How can I find Google Lens?",
            content: `
                <p>Google Lens is usually pre-installed in android phones. You can find it in the Google
search bar. It is a small icon that looks like a camera.</p>
<p>If you have an iPhone, you can download the Google search app from the App store.</p>`,
          },
          {
            id: 3,
            title: "How do I use Google Lens to read text out loud?",
            content:
                `<ul>
<li>Open Google Lens by tapping on the Lens icon in the Google search bar.</li>
<li>Aim your phone on the text you want to hear.</li>
<li>Tap the round shuter buton.</li>
<li>Move the borders of the frame to select a part of the text. Tap <strong>Select text.</strong> </li>
<li>Tap on <strong>Listen</strong>.</li>
</ul><p>Google Lens will read the text out loud, and highlight each word as it is read.</p>`,
          },
          {
            id: 4,
            title: "How do I use Google Lens to translate text?",
            content: `<ul>
                        <li>Open Google Lens by tapping on the Lens icon in the Google search bar.</li>
                        <li>Aim your phone on the text you want to translate, such as a sign or flyer.</li>
                        <li>Tap the round shuter buton.</li>
                        <li>Move the borders of the frame if needed. Tap Select text.</li>
                        <li>Tap on Translate</li>
                      </ul>`,
          },
          {
            id: 5,
            title: "How can I learn more?",
            content: `
                <p>Search for how-to videos about Google Lens. Use search terms, such as <i>Google Lens for
beginners</i>, or How to use Google Lens.</p>`,
          },

        ]
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Using Google Lens on Your Phone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Google+Lens/lessons/Mini+Lesson_Using+Google+Lens.pdf",
        key: "modules/Using Google Lens/lessons/Mini Lesson_Using Google Lens.pdf",
      },
    ],
    // videos: [
    //   {
    //     id: 1,
    //     youtubeLink: "https://www.youtube.com/embed/p2X-c9qQgng",
    //     link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Google+Lens/videos/Using+Google+Lens+on+Smartphones.mp4",
    //     // subtitle: `${window.location.protocol}//${window.location.host}/modules/Learning+about+Popular+Apps/videos/english.vtt`,
    //     subtitle: '',
    //     key: "modules/Using Google Lens/videos/Using Google Lens on Smartphones.mp4",
    //   },
    // ],
    // transcript:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Video+Transcript_Location+Safety+on+your+Phone.pdf",
    h5p: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Google+Lens/videos/H5P_Using_Google_Lens",
    // videoLink:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    // videoKey:
    //     "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },
  {
    id: 22,
    name: "Using text to speech",
    imageUrl: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Text+to+Speech+for+android/videos/thumbnail-text-speech.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: null,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Tip Sheet: Using Text to Speech for android",
        shortContent: ``,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Text+to+Speech+for+android/tipsheets/TipSheet_Text+to+Speech+for+android.pdf",
        key: "modules/Text to Speech for android/tipsheets/TipSheet_Text to Speech for android.pdf",
        items: [
          {
            id: 1,
            title: "What is Text to Speech?",
            content: `
                <p>Text to speech is a technology that reads digital text out loud. It is preinstalled on many
smartphones. On android smartphones, the text to speech func�on is called Select to Speak.
You can choose when to use it, and what you want it to read on your phone. It can read any
text on your phone. It can help people who have low vision, reading difficulties, or anyone
who prefers to listen to text rather than read it.</p>`,
          },
          {
            id: 2,
            title: "How can I get Select to Speak on my phone?",
            content: `
                <p>Follow these steps on your android phone.</p>
<ol>
<li>Open settings.</li>
<li>Scroll down and tap on Accessibility.</li>
<li>Find and tap on Select to Speak. If you don’t see it, it may be in “installed apps.”</li>
<li>Tap on the toggle buton for the Select to Speak shortcut.</li>
<li>Allow Select to Speak to have control, and select OK for language.</li>
<li>Tap on Use accessibility gesture, and choose how you want to open Select to Speak (e.g.,
two finger swipe up from the botom of your phone).</li>
</ol><p>Now, Select to speak is available whenever you need it.</p>`,
          },
          {
            id: 3,
            title: "How do I use Select to Speak?",
            content: `
                <p>When you have something on your phone screen you want to hear our loud, start Select to
Speak. I use a two-finger swipe up, from the bottom.</p>
<p>If you want to hear the entire screen, tap play. It will read from top to bottom. If you want to
hear part of the screen, first select what you want to hear by touching your screen. A green
box will outline the text, and when you lift your finger, Select to Speak automatically reads it
out loud.</p>
<p>If you want to change the speaking speed, tap the small len arrow on the Select to Speak
playbar, then tap the settings icon. Tap it again and change the speaking speed. Tap on the
back arrow to get back.</p>`,
          },
        ]
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Location Safety on your Smartphone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Text+to+Speech+for+android/lessons/Mini+Lesson_Text+to+Speech.pdf",
        key: "modules/Text to Speech for android/lessons/Mini Lesson_Text to Speech.pdf",
      },
    ],
    // videos: [
    //   {
    //     id: 1,
    //     youtubeLink: "https://www.youtube.com/embed/p2X-c9qQgng",
    //     link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Text+to+Speech+for+android/videos/Using+Text+to+Speech+for+android.mp4",
    //     // subtitle: `${window.location.protocol}//${window.location.host}/modules/Learning+about+Popular+Apps/videos/english.vtt`,
    //     subtitle: '',
    //     key: "modules/Text to Speech for android/videos/Using Text to Speech for android.mp4",
    //   },
    // ],
    // transcript:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Video+Transcript_Location+Safety+on+your+Phone.pdf",
    h5p: "https://mtml.s3.ca-central-1.amazonaws.com/modules/Text+to+Speech+for+android/videos/H5P_Using_Text_to_Speech_android",
    // videoLink:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    videoKey:
        "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },

  {
    id: 23,
    name: "Using Chatgpt",
    imageUrl:
        "https://mtml.s3.ca-central-1.amazonaws.com/modules/ChatGPT+on+your+Phone/videos/thumbnail-chatgpt.png",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
    willLearnContent: null,
    greatForAnyoneContent: null,
    tipSheets: [
      {
        id: 1,
        name: "Tip Sheet: Using ChatGPT on your phone",
        shortContent: ``,
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/ChatGPT+on+your+Phone/tipsheets/TipSheet_Using+ChatGPT+on+your+phone.pdf",
        key: "modules/ChatGPT on your Phone/tipsheets/TipSheet_Using ChatGPT on your phone.pdf",
        items: [
          {
            id: 1,
            title: "What is ChatGPT?",
            content: `
                <p>ChatGPT is a powerful artificial intelligence chatbot. You can ask it questions and have conversations in a way that seems like you’re talking to a real person. It was trained using a massive amount of information from the internet. It uses this information to recognize your questions and give you instant responses.</p>
<p>ChatGPT is free to use. However, you need to make an account. There is also a paid version of ChatGPT. The paid version is faster and has more features.</p>`,
          },
          {
            id: 2,
            title: "How do I find ChatGPT?",
            content: `
                <p>There are two ways to find ChatGPT. You can go to the ChatGPT website at chat.openai.com, or you can download the mobile app on your phone and use the mobile app.</p>`,
          },{
            id: 3,
            title: "How do I use ChatGPT?",
            content: `
                <p>You can use ChatGPT by asking it do something, such as: </p>
<ul>
<li>Write something. You can ask ChatGPT to write anything. It is a good idea to give details, such as  how formal you want the writing, or the any details the writing should include.</li>
<li>Improve your writing. You can copy and paste something you wrote into ChatGPT, then ask for feedback on your writing. You can also ask ChatGPT to improve the writing.</li>
<li>Answer a question. You can ask ChatGPT to explain something in simple language.</li>
<li>Summarize something. You can ask ChatGPT to summarize something. For example, an article you copy into ChatGPT, or a movie, a concept, or a historical event.</li>
</ul>`,
          },
          {
            id: 4,
            title: "Is ChatGPT safe to use?",
            content: `
                <p>Chat GPT is amazing, but there are some things you should be careful about:</p>
                <ul>
                  <li>Private information: Don’t give personal or financial information about yourself or your employer. Chat GPT collects everything on your account.</li>
                  <li>Accuracy: Don’t believe everything ChatGPT says. It can give incorrect or biased answers. </li>
                  <li>Plagiarism: ChatGPT draws on information from other sources. Relying on it for a course assignment would be plagiarism.</li>
                </ul>`,
          },
          {
            id: 5,
            title: "How can I learn more?",
            content: `
                <p>Search for how-to videos about ChatGPT.  Use search terms, such as ChatGPT for beginners, or ChatGPT mobile app.</p>`,
          },
        ]
      },
    ],
    lessons: [
      {
        id: 1,
        name: "Using ChatGPT on Your Phone",
        link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/ChatGPT+on+your+Phone/lessons/Mini+Lesson_Using+ChatGPT.pdf",
        key: "modules/ChatGPT on your Phone/lessons/Mini Lesson_Using ChatGPT.pdf",
      },
    ],
    // videos: [
    //   {
    //     id: 1,
    //     youtubeLink: "https://www.youtube.com/embed/p2X-c9qQgng",
    //     link: "https://mtml.s3.ca-central-1.amazonaws.com/modules/ChatGPT+on+your+Phone/videos/Using+ChatGPT+on+Smartphone.mp4",
    //     // subtitle: `${window.location.protocol}//${window.location.host}/modules/Learning+about+Popular+Apps/videos/english.vtt`,
    //     subtitle: '',
    //     key: "modules/ChatGPT on your Phone/videos/Using ChatGPT on Smartphone.mp4",
    //   },
    // ],
    // transcript:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Location+Safety+on+your+Phone/videos/Video+Transcript_Location+Safety+on+your+Phone.pdf",
    h5p: "https://mtml.s3.ca-central-1.amazonaws.com/modules/ChatGPT+on+your+Phone/videos/H5P_Using_ChatGPT_on_Phone",
    // videoLink:
    //     "https://mtml.s3.ca-central-1.amazonaws.com/modules/Using+Bluetooth+Safely/Videos/Using+Bluetooth+Safely.mp4",
    // videoKey:
    //     "modules/Using Bluetooth Safely/videos/Using Bluetooth Safely.mp4",
  },
];

export { modules };
