import SelectionBox from "components/Common/SelectionBox";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const ClosingAppOnYourSmartphone = ({ item }) => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [navigateButton1, setNavigateButton1] = useState(null);
  const [navigateButton2, setNavigateButton2] = useState(null);
  const [navigateButton3, setNavigateButton3] = useState(null);

  const [navigateButtonCommonName1, setNavigateButtonCommonName1] =
    useState(null);
  const [navigateButtonCommonName2, setNavigateButtonCommonName2] =
    useState(null);
  const [navigateButtonCommonName3, setNavigateButtonCommonName3] =
    useState(null);

  const [viewAllApp1, setViewAllApp1] = useState(null);
  const [viewAllApp2, setViewAllApp2] = useState(null);
  const [viewAllApp3, setViewAllApp3] = useState(null);
  const [viewAllApp4, setViewAllApp4] = useState(null);
  const [viewAllApp5, setViewAllApp5] = useState(null);
  const [viewAllApp6, setViewAllApp6] = useState(null);

  const [selectedSmartphone, setSelectedSmartphone] = useState(null);
  const [selectedSmartphoneSubmitted, setSelectedSmartphoneSubmitted] =
    useState(false);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setActiveTab(3);
  };

  const handleOnClick = (name, value) => {
    switch (name) {
      case "viewAllApp1":
        setViewAllApp1(value);
        break;
      case "viewAllApp2":
        setViewAllApp2(value);
        break;
      case "viewAllApp3":
        setViewAllApp3(value);
        break;
      case "viewAllApp4":
        setViewAllApp4(value);
        break;
      case "viewAllApp5":
        setViewAllApp5(value);
        break;
      case "viewAllApp6":
        setViewAllApp6(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.name}</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href={item.link}
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Row>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              <strong>
                                Are you using Android or iPhone Smartphone?
                              </strong>
                            </Label>
                            <div
                              className={
                                selectedSmartphoneSubmitted &&
                                !selectedSmartphone
                                  ? "is-invalid"
                                  : ""
                              }
                            >
                              <SelectionBox
                                title="A"
                                name=""
                                value="Android"
                                isCorrect={
                                  selectedSmartphone === "Android" ? true : null
                                }
                                onClick={() => setSelectedSmartphone("Android")}
                              />
                              <SelectionBox
                                title="B"
                                value="iPhone"
                                isCorrect={
                                  selectedSmartphone === "iPhone" ? true : null
                                }
                                onClick={() => setSelectedSmartphone("iPhone")}
                              />
                            </div>
                            {selectedSmartphoneSubmitted &&
                              !selectedSmartphone && (
                                <div className="invalid-feedback-custom">
                                  Select your smartphone is required.
                                </div>
                              )}
                          </div>
                          <div className="mb-5">
                            <Button
                              style={{
                                width: "fit-content",
                                // marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                setSelectedSmartphoneSubmitted(true);
                                if (selectedSmartphone) {
                                  toggleTab(activeTab + 1);
                                }
                              }}
                            >
                              <span>Next Activity</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div
                    style={{ height: "25rem" }}
                    className="center-container content-text"
                  >
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>
                          Multiple choice: Choose the best answer for each
                          question
                        </strong>
                      </p>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label>
                              What are Navigation Buttons on your Smartphone?
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Three buttons to help you find your way around your
                              phone"
                              isCorrect={navigateButton1 ? true : null}
                              onClick={() =>
                                setNavigateButton1(!navigateButton1)
                              }
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="Apps for traveling from home to other places"
                              isCorrect={navigateButton2 ? false : null}
                              onClick={() =>
                                setNavigateButton2(!navigateButton2)
                              }
                            />
                            <SelectionBox
                              title="C"
                              name=""
                              value="Three buttons at the top of the home screen"
                              isCorrect={navigateButton3 ? false : null}
                              onClick={() =>
                                setNavigateButton3(!navigateButton3)
                              }
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              What are the common names for the three navigation
                              buttons?
                            </Label>
                            <p>
                              <strong>Note: </strong>Depending on the type of
                              phone you have your navigation buttons may use
                              different terms or wording and may look slightly
                              different.
                            </p>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Home, Close Apps, Back."
                              isCorrect={
                                navigateButtonCommonName1 ? false : null
                              }
                              onClick={() =>
                                setNavigateButtonCommonName1(
                                  !navigateButtonCommonName1
                                )
                              }
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="Home, Back, View all Apps."
                              isCorrect={
                                navigateButtonCommonName2 ? true : null
                              }
                              onClick={() =>
                                setNavigateButtonCommonName2(
                                  !navigateButtonCommonName2
                                )
                              }
                            />
                            <SelectionBox
                              title="C"
                              name=""
                              value="View all Apps, Return, Screen."
                              isCorrect={
                                navigateButtonCommonName3 ? false : null
                              }
                              onClick={() =>
                                setNavigateButtonCommonName3(
                                  !navigateButtonCommonName3
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>
                          True or False: Read the statements below. Decide if
                          each statement is either True or False
                        </strong>
                      </p>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label>
                              It is necessary to close apps every time you are
                              finished using them.
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="True"
                              isCorrect={viewAllApp2 === "True" ? false : null}
                              onClick={() =>
                                handleOnClick("viewAllApp2", "True")
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="False"
                              isCorrect={viewAllApp2 === "False" ? true : null}
                              onClick={() =>
                                handleOnClick("viewAllApp2", "False")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              Gestures are swiping and tapping on the touch
                              screen to help you find your way around your
                              phone.
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="True"
                              isCorrect={viewAllApp3 === "True" ? true : null}
                              onClick={() =>
                                handleOnClick("viewAllApp3", "True")
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="False"
                              isCorrect={viewAllApp3 === "False" ? false : null}
                              onClick={() =>
                                handleOnClick("viewAllApp3", "False")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              ‘View all Apps’ means you can scroll through all
                              the open apps by swiping left or right on your
                              screen.
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="True"
                              isCorrect={viewAllApp4 === "True" ? true : null}
                              onClick={() =>
                                handleOnClick("viewAllApp4", "True")
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="False"
                              isCorrect={viewAllApp4 === "False" ? false : null}
                              onClick={() =>
                                handleOnClick("viewAllApp4", "False")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              When swiping through your open apps, if you see
                              the app you want to use, tap on it to open it up.
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="True"
                              isCorrect={viewAllApp5 === "True" ? true : null}
                              onClick={() =>
                                handleOnClick("viewAllApp5", "True")
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="False"
                              isCorrect={viewAllApp5 === "False" ? false : null}
                              onClick={() =>
                                handleOnClick("viewAllApp5", "False")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              Common terms about looking for Apps include: open,
                              switch, used, close, view.
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="True"
                              isCorrect={viewAllApp6 === "True" ? true : null}
                              onClick={() =>
                                handleOnClick("viewAllApp6", "True")
                              }
                            />
                            <SelectionBox
                              title="B"
                              name=""
                              value="False"
                              isCorrect={viewAllApp6 === "False" ? false : null}
                              onClick={() =>
                                handleOnClick("viewAllApp6", "False")
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={5}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col lg="12">
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <p>
                              <strong>
                                Congratulation! you have successfully finished
                                the lesson!
                              </strong>
                            </p>
                            <p className="text-muted">
                              If you would like to try and create a new click on
                              the Start Lesson again button to start over.
                            </p>
                          </div>
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(1);
                              setSelectedSmartphone(null);
                              setSelectedSmartphoneSubmitted(false);
                            }}
                          >
                            <span>Restart Lesson</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ClosingAppOnYourSmartphone.propTypes = {
  item: PropTypes.object,
};

export default ClosingAppOnYourSmartphone;
