import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  CardHeader,
  CardTitle,
  Label,
  Row,
  Col,
} from "reactstrap";
import NumberFormat from "react-number-format";

export class BidOfferEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      isAcceptService: false,
    };
  }

  handleOnBidPriceChange = value => {
    const { id, name, isProduct, isAcceptService, price } = this.props.item;
    const item = {
      id,
      name,
      isProduct,
      price,
      isAcceptService,
    };

    item.price = Number(value);
    this.props.onChange(item);
  };

  handleChange = e => {
    const { name: clName, checked } = e.target;
    const { id, name, isProduct, isAcceptService, price } = this.props.item;
    const item = {
      id,
      name,
      isProduct,
      price,
      isAcceptService,
    };
    switch (clName) {
      case "isAcceptService":
        item.isAcceptService = checked;
        this.setState({
          isAcceptService: checked,
        });
        break;
      default:
        break;
    }

    this.props.onChange(item);
  };

  static getDerivedStateFromProps(props, state) {
    const { item } = props;
    if (item) {
      const { id, isAcceptService } = item;
      if (id !== state.id) {
        return {
          id,
          isAcceptService,
        };
      }
    }

    return {
      ...state,
    };
  }

  render() {
    const { item } = this.props;
    const { name, isProduct } = item;
    return (
      <Card className="mb-2 border">
        <CardHeader className="bg-transparent border-bottom">
          <CardTitle>{name}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={8}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Bid Price</Label>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  placeholder="Bid Price"
                  onValueChange={values => {
                    const { value } = values;
                    this.handleOnBidPriceChange(value);
                  }}
                  prefix={"$"}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <div className="d-flex justify-content-end">
                <Input
                  type="checkbox"
                  name="isAcceptService"
                  disabled={isProduct}
                  checked={this.state.isAcceptService}
                  className="form-check-input"
                  style={{ fontSize: "28px" }}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

BidOfferEntry.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
};

export default BidOfferEntry;
