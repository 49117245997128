import { modules } from "common/data/modules";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, CardSubtitle, CardTitle } from "reactstrap";

import FilterModule from "./FilterModule";
const parterNames = [
  {
    id: 1,
    name: "LAMP",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png",
  },
  {
    id: 2,
    name: "ACCES Employment",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png",
  },
  {
    id: 3,
    name: "Literacy Council York Simcoe",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png",
  },
  {
    id: 4,
    name: "Labour Education Centre",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png",
  },
  {
    id: 5,
    name: "Toronto Council Fire",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png",
  },
  {
    id: 6,
    name: "YMCA",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png",
  },
  {
    id: 7,
    name: "MTML",
    logo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png",
  },
];

export class MoreModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      items: modules,
    });
  };

  handleOnFilter = item => {
    this.setState({
      items:
        item === "All" ? modules : modules.filter(e => e.partnerName === item),
    });
  };

  handleSelectModule = module => {
    const { profile } = this.props;
    const { id } = module;
    window.location = `/${profile}/module/${id}`;
  };

  render() {
    const { items } = this.state;
    return (
      <div>
        <div>
          <CardTitle style={{ fontSize: "18px" }}>
            Click on the logo to view videos created by a specific Service
            Provider?
          </CardTitle>
          <div>
            <Button
              color="primary"
              onClick={() => this.handleOnFilter("All")}
              outline
              className="mx-1 my-1 sp-card"
            >
              <div className="avatar-sm d-flex justify-content-center align-items-center">
                <span className="font-size-20 text-primary">All</span>
              </div>
            </Button>
            {parterNames.map((item, index) => {
              return (
                <FilterModule
                  item={item}
                  key={index}
                  onClick={this.handleOnFilter}
                />
              );
            })}
          </div>
        </div>
        <CardTitle style={{ fontSize: "18px" }} className="mt-4 mb-3">
          Smartphone Modules
        </CardTitle>
        <div className="mt-2">
          {items.map((module, index) => {
            return (
              <div
                className="d-flex mb-3"
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => this.handleSelectModule(module)}
              >
                <div className="me-3 card-module-img">
                  <img
                    src={module.imageUrl}
                    style={{ width: "168px", height: "94px" }}
                  />
                </div>

                <div className="flex-grow-1">
                  <CardTitle
                    className="text-truncate-2lines"
                    style={{ width: "15rem" }}
                  >
                    {module.name}
                  </CardTitle>
                  <CardSubtitle className="text-truncate">
                    {module.partnerName}
                    <i className="fas fa-check-circle font-size-14 ms-2"></i>
                  </CardSubtitle>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

MoreModule.propTypes = {
  profile: PropTypes.string,
  onFilter: PropTypes.func,
};

export default MoreModule;
