import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";

class NotificationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getIcon(severity) {
    if (severity == 0) {
      return "bx bx-comment";
    } else if (severity == 1) {
      return "bx bx-error";
    } else if (severity == 2) {
      return "bx bx-error-circle";
    }
  }

  render() {
    const { title, content, link, dateCreated, severity } = this.props;
    return (
      <Link to={link} className="text-reset notification-item">
        <div className="media">
          <div className="avatar-xs me-3">
            <span className="avatar-title bg-primary rounded-circle font-size-16">
              <i className={this.getIcon(severity)} />
            </span>
          </div>
          <div className="media-body">
            <h6 className="mt-0 mb-1">{title}</h6>
            <div className="font-size-12 text-muted">
              <p className="mb-1">{content}</p>
              <p className="mb-0">
                <i className="mdi mdi-clock-outline" />{" "}
                <TimeAgo date={dateCreated} />
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

NotificationItem.propTypes = {
  onCloseClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.string,
  severity: PropTypes.number,
  dateCreated: PropTypes.instanceOf(Date),
};

export default NotificationItem;
