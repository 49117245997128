import SelectionBox from "components/Common/SelectionBox";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import LearningAboutSmartphoneAndroid from "./LearningAboutSmartphoneAndroid";
import LearningAboutSmartphoneIPhone from "./LearningAboutSmartphoneIPhone";

import UsingYoutubeStepA from "./UsingYoutubeStepA";

const LearningAboutSmartphone = ({ item }) => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedSmartphone, setSelectedSmartphone] = useState(null);
  const [selectedSmartphoneSubmitted, setSelectedSmartphoneSubmitted] =
    useState(false);

  const [yourTurn, setYourTurn] = useState("");

  const [videoDescription, setVideoDescription] = useState(null);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setActiveTab(2);
  };

  const handleOnClick = (name, value) => {
    switch (name) {
      case "titleOfVideo":
        setTitleOfVideo(value);
        break;
      case "videoView":
        setVideoView(value);
        break;
      case "videoUpload":
        setvideoUpload(value);
        break;
      case "videoLike":
        setVideoLike(value);
        break;
      case "videoDescription":
        setVideoDescription(value);
        break;

      case "whatIsWiFiHotspot1":
        setWhatIsWiFiHotspot1(value);
        break;
      case "whatIsWiFiHotspot2":
        setWhatIsWiFiHotspot2(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.name}</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href={item.link}
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div
                    style={{ height: "25rem" }}
                    className="center-container content-text"
                  >
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Row>
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              <strong>
                                Are you using Android or iPhone Smartphone?
                              </strong>
                            </Label>
                            <div
                              className={
                                selectedSmartphoneSubmitted &&
                                !selectedSmartphone
                                  ? "is-invalid"
                                  : ""
                              }
                            >
                              <SelectionBox
                                title="A"
                                name=""
                                value="Android"
                                isCorrect={
                                  selectedSmartphone === "Android" ? true : null
                                }
                                onClick={() => setSelectedSmartphone("Android")}
                              />
                              <SelectionBox
                                title="B"
                                value="iPhone"
                                isCorrect={
                                  selectedSmartphone === "iPhone" ? true : null
                                }
                                onClick={() => setSelectedSmartphone("iPhone")}
                              />
                            </div>
                            {selectedSmartphoneSubmitted &&
                              !selectedSmartphone && (
                                <div className="invalid-feedback-custom">
                                  Select your smartphone is required.
                                </div>
                              )}
                          </div>
                          <div className="mb-5">
                            <Button
                              style={{
                                width: "fit-content",
                                // marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                setSelectedSmartphoneSubmitted(true);
                                if (selectedSmartphone) {
                                  toggleTab(activeTab + 1);
                                }
                              }}
                            >
                              <span>Next Activity</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      {selectedSmartphone === "Android" && (
                        <LearningAboutSmartphoneAndroid />
                      )}
                      {selectedSmartphone === "iPhone" && (
                        <LearningAboutSmartphoneIPhone />
                      )}

                      <Row>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                  setSelectedSmartphone(null);
                                  setSelectedSmartphoneSubmitted(false);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>Pick the Best Search Phrase</strong>
                      </p>
                      <Row>
                        <Col md={8}>
                          <FormGroup>
                            <Label>
                              Imagine you are trying to learn how to change the
                              wallpaper on the home screen of your new iPhone.
                              You decide to try and learn the steps from a
                              YouTube video. Look at the following options
                              below. Select the search phrase that you would use
                              on YouTube to find the best video to learn from.
                            </Label>
                            <SelectionBox
                              title="A"
                              name=""
                              value="Changing wallpaper on smartphone"
                              isCorrect={
                                videoDescription === "false" ? false : null
                              }
                              onClick={() =>
                                handleOnClick("videoDescription", "false")
                              }
                            />

                            <SelectionBox
                              title="B"
                              name=""
                              value="How to change wallpaper on iPhone home screen "
                              isCorrect={
                                videoDescription === "true" ? true : null
                              }
                              onClick={() =>
                                handleOnClick("videoDescription", "true")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            {/* <Label>
                              Write 1-2 sentences on why think the search phrase
                              you chose will help you find the best video to
                              learn from.
                            </Label> */}
                            <Input
                              id="searchPhrase"
                              name="searchPhrase"
                              type="textarea"
                              rows={4}
                              placeholder=""
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          {
                            <div className="mb-5">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <span>Next Activity</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={5}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col lg="12">
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <p>
                              <strong>
                                Congratulation! you have successfully finished
                                the lesson!
                              </strong>
                            </p>
                            <p className="text-muted">
                              If you would like to try and create a new click on
                              the Start Lesson again button to start over.
                            </p>
                          </div>
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <span>Restart Lesson</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

LearningAboutSmartphone.propTypes = {
  item: PropTypes.object,
};

export default LearningAboutSmartphone;
