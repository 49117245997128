import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displayBidStatus } from "helpers/utils";
import { BadgeButton } from "components/Common/BadgeButton";

export class MyBidTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onViewDetails,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <>
        <div className="table-rep-plugin">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              {/* <Table className="table table-striped table-bordered align-middle"> */}
              <Table className="project-list-table table table-nowrap align-top table-borderless">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer</th>
                    <th>Vehicle</th>
                    <th>Services</th>
                    <th>Expiry Date</th>
                    <th>Earliest Available Date</th>
                    <th>Status</th>
                    <th>
                      <div className="text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => {
                    const {
                      id,
                      statusName,
                      vehicleMake,
                      vehicleModel,
                      vehicleYear,
                      firstName,
                      lastName,
                      email,
                      phone,
                    } = item;
                    let num = (currentPage - 1) * defaultPageSize + (index + 1);
                    let vehicle = `${vehicleYear} ${vehicleMake} ${vehicleModel}`;
                    /*
                    if (isMobile)
                      return (
                        <Card>
                          <table>
                            <tr key={index}>
                              <td>{id}</td>
                              <td>
                                {statusName === "Accepted" && (
                                  <>
                                    <h5 className="font-size-14 mb-1">
                                      {firstName} {lastName}
                                    </h5>
                                    <p className="text-muted mb-0">{phone}</p>
                                    <p className="text-muted mb-0">{email}</p>
                                  </>
                                )}
                              </td>
                              <td>{vehicle}</td>
                              <td>
                                <div className="d-flex flex-column">
                                  {item.bidServices &&
                                    item.bidServices.map((a, index) => {
                                      return (
                                        <p
                                          key={index}
                                          className="text-muted mb-0"
                                        >
                                          {a.name}
                                        </p>
                                      );
                                    })}
                                </div>
                              </td>
                              <td>
                                {moment(item.expiryDate).format("DD-MMM-YYYY")}
                              </td>
                              <td>
                                {moment(item.earliestAvailableDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </td>
                              <td>{displayBidStatus(item)}</td>
                              <td>
                                <div className="text-center">
                                  {["Draft"].includes(statusName) && (
                                    <Button
                                      size="sm"
                                      outline
                                      color="success"
                                      // onClick={() => onEdit(item.id)}
                                    >
                                      <i className="mdi mdi-pencil font-size-16" />
                                    </Button>
                                  )}
                                  {["Accepted"].includes(statusName) && (
                                    <BadgeButton
                                      action={() => onViewDetails(item.id)}
                                      color={"primary"}
                                      iconClass={"mdi mdi-card-account-details"}
                                      label={"Details"}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </Card>
                      );*/

                    return (
                      <tr key={index}>
                        <td>{id}</td>
                        <td>
                          {statusName === "Accepted" && (
                            <>
                              <h5 className="font-size-14 mb-1">
                                {firstName} {lastName}
                              </h5>
                              <p className="text-muted mb-0">{phone}</p>
                              <p className="text-muted mb-0">{email}</p>
                            </>
                          )}
                        </td>
                        <td>{vehicle}</td>
                        <td>
                          <div className="d-flex flex-column">
                            {item.bidServices &&
                              item.bidServices.map((a, index) => {
                                return (
                                  <p key={index} className="text-muted mb-0">
                                    {a.name}
                                  </p>
                                );
                              })}
                          </div>
                        </td>
                        <td>{moment(item.expiryDate).format("DD-MMM-YYYY")}</td>
                        <td>
                          {moment(item.earliestAvailableDate).format(
                            "DD-MMM-YYYY"
                          )}
                        </td>
                        <td>{displayBidStatus(item)}</td>
                        <td>
                          <div className="text-center">
                            {["Draft"].includes(statusName) && (
                              <Button
                                size="sm"
                                outline
                                color="success"
                                // onClick={() => onEdit(item.id)}
                              >
                                <i className="mdi mdi-pencil font-size-16" />
                              </Button>
                            )}
                            {["Accepted"].includes(statusName) && (
                              <BadgeButton
                                action={() => onViewDetails(item.id)}
                                color={"primary"}
                                iconClass={"mdi mdi-card-account-details"}
                                label={"Details"}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>

        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </>
    );
  }
}

MyBidTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onBidDetail: PropTypes.func,
  onViewDetails: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default MyBidTable;
