import axios from "axios";
import { Auth } from "aws-amplify";
import { resetSection } from "redux-form";

let API_URL = "";
let axiosApi;

if (process.env.REACT_APP_API_URL) {
  API_URL = process.env.REACT_APP_API_URL;
}
const getToken = async () => {
  let token = localStorage.getItem("token");
  try {
    const newToken = (await Auth.currentSession()).getIdToken().getJwtToken();

    if (newToken) {
      token = newToken;
      localStorage.setItem("token", newToken);
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error refreshing token", error);
    console.log("cannot refresh, redirect to login");
    window.location = "/login";
    return null;
  }

  return token;
};

const initializeAxios = () => {
  axiosApi = axios.create({
    baseURL: API_URL,
  });

  // let token = await getToken();
  try {
    let token = localStorage.getItem("token");

    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    axiosApi.interceptors.response.use(
      response => response,
      error => Promise.reject(error)
    );
  } catch (err) {
    console.log(err);
  }
};

export async function get(url, config = {}) {
  initializeAxios();
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function getFile(url, config = {}) {
  initializeAxios();
  return await axiosApi.get(url, { ...config });
}

export async function post(url, data, config = {}) {
  initializeAxios();
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  initializeAxios();
  return axiosApi.put(url, data, { ...config }).then(response => response.data);
}

export async function del(url, config = {}) {
  initializeAxios();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
