import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import verifyCode from "./auth/verify-code/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";
import serviceRequest from "./service-request-question/reducer";
import serviceReq from "./service-request/reducer";
import user from "./user/reducer";
import bid from "./bid/reducer";
import notifications from "./notifications/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  verifyCode,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  serviceRequest,
  serviceReq,
  bid,
  notifications,
  user,
});

export default rootReducer;
