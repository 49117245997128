import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import { Auth } from "aws-amplify";

const forgotPasswordRequest = email => {
  // Send confirmation code to user's email
  console.log("forgotPasswordRequest", email);
  Auth.forgotPassword(email)
    .then(data => console.log(data))
    .catch(err => console.log(err));
};

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    yield call(forgotPasswordRequest, user.email);

    yield put(
      userForgetPasswordSuccess("A Reset link has been sent to your email.")
    );
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export default forgetPasswordSaga;
