import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
import avatar from "../../../assets/images/avatar.png";

import { connect } from "react-redux";
import { isCustomer, isVendor, isAdministrator } from "helpers/utils";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "Admin",
      user: null,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    const { profile } = this.props;
    const { firstName, lastName, companyName } = profile;

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={avatar}
              alt="Header Avatar"
            />{" "}
            {isCustomer() && (
              <span className="d-none d-xl-inline-block ms-1">
                {firstName} {lastName}
              </span>
            )}
            {isVendor() && (
              <span className="d-none d-xl-inline-block ms-1">
                {companyName}
              </span>
            )}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {isVendor() && (
              <DropdownItem tag="a" href="/bid-offer">
                <i className="fas fa-wrench font-size-16 align-middle me-1 text-primary" />
                {this.props.t("Bid Offer")}
              </DropdownItem>
            )}
            {isAdministrator() && (
              <DropdownItem tag="a" href="/vendors">
                <i className="fas fa-user font-size-16 align-middle me-1 text-primary" />
                {this.props.t("Vendors")}
              </DropdownItem>
            )}
            {isCustomer() && (
              <DropdownItem tag="a" href="/service-request">
                <i className="fas fa-car font-size-16 align-middle me-1 text-primary" />
                {this.props.t("My Service Request")}
              </DropdownItem>
            )}
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  profile: PropTypes.object,
  success: PropTypes.string,
};

const mapStateToProps = ({ Login, Profile }) => {
  const { success } = Profile;
  const { token } = Login;
  return {};
  // return { success, user: token ? decodeJwt(token) : getUserProfile() };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
);
