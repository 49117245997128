import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_BY_USER,
  USER_CLEAR_ERROR,
  GET_PAGED_VENDORS,
  GET_PAGED_VENDORS_SUCCESS,
  GET_PAGED_VENDORS_FAIL,
  VERIFY_USER,
  VERIFY_USER_FAIL,
  UPDATE_USER_STAR_RATING,
} from "./actionTypes";

// User
export const getUsers = query => ({
  type: GET_ALL_USERS,
  payload: query,
});

export const getUsersSuccess = users => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
});

export const getUsersFail = error => ({
  type: GET_ALL_USERS_FAIL,
  payload: error,
});

export const getUserById = id => ({
  type: GET_USER_BY_ID,
  payload: id,
});

export const getUserByIdSuccess = user => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
});

export const getUserByIdFail = error => ({
  type: GET_USER_BY_ID_FAIL,
  payload: error,
});

export const updateUser = user => ({
  type: UPDATE_BY_USER,
  payload: user,
});

export const updateUserStarRating = user => ({
  type: UPDATE_USER_STAR_RATING,
  payload: user,
});

export const clearUserError = () => ({
  type: USER_CLEAR_ERROR,
});

export const getPagedVendors = query => ({
  type: GET_PAGED_VENDORS,
  payload: query,
});

export const getPagedVendorsSuccess = users => ({
  type: GET_PAGED_VENDORS_SUCCESS,
  payload: users,
});

export const getPagedVendorsFail = error => ({
  type: GET_PAGED_VENDORS_FAIL,
  payload: error,
});

export const verifyUser = id => ({
  type: VERIFY_USER,
  payload: id,
});

export const verifyUserFail = error => ({
  type: VERIFY_USER_FAIL,
  payload: error,
});
