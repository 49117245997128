const accessibilitySettings = [
  {
    id: 0,
    title:
      "Guide to Accessibility Settings on Adobe Acrobat App and Microsoft Word App",
    items: [
      {
        id: 1,
        title: "Did You Know?",
        content:
          "Most digital devices have accessibility settings. If you live with a disability, accessibility settings can make it easier for you to use your digital device, especially when learning. Continue reading to learn more about how to find accessibility settings on an iPhone.",
      },
      {
        id: 2,
        title: "Let’s Get Started",
        content: `<img width='200px' src='https://mtml.s3.ca-central-1.amazonaws.com/assets/phone.png'/><p>What type of phone do you have?</p><p>If you have an iPhone, there will be an apple on the back of the phone.</p><p>If there is no image of an apple, you almost certainly have an Android phone.</p>`,
      },
      {
        id: 3,
        title: "Adobe Acrobat App",
        content: `<p><strong>What accessibility features exist on the Adobe Acrobat app?</strong><p><p>When you open a tip sheet or mini lesson in the Adobe Acrobat app, you will have
        the option to control the: text size, spacing between lines, or spacing between
        words. These options are categorized as ‘Reading Settings.’</p>
        <br/><p><strong>How do I access ‘Reading Settings’?</strong></p><p>Be sure your document is in ‘liquid mode.’ Liquid mode displays your document in
        the ideal format for your device. Tap on the teardrop icon on top of the screen to
        enter ‘liquid mode’. Once in ‘liquid mode’, tap on the icon that contains an upper
        case ‘A’ and lower case ‘a.’ The ‘Reading Settings’ will open in a menu at the
        bottom of the screen.</p>
        <img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/2.png"/>
        <p><strong>How do I change the line spacing of the document?</strong></p><p>Tap on one of the squares beside ‘Line Spacing’ to adjust the amount of space
        between each line in the document. You will notice that each square contains a
        preview of how much space will be between each line.</p><p><strong>How do I change the word spacing of the document?</strong></p><p>
        Tap on the square that says ‘VA’ to change the space between the words in the document. The square that says ‘VA’ with the arrows pointing away from each other will increase the space between the text. The square that says ‘VA’ with 
        the arrows pointing towards each other will decrease the space between the text.
        </p><p><strong>How do I change the text size of the document?</strong></p><p>Tap on the square with the big ‘A’ to increase the text size. Tap on the square with
        the smaller ‘A’ to decrease text size.</p><img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/3.png"/>`,
      },
      {
        id: 4,
        title: `What Other Settings Can I Use on Adobe Acrobat?`,
        content: `<p>You can turn on ‘night mode’ to change the contrast of your screen and the text.
        To enter ‘night mode,’ be sure that you are not in ‘liquid mode’ by deselecting the
        teardrop icon at the top of the screen.</p><p>Tap on the icon that looks like an A next to five lines. A menu with ‘view settings’
        will appear at the bottom of your screen. Tap on the button beside ‘night mode’ so
        that it is in blue. This means that it is on.</p>
        <img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/4.png"/>`,
      },
      {
        id: 5,
        title: "Microsoft Word App",
        content: `<p><strong>What accessibility features exist on the Microsoft Word app?</strong></p><p>If you can convert the PDF tip sheet or PDF mini lesson to the Microsoft Word format, you
        can open the document with the Microsoft Word app. When you open the document with
        the Microsoft Word app, you will notice an option that says ‘Read Aloud’ at the bottom of
        the screen. Tap on ‘Read Aloud’ to have the tip sheet or mini lesson read to you.</p>
        <p><strong>How does the ‘Read Aloud’ feature work?</strong></p>
        <p>Once you tap ‘Read Aloud,’ you will see a control bar appear at the bottom of your screen.
        The control bar allows you to rewind, pause, or fast forward as your document is read
        aloud. Tap the left facing arrows to rewind or go back. Tap the two vertical rectangles to
        pause. Tap the right facing arrows to fast forward. If you need to adjust how fast or slow
        your document is read aloud tap the speaker icon with the gear in front of it. Tap the ‘x’ to
        exit from the read aloud feature.</p>
        <img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/5.png"/>`,
      },
      {
        id: 6,
        title: "Finally...",
        content: `<p>If you don’t have the Microsoft Word app or the Adobe Acrobat app, you can
        download them. If you are on an iPhone, you can download them from the App
        Store. If you are on an Android smartphone, you can download them from the
        Google Play Store. To learn more about downloading apps, check out the module
        on <a href="https://www.youtube.com/watch?v=0cqb3EpaR5o">How to Learn About Popular Apps - YouTube.</a></p>`,
      },
    ],
  },
  {
    id: 1,
    title:
      "Resources to Help you Learn More about Accessibility Settings on your Smartphone",
    items: [
      {
        id: 1,
        title: `Did You Know?`,
        content: `Most digital devices have accessibility settings. If you live with a disability,
      accessibility settings can make it easier for you to use your digital device,
      especially when learning. The resources provided in this document are intended
      to help you learn more about accessibility settings for your smartphone.`,
      },
      {
        id: 2,
        title: `Let’s Get Started`,
        content: `<img width='200px' src='https://mtml.s3.ca-central-1.amazonaws.com/assets/phone.png'/><p><strong>What type of phone do you have?</strong></p>
        <p>If you have an iPhone, there will be an apple on the back of the phone.</p>
        <p>If there is no image of an apple, you almost certainly have an Android phone.</p>
        `,
      },
      {
        id: 3,
        title: "Resource for iPhone Users: Apple YouTube Channel",
        content: `<p><strong>What is it?</strong></p>
        <p>Apple has a YouTube channel with a variety of videos to help you understand the
        features of an iPhone. Some of these videos provide more information about
        some of the accessibility features on your iPhone. For example, there may be
        videos on how to navigate your iPhone with voice control, how to customize
        accessibility settings for each app, and much more.</p>
        <p><strong>How do I Access it?</strong></p>
        <p>To access the YouTube channel, click here:<a href="https://www.youtube.com/playlist?list=PLIl2EzNYri0cLtSlZowttih25VnSvWITu"> Apple Accessibility - YouTube</a>. Then
        scroll through the different videos. When you find a video you want to watch, click
        on it.</p>
        <p>Tip: If you are new to using YouTube and would like to learn more about how to
        use it, click here: <a href="https://www.youtube.com/watch?v=HoiH8ui56Os">How to Use the YouTube App - YouTube</a></p>
        `,
      },
      {
        id: 4,
        title: "Other Resources for iPhone users",
        content: `
        <p>If you are on an older iPhone, such as an iPhone 6 or lower, you may have to open
        your settings app, and tap on ‘general’ before you can see the option for
        ‘accessibility.’ To learn more, click here:<a href="https://www.youtube.com/watch?v=Gep7nuX7o00">How To Access Accessibility Settings -
        Apple iPhone 6s/6s Plus - YouTube</a></p>
        <p>Tip: If you would like to learn how to check your iPhone model, click here:
       <a href="https://www.youtube.com/watch?v=ZKgPPgSQqfg">Learning About Your Smartphone - YouTube</a>. If you would like to learn more about
        the software of your iPhone, read the Tip Sheet here: <a href="https://www.mtml.ca/smartphones-and-employment-skills_1/module-pilot/learners-ses/lcys-2b-tip-sheet"> MTML – Learning about your
        Smartphone Tip Sheet</a></p>
        `,
      },
      {
        id: 5,
        title: "Resources for Android Users",
        content: `
        <p>It is important to note that there are many types of Android phones, such as LG,
        Motorola, Google Pixel, Xiaomi etc. Each type of Android phone may be slightly
        different. Each type of Android phone may have different accessibility features and
        may require you to take slightly different steps to access them. Refer to the links
        below to learn more. Each link will take you to a YouTube video that you can watch
        to learn more about accessibility features.</p>
        <ol>
        <li>Learn how to access accessibility features on an LG phone by clicking here:
        <a href="https://www.youtube.com/watch?v=DsHyLlMkFlU" target="_blank">[LG Mobile Phones] How To Use Accessibility Features - YouTube</a></li>
        <li>Learn how to access the accessibility settings and create a shortcut to
        accessibility features on the home screen of a Motorola phone by clicking
        here: <a href="https://www.youtube.com/watch?v=yPqgx6OVbUg" target="_blank">Accessibility Menu On/Off Motorola Moto G - YouTube</a></li>
        <li>Learn more about accessibility settings on a Google Pixel phone by clicking
        here: <a href="https://www.youtube.com/watch?v=T9aDatcJZLA" target="_blank">Google Pixel 4 XL Accessibility Settings and Features - YouTube</a></li>
        <li>Learn how to access the accessibility settings and create a shortcut to
        accessibility features on the home screen of a Xiaomi (Redmi Note 7) phone
        by clicking here: <a href="https://www.youtube.com/watch?v=EZI-ezPwEzI" target="_blank">How to Enable Accessibility Menu in Redmi Note 7 Pro (MIUI
        11.0)? - YouTube</a></li>
        </ol>
        <p>Tip: If you are new to using YouTube and would like to learn more about how to
        use it, click here: <a href="https://www.youtube.com/watch?v=HoiH8ui56Os target="_blank"> How to Use the YouTube App - YouTube</a></p>
        `,
      },
      {
        id: 6,
        title: `Other Resources for Android Users`,
        content: `<p>If you are on an Android phone that is older (from 2014, or 2015), it is most likely
        running an older operating system, called Lollipop (Android 5.0 – 5.1.1). You can
        learn more about your device’s accessibility features by clicking here: <a href="https://www.youtube.com/watch?v=20J9mKLBV9M" target="_blank">Android
        Accessibility - YouTube</a></p>
        <p>Tip: If you would like to learn how to check the model of your Android phone,
        click here: <a href="https://www.youtube.com/watch?v=ZKgPPgSQqfg" target="_blank"> Learning About Your Smartphone - YouTube</a>. If you would like to learn
        more about the software of your Android phone, read the Tip Sheet here: MTML –
        Learning about your Smartphone Tip Sheet</p>
        <ol>
        <li>If you would like to learn more about some of the common accessibility
        settings on an Android phone, you can read about them by clicking here:
        <a href="https://www.lifewire.com/guide-to-android-accessibility-settings-4064536" target="_blank">The 4 Best Android Accessibility Settings (lifewire.com)</a></li>
        <li>If you would like to learn more about Android Accessibility Suite, you can
        read more about it by clicking here:<a href="https://helpdeskgeek.com/free-tools-review/what-is-android-accessibility-suite-a-review/"> What Is Android Accessibility Suite? A
        Review (helpdeskgeek.com).</a></li>
        </ol>`,
      },
    ],
  },
  {
    id: 2,
    title: "Guide to Accessibility Settings on Android Smartphone",
    items: [
      {
        id: 1,
        title: `Did You Know?`,
        content: `Most digital devices have accessibility settings. If you live with a disability,
      accessibility settings can make it easier for you to use your digital device,
      especially when learning. The resources provided in this document are intended
      to help you learn more about accessibility settings for your smartphone.`,
      },
      {
        id: 2,
        title: `Let’s Get Started`,
        content: `<img width='200px' src='https://mtml.s3.ca-central-1.amazonaws.com/assets/phone.png'/><p><strong>What type of phone do you have?</strong></p>
        <p>If you have an iPhone, there will be an apple on the back of the phone.</p>
        <p>If there is no image of an apple, you almost certainly have an Android phone.</p>
        `,
      },
      {
        id: 3,
        title: `Where Do I Find the Accessibility Settings on my Android Smartphone?`,
        content: `<p>Step 1: Tap on the settings app on your Android Smartphone. If you are having trouble
        finding the settings app, try swiping up from the very bottom of the screen, or try swiping
        left and right to see pages of apps. The settings app looks like a grey gear icon.</p>
        <img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/6.png"/>
        <p>Step 2: Scroll through the settingsmenu and tap on ‘Accessibility.’ If you cannot find
        ‘Accessibility,’ tap on the magnifying glass in the top right corner of the screen. Tap on the
        search bar that will appear and type ‘accessibility’ to search for it in the settings menu.</p>
        <img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/7.png"/>
        <p>Step 3: You will now see the Accessibility menu with different settingsto choose from. Scroll
        through the menu to see all of them.</p><img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/8.png"/>`,
      },
      {
        id: 4,
        title: `What Accessibility Settings can I use to Read the Tip Sheets or Mini Lessons?`,
        content: `<p>You may find that many of the accessibility settings are beneficial to you. An
        accessibility setting you can try, depending on your needs, is ‘Visibility
        enhancements.’ Keep reading to learn more.</p><img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/9.png"/>
        <p><strong>VISIBILITY ENHANCEMENTS</strong></p>
        <p><strong>What does the ‘Visibility enhancements’ setting do?</strong></p>
        <p>You may find that the current colour and contrast of your smartphone screen is
        difficult to see. The ‘Visibility enhancements’ setting allows you to change the
        colour and contrast of your smartphone screen.</p>
        <p><strong>How do I turn on the ‘Visibility enhancements’ setting?</strong></p>
        <p>Tap on ‘Visibility enhancements’ from the accessibility menu. You will see several
        options, including ‘Colour inversion.’ Tap on the button beside ‘Colour inversion,’
        so that it is in blue. Blue means that this setting is turned on.</p>
        <p>TIP: Read the text under a setting to learn more about it. For example, read the
        text under ‘remove animations’ to learn more about it.</p> <img width='400px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/10.png"/>`,
      },
      {
        id: 5,
        title: `What Other Accessibility Settings might be Beneficial to Me?`,
        content: `<p>Interaction and Dexterity is another setting that may be beneficial to you.</p>
        <p><strong>What does the ‘Interaction and dexterity’ setting do?</strong></p>
        <p>You may use the ‘Interaction and dexterity’ setting to replace pressing physical
        buttons on your Android smartphone with a tap gesture on screen instead. For
        example, instead of pressing your smartphone’s volume keys located at the side of
        the device, you can control the volume by tapping on an icon on your
        smartphone’s screen.</p>
        <p><strong>How do I turn on the ‘Interaction and dexterity’ setting?</strong></p>
        <p>Tap on ‘Interaction and dexterity’ from the accessibility menu. Then look for
        ‘Assistant menu.’ Tap on the button beside ‘Assistant menu’ so that it’s in blue.
        You will notice circle containing four small squares appear on your smartphone’s
        screen. Tap on the circle to reveal icons you can tap on to control or navigate your
        device, including one for volume.</p><img width='400px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/11.png"/>
        <p>TalkBack is another setting that may be beneficial to you.</p>
        <p><strong>What does the ‘TalkBack’ setting do?</strong></p>
        <p>‘TalkBack’ is the screen reader that comes on most Android smartphones. This
        setting reads your screen, including dialogue boxes, instruction/text in menus
        displayed on your smartphone, buttons on the smartphone screen, and the text in
        some apps. ‘TalkBack’ may be a little different depending on the Android
        smartphone you have.</p>
        <img width='200px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/12.png"/>
        <p><strong>How do I turn on the ‘TalkBack’ setting?</strong></p>
        <p>Tap on ‘Talkback’ from the accessibility menu. Look for ‘off.’ Tap on the button
        beside ‘off’ so that it is blue. When the button is in blue, this means that ‘Talkback’
        is turned on. A dialogue box will appear asking you to allow ‘TalkBack’ to have full
        control of your phone. Read the dialogue box and tap ‘allow’ to get started.</p><img width='300px' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/13.png"/>
        <p>*Please note that turning on ‘TalkBack’ changes the gestures you must use to
        navigate your smartphone. For example, instead of tapping once to select an
        option on your smartphone, you may have to tap twice when ‘TalkBack is on. Be
        sure to read the instructions carefully.</p>`,
      },
      {
        id: 6,
        title: `Ultimately....`,
        content: `<p>It is up to you to decide which accessibility settings will work well for you. There
        are more accessibility settings than those presented in the guide. Take some time
        to become familiar with them to decide which ones may be of benefit to you.</p>
        <p>If you are ever unsure about what an accessibility setting does, you can also read
        the instructions and/or description on the smartphone, or research it online. Check
        out the module on <a href="https://www.youtube.com/watch?v=ZKgPPgSQqfg">Learning About Your Smartphone - YouTube</p> or the module on
       <a href="https://www.youtube.com/watch?v=0cqb3EpaR5o">How to Learn About Popular Apps - YouTube</a> to learn how to conduct research
        about your smartphone online.</p>`,
      },
    ],
  },
  {
    id: 3,
    title: `Service Provider Guide to Changing Unhelpful Thoughts for Success while Learning`,
    items: [
      {
        id: 1,
        title: `About this Guide`,
        content: `<p>This guide is part of Metro Toronto Movement for Literacy’s Smartphone Skills and Employment
        project- a project dedicated to helping people build their smartphone skills. The guide is intended
        for service providers of LBS/ES organizations.</p>
        <p>This guide will help service providers understand that the thoughts clients have may actually have
        a powerful influence on how they feel and act while learning.</p>
        <p>This guide is intended to provide you with more information on:</p>
        <ul>
        <li>Thoughts and their impact</li>
        <li>Unhelpful thoughts clients might experience while learning</li>
        <li>How to change unhelpful thoughts to helpful ones so clients can have a better learning experience.</li>
        </ul>`,
      },
      {
        id: 2,
        title: `Understanding Thoughts`,
        content: `<p>Everyone experiences thoughts that pop into their head throughout the day. For
        example:</p>
        <ul>
          <li>“my meal tastes really good”</li>
          <li>“I’m tired today”</li>
          <li>“There’s a lot of traffic right now”</li>
        </ul>
        <p>These thoughts are called automatic thoughts. You can think of them as the
        thoughts that automatically pop in your mind in a given situation.</p>
        <p>Client’s may have automatic thoughts in their mind as they learn.</p>
        <p>Thoughts are powerful because they are connected to emotions,
        behaviors, and the way your body reacts in given situations</p>
        <p>This is proven in the field of mental health.</p>`,
      },
      {
        id: 3,
        title: `How do Thoughts Impact a Client who is Learning?`,
        content: `<p>Thoughts are deeply connected to how an individual feels, behaves, as well as how their body reacts in a given
        situation. Let’s look at the diagram to learn more.</p>
        <img width='100%' src="https://mtml.s3.ca-central-1.amazonaws.com/assets/14.png"/>
        <ul>
          <li>As you can see from the previous example, there are many ways thoughts
          can impact a client.</li>
          <li>For example, if clients have thoughts about everything in the lesson being
          too difficult for them, it can create frustration. This can make it harder for
          clients to use the part of their brain that are responsible for decision
          making and problem solving. As a result, they may not complete the
          lesson.</li>
          <li>As you can see, clients may have thoughts that can hold them back while
          they are trying to learn</li>
          <li>As you might be able to tell, clients
          might experience thoughts that are
          unhelpful when they are trying to
          learn.</li>
          <li>You might say an unhelpful thought
          holds clients back from success or
          happiness, especially as they are
          trying to learn.</li>
          <li>Let’s take a moment to learn more
          about unhelpful thoughts.</li>
        </ul>`,
      },
      {
        id: 4,
        title: "What is an Unhelpful Thought?",
        content: `<ul>
          <li>It is important to remember that
          thoughts are not facts</li>
          <li>You might say an unhelpful thought
          ‘fails’ to:
          <ul>
            <li>See both the positive and
            negative of a situation</li>
            <li>See the entire situation</li>
            <li>Be fair</li>
          </ul>
          </li>
          <li>Because of this, an unhelpful
          thought is unbalanced</li>
        </ul>`,
      },
      {
        id: 5,
        title: `How can I Help Clients Identify an Unhelpful Thought?`,
        content: `<ul>
          <li>An unhelpful thought uses words like:
            <ul>
            <li>Always</li>
            <li>Never</li>
            <li>Everything</li>
            <li>None</li>
            <li>All</li>
            </ul>
          </li>
          <li>For example: if a client has the thought “I always fail!” this is unhelpful because they
          have most likely have succeeded at one thing in their life.</li>
          <li>This means it is not fair for a client to say they ‘always’ fail.</li>
          <li>As you can see, this thought fails to include the success a client has had by using the
          word ‘always’.</li>
        </ul>`,
      },
      {
        id: 6,
        title: `What are the Benefits of Changing Unhelpful Thoughts?`,
        content: `Taking the time to help a client improve their own thoughts is
        important because:
        <ul>
        <li>It can reduce frustration when they are learning</li>
        <li>It can make them a better learner and more productive in the long run</li>
        <li>It can help them feel less worried, and more confident as they learn</li>
        </ul>`,
      },
      {
        id: 7,
        title: `How can Clients Change an Unhelpful Thought?`,
        content: `<p>Encourage clients to use words and sentences
        that reflect balance (both sides of a situation).
        These words and sentence
        s are:</p>
        <ul>
        <li>sometimes</li>
        <li>some</li>
        <li>most of the time</li>
        <li>there is room for improvement</li>
        <li>I can try different strategy</li>
        <li>I can learn from my mistakes</li>
        <li>I can improve</li>
        </ul>`,
      },
      {
        id: 8,
        title: `In Summary....`,
        content: `<ul>
          <li>Encourage clients to be aware of their own thoughts. Encourage clients to
          practice mindfulness, as this will help them become aware of their thoughts.</li>
          <li>Before starting a module, encourage clients to write down their own thoughts
          about starting the module. They don’t have to share this with anyone. You
          might ask the if they notice any words like ‘nothing’, ‘everything’, ‘all,’ ‘always’</li>
          <li>Encourage clients to say some helpful thoughts out loud before starting the
          module. A list of helpful thoughts they can try saying out loud is located on
          the next page.</li>
          <li>Practicing helpful thoughts can help reduce anxiety, frustration, sadness while learning. This can
          help make you more focused and engaged while learning.</li>
          <li>Some helpful thoughts clients can practice saying before learning smartphone modules, include:
            <ul>
            <li>“It may be true that some of this lesson is difficult AND it is also true that I can understand
            some of this lesson.”</li>
            <li>“It may be true that I am struggling with some smartphone skills AND it is also true that I can
            learn and improve.”</li>
            <li>“It may be true that I can define success by getting some questions right some of the time AND
            it might also be true that I can define success by learning and improving.”</li>
            </ul>
          </li>
          
        </ul>`,
      },
    ],
  },
  {
    id: 4,
    title: `Service Provider Guide to Mindfulness for Better Learning`,
    items: [
      {
        id: 1,
        title: `About this Guide`,
        content: `<p>This guide is part of Metro Toronto Movement for Literacy’s Smartphone Skills and Employment
        Project- a project dedicated to helping people build their smartphone skills. The guide is
        intended for service providers of LBS/ES organizations.</p>
        <p>Learning can be difficult especially when related to smartphone skills. This guide is intended to
        help service providers learn about mindfulness and how it can help clients be better learners.</p>`,
      },
      {
        id: 2,
        title: `What is Mindfulness?`,
        content: `<p>Mindfulness is about</p>
                <ul>
                  <li>Slowing down</li>
                  <li>Being present in the moment you are in,
                  rather than thinking about the future or
                  past</li>
                  <li>Bringing your full attention and
                  awareness to something in a given
                  moment</li>
                </ul>
                <p>Mindfulness is a skill that you have to
                practice</p>
                <p>There are many ways to be mindful. An individual might choose to try one
                of the following examples :</p>
                <ul>
                  <li>Slow down and bring attention how your breath feels as it enters and
                  leaves your body for 1 minute.</li>
                  <li>Slow down and bring attention to what you can hear in your
                  surroundings for 1 minute.</li>
                  <li>Slow down and bring attention to how the sun feels on your skin in a
                  given moment</li>
                  <li>You might encourage clients to do any of these for just one minute.
                  They can slowly increase the length of time they practice mindfulness
                  as be they come more comfortable.s</li>
                </ul>`,
      },
      {
        id: 3,
        title: `Why is Mindfulness Important for Learning?`,
        content: `<ul>
          <li>To understand why mindfulness is important,
          we need to take some time to understand
          our brain</li>
          <li>Some parts of our brain are responsible for
          scanning and looking for danger to help keep
          us safe! This is the limbic system of the brain.
          Some people call this the ‘lizard part’ of the
          brain.</li>
          <li>Some parts of our brain are responsible for
          problem solving, rational thinking. We need
          this part of the brain when we are learning.</li>
          <li>When clients are learning something that is brand new to
          them, the parts of the brain that scan for danger can take over,
          and the part of the brain that is responsible for problem
          solving, and rational thinking may no longer be in control.</li>
          <li>Mindfulness allows the part of the brain responsible for
          rational thinking and problem solving to regain control.</li>
          <li>This means that clients will be able to learn information, solve
          problems, and retain information better</li>
        </ul>`,
      },
      {
        id: 4,
        title: `What Happens if the Part of the Brain that Scans for Danger takes Control?`,
        content: `
              <p>Clients might be in:</p>
              <ul>
                <li>Fight Mode</li>
                <li>Flight Mode</li>
                <li>Freeze Mode</li>
              </ul>`,
      },
      {
        id: 5,
        title: `Fight Mode when Learning might Look Like:`,
        content: `<p>A client who is</p>
                <ul>
                  <li>Screaming at self, instructor, learning video</li>
                  <li>Feeling irritated or angry with others, self, or the lesson</li>
                  <li>Arguing with instructors, peers</li>
                  <li>Smashing, punching, hitting</li>
                </ul>`,
      },
      {
        id: 6,
        title: `Flight Mode when Learning might Look Like:`,
        content: `<p>A client who is:</p>
                  <ul>
                    <li>Avoiding learning activities</li>
                    <li>Not attending lessons</li>
                    <li>Sleeping or daydreaming instead of learning</li>
                    <li>Not fully engaging with lesson</li>
                  </ul>`,
      },
      {
        id: 7,
        title: `Freeze Mode when Learning might Look Like:`,
        content: `<p>A client who is:</p>
                  <ul>
                    <li>Feeling numb</li>
                    <li>Shutting down when asked a question</li>
                    <li>Feeling stiff</li>
                    <li>Feeling stuck</li>
                  </ul>`,
      },
      {
        id: 8,
        title: `Why is Mindfulness Important for Learning?`,
        content: `<p>As you can probably tell, clients wouldn’t be able to learn or
        remember information well if we are in fight, flight, or freeze mode.</p>`,
      },
      {
        id: 9,
        title: `Why Else is Mindfulness Important?`,
        content: `<ul>
          <li>Learning is an emotional process.</li>
          <li>When clients have trouble learning, they
          might feel sad, worried, or frustrated.</li>
          <li>Learning while sad, frustrated or worried is
          not ideal. Clients may associate learning with
          these negative emotions. As a result, clients
          might avoid learning. This is why they should
          not simply ‘push through’ frustration, worry,
          or sadness, especially when learning.</li>
          <li>Mindfulness helps activate parts of
          the brain associated with happiness,
          hopefulness, and positive emotions</li>
          <li>This is important because we want
          clients to associate learning with
          emotions that make them feel good,
          so that they will want to continue to
          learn.</li>
          <li>Mindfulness also help clients to be more aware of when they are feeling frustrated, or worried. It is important for 
          clients to be aware of when they are experiencing difficult emotions, so they can then take steps to address them.</li>
          <li>Mindfulness also helps improve memory and concentration</li>
          <li></li>
        </ul>`,
      },
      {
        id: 10,
        title: `Common Challenges with Mindfulness`,
        content: `<p>You may notice that as clients begin practicing mindfulness, they encounter some challenges. For example, clients might notice:</p>
                <ul>
                  <li>Their mind wanders</li>
                  <li>They feel more nervous</li>
                  <li>They have trouble finding time to practice mindfulness</li>
                  <li>
                  </li>
                </ul>`,
      },
      {
        id: 11,
        title: `What Should Clients do if Their Mind Wanders?`,
        content: `<p>It is normal for a client’s mind to wander
        when practicing mindfulness. It is
        important that they do not judge
        themselves when this happens.</p><p>
        Instead, they should simply notice their
mind wandering and gently bring their
attention/awareness back to what they
were focusing on.
        </p>`,
      },
      {
        id: 12,
        title: `What can I do if Mindfulness makes my Client Nervous?`,
        content: `<p>It’s normal for many people to feel an increased sense
        of nervousness when they first begin practicing
        mindfulness.</p>
          <ul>
          <li>If mindfulness is making your client feel nervous,
          encourage them to acknowledge that they are feeling
          nervous and encourage them to simply accept that
          they are feeling nervous. Sometimes, when we don’t
          accept feeling nervous or anxious, we can end up
          feeling even more nervous!</li>
          <li>If mindfulness is making a client feel nervous, you
          can encourage them to try a mindfulness activity
          that is not related to deep breathing.</li>
          </ul>`,
      },
      {
        id: 13,
        title: `What can I do if Clients are having
        Trouble Finding the Time to Practice
        Mindfulness?`,
        content: `<p>Clients can start small by trying to dedicate just a few
        minutes to mindfulness each day.</p>
        <p>Clients can try to build mindfulness into different parts of
        their day. This is called informal mindfulness. For example:</p>
        <ul>
          <li>As they wash dishes, they might bring attention to how
          the water feels on their hands</li>
          <li>As they fold laundry, they might bring attention to how
          the fabric feels against their hands</li>
        </ul>`,
      },
      {
        id: 14,
        title: `A Summary of what
        Mindfulness is:`,
        content: `<ol>
            <li>Mindfulness is bringing your full attention to
            something in a given moment, rather than
            thinking about the past or present.</li>
            <li>There are many ways to practice mindfulness.
            One way to practice mindfulness is to bring
            attention to just 1 of your 5 senses in a given
            moment (for example, slowing down and paying
            attention to what you can hear in a given
            moment).</li>
            <li>Mindfulness is a skill you have to practice in order
            to feel comfortable and confident doing it.</li>
        </ol>`,
      },
      {
        id: 15,
        title: `A Summary of the Benefits of Mindfulness`,
        content: `<ol>
          <li>Mindfulness helps us engage the part of
          the brain that helps us problem solve and
          think rationally. Mindfulness also helps us
          concentrate, remember information
          better, and have positive emotions. This
          means mindfulness can help us be better
          learners.</li>
          <li>Mindfulness helps to prevent us from
          being in flight, fight or freeze mode. It is
          more difficult to learn when in fight, flight,
          or freeze mode.</li>
        </ol>`,
      },
      {
        id: 16,
        title: `A Summary of How to
        Overcome Challenges to
        Practicing Mindfulness`,
        content: `<ol>
          <li>It’s normal to encounter challenges
          when you start your mindfulness
          practice. Remember, you do not have
          to be perfect at it! Mindfulness is
          something you have to practice.</li>
          <li>If your mind wanders while
          practicing mindfulness, simply notice
          your mind wandering and bring your
          attention back to what you were
          focusing on.</li>
          <li>There are many ways to practice
          mindfulness. Find the mindfulness
          practice that works best for you.</li>
        </ol>`,
      },
    ],
  },
  {
    id: 5,
    title: `Guide to Accessibility Settings on iPhone`,
    items: [
      {
        id: 1,
        title: "Did You Know?",
        content:
          "Most digital devices have accessibility settings. If you live with a disability, accessibility settings can make it easier for you to use your digital device, especially when learning. Continue reading to learn more about how to find accessibility settings on an iPhone.",
      },
      {
        id: 2,
        title: "Let’s Get Started",
        content: `<img width='200px' src='https://mtml.s3.ca-central-1.amazonaws.com/assets/phone.png'/><p>What type of phone do you have?</p><p>If you have an iPhone, there will be an apple on the back of the phone.</p><p>If there is no image of an apple, you almost certainly have an Android phone.</p>`,
      },
      {
        id: 3,
        title: "Where Do I Find the Accessibility Settings on my iPhone?",
        content: `
            <ul>
                <li>Step 1: Tap on the settings app on your iPhone. If you are having trouble finding the settings app, try swiping several times until you see the ‘app library’ page. The settings app looks like a grey gear icon, similar to this:</li>
                <li>Step 2: Scroll through the settingsmenu and tap on ‘Accessibility’. If you don’t see the option for ‘Accessibility’, you might be on an older iPhone. In that case, tap on ‘General’ first then look for ‘Accessibility’.</li>
                <li>Step 3: You will now see the Accessibility menu with different settingsto choose from. Scroll
                through the menu to see all of them. If you are on an older iPhone, this menu may look a
                little different, but the settings available will largely be the same.</li>
            </ul>`,
      },
      {
        id: 4,
        title:
          "What Accessibility Settings can I use to Read the Tip Sheets or Mini Lessons?",
        content: `You may find that many of the accessibility settings are beneficial to you. Some
        accessibility settings you can try, depending on your needs, include: ‘Spoken
        Content,’ ‘Display & Text Size’ (If you are on an older iPhone, it will say ‘Speech’
        ‘Display Accommodations’ instead) Keep reading to learn more about each.
        <h5>SPOKEN CONTENT (or SPEECH on an older iPhone)</h5>
        <p><strong>What does the ‘Spoken Content’ setting do?</strong></p>
        <p>When the ‘Spoken Content’ setting is turned on, your device will read the text in a
        tip sheet or mini lesson when you’ve opened the document in an app like Adobe
        on your smartphone.</p>
        <p><strong>
        How do I turn on the ‘Spoken Content’ setting?
        </strong></strong>
        <p>Tap on ‘Spoken Content’ in the accessibility menu. Then look for ‘Speak Selection.’
        Tap on the button beside ‘speak selection’ so that it is in green. This means that
        the spoken setting it is turned on. On an older iPhone, tap on ‘Speech’ first.</p>
        <p><i>TIP</i>: Read the text under a setting to learn more about it. For example, read the
        text under ‘Speak Screen’ to learn more about it.</p>
        <p><strong>How does the ‘Spoken Content’ setting work?</strong></p>
        <p>Open the tip sheet or mini lesson in an app like Adobe. Select the text you would
        like your iPhone to read to you. After selecting the text, you will see a horizontal
        menu appear. Look for the arrow on the menu and tap on the arrow until you see
        the button that says ‘speak.’ Tap on ‘speak.’ Your iPhone will read the selected
        text.</p>
        <p>TIP: If you are having trouble selecting text, select ‘Speak Screen’ from the spoken
        content menu. Then open the document and swipe with 2 fingers from the top of
        the screen to hear the content read.</p>
        <h5>DISPLAY & TEXT SIZE (or DISPLAY ACCOMMODATIONS on an older iPhone)</h5>
        <p><strong>What does the ‘Display & Text Size’ setting do?</strong></p>
        <p>You may find that the current colour and contrast of your smartphone screen is
        difficult to see. The ‘Display and Text Size’ setting allows you to change the colour and
        contrast of your smartphone screen.</p>
        <p><strong>How do I turn on the ‘Display & Text Size’ setting?</strong></p>
        <p>Tap on ‘Display and Text Size’ from the accessibility menu. You will see several
        options, such as ‘Smart Invert’ or ‘Classic Invert.’ Tap on the button beside ‘Smart
        Invert’ or ‘Classic Invert’ so that the button is in green. This means that it is turned on.
        Once turned on, you will notice that the colours of your smartphone screen have
        changed. If you are on an older iPhone tap on ‘Display Accommodations’ first and
        then tap on ‘Invert Colors.’</p>
        <p>TIP: Read the text under a setting to learn more about it. For example, read the text
        under ‘Smart Invert’ to learn more about it.</p>`,
      },
      {
        id: 5,
        title: `What Other Accessibility Settings might be
        Beneficial to Me?`,
        content: `<p><strong>Assistive touch</strong> is another setting that may be beneficial to you.</p>
        <p><strong>What does the ‘AssistiveTouch’ setting do?</strong></p>
        <p>You may use the ‘AssistiveTouch’ setting to replace pressing physical buttons on
        your iPhone with a tap gesture on screen instead. For example, instead of pressing
        your iPhone’s volume keys located at the side of the device, you can control the
        volume by tapping on an icon on your iPhone’s screen. ‘AssistiveTouch’ can also be
        used to easily access different features such as the notification centre, or control
        centre for your iPhone.</p>
        <p><strong>How do I turn on the ‘AssistiveTouch’ setting?</strong></p>
        <p>Tap on ‘touch’ from the accessibility menu. Then tap on ‘AssistiveTouch.’ Tap on
        the button beside ‘AssistiveTouch’ so that it’s in green. You will notice a circle
        appear on your iPhone’s screen. Tap on the circle to open the assistive touch
        menu. The menu will contain icons you can tap on, such as one for ‘Siri’, ‘Home,’
        ‘Device’ etc. Tap on ‘Device’ from the menu to see the option for volume etc.</p>`,
      },
      {
        id: 6,
        title: `Ultimately....`,
        content: `<p>It is up to you to decide which accessibility settings will work well for you. There
        are more accessibility settings than those presented in the guide. Take some time
        to become familiar with them to decide which ones may be of benefit to you.</p>
        <p>If you are ever unsure about what an accessibility setting does, you can read the
        instructions and/or description on the smartphone, or research it online. Check out
        the module on <a href='https://www.youtube.com/watch?v=ZKgPPgSQqfg'> Learning About Your Smartphone - YouTube</a> or the module on <a href="https://www.youtube.com/watch?v=0cqb3EpaR5o">How
        to Learn About Popular Apps - YouTube </p> to learn how to conduct research about
        your smartphone online.</p>`,
      },
    ],
  },
];

export { accessibilitySettings };
