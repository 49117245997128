import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

export class FundersLogo extends Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid={true}>
          <Row className="d-flex justify-content-center">
            <Col sm={6} className="text-center">
              dfdsfdsf sdfdsfdsf
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default FundersLogo;
