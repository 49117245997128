import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_SERVICE_REQUESTS,
  // ADD_NEW_ROLE,
  GET_SERVICE_REQUEST_BY_ID,
  // UPDATE_ROLE,
  // DELETE_ROLE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getServiceRequestsSuccess,
  getServiceRequestByIdSuccess,
  getServiceRequestsFail,
} from "./actions";

const getServiceReqeustsRequest = query =>
  get("/api/ServiceRequest/paging", { params: query });
// const addNewRoleRequest = role => post("/api/role", role);
const getServiceRequestByIdRequest = id => get(`/api/ServiceRequest/${id}`);
// const updateRoleRequest = role => update("/api/role", role);
// const deleteRoleRequest = id => del(`/api/role/${id}`);

function* fetchServiceRequests({ payload }) {
  try {
    const response = yield call(getServiceReqeustsRequest, payload);
    yield put(getServiceRequestsSuccess(response));
  } catch (err) {
    const { status, statusText } = err?.response;
    if (status && status === 401) {
      window.location = "/login";
    }
    let errorMessage = statusText ?? "Something has gone wrong.";
    yield put(getServiceRequestsFail({ status, errorMessage }));
  }
}

// function* addNewRole({ payload }) {
//   try {
//     yield call(addNewRoleRequest, payload);
//     const response = yield call(getRolesRequest, {});
//     yield put(getRolesSuccess(response));
//   } catch (err) {
//     const error = err?.response?.data?.title ?? "Something has gone wrong.";
//     yield put(getRolesFail(error));
//   }
// }

// function* updateRole({ payload }) {
//   try {
//     yield call(updateRoleRequest, payload);
//     const response = yield call(getRolesRequest, {});
//     yield put(getRolesSuccess(response));
//   } catch (err) {
//     const error = err?.response?.data?.title ?? "Something has gone wrong.";
//     yield put(getRolesFail(error));
//   }
// }

function* getServiceRequestById({ payload }) {
  try {
    var response = yield call(getServiceRequestByIdRequest, payload);
    yield put(getServiceRequestByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getServiceRequestsFail(error));
  }
}

// function* deleteRole({ payload }) {
//   try {
//     yield call(deleteRoleRequest, payload);
//     const response = yield call(getRolesRequest, {});
//     yield put(getRolesSuccess(response));
//   } catch (error) {
//     const err = err?.response?.data?.title ?? "Something has gone wrong.";
//     yield put(getRolesFail(error));
//   }
// }

function* roleSaga() {
  yield takeEvery(GET_SERVICE_REQUESTS, fetchServiceRequests);
  // yield takeEvery(ADD_NEW_ROLE, addNewRole);
  yield takeEvery(GET_SERVICE_REQUEST_BY_ID, getServiceRequestById);
  // yield takeEvery(UPDATE_ROLE, updateRole);
  // yield takeEvery(DELETE_ROLE, deleteRole);
}

export default roleSaga;

export { getServiceRequestByIdRequest, getServiceReqeustsRequest };
