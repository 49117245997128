import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse} from "reactstrap";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import NavMenuItemCard from "../Common/NavMenuItemCard";

const menuItems=[
  {
    id: 1,
    name: "Creating a Username and Password",
    partnerName: "LAMP",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png"
  },
  {
    id: 2,
    name: "Using your phone to create a hotspot",
    partnerName: "LAMP",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/lamp.png"
  },
  {
    id: 3,
    name: "Scanning documents with Android Phone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 4,
    name: "Scanning Documents with iphone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 5,
    name: "Signing documents with Android phone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 6,
    name: "Signing documents with iphone",
    partnerName: "ACCES Employment",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/access-employment.png"
  },
  {
    id: 7,
    name: "How to use youtube",
    partnerName: "Literacy Council York Simcoe",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png"
  },
  {
    id: 8,
    name: "Learning about your smartphone",
    partnerName: "Literacy Council York Simcoe",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/skills.png"
  },
  {
    id: 9,
    name: "Switching and Closing Apps",
    partnerName: "Labour Education Centre",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png"
  },
  {
    id: 10,
    name: "Icons at top of screen",
    partnerName: "Labour Education Centre",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/labour-education-centre.png"
  },
  {
    id: 11,
    name: "Security/Privacy",
    partnerName: "Toronto Council Fire",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png"
  },
  {
    id: 12,
    name: "Sharing Files",
    partnerName: "Toronto Council Fire",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/toronto-council-fire.png"
  },
  {
    id: 13,
    name: "Learning about Popular Apps",
    partnerName: "YMCA",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png"
  },
  {
    id: 14,
    name: "View Storage and uninstall Apps",
    partnerName: "YMCA",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/literacy-council+york-simcoe.png"
  },
  {
    id: 15,
    name: "Using Bluetooth Safely",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 16,
    name: "Protecting yourself from Phishing",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 17,
    name: "Banking Safely on your Smartphone",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 18,
    name: "Location Safety on your Smartphone",
    partnerName: "MTML",
    partnerLogo: "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 19,
    name: "Using voice typing on smartphone",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 20,
    name: "Using google maps",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 21,
    name: "Using google lens",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 22,
    name: "Using text to speech",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
  {
    id: 23,
    name: "Using Chatgpt",
    partnerName: "MTML",
    partnerWebsite: "https://www.mtml.ca",
    partnerLogo:
        "https://mtml.s3.ca-central-1.amazonaws.com/assets/mtml-logo.png"
  },
];


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  handleSelectModule = id => {
    window.location = `/learner/module/${id}`;
  };

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {menuItems.map((menuItem, index) =>{
                    return<li key={index}>
                      <NavMenuItemCard key={index} menuItem={menuItem} onClick={this.handleSelectModule}/>
                    </li>
                  })}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
