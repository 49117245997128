import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, CardTitle, Col, Collapse, Row } from "reactstrap";
import phones from "../../assets/images/phones.png";
import createPwd1 from "../../assets/images/creating-pwd-1.png";
import appStore from "../../assets/images/app-store.png";
import playStore from "../../assets/images/play-store.png";
import { Link } from "react-router-dom";

export class CreatingPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    };

    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
    this.t_col6 = this.t_col6.bind(this);
    this.t_col7 = this.t_col7.bind(this);
    this.t_col8 = this.t_col8.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col4() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: !this.state.col4,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col5() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: !this.state.col5,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col6() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: !this.state.col6,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col7() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: !this.state.col7,
      col8: false,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col8() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: !this.state.col8,
      col9: false,
      col10: false,
      col11: false,
    });
  }

  t_col9() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: !this.state.col9,
      col10: false,
      col11: false,
    });
  }

  t_col10() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: !this.state.col10,
      col11: false,
    });
  }
  t_col11() {
    this.setState({
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
      col10: false,
      col11: !this.state.col11,
    });
  }

  render() {
    return (
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-between">
            <p className="card-title-desc">
              Module: Creating a Username and Password for Email
            </p>
            <div>
              <a
                href="https://drive.google.com/file/d/1Bm8bkbbKdBFvJdsr27loOKVmiNHTvgYE/view?usp=share_link"
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
          </div>
          <p className="card-title-desc">
            <strong>Instructions:</strong> Use the tip sheet to answer any
            questions you still have about creating a password for a new email
            address. You do not need to read the entire tip sheet. Instead, find
            the heading that is related to your question. Read the information
            under that heading.
          </p>
        </Col>
        <Col md={12}>
          <div className="accordion" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingOne">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col1 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col1}
                  style={{ cursor: "pointer" }}
                >
                  How do I know if I have an Apple or Android Smartphone?
                </button>
              </h2>

              <Collapse isOpen={this.state.col1} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    <Col md={8}>
                      <ul>
                        <li>
                          If you have an iPhone, there will be an image of an
                          apple at the back.
                        </li>
                        <li>
                          If there is no image of an apple, you most likely have
                          an Android phone.{" "}
                        </li>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <img width={"100%"} src={phones} />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col2 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col2}
                  style={{ cursor: "pointer" }}
                >
                  What are some other ways I can create a memorable password?
                </button>
              </h2>

              <Collapse isOpen={this.state.col2} className="accordion-collapse">
                <div className="accordion-body">
                  <ol>
                    <li>
                      Think of a few lines from your favourite song or book. For
                      example: ‘Mary had a little lamb.’
                    </li>
                    <li>
                      Then write down the first and last letters of each word in
                      that line. For example:{" "}
                      <span style={{ color: "#F00" }}>M</span>ar
                      <span style={{ color: "#F00" }}>y</span>{" "}
                      <span style={{ color: "#F00" }}>h</span>a
                      <span style={{ color: "#F00" }}>d</span>{" "}
                      <span style={{ color: "#F00" }}>a</span>{" "}
                      <span style={{ color: "#F00" }}>l</span>ittl
                      <span style={{ color: "#F00" }}>e</span>{" "}
                      <span style={{ color: "#F00" }}>l</span>am
                      <span style={{ color: "#F00" }}>b</span>’ becomes
                      myhdalelb.
                    </li>
                    <li>
                      You can now make alternating letters uppercase. For
                      example: MyHdAlElB.
                    </li>
                    <li>
                      Then add some special characters and/or numbers. For
                      example: MyHdAlElB3!
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col3 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col3}
                  style={{ cursor: "pointer" }}
                >
                  Where can I learn more strategies for creating a memorable
                  password?
                </button>
              </h2>

              <Collapse isOpen={this.state.col3} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Click on: Tech Tips:
                      <a href="https://www.youtube.com/watch?v=3f0u-vw58A0">
                        How to create a strong password. - YouTube
                      </a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col4 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col4}
                  style={{ cursor: "pointer" }}
                >
                  Where can I test my password strength?
                </button>
              </h2>

              <Collapse isOpen={this.state.col4} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Click on:
                      <a href="https://www.security.org/how-secure-is-my-password/">
                        https://www.security.org/how-secure-is-my-password/
                      </a>
                    </li>
                    <li>
                      Tap inside the box that says ‘enter password’ then type
                      your password. Tap ‘enter’ on your keyboard.
                    </li>
                  </ul>
                  <img src={createPwd1} width="100%" />
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col5 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col5}
                  style={{ cursor: "pointer" }}
                >
                  What do I do if my password strength is too weak?
                </button>
              </h2>

              <Collapse isOpen={this.state.col5} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Take some time to pause. Ask yourself some questions about
                      the password you created. Is it missing any of the
                      requirements of a strong password?
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col6 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col6}
                  style={{ cursor: "pointer" }}
                >
                  How do I get to the email sign in page to enter my newly
                  created password?
                </button>
              </h2>

              <Collapse isOpen={this.state.col6} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      To get to the email sign in page, you must download the
                      email app of your choice. Once you’ve downloaded the app,
                      tap on it to open the app. Then look for the words ‘sign
                      up’ or ‘create account.’
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col7 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col7}
                  style={{ cursor: "pointer" }}
                >
                  I’m on an iPhone. How do I download an app for email, like
                  Yahoo, Outlook, or Gmail?
                </button>
              </h2>

              <Collapse isOpen={this.state.col7} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Apps are available through an online store. If you are
                      using an iPhone, you will use the App Store to download an
                      app. The App Store may look like this:{" "}
                      <img src={appStore} style={{ width: "30px" }} />
                    </li>
                    <li>
                      To learn more about downloading apps on an iPhone, click
                      here:
                      <a href="https://www.youtube.com/watch?v=MXUenHiO3VA">
                        How to download and install an app on iPhone 12 from App
                        Store - YouTube
                      </a>
                    </li>
                    <li>
                      Some iPhones may require your Apple ID to download an app.
                      You most likely had to set up an Apple ID when you first
                      got your iPhone. If you have forgotten your Apple ID,
                      click here to learn how to reset it:
                      <a href="https://www.youtube.com/watch?v=LIOapHreNe4"></a>{" "}
                      How to reset your Apple ID password on your iPhone | Apple
                      Support - YouTube
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col8 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col8}
                  style={{ cursor: "pointer" }}
                >
                  I’m on an Android smartphone. How do I download an app for
                  email, like Yahoo, or Outlook?
                </button>
              </h2>

              <Collapse isOpen={this.state.col8} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Apps are available through an online store. If you are
                      using an Android phone, you will use the Google Play Store
                      to download an app. The Google Play Store looks like this:
                      <img src={playStore} style={{ width: "30px" }} />
                    </li>
                    <li>
                      To learn more about downloading an app from the Google
                      Play Store, click here:
                      <a href="https://www.youtube.com/watch?v=wu3bWmPGIyQ">
                        How to Download an App or Game on ANY Samsung Phone -
                        YouTube
                      </a>
                    </li>
                    <li>
                      Some Android devices, you may be required to sign into
                      your Google Account in order to access the Google Play
                      Store. If you are already using Gmail, your Google Account
                      will be the same as your gmail address and password. If
                      you have never created a Google Account, click here to
                      learn more:{" "}
                      <a href="https://www.youtube.com/watch?v=bU3BpROLfc0">
                        How To Create a Google Account Using an Android
                        Smartphone - YouTube
                      </a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col9 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col9}
                  style={{ cursor: "pointer" }}
                >
                  I’m feeling exhausted because I’ve had to make changes to my
                  password so many times.
                </button>
              </h2>

              <Collapse isOpen={this.state.col9} className="accordion-collapse">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Take a break. As you take a break, give yourself credit
                      for all the hard work you have put into creating a strong
                      password.{" "}
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header  bg-primary" id="headingTwo">
                <button
                  className={
                    "accordion-button fw-medium" +
                    (this.state.col11 ? "" : " collapsed")
                  }
                  type="button"
                  onClick={this.t_col11}
                  style={{ cursor: "pointer" }}
                >
                  This process is frustrating. How can I handle frustration?
                </button>
              </h2>

              <Collapse
                isOpen={this.state.col11}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <p className="card-title-desc">
                    <i>
                      Frustration will make it harder to problem solve. To
                      reduce frustration and increase problem solving, follow
                      the steps below.
                    </i>
                  </p>
                  <ul>
                    <li>Step 1: Pause. </li>
                    <li>
                      Step 2: Name what you’re feeling without judgement. Write
                      the phrase “I am feeling______.”
                    </li>
                    <li>
                      Step 3: Take a 5-minute break. You have the option to do
                      something to help you feel calm, such as doing some gentle
                      stretches, listening to music, or breathing deeply until
                      you feel calm.
                    </li>
                    <li>
                      • You can also check out: Videos to Help you Feel Calm and
                      Focused or the document on Changing Unhelpful Thoughts for
                      Success while Learning. You can access them here:
                      <a href="https://www.mtml.ca/smartphones-and-employment-skills_1/module-pilot/learners-ses/set-yourself-up-for-success">
                        https://www.mtml.ca/smartphones-and-employment-skills_1/modulepilot/learners-ses/set-yourself-up-for-success
                      </a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

CreatingPassword.propTypes = {
  module: PropTypes.object,
};

export default CreatingPassword;
