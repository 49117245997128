import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "reactstrap";

import Rating from "react-rating";

export class ModalRatingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      starRating: 0,
    };
  }

  onOpened = () => {
    const { item } = this.props;
    if (item) {
      console.log(item);
      this.setState({
        id: item.id,
        starRating: item.starRating,
      });
    } else {
      this.setState({
        id: null,
        starRating: 0,
      });
    }
  };

  handleSubmit = () => {
    const { id, starRating } = this.state;
    this.props.onSubmit({ userId: id, starRating });
  };

  handleOnRatingChange = rate => {
    this.setState({
      starRating: rate,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;

    return (
      <Modal
        backdrop="static"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md="12">
              <div className="p-4 text-center">
                <Rating
                  max={5}
                  onChange={this.handleOnRatingChange}
                  initialRating={this.state.starRating}
                  emptySymbol="mdi mdi-star-outline text-primary fa-5x"
                  fullSymbol="mdi mdi-star text-primary fa-5x"
                  fractions={2}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit}>
            Submit
          </Button>

          <Button outline data-dismiss="modal" color="primary" onClick={toggle}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

ModalRatingForm.propTypes = {
  id: PropTypes.number,
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalRatingForm;
