import React, { Component } from "react";
import { Button, Card, CardImg } from "reactstrap";
import PropTypes from "prop-types";

export class FilterModule extends Component {
  render() {
    const { item, onClick } = this.props;
    return (
      // <div style={{ width: "50px" }}>
      //   <img src={item.logo} className="avatar-sm" />
      // </div>
      <Button
        color="primary"
        onClick={() => onClick(item.name)}
        outline
        className="mx-1 my-1 sp-card"
      >
        <img src={item.logo} className="avatar-sm" />
      </Button>
    );
  }
}

FilterModule.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default FilterModule;
