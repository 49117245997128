import SelectionBox from "components/Common/SelectionBox";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const ViewStorageAndUninstallApps = () => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isCorrect, setIsCorrect] = useState(null);

  const [storageQuestion1, setStorageQuestion1] = useState(null);
  const [lastSelectedStorageQuestion1, setLastSelectedStorageQuestion1] =
    useState(null);

  const [storageQuestion2, setStorageQuestion2] = useState(null);
  const [lastSelectedStorageQuestion2, setLastSelectedStorageQuestion2] =
    useState(null);

  const [storageQuestion3, setStorageQuestion3] = useState(null);
  const [lastSelectedStorageQuestion3, setLastSelectedStorageQuestion3] =
    useState(null);

  const [yourTotalStorage, setYourTotalStorage] = useState("");
  const [yourTotalStorageUsed, setYourTotalStorageUsed] = useState("");
  const [yourTotalStorageLeft, setYourTotalStorageLeft] = useState("");

  const [mostSpaceApp1, setMostSpaceApp1] = useState("");
  const [mostSpaceApp2, setMostSpaceApp2] = useState("");
  const [mostSpaceApp3, setMostSpaceApp3] = useState("");

  const [menuWords, setMenuWords] = useState("");

  const [lastSubmitted, setLastSubmitted] = useState(null);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setStorageQuestion1(null);
    setLastSelectedStorageQuestion1(null);

    setStorageQuestion2(null);
    setLastSelectedStorageQuestion2(null);

    setStorageQuestion3(null);
    setLastSelectedStorageQuestion3(null);

    setYourTotalStorage("");
    setYourTotalStorageUsed("");
    setYourTotalStorageLeft("");

    setMostSpaceApp1("");
    setMostSpaceApp2("");
    setMostSpaceApp3("");

    setMenuWords("");
    setLastSubmitted(null);
    setActiveTab(2);
  };

  const handleOnClick = (name, value, target) => {
    console.log(name, value, target);
    switch (name) {
      case "storageQuestion1":
        setStorageQuestion1(value);
        setLastSelectedStorageQuestion1(target);
        break;
      case "storageQuestion2":
        setStorageQuestion2(value);
        setLastSelectedStorageQuestion2(target);
        break;
      case "storageQuestion3":
        setStorageQuestion3(value);
        setLastSelectedStorageQuestion3(target);
        break;
      default:
        break;
    }
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>Viewing Phone Storage and Uninstalling Apps</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href="https://mtml.s3.ca-central-1.amazonaws.com/modules/View+Storage+and+uninstall+Apps/lessons/Jan+22_YMCA_+View+Storage+and+Uninstall+Apps+Mini+Lesson.pdf"
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div style={{ height: "55rem" }} className="center-container">
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div style={{ height: "55rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p">Reading Storage Info</p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Reading Storage Info</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <img
                            style={{ maxWidth: "518px" }}
                            width="100%"
                            src="https://mtml.s3.ca-central-1.amazonaws.com/assets/storage.png"
                          />
                        </Col>
                        <Col md={12}>
                          <p>{`The image above shows a summary of a smartphone’s storage space.
Use the information in the image to answer the following questions.
Watch the video again if you are having trouble remembering.`}</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              1. What is the total amount of storage space on
                              this phone?
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                value="90.65 GB"
                                isCorrect={
                                  lastSelectedStorageQuestion1 ==
                                    "storageQuestion1-A" &&
                                  storageQuestion1 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion1",
                                    "false",
                                    "storageQuestion1-A"
                                  )
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="128 GB"
                                isCorrect={
                                  lastSelectedStorageQuestion1 ==
                                    "storageQuestion1-B" &&
                                  storageQuestion1 === "true"
                                    ? true
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion1",
                                    "true",
                                    "storageQuestion1-B"
                                  )
                                }
                              />
                              <SelectionBox
                                title="C"
                                value="70 %"
                                isCorrect={
                                  lastSelectedStorageQuestion1 ==
                                    "storageQuestion1-C" &&
                                  storageQuestion1 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion1",
                                    "false",
                                    "storageQuestion1-C"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              2. What amount of storage space is being used?
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                value="90.65 GB"
                                isCorrect={
                                  lastSelectedStorageQuestion2 ==
                                    "storageQuestion2-A" &&
                                  storageQuestion2 === "true"
                                    ? true
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion2",
                                    "true",
                                    "storageQuestion2-A"
                                  )
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="128 GB"
                                isCorrect={
                                  lastSelectedStorageQuestion2 ==
                                    "storageQuestion2-B" &&
                                  storageQuestion2 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion2",
                                    "false",
                                    "storageQuestion2-B"
                                  )
                                }
                              />
                              <SelectionBox
                                title="C"
                                value="70 %"
                                isCorrect={
                                  lastSelectedStorageQuestion2 ==
                                    "storageQuestion2-C" &&
                                  storageQuestion2 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion2",
                                    "false",
                                    "storageQuestion2-C"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              3. What percentage of storage space is left on
                              this phone?
                            </Label>
                            <div>
                              <SelectionBox
                                title="A"
                                value="90.65 GB"
                                isCorrect={
                                  lastSelectedStorageQuestion3 ==
                                    "storageQuestion3-A" &&
                                  storageQuestion3 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion3",
                                    "false",
                                    "storageQuestion3-A"
                                  )
                                }
                              />
                              <SelectionBox
                                title="B"
                                value="30 %"
                                isCorrect={
                                  lastSelectedStorageQuestion3 ==
                                    "storageQuestion3-B" &&
                                  storageQuestion3 === "true"
                                    ? true
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion3",
                                    "true",
                                    "storageQuestion3-B"
                                  )
                                }
                              />
                              <SelectionBox
                                title="C"
                                value="70 %"
                                isCorrect={
                                  lastSelectedStorageQuestion3 ==
                                    "storageQuestion3-C" &&
                                  storageQuestion3 === "false"
                                    ? false
                                    : null
                                }
                                onClick={() =>
                                  handleOnClick(
                                    "storageQuestion3",
                                    "false",
                                    "storageQuestion3-C"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          {storageQuestion1 === "true" &&
                            storageQuestion2 === "true" &&
                            storageQuestion3 === "true" && (
                              <div className="mb-3">
                                <Button
                                  style={{
                                    width: "fit-content",
                                    // marginTop: "1rem",
                                  }}
                                  className="mini-lesson-btn"
                                  color="primary"
                                  size="lg"
                                  outline
                                  onClick={() => {
                                    toggleTab(activeTab + 1);
                                  }}
                                >
                                  <span>OK</span>
                                  <i
                                    style={{ marginLeft: "0.5rem" }}
                                    className="fa fa-check"
                                  />
                                </Button>
                              </div>
                            )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div style={{ height: "55rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p">Reading Storage Info</p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Checking Your Storage</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <p>{`Your turn: Follow the steps in the video to view the available storage space on your
phone.`}</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="yourTotalStorage">
                              What is the total amount of storage space on your
                              phone?
                            </Label>
                            <Input
                              style={{ width: "300px" }}
                              id="yourTotalStorage"
                              name="yourTotalStorage"
                              type="text"
                              className={
                                lastSubmitted && !yourTotalStorage
                                  ? "is-invalid"
                                  : ""
                              }
                              placeholder="Enter total amount of storage"
                              value={yourTotalStorage}
                              onChange={e => {
                                console.log(e);
                                setYourTotalStorage(e.target.value);
                              }}
                            />
                            {lastSubmitted && !yourTotalStorage && (
                              <div className="invalid-feedback-custom">
                                Your total storage is required.
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="yourTotalStorageUsed">
                              How much storage space is being used on your phone
                            </Label>
                            <Input
                              style={{ width: "300px" }}
                              id="yourTotalStorageUsed"
                              name="yourTotalStorageUsed"
                              type="text"
                              className={
                                lastSubmitted && !yourTotalStorageUsed
                                  ? "is-invalid"
                                  : ""
                              }
                              placeholder="Enter amount of storage used"
                              value={yourTotalStorageUsed}
                              onChange={e => {
                                console.log(e);
                                setYourTotalStorageUsed(e.target.value);
                              }}
                            />
                            {lastSubmitted && !yourTotalStorageUsed && (
                              <div className="invalid-feedback-custom">
                                Your storage space being used is required.
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="yourTotalStorageLeft">
                              How much storage space is left?
                            </Label>
                            <Input
                              style={{ width: "300px" }}
                              id="yourTotalStorageLeft"
                              name="yourTotalStorageLeft"
                              type="text"
                              className={
                                lastSubmitted && !yourTotalStorageLeft
                                  ? "is-invalid"
                                  : ""
                              }
                              placeholder="Enter amount of storage left"
                              value={yourTotalStorageLeft}
                              onChange={e => {
                                console.log(e);
                                setYourTotalStorageLeft(e.target.value);
                              }}
                            />
                            {lastSubmitted && !yourTotalStorageLeft && (
                              <div className="invalid-feedback-custom">
                                Your storage space left is required.
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="mostSpaceApp1">
                              Which three apps on your phone take up the most
                              space?
                            </Label>
                            <div className="d-flex flex-column">
                              <Input
                                style={{ width: "300px" }}
                                id="mostSpaceApp1"
                                name="mostSpaceApp1"
                                type="text"
                                className={
                                  lastSubmitted && !mostSpaceApp1
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="App 1"
                                value={mostSpaceApp1}
                                onChange={e => {
                                  console.log(e);
                                  setMostSpaceApp1(e.target.value);
                                }}
                              />
                              {lastSubmitted && !mostSpaceApp1 && (
                                <div className="invalid-feedback-custom">
                                  App is required.
                                </div>
                              )}

                              <Input
                                style={{ width: "300px", marginTop: "1rem" }}
                                id="mostSpaceApp2"
                                name="mostSpaceApp2"
                                type="text"
                                className={
                                  lastSubmitted && !mostSpaceApp2
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="App 2"
                                value={mostSpaceApp2}
                                onChange={e => {
                                  console.log(e);
                                  setMostSpaceApp2(e.target.value);
                                }}
                              />
                              {lastSubmitted && !mostSpaceApp2 && (
                                <div className="invalid-feedback-custom">
                                  App is required.
                                </div>
                              )}

                              <Input
                                style={{ width: "300px", marginTop: "1rem" }}
                                id="mostSpaceApp3"
                                name="mostSpaceApp3"
                                type="text"
                                className={
                                  lastSubmitted && !mostSpaceApp3
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="App 3"
                                value={mostSpaceApp3}
                                onChange={e => {
                                  console.log(e);
                                  setMostSpaceApp3(e.target.value);
                                }}
                              />
                            </div>
                            {lastSubmitted && !mostSpaceApp3 && (
                              <div className="invalid-feedback-custom">
                                App is required.
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          {
                            <div className="mb-3">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  setLastSubmitted(true);
                                  if (
                                    yourTotalStorage &&
                                    yourTotalStorageUsed &&
                                    yourTotalStorageLeft &&
                                    mostSpaceApp1 &&
                                    mostSpaceApp2 &&
                                    mostSpaceApp3
                                  ) {
                                    setLastSubmitted(null);
                                    toggleTab(activeTab + 1);
                                  }
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "55rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p">Reading Storage Info</p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Tap and Hold</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <p>{`1. Tap and hold an icon on your phone’s home screen – “tap and hold”
means lightly press your finger on the screen until you see a small menu
appear.`}</p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <div
                            style={{
                              border: "1px solid #5b235c",
                              background: "#efe9ef",
                            }}
                            className="d-flex p-2"
                          >
                            <p>{`Note: be careful not to tap “uninstall” or “remove app” unless you want
to remove the app from your phone.`}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <p>{`2. In the menu that appears, you should see several options appear such as
the words “remove” or “select”. These words may differ depending on the
phone you have. Write down the words you see in the menu here:`}</p>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{ minWidth: "20rem" }} className="mb-3">
                            <Label for="menuWords">
                              Enter the words you see in the menu
                            </Label>
                            <Input
                              style={{ width: "600px" }}
                              id="menuWords"
                              name="menuWords"
                              type="text"
                              className={
                                lastSubmitted && !menuWords ? "is-invalid" : ""
                              }
                              placeholder="Enter the words you see in the menu"
                              value={menuWords}
                              onChange={e => {
                                console.log(e);
                                setMmenuWords(e.target.value);
                              }}
                            />
                            {lastSubmitted && !menuWords && (
                              <div className="invalid-feedback-custom">
                                Menu words are required.
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col md={12}>
                          <div style={{}} className="d-flex p-2">
                            <p>{`To close this menu, tap anywhere on the screen outside of the menu.`}</p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          {
                            <div className="mb-3">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  setLastSubmitted(true);
                                  if (menuWords) {
                                    toggleTab(activeTab + 1);
                                  }
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={5}
                >
                  <div style={{ height: "55rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column">
                      <div className="row justify-content-center">
                        {
                          <Col lg="12">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>
                                  Congratulation! you have successfully finished
                                  the lesson!
                                </h5>
                                <p className="text-muted">
                                  If you would like to try again click on the
                                  Restart Lesson button to start over.
                                </p>
                              </div>
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(1);
                                }}
                              >
                                <span>Restart Lesson</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        }
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ViewStorageAndUninstallApps;
