import React, { Component } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardHeader,
  Collapse,
} from "reactstrap";

export class CreatingUsername extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      initial: "",
      activeTab: 1,
      // activeIndex: 0,
      // passedSteps: [1],
      // passedStepsVertical: [1],
      isStarted: false,

      firstSubmitted: false,
      lastSubmitted: false,
      initialSubmitted: false,

      isIncludeFirstOrLastName: false,
      isIncludeNumber: false,
      isIncludePeriod: false,
      isNotIncludeLongString: false,
    };
  }

  toggleIsExpanding = () => {
    this.setState(prevState => ({
      isExpanding: !prevState.isExpanding,
    }));
  };

  handleChange = e => {
    const { firstName, lastName } = this.state;
    const { name, value } = e.target;

    switch (name) {
      case "username":
        this.setState({
          isIncludeFirstOrLastName: value
            ? value
                .toLocaleLowerCase()
                .includes(firstName.toLocaleLowerCase()) ||
              value.toLocaleLowerCase().includes(lastName.toLocaleLowerCase())
            : false,
          isIncludeNumber: value ? /\d/.test(value) : false,
          isIncludePeriod: value ? /\.{1}/.test(value) : false,
          isNotIncludeLongString: value ? !/\d{6,}/.test(value) : false,
        });
        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  };

  // next = () => {
  //   if (this.animating) return;
  //   const nextIndex =
  //     this.state.activeIndex === items.length - 1
  //       ? 0
  //       : this.state.activeIndex + 1;
  //   this.setState({ activeIndex: nextIndex });
  // };

  // previous = () => {
  //   if (this.animating) return;
  //   const nextIndex =
  //     this.state.activeIndex === 0
  //       ? items.length - 1
  //       : this.state.activeIndex - 1;
  //   this.setState({ activeIndex: nextIndex });
  // };

  handleStartLesson = () => {
    this.setState({
      username: "",
      firstName: "",
      lastName: "",
      initial: "",
      activeTab: 2,
      // activeIndex: 0,
      // passedSteps: [1],
      // passedStepsVertical: [1],
      isStarted: true,

      firstSubmitted: false,
      lastSubmitted: false,
      initialSubmitted: false,

      isIncludeFirstOrLastName: false,
      isIncludeNumber: false,
      isIncludePeriod: false,
      isNotIncludeLongString: false,
      isExpanding: true,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 7) {
        if (tab === 1) {
          this.setState({
            activeTab: tab,
          });
        } else if (tab === 3) {
          this.setState({ firstSubmitted: true });

          let isValid = this.state.firstName;

          if (isValid) {
            this.setState({
              activeTab: tab,
            });
          }
        } else if (tab === 4) {
          this.setState({ lastSubmitted: true });

          let isValid = this.state.lastName;

          if (isValid) {
            this.setState({
              activeTab: tab,
            });
          }
        } else if (tab === 5) {
          this.setState({ initialSubmitted: true });

          let isValid = this.state.initial;

          if (isValid) {
            this.setState({
              activeTab: tab,
            });
          }
        } else {
          this.setState({
            activeTab: tab,
          });
        }
      }
    }
  }

  render() {
    const {
      firstSubmitted,
      lastSubmitted,
      initialSubmitted,
      isIncludeFirstOrLastName,
      isIncludeNumber,
      isIncludePeriod,
      isNotIncludeLongString,
    } = this.state;

    let isValidUsername =
      isIncludeFirstOrLastName &&
      isIncludeNumber &&
      isIncludePeriod &&
      isNotIncludeLongString;
    return (
      <div className="accordion bg-white mb-3" id="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header bg-primary" id="headingOne">
            <button
              className={
                "accordion-button fw-medium" +
                (this.state.isExpanding ? "" : " collapsed")
              }
              type="button"
              onClick={this.toggleIsExpanding}
              style={{ cursor: "pointer" }}
            >
              <CardTitle>Creating a Username for New Email Address</CardTitle>
            </button>
          </h2>

          <Collapse
            isOpen={this.state.isExpanding}
            className="accordion-collapse"
          >
            <div className="accordion-body">
              <div className="d-flex justify-content-end">
                <a
                  href="https://mtml.s3.ca-central-1.amazonaws.com/modules/Creating+a+Username+and+Password/lessons/LAMP-Create+Username+_+Password_SL.pdf"
                  target="_blank"
                  rel={"noreferrer"}
                  className="w-lg btn btn-outline-primary btn-sm"
                >
                  Download
                </a>
              </div>
              <Card style={{ minHeight: "25rem" }}>
                <TabContent
                  style={{ overflow: "hidden" }}
                  activeTab={this.state.activeTab}
                >
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={1}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <Button
                        style={{ width: "12rem", backgroundColor: "#5b235c" }}
                        className="mini-lesson-btn"
                        color="primary"
                        size="lg"
                        outline
                        onClick={this.handleStartLesson}
                      >
                        Start Lesson
                      </Button>
                    </div>
                  </TabPane>

                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={2}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <Row>
                          <Col md={12}>
                            <div style={{ minWidth: "20rem" }} className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                What is your first name?
                              </Label>
                              <Input
                                id="firstName"
                                name="firstName"
                                type="text"
                                className={
                                  firstSubmitted && !this.state.firstName
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Enter Your First Name"
                                value={this.state.firstName}
                                onChange={this.handleChange}
                              />
                              {firstSubmitted && !this.state.firstName && (
                                <div className="invalid-feedback-custom">
                                  First Name is required.
                                </div>
                              )}
                            </div>
                            <div className="mb-3">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={3}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        {/* <p
                          style={{ marginBottom: 0 }}
                          className="mini-lesson-p"
                        >
                          First we need some information from you.
                        </p>
                        <p className="mini-lesson-p2">
                          Please type your answer into the boxes below.
                        </p> */}
                        <Row>
                          <Col md={12}>
                            <div style={{ minWidth: "20rem" }} className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                What is your last name?
                              </Label>
                              <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                className={
                                  lastSubmitted && !this.state.lastName
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Enter Your Last Name"
                                value={this.state.lastName}
                                onChange={this.handleChange}
                              />
                              {lastSubmitted && !this.state.lastName && (
                                <div className="invalid-feedback-custom">
                                  Last Name is required.
                                </div>
                              )}
                            </div>

                            <div className="mb-3">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={4}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <Row>
                          <Col md={12}>
                            <div style={{ minWidth: "20rem" }} className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                What are your initials?
                              </Label>
                              <Input
                                id="initial"
                                name="initial"
                                type="text"
                                className={
                                  initialSubmitted && !this.state.initial
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Enter your initials (example: AB)"
                                value={this.state.initial}
                                onChange={this.handleChange}
                              />
                              {initialSubmitted && !this.state.initial && (
                                <div className="invalid-feedback-custom">
                                  Initials are required.
                                </div>
                              )}
                            </div>
                            <div className="mb-3">
                              <Button
                                style={{
                                  width: "fit-content",
                                  // marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={5}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <p className="mini-lesson-p">
                          Here are the suggestions for creating a good Username.
                        </p>

                        <div className="d-flex flex-column w-100">
                          <p className="font-size-14 mb-1">
                            <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                            includes first name, last name, and/or initials
                          </p>
                          <p className="font-size-14 mb-1">
                            <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                            Includes names and initials.
                          </p>
                          <p className="font-size-14 mb-1">
                            <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                            Includes numbers
                          </p>
                          <p className="font-size-14 mb-1">
                            <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                            Includes periods to separate letters or words.
                          </p>
                          <p className="font-size-14 mb-1">
                            <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                            <strong>Does not</strong> include a long string of
                            letters or numbers that will be hard to remember.
                          </p>
                          <p className="font-size-14 mb-1">
                            <i className="fas fa-check-circle me-2 font-size-14 text-success"></i>{" "}
                            <strong>Does not</strong> include birth date
                          </p>
                        </div>

                        <Button
                          style={{
                            width: "5rem",
                            marginLeft: "1rem",
                            marginTop: "1rem",
                          }}
                          className="mini-lesson-btn"
                          color="primary"
                          size="lg"
                          outline
                          onClick={() => {
                            this.toggleTab(this.state.activeTab + 1);
                          }}
                        >
                          <span>OK</span>
                          <i
                            style={{ marginLeft: "0.5rem" }}
                            className="fa fa-check"
                          />
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={6}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <p className="mini-lesson-p">
                          Try to create a good username
                        </p>

                        <Row className="mt-4">
                          <Col md={5}>
                            <Input
                              id="username"
                              name="username"
                              className="mb-4"
                              type="text"
                              style={{ fontSize: "1.2rem" }}
                              value={this.state.username}
                              onChange={this.handleChange}
                            />
                            {isValidUsername && (
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                <span>OK</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            )}
                          </Col>
                          <Col md={7}>
                            <div className="d-flex flex-column w-100">
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isIncludeFirstOrLastName
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                includes first name, last name, and/or initials
                              </p>
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isIncludeNumber
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                Includes numbers.
                              </p>
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isIncludePeriod
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                Includes periods to separate letters or words.
                              </p>
                              <p className="font-size-14 mb-1">
                                <i
                                  className={
                                    "me-2 font-size-14  " +
                                    (this.state.isNotIncludeLongString
                                      ? "fas fa-check-circle text-success"
                                      : "fas fa-times-circle text-danger")
                                  }
                                ></i>{" "}
                                <strong>Does not</strong> include a long string
                                of letters or numbers that will be hard to
                                remember.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    className="animate__animated animate__slideInUp"
                    tabId={7}
                  >
                    <div
                      style={{ height: "25rem" }}
                      className="center-container"
                    >
                      <div className="d-flex justify-content-center flex-column">
                        <div className="row justify-content-center">
                          {isValidUsername ? (
                            <Col lg="12">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>
                                    Congratulation! you have successfully
                                    created a username!
                                  </h5>
                                  <p className="text-muted">
                                    If you would like to try and create a new
                                    username click on the Restart Lesson button
                                    to start over.
                                  </p>
                                </div>
                                <Button
                                  style={{
                                    width: "fit-content",
                                    marginTop: "1rem",
                                  }}
                                  className="mini-lesson-btn"
                                  color="primary"
                                  size="lg"
                                  outline
                                  onClick={() => {
                                    this.toggleTab(1);
                                  }}
                                >
                                  <span>Restart Lesson</span>
                                  <i
                                    style={{ marginLeft: "0.5rem" }}
                                    className="fa fa-check"
                                  />
                                </Button>
                              </div>
                            </Col>
                          ) : (
                            <Col lg="12">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-close-circle-outline text-danger display-4" />
                                </div>
                                <div>
                                  <h5>
                                    Sorry! you have unsuccessfully created a
                                    username!
                                  </h5>
                                  <p className="text-muted">
                                    If you would like to try and create a new
                                    username click on the ReRestart Lesson
                                    button to start over.
                                  </p>
                                </div>
                                <Button
                                  style={{
                                    width: "fit-content",
                                    marginTop: "1rem",
                                  }}
                                  className="mini-lesson-btn"
                                  color="primary"
                                  size="lg"
                                  outline
                                  onClick={() => {
                                    this.toggleTab(1);
                                  }}
                                >
                                  <span>Restart Lesson</span>
                                  <i
                                    style={{ marginLeft: "0.5rem" }}
                                    className="fa fa-check"
                                  />
                                </Button>
                              </div>
                            </Col>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>

                <div className="actions clearfix">
                  {this.state.activeTab !== 1 && (
                    <div
                      style={{ bottom: "0", position: "absolute", right: "0" }}
                      className="d-flex justify-content-end"
                    >
                      <Button
                        style={{ width: "3rem", marginRight: "1px" }}
                        className="mini-lesson-nav-up"
                        color="primary"
                        size="lg"
                        outline
                        onClick={() => {
                          this.toggleTab(this.state.activeTab - 1);
                        }}
                      >
                        <i className="fa fa-chevron-up"></i>
                      </Button>
                      <Button
                        style={{ width: "3rem" }}
                        className="mini-lesson-nav-down"
                        color="primary"
                        size="lg"
                        outline
                        onClick={() => {
                          this.toggleTab(this.state.activeTab + 1);
                        }}
                      >
                        <i className="fa fa-chevron-down"></i>
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default CreatingUsername;
