import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import moment from "moment";
import BidOfferCard from "./BidOfferCard";

export class ServiceRequestRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      bids: null,
      loading: false,
    };
  }
  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { onConfirmAccept, onConfirmBookNow } = this.props;
    const { make, bids, model, year, createdDate } = this.props.item;
    const { isOpen } = this.state;
    return (
      <Card className="mb-2">
        <CardHeader
          onClick={this.toggleBody}
          className="bg-transparent border-bottom"
          style={{ cursor: "pointer" }}
        >
          <Row>
            <Col md={4}>
              <h5 className="font-size-14 mb-1">
                {year} {make} {model}
              </h5>
              <p className="text-muted mb-0">
                {" "}
                {moment(createdDate).format("DD-MMM-YYYY")}
              </p>
            </Col>
            <Col md={4}></Col>
            <Col md={4} className="text-end">
              <Button outline color="primary mx-4">
                Bids <span className="badge bg-danger ms-1">{bids.length}</span>
              </Button>
            </Col>
          </Row>
          <div style={{ position: "absolute", top: "12px", right: "12px" }}>
            <span className="font-size-24">
              <i
                className={isOpen ? "fas fa-angle-up" : "fas fa-angle-down"}
              ></i>
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            {bids &&
              bids.map((bid, index) => {
                return (
                  <BidOfferCard
                    key={index}
                    bid={bid}
                    onConfirmAccept={onConfirmAccept}
                    onConfirmBookNow={onConfirmBookNow}
                  />
                );
              })}
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

ServiceRequestRow.propTypes = {
  item: PropTypes.object,
  onConfirmAccept: PropTypes.func,
  onConfirmBookNow: PropTypes.func,
};

export default ServiceRequestRow;
