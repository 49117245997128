import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Badge,
} from "reactstrap";
import { sumBy } from "lodash";

export class BidViewDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isOpen, toggle, title, item } = this.props;
    const { bidServices, preferedContactMethod, note } = item;
    let contactMethods = preferedContactMethod
      ? preferedContactMethod.split("|")
      : [];
    let notes = note ? note.split("|") : [];
    let totalPrice = sumBy(
      bidServices.filter(e => e.isAcceptService),
      a => {
        return a.price;
      }
    );

    return (
      <Modal
        size="xl"
        backdrop="static"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={4}>
              <Card className="border">
                <CardHeader className="border-bottom d-flex">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ width: "60px" }}
                  >
                    <span style={{ fontSize: "35px" }}>
                      <i className="fas fa-cog text-danger"></i>
                    </span>
                  </div>
                  <div>
                    <CardTitle>
                      {item.firstName} {item.lastName}
                    </CardTitle>
                    <h5 className="font-size-14 mb-2">{item.phone}</h5>
                    <h5 className="font-size-14 mb-2">{item.email}</h5>
                    <div>
                      {contactMethods.map((contactMethod, index) => {
                        return (
                          <span key={index}>
                            <Badge
                              color="primary"
                              className="px-2 py-1 btn-xs btn-outline"
                            >
                              {contactMethod}
                            </Badge>{" "}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </CardHeader>
                <CardHeader className="bg-transparent border">
                  <h5 className="font-size-14 mb-2">
                    {item.vehicleYear} {item.vehicleMake} {item.vehicleModel}
                  </h5>

                  <div>
                    <Badge
                      color={"danger"}
                      className="px-2 py-1 btn-xs btn-outline"
                    >
                      <span>{item.isOnRimsn ? "On Rims" : "Off Rims"} </span>
                    </Badge>{" "}
                    {item.needStorage && (
                      <Badge
                        color={"danger"}
                        className="px-2 py-1 btn-xs btn-outline"
                      >
                        <span>Needs Storage </span>
                      </Badge>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <p>What is your preferred time for service?</p>
                  <p className="fw-bolder">{item.preferredDayTime}</p>

                  <p>Can you wait up to 1 hour for the service?</p>
                  <p className="fw-bolder">
                    {item.canWaitOneHourForServicing ? <>Yes</> : <>No</>}
                  </p>

                  <p>Do you require shuttle service?</p>
                  <p className="fw-bolder">
                    {item.requireShuttle ? <>Yes</> : <>No</>}
                  </p>

                  <p>How long can you wait for?</p>
                  <p className="fw-bolder">{item.maxTimeWithoutCar}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="border">
                <CardHeader>
                  <CardTitle className="text-center">
                    Bid Offer Service
                  </CardTitle>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table align-middle mb-0 table">
                    <tbody>
                      {bidServices &&
                        bidServices
                          .filter(e => e.isAcceptService)
                          .map((bidService, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <h5 className="font-size-14 mb-0">
                                    {bidService.name}
                                  </h5>
                                </td>
                                <td>
                                  <p className="mb-0 text-end">
                                    ${Number(bidService.price).toFixed(2)}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                      <tr>
                        <td>
                          <h5 className="font-size-14 fw-bold mb-1">TOTAL</h5>
                        </td>
                        <td>
                          <p className="mb-0 fw-bold text-end">
                            ${Number(totalPrice).toFixed(2)}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="border">
                <CardHeader>
                  <CardTitle className="text-center">Benefits</CardTitle>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table align-middle mb-0 table">
                    <tbody>
                      {notes.map((note, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className="mb-0">{note}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button outline data-dismiss="modal" color="primary" onClick={toggle}>
            Ok
          </Button>
        </div>
      </Modal>
    );
  }
}

BidViewDetailsModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.object,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  onSignup: PropTypes.func,
  error: PropTypes.string,
};

export default BidViewDetailsModal;
