import AccessibilitySection from "containers/accessibility-settings/AccessibilitySection";
import Information from "containers/sidebar/Information";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { accessibilitySettings } from "common/data/accessibilitySettings";
import MoreModule from "containers/sidebar/MoreModule";

export class AccessibilitySetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: "",
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile } = match.params;
    this.setState({
      profile,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container fluid>
            <Row>
              <Col md={8}>
                <h2>Accessibility Settings</h2>
                {accessibilitySettings.map((item, index) => {
                  return <AccessibilitySection key={index} item={item} />;
                })}
              </Col>
              <Col md={4}>
                <Information profile={this.state.profile} />
                <MoreModule profile={this.state.profile} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AccessibilitySetting.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default AccessibilitySetting;
