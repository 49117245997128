import {
  SUBMIT_SERVICE_REQUEST_QUESTION,
  SUBMIT_SERVICE_REQUEST_QUESTION_SUCCESS,
  SUBMIT_SERVICE_REQUEST_QUESTION_ERROR,
} from "./actionTypes";

// Role
export const submitServiceRequestQuestion = question => ({
  type: SUBMIT_SERVICE_REQUEST_QUESTION,
  payload: question,
});

export const submitServiceRequestQuestionSuccess = question => ({
  type: SUBMIT_SERVICE_REQUEST_QUESTION_SUCCESS,
  payload: question,
});

export const submitServiceRequestQuestionError = error => ({
  type: SUBMIT_SERVICE_REQUEST_QUESTION_ERROR,
  payload: error,
});
