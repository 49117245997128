import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import info0 from "../../assets/images/info-0.png";
import info1 from "../../assets/images/info-1.png";
import info2 from "../../assets/images/info-2.png";
import info3 from "../../assets/images/info-3.png";
import info5 from "../../assets/images/info-5.jpg";

const spInformation = [
  {
    id: 1,
    icon: info0,
    title: "Set yourself up for success",
    text: "Learn how to manage stress and frustration to be a better learner",
  },
  {
    id: 2,
    icon: info1,
    link: "/service-provider-guide",
    title: "Service Provider's Guide",
    text: "Learn how to pilot these module with clients",
  },
  {
    id: 3,
    icon: info1,
    title: "Accessibility Settings",
    link: "/accessibility-setting",
    text: "Find out how to make your phone easier to use",
  },
  {
    id: 4,
    icon: info2,
    title: "Feedback Form",
    link: "/feedback-form",
    text: "Your feedback matters to us. Please fill out the form after using any materials (video, tip sheet, lessons)",
  },
  {
    id: 5,
    icon: info3,
    title: "Need Help?",
    link: "/contact-us",
    text: "Contact us for help or for more information",
  },
  {
    id: 6,
    icon: info5,
    title: "Download Modules",
    link: "/download-modules",
    text: "Download Modules",
  },
];

const learnerInformation = [
  {
    id: 1,
    icon: info0,
    title: "Set yourself up for success",
    text: "Learn how to manage stress and frustration to be a better learner",
  },
  {
    id: 2,
    icon: info1,
    title: "Accessibility Settings",
    link: "/accessibility-setting",
    text: "Find out how to make your phone easier to use",
  },
  {
    id: 3,
    icon: info2,
    title: "Feedback Form",
    link: "/feedback-form",
    text: "Your feedback matters to us. Please fill out the form after using any materials (video, tip sheet, lessons)",
  },
  {
    id: 4,
    icon: info3,
    title: "Need Help?",
    link: "/contact-us",
    text: "Contact us for help or for more information",
  },
  {
    id: 5,
    icon: info5,
    title: "Download Modules",
    link: "/download-modules",
    text: "Download Modules",
  },
];

export class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapse: false,
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isCollapse: !prevState.isCollapse,
    }));
  };

  handleClickLink = item => {
    let url = "/";
    const { profile } = this.props;
    const { title } = item;
    if (title === "Accessibility Settings") {
      url = `/${profile}/accessibility-setting`;
    } else if (title === "Feedback Form") {
      url = `/${profile}/feedback-form`;
    } else if (title === "Need Help?") {
      url = `/${profile}/contact-us`;
    } else if (title === "Service Provider's Guide") {
      url = `/${profile}/service-provider-guide`;
    } else if (title === "Set yourself up for success") {
      url = `/${profile}/set-yourself-up-for-success`;
    } else if (title === "Download Modules") {
      url = `/${profile}/download-modules`;
    }
    window.location = url;
  };

  render() {
    const { profile } = this.props;
    let items =
      profile === "service-providers" ? spInformation : learnerInformation;
    return (
      <Card>
        <CardHeader className="bg-transparent border">
          <CardTitle style={{ fontSize: "18px" }}>Information</CardTitle>
        </CardHeader>
        <CardBody>
          {items.map((item, index) => {
            return (
              <div className="d-flex mb-4" key={index}>
                <div className="flex-shrink-0 me-3">
                  <img
                    className="rounded avatar-sm"
                    src={item.icon}
                    alt={item.title}
                  />
                </div>
                <div className="flex-grow-1">
                  <a href={item.link} target="_blank" rel="noreferrer"></a>
                  <Link to="#" onClick={() => this.handleClickLink(item)}>
                    <CardTitle>{item.title}</CardTitle>
                  </Link>
                  <p className="mb-0">{item.text}</p>
                </div>
              </div>
            );
          })}
        </CardBody>
      </Card>
    );
  }
}

Information.propTypes = {
  toggleCollapse: PropTypes.func,
  isCollapse: PropTypes.bool,
  profile: PropTypes.string,
  onClick: PropTypes.func,
};

export default Information;
