import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SelectionBox from "components/Common/SelectionBox";

const LocationSafety = ({ item }) => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setActiveTab(2);
  };

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>{item.name}</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href={item.link}
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            {/* <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div
                    style={{ height: "25rem" }}
                    className="center-container content-text"
                  >
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col md={12}>
                        {
                          <div className="mb-5">
                            <Button
                              style={{
                                width: "fit-content",
                                // marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <span>Next Activity</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        }
                      </Col>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div
                    style={{ minHeight: "35rem" }}
                    className="center-container"
                  >
                    <div className="d-flex justify-content-center flex-column content-text">
                      <p>
                        <strong>
                          Locate Important Information about the YouTube Video
                        </strong>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "35rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column content-text">
                      <Col lg="12">
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <p>
                              <strong>
                                Congratulation! you have successfully finished
                                the lesson!
                              </strong>
                            </p>

                            <p className="text-muted">
                              If you would like to try and create a new click on
                              the Start Lesson again button to start over.
                            </p>
                          </div>
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <span>Restart Lesson</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card> */}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

LocationSafety.propTypes = {
  item: PropTypes.object,
};

export default LocationSafety;
