import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  VERIFY_CODE,
} from "./actionTypes";
import { apiError } from "./actions";
import { get, post, del } from "../../../helpers/api_helper";

import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postSocialLogin } from "../../../helpers/fakebackend_helper";

const verifyCodeRequest = values =>
  get(`/api/Account/verifyCode`, { params: values });

function* verifyCode({ payload: { values, history } }) {
  try {
    console.log(values);
    yield call(verifyCodeRequest, values);
    history.push("/login");
  } catch (err) {
    const errMsg = err.response?.data?.message ?? "Something was wrong";
    // yield put(apiError(errMsg));
  }
}

function* authSaga() {
  yield takeEvery(VERIFY_CODE, verifyCode);
}

export default authSaga;
