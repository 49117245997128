import React, { Component } from "react";
import { Badge } from "reactstrap";
import jwt from "jsonwebtoken";
import { role } from "constants/common";

export const decodeJwt = token => {
  if (!token) {
    console.log("Cannot decode empty token.");
    return null;
  }
  const decoded = jwt.decode(token);
  // decoded.uniqueId = decoded["uniqueId"];
  // decoded.firstName = decoded["firstName"];
  // decoded.lastName = decoded["lastName"];
  // decoded.email = decoded["email"];
  return decoded;
};

export const getMaxValue = values => {
  return Math.max.apply(Math, values);
};

export const timestampFileName = () => {
  var today = new Date();
  const fileName = `${today.getFullYear()}${
    today.getMonth() + 1
  }${today.getDate()}${today.getHours()}${today.getMinutes()}`;

  return fileName;
};

export const handleDateChange = (obj, date, name) => {
  obj.setState({ [name]: date });
};

export const displayPaymentStatus = order => {
  const { paymentStatus, paymentStatusName } = order;
  let color = "info";

  switch (paymentStatus) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "info";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
  }

  return (
    <Badge color={color}>
      <span>{paymentStatusName}</span>
    </Badge>
  );
};

export const displayDeliveryStatus = order => {
  const { deliveryStatus, deliveryStatusName } = order;
  let color = "info";

  switch (deliveryStatus) {
    case 0:
      color = "primary";
      break;
    case 5:
      color = "success";
      break;
    case 6:
      color = "danger";
      break;
    case 7:
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{deliveryStatusName}</span>
    </Badge>
  );
};

export const displayBidStatus = serviceRequest => {
  const { status, statusName } = serviceRequest;
  let color = "info";

  switch (status) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "info";
      break;
    case 2:
      color = "danger";
      break;
    case 3:
      color = "success";
      break;
    case 4:
      color = "dark";
      break;

    default:
      color = "info";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displaySerivceRequestStatus = serviceRequest => {
  const { status, statusName } = serviceRequest;
  let color = "info";

  switch (status) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "secondary";
      break;
    case 2:
      color = "info";
      break;
    case 3:
      color = "dark";
      break;
    case 4:
      color = "success";
      break;
    default:
      color = "info";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayPickupPaymentStatus = order => {
  const { pickupPaymentStatus, pickupPaymentStatusName } = order;
  let color = "info";

  switch (pickupPaymentStatus) {
    case 0:
      color = "danger";
      break;
    case 1:
      color = "success";
      break;
  }

  return (
    <Badge color={color}>
      <span>{pickupPaymentStatusName}</span>
    </Badge>
  );
};

export const displayDeliveryPaymentStatus = order => {
  const { deliveryPaymentStatus, deliveryPaymentStatusName } = order;
  let color = "info";

  switch (deliveryPaymentStatus) {
    case 0:
      color = "danger";
      break;
    case 1:
      color = "success";
      break;
  }

  return (
    <Badge color={color}>
      <span>{deliveryPaymentStatusName}</span>
    </Badge>
  );
};

export const getUserProfile = () => {
  if (localStorage.getItem("token")) {
    const obj = JSON.parse(localStorage.getItem("token"));
    return decodeJwt(obj);
  }
};

const isVendor = () => {
  let userProfile = getProfile();
  if (userProfile) {
    const { roleNames } = userProfile;
    return roleNames.some(r => r === role.VENDOR);
  }
  return false;
};

const isVerifiedVendor = () => {
  let userProfile = getProfile();
  if (userProfile) {
    const { roleNames } = userProfile;
    return roleNames.some(r => r === role.VENDOR) && userProfile.isVerified;
  }
  return false;
};

const isCustomer = () => {
  let userProfile = getProfile();
  if (userProfile) {
    const { roleNames } = userProfile;
    return roleNames.some(r => r === role.CUSTOMER);
  }
  return false;
};

const isAdministrator = () => {
  let userProfile = getProfile();
  if (userProfile) {
    const { roleNames } = userProfile;
    return roleNames.some(r => r === role.ADMINISTRATOR);
  }
  return false;
};

const getProfile = () => {
  if (localStorage.getItem("userProfile")) {
    return JSON.parse(localStorage.getItem("userProfile"));
  }
};

const bidStatusOptions = () => {
  return [
    {
      key: 1,
      value: 3,
      label: "Accepted",
    },
    {
      key: 2,
      value: 2,
      label: "Declined",
    },
  ];
};

export {
  isVendor,
  isVerifiedVendor,
  isAdministrator,
  isCustomer,
  getProfile,
  bidStatusOptions,
};
