import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Button,
} from "reactstrap";
import { displaySerivceRequestStatus } from "helpers/utils";
import moment from "moment";

class ServiceRequestMobileItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { onBidDetail } = this.props;
    const { id, status, year, make, model } = this.props.item;
    let vehicle = `${year} ${make} ${model}`;
    return (
      <Card
        style={{
          margin: "20px 0",
          border: "1px solid lightgrey",
          backgroundColor: "white",
        }}
      >
        <CardHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          <Badge color={"secondary"} className="px-2 py-2 btn-xs btn-outline">
            <span>
              <span>Request {id}</span>
            </span>
          </Badge>
          <div className="">
            <p className="font-size-14 text-muted mb-0">
              <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
              {moment(this.props.item.createdDate).format("DD-MMM-YYYY")}
            </p>
          </div>
          {displaySerivceRequestStatus(this.props.item)}
        </CardHeader>
        <CardBody style={{ backgroundColor: "white" }}>
          <div style={{ justifyContent: "center" }} className="d-flex">
            <i
              style={{
                fontSize: "25px",
                marginTop: "-10px",
                marginRight: "5px",
              }}
              className="mdi mdi-steering"
            ></i>
            <div className="">
              <h5 className="text-truncate font-size-15">{vehicle}</h5>
            </div>
          </div>

          <div
            style={{
              justifyContent: "center",
              backgroundColor: "#eaeff3",
              border: "1px solid lightgrey",
            }}
            className="d-flex"
          >
            <div className="text-muted mt-4 mb-4">
              <p>
                <i className="fas fa-cog me-1"></i>
                <strong>Services</strong>
              </p>
              {this.props.item.services &&
                this.props.item.services.map((a, index) => {
                  return (
                    <p style={{ marginBottom: "0" }} key={index}>
                      <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                      {a.name}
                    </p>
                  );
                })}
            </div>
          </div>
        </CardBody>
        <CardFooter
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Button
            size="lg"
            style={{ width: "100%" }}
            color="primary"
            onClick={() => onBidDetail(id)}
          >
            <i style={{ marginRight: "5px" }} className="bx bxs-megaphone" />
            <span>{"Bid"}</span>
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

ServiceRequestMobileItem.propTypes = {
  item: PropTypes.object,
  onBidDetail: PropTypes.func,
};

export default ServiceRequestMobileItem;
