import React from "react";
// Layouts
import VerticalLayout from "components/VerticalLayout";
import HorizontalLayout from "components/HorizontalLayout";
import NonAuthLayout from "components/NonAuthLayout";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPwd from "../pages/Authentication/ResetPassword";
import Confirm from "pages/Authentication/Confirm";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//HOME
import ServiceRequestList from "pages/ServiceRequest/ServiceRequestList";
import BidDefail from "pages/BidOffer/BidDefail";
import BidOffer from "pages/BidOffer/BidOffer";
import Vendors from "containers/users/vendors/Vendors";
import Profile from "pages/Profile";
import SearchModule from "pages/SearchModule";
import ModuleDetail from "pages/ModuleDetail";
import AccessibilitySetting from "pages/AccessibilitySetting";
import ServiceProviderGuide from "pages/ServiceProviderGuide";
import FeedbackForm from "pages/FeedbackForm";
import ContactUs from "pages/ContactUs";
import SetYourselfUpForSuccess from "pages/SetYourselfUpForSuccess";
import DownloadModules from "pages/DownloadModules";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard, layout: VerticalLayout },

  //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout, layout: NonAuthLayout },
  { path: "/login", component: Login, layout: NonAuthLayout },
  { path: "/forgot-password", component: ForgetPwd, layout: NonAuthLayout },
  { path: "/reset-password", component: ResetPwd, layout: NonAuthLayout },

  { path: "/register/:type", component: Register, layout: NonAuthLayout },
  { path: "/register", component: Register, layout: NonAuthLayout },
  { path: "/confirm", component: Confirm, layout: NonAuthLayout },
  { path: "/dashboard", component: Dashboard, layout: VerticalLayout },
  { path: "/vendors", component: Vendors, layout: VerticalLayout },
  {
    path: "/service-request",
    component: ServiceRequestList,
    layout: VerticalLayout,
  },
  {
    path: "/bid-offer",
    component: BidOffer,
    layout: VerticalLayout,
  },
  {
    path: "/bid-offer-detail/:id",
    component: BidDefail,
    layout: VerticalLayout,
  },
  {
    path: "/:profile/accessibility-setting",
    component: AccessibilitySetting,
    layout: HorizontalLayout,
  },
  {
    path: "/profile",
    component: Profile,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/service-provider-guide",
    component: ServiceProviderGuide,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/feedback-form",
    component: FeedbackForm,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/set-yourself-up-for-success",
    component: SetYourselfUpForSuccess,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/contact-us",
    component: ContactUs,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/download-modules",
    component: DownloadModules,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/search",
    component: SearchModule,
    layout: HorizontalLayout,
  },
  {
    path: "/:profile/module/:id",
    component: ModuleDetail,
    layout: HorizontalLayout,
  },
  { path: "/", component: Profile, layout: HorizontalLayout },
];

export { authProtectedRoutes, publicRoutes };
