import {
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUEST_BY_ID_SUCCESS,
  SERVICE_REQUEST_CLEAR_ERROR,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const ServiceRequest = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SERVICE_REQUESTS:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_REQUESTS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_SERVICE_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case SERVICE_REQUEST_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case GET_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default ServiceRequest;
