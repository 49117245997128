import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { IconEdit } from "components/Common/IconEdit";

const LearningAboutPopularApps = () => {
  const [isExpanding, setIsExpanding] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isCorrect, setIsCorrect] = useState(null);

  const [app1, setApp1] = useState("");
  const [app2, setApp2] = useState("");
  const [app3, setApp3] = useState("");
  const [app4, setApp4] = useState("");
  const [app5, setApp5] = useState("");

  const [lastSubmitted, setLastSubmitted] = useState(null);

  const toggleIsExpanding = () => {
    setIsExpanding(!isExpanding);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab);
      }
    }
  };

  const handleStartLesson = () => {
    setApp1("");
    setApp2("");
    setApp3("");
    setApp4("");
    setApp5("");
    setLastSubmitted(null);
    setActiveTab(2);
  };

  const allAppsAreCorrect =
    app1?.toLocaleLowerCase() === "whatsapp" &&
    app2?.toLocaleLowerCase() === "discord" &&
    app3?.toLocaleLowerCase() === "snapchat" &&
    app4?.toLocaleLowerCase() === "telegram" &&
    app5?.toLocaleLowerCase() === "signal";

  return (
    <div className="accordion bg-white mb-3" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header bg-primary" id="headingOne">
          <button
            className={
              "accordion-button fw-medium" + (isExpanding ? "" : " collapsed")
            }
            type="button"
            onClick={toggleIsExpanding}
            style={{ cursor: "pointer" }}
          >
            <CardTitle>How to Learn About Popular Apps</CardTitle>
          </button>
        </h2>
        <Collapse isOpen={isExpanding} className="accordion-collapse">
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <a
                href="https://mtml.s3.ca-central-1.amazonaws.com/modules/Learning+about+Popular+Apps/lessons/Jan+22_YMCW_+Learning+About+Apps+Mini+Lesson.pdf"
                target="_blank"
                rel={"noreferrer"}
                className="w-lg btn btn-outline-primary btn-sm"
              >
                Download
              </a>
            </div>
            <Card style={{ minHeight: "25rem" }}>
              <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={1}
                >
                  <div style={{ height: "65rem" }} className="center-container">
                    <Button
                      style={{ width: "12rem" }}
                      className="mini-lesson-btn"
                      color="primary"
                      size="lg"
                      // outline
                      onClick={handleStartLesson}
                    >
                      Start Lesson
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={2}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p">Reading Storage Info</p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">App Icons</p>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">
                            1. Find and open your App Store (if you have an
                            iPhone) or Google Play Store (if you have an Android
                            phone).{" "}
                          </p>
                        </Col>
                        <Col md={12}>
                          <img
                            style={{ maxWidth: "350px" }}
                            width="100%"
                            src="https://mtml.s3.ca-central-1.amazonaws.com/assets/stores.png"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <p className="mini-lesson-p">
                            2. Use the search bar at the top of the screen to
                            search for each of the five apps below. Identify the
                            icon for each app.
                          </p>

                          <div style={{ marginLeft: "2rem" }}>
                            <ul>
                              <li>
                                <p
                                  style={{ marginBottom: "0" }}
                                  className="mini-lesson-p"
                                >
                                  WhatsApp
                                </p>
                              </li>
                              <li>
                                <p
                                  style={{ marginBottom: "0" }}
                                  className="mini-lesson-p"
                                >
                                  Signal
                                </p>
                              </li>
                              <li>
                                <p
                                  style={{ marginBottom: "0" }}
                                  className="mini-lesson-p"
                                >
                                  Telegram
                                </p>
                              </li>
                              <li>
                                <p
                                  style={{ marginBottom: "0" }}
                                  className="mini-lesson-p"
                                >
                                  Snapchat
                                </p>
                              </li>
                              <li>
                                <p
                                  style={{ marginBottom: "0" }}
                                  className="mini-lesson-p"
                                >
                                  Discord
                                </p>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col md={12}>
                          <p
                            style={{ marginBottom: "0" }}
                            className="mini-lesson-p"
                          >
                            3. Which icon below represents which app (from the
                            list in step 2)?
                          </p>
                          <p
                            style={{ marginLeft: "2rem" }}
                            className="mini-lesson-p"
                          >
                            Write your answer in the line below the app icon.
                          </p>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <div className="d-flex">
                          <IconEdit
                            imageSrc="https://mtml.s3.ca-central-1.amazonaws.com/assets/whatsapp-icon.png"
                            width="100px"
                            onChange={val => setApp1(val)}
                            value={app1}
                            appName="whatsapp"
                          />
                          <IconEdit
                            imageSrc="https://mtml.s3.ca-central-1.amazonaws.com/assets/discord-icon.png"
                            width="100px"
                            onChange={val => setApp2(val)}
                            value={app2}
                            appName="discord"
                          />
                          <IconEdit
                            imageSrc="https://mtml.s3.ca-central-1.amazonaws.com/assets/snapchat-icon.png"
                            width="100px"
                            onChange={val => setApp3(val)}
                            value={app3}
                            appName="snapchat"
                          />
                          <IconEdit
                            imageSrc="https://mtml.s3.ca-central-1.amazonaws.com/assets/telegram-icon.png"
                            width="100px"
                            onChange={val => setApp4(val)}
                            value={app4}
                            appName="telegram"
                          />
                          <IconEdit
                            imageSrc="https://mtml.s3.ca-central-1.amazonaws.com/assets/signal-icon.png"
                            width="100px"
                            onChange={val => setApp5(val)}
                            value={app5}
                            appName="signal"
                          />
                        </div>
                      </Row>
                      {allAppsAreCorrect && (
                        <Row>
                          <div className="text-center">
                            <div className="mb-4"></div>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                {" "}
                                <i
                                  style={{
                                    fontSize: "2rem",
                                    marginBottom: "-10px",
                                  }}
                                  className="mdi mdi-check-circle-outline text-success display-4"
                                />
                              </div>
                              <h5
                                style={{ marginTop: "10px", marginLeft: "5px" }}
                              >
                                {`Nicely done! You've successfully identified each app!`}
                              </h5>
                            </div>
                            <Button
                              style={{
                                width: "fit-content",
                                marginTop: "1rem",
                              }}
                              className="mini-lesson-btn"
                              color="primary"
                              size="lg"
                              outline
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <span>OK</span>
                              <i
                                style={{ marginLeft: "0.5rem" }}
                                className="fa fa-check"
                              />
                            </Button>
                          </div>
                        </Row>
                      )}
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={3}
                >
                  <div style={{ height: "65rem" }} className="">
                    <div className="d-flex justify-content-center flex-column">
                      {/* <p className="mini-lesson-p">Reading Storage Info</p> */}
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <p className="mini-lesson-p">Search Words</p>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "" }}>
                        <Col md={12}>
                          <ol>
                            <li>
                              <p>
                                <span>{`Imagine you are searching for the apps listed below in`}</span>{" "}
                                <span style={{ color: "red" }}>red</span>
                                <span>{`. What search
words would you use? Fill in the blanks below. (Use the words “Best” or
“Most popular” to fill in the first blank in each.)`}</span>
                              </p>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                        <Col md={12}>
                          <h5 style={{ color: "red" }}>Calendar App</h5>
                          <div className="d-flex" style={{ maxWidth: "800px" }}>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted"></div>
                            </div>
                            <h5
                              style={{ marginTop: "10px" }}
                              className="text-muted"
                            >
                              +
                            </h5>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted">Type of App</div>
                            </div>
                            <h5
                              style={{ marginTop: "10px" }}
                              className="text-muted"
                            >
                              +
                            </h5>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted">{`Type of Phone (Android/iPhone)`}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                        <Col md={12}>
                          <h5 style={{ color: "red" }}>Communication App</h5>
                          <div className="d-flex" style={{ maxWidth: "800px" }}>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted"></div>
                            </div>
                            <h5
                              style={{ marginTop: "10px" }}
                              className="text-muted"
                            >
                              +
                            </h5>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted">Type of App</div>
                            </div>
                            <h5
                              style={{ marginTop: "10px" }}
                              className="text-muted"
                            >
                              +
                            </h5>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted">{`Type of Phone (Android/iPhone)`}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                        <Col md={12}>
                          <h5 style={{ color: "red" }}>Budgeting App</h5>
                          <div className="d-flex" style={{ maxWidth: "800px" }}>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted"></div>
                            </div>
                            <h5
                              style={{ marginTop: "10px" }}
                              className="text-muted"
                            >
                              +
                            </h5>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted">Type of App</div>
                            </div>
                            <h5
                              style={{ marginTop: "10px" }}
                              className="text-muted"
                            >
                              +
                            </h5>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <Input></Input>
                              <div className="text-muted">{`Type of Phone (Android/iPhone)`}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="2">
                            <li>
                              <p>
                                <span>{`Now, imagine you are searching for a free app that allows you to find local
transit directions on a map. `}</span>
                              </p>
                              <p>{`Which type of words from the list below do you think might be important to
include in your search:
`}</p>
                            </li>
                          </ol>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                        <Col md={12}>
                          <ul start="2">
                            <li>
                              <p className="mini-lesson-p m-0">Free</p>
                            </li>
                            <li>
                              <p className="mini-lesson-p m-0">
                                Name of your location (Ex: Toronto)
                              </p>
                            </li>
                            <li>
                              <p className="mini-lesson-p m-0">
                                Current Year (Ex: 2022)
                              </p>
                            </li>
                            <li>
                              <p className="mini-lesson-p m-0">Type of App</p>
                            </li>
                            <li>
                              <p className="mini-lesson-p m-0">Type of Phone</p>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="3">
                            <li>
                              <p>{`Using the words you selected above, write your search words below:`}</p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <div
                            style={{ marginLeft: "2rem", maxWidth: "800px" }}
                          >
                            {" "}
                            <Input></Input>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col md={12}>
                          <ol start="4">
                            <li>
                              <p>{`Try using Google to search for the words you wrote above. Write the
name of an app you found in the search results:
`}</p>
                            </li>
                          </ol>
                        </Col>
                        <Col md={12}>
                          <div
                            style={{ marginLeft: "2rem", maxWidth: "800px" }}
                          >
                            {" "}
                            <Input></Input>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="text-center">
                          <Button
                            style={{
                              width: "fit-content",
                              marginTop: "1rem",
                            }}
                            className="mini-lesson-btn"
                            color="primary"
                            size="lg"
                            outline
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <span>OK</span>
                            <i
                              style={{ marginLeft: "0.5rem" }}
                              className="fa fa-check"
                            />
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  className="animate__animated animate__slideInUp"
                  tabId={4}
                >
                  <div style={{ height: "55rem" }} className="center-container">
                    <div className="d-flex justify-content-center flex-column">
                      <div className="row justify-content-center">
                        {
                          <Col lg="12">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>
                                  Congratulation! you have successfully finished
                                  the lesson!
                                </h5>
                                <p className="text-muted">
                                  If you would like to try again click on the
                                  Restart Lesson button to start over.
                                </p>
                              </div>
                              <Button
                                style={{
                                  width: "fit-content",
                                  marginTop: "1rem",
                                }}
                                className="mini-lesson-btn"
                                color="primary"
                                size="lg"
                                outline
                                onClick={() => {
                                  toggleTab(1);
                                }}
                              >
                                <span>Restart Lesson</span>
                                <i
                                  style={{ marginLeft: "0.5rem" }}
                                  className="fa fa-check"
                                />
                              </Button>
                            </div>
                          </Col>
                        }
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              <div className="actions clearfix">
                {activeTab !== 1 && (
                  <div
                    style={{ bottom: "0", position: "absolute", right: "0" }}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      style={{ width: "3rem", marginRight: "1px" }}
                      className="mini-lesson-nav-up"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </Button>
                    <Button
                      style={{ width: "3rem" }}
                      className="mini-lesson-nav-down"
                      color="primary"
                      size="lg"
                      outline
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                    >
                      <i className="fa fa-chevron-down"></i>
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default LearningAboutPopularApps;
