import React, { useCallback } from "react";
import { SayButton } from "react-say";
import PropTypes from "prop-types";

const Speak = ({ text }) => {
  const selector = useCallback(voices => {
    console.log(voices);
    return [...voices].find(
      v => v.lang === "en-GB" && v.name === "Google UK English Female"
    );
  }, []);

  const selector2 = useCallback(voices => voices, []);
  console.log(selector2);
  return (
    <div className="d-inline-block say-button-container">
      <SayButton
        className="saysay"
        // onClick={}
        speak={text}
        voice={selector}
      >
        <i className="say fa fa-volume-up" />
      </SayButton>
    </div>
  );
};

Speak.propTypes = {
  text: PropTypes.string,
};

export { Speak };
