import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Modal,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";

import { Link } from "react-router-dom";
import { sumBy } from "lodash";
import { compareBidServicesRequest } from "store/bid/saga";

export class ConfirmBidModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      averageServices: null,
      lowestServices: null,
    };
  }

  onOpened = () => {
    const { item } = this.props;
    const { serviceRequestId, bidServices } = item;

    compareBidServicesRequest(serviceRequestId, bidServices).then(data => {
      const { averageServices } = data;
      this.setState({ averageServices });
    });
  };

  render() {
    const { averageServices } = this.state;
    const { isOpen, toggle, onSubmit, title, item } = this.props;
    const { bidServices } = item;
    let totalBidPrice = sumBy(bidServices, a => {
      return a.price;
    });
    return (
      <Modal
        size="md"
        backdrop="static"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row className="justify-content-center pb-4">
            <Col md={4}>
              <h3>Your Total Bid: ${Number(totalBidPrice).toFixed(2)}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className="border">
                <CardHeader>
                  <CardTitle className="text-center">Your Bids</CardTitle>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table align-middle mb-0 table">
                    <tbody>
                      {bidServices.map((bidService, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <h5 className="font-size-14 mb-0">
                                {bidService.name}
                              </h5>
                            </td>
                            <td>
                              <p className="mb-0 text-end">
                                ${Number(bidService.price).toFixed(2)}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md={6}>
              <Card className="border">
                <CardHeader>
                  <CardTitle className="text-center">Avg Vendor Bids</CardTitle>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table align-middle mb-0 table">
                    <tbody>
                      {averageServices &&
                        averageServices.map((bidService, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <h5 className="font-size-14 mb-0">
                                  {bidService.name}
                                </h5>
                              </td>
                              <td>
                                <p className="mb-0 text-end">
                                  ${Number(bidService.price).toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row className="justify-content-center">
            <Col md={12}>
              <div>
                <p className="text-center">
                  Would you like to{" "}
                  <Link onClick={toggle} className="fst-italic" to={"#/"}>
                    Edit Your Bid
                  </Link>{" "}
                  or
                </p>
              </div>
              <div className="text-center">
                <Button color="primary" onClick={onSubmit}>
                  Send Bid
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

ConfirmBidModal.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfirmBidModal;
