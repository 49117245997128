import Information from "containers/sidebar/Information";
import MoreModule from "containers/sidebar/MoreModule";
import React, { Component } from "react";
import FileSaver from "file-saver";
import fileDownload from "js-file-download";
import PropTypes from "prop-types";
import Config from "../../src/config";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import DownloadModuleCard from "containers/download-modules/DownloadModuleCard";
import axios from "axios";
import { modules } from "common/data/modules";
import { forEach } from "lodash";

export class DownloadModules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: "",
      items: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profile } = match.params;
    this.setState({
      profile,
      items: modules.map(item => {
        return {
          ...item,
          checked: false,
          checkedVideo: false,
          checkedTipSheet: false,
          checkedMiniLession: false,
        };
      }),
    });
  };

  handleCheckAll = e => {
    const { checked } = e.target;
    this.setState(prevState => ({
      items: prevState.items.map(item => {
        return {
          ...item,
          checked,
          checkedVideo: checked,
          checkedTipSheet: checked,
          checkedMiniLession: checked,
        };
      }),
    }));
  };

  handleOnCheckChange = item => {
    this.setState(prevState => ({
      items: prevState.items.map(a => {
        return a.id === item.id ? item : a;
      }),
    }));
  };

  handleDownload = () => {
    const { items } = this.state;

    const fileKeys = [];

    items.forEach(element => {
      const { videos, tipSheets, lessons } = element;

      if (element.checkedVideo) {
        if (videos) {
          videos.forEach(video => {
            fileKeys.push(video.key);
          });
        }
      }
      if ([15, 16, 7, 18].includes(element.id)) {
        fileKeys.push(element.videoKey);
      }

      if (element.checkedTipSheet) {
        tipSheets.forEach(tipSheet => {
          fileKeys.push(tipSheet.key);
        });
      }

      if (element.checkedMiniLession) {
        lessons.forEach(lesson => {
          fileKeys.push(lesson.key);
        });
      }
    });

    console.log("======>", fileKeys);

    this.setState({ loading: true });
    axios({
      url: `${Config.apiServerHost}/api/module/download`,
      method: "POST",
      data: { fileKeys },
      responseType: "blob", // Important
    })
      .then(response => {
        this.setState({ loading: false });
        console.log(response);
        fileDownload(response.data, "files.zip");
      })
      .catch(err => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  render() {
    const { items } = this.state;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container fluid>
            <Row>
              <Col md={8}>
                <div className="mt-5 mb-5 text-center">
                  <h1 className="mb-4">Download Modules</h1>
                </div>
                <Card className="mb-1">
                  <CardHeader className="bg-transparent">
                    <div className="d-flex justify-content-end">
                      <Button
                        style={{ minWidth: "75px" }}
                        size="sm"
                        disabled={this.state.loading}
                        color="primary"
                        outline
                        className="me-2"
                        onClick={this.handleDownload}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-secondary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span>Download</span>
                        )}
                      </Button>
                      <div className="form-check form-check-primary pt-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckcolor1"
                          checked={this.state.freeAirFresher}
                          onChange={e => this.handleCheckAll(e)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckcolor1"
                        >
                          Select All
                        </label>
                      </div>
                      {/* <Input
                        type="checkbox"
                        onChange={e => this.handleCheckAll(e)}
                        title="Select all"
                      ></Input> */}
                    </div>
                  </CardHeader>
                </Card>
                {items.map((module, index) => {
                  return (
                    <DownloadModuleCard
                      key={index}
                      module={module}
                      onCheckChange={this.handleOnCheckChange}
                    />
                  );
                })}
              </Col>

              <Col md={4}>
                <Information profile={this.state.profile} />
                <MoreModule profile={this.state.profile} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

DownloadModules.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default DownloadModules;
